import * as yup from "yup"
const compositeNameRegex = /\w{2,}\s\w{2,}/

const generalDataSchema = yup.object().shape({
  abortEarly: false,
  corporateName: yup
    .string()
    .max(90, "Campo limitado a 90 caracteres")
    .matches(compositeNameRegex, {
      message: "A razão social deve ser composto",
      excludeEmptyString: true,
    })
    .required("Razão social é um campo requerido"),
  tradeName: yup.string().max(40, "Campo limitado a 40 caracteres").required("Razão social é um campo requerido"),
  email: yup.string().email("É preciso que seja um e-mail valido").required("E-mail é um campo requerido"),
  creditProcessingDays: yup
    .number("")
    .typeError("Dias para crédito é um campo requerido")
    .min(1, "1 dia é o valor mínimo")
    .max(15, "15 dias é o valor máximo")
    .required("Dias para crédito é um campo requerido"),
  site: yup.string().url("É preciso informar um site valido"),
})

export default generalDataSchema
