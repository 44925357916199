import { Icon } from "presentation/components";
import styled from "styled-components";

export const TitlePanel = styled.p`
  color: var(--color-primary-04);
  font-weight: 600;
  font-size: 2rem;
`;

export const IconArrows = styled(Icon)`
  width: 128px;
  stroke: var(--color-primary-01);
  height: 128px;
  cursor: pointer;
  margin: 32px;

  :active{
    transform: scale(0.9);
  }

  :last-of-type{
    transform: scaleX(-1);
    :active{
      transform: scale(0.9) scaleX(-1);
    }
  }
`;

export const Section = styled.div`
  margin-block-start: 15px;
  inline-size: 70%;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .arrows{
    display: flex;
    flex-direction: column;
  }
  .react-tabs__tab{
    transition: all 0.4s;
  }
  
  .react-tabs__tab--selected{
    background-color: var(--color-primary-01);
    color: white;
  }
  .disabled {
    opacity: 0.4;
    cursor: default;
    transition: none;
  }
`;
export const PanelContainer = styled.div`

  padding: 32px;
  height: 630px;
  max-width: 100%;
  padding-bottom: 5px;

  div[loading="auto"]{
    max-height: 420px;
  }
`;


export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--color-primary-03);
  background-color: var(--color-primary-05);
  position: sticky;
  top: 0px;
  > span {
    font-weight: var(--font-weight-semi-bold);
   
  }
  .check {
    color: var(--color-primary-01);
  }
  .check-box { height: 0; margin-right: 8px }
`;

