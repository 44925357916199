import { StyledTr } from './styles';

const Tr = ({ children, interactable, ...props }) => {
  return (
    <StyledTr interactable={interactable} {...props}>
      {children}
    </StyledTr>
  );
};

export default Tr;
