import { Button } from "@stationkim/components-lib";
import { useFieldArray, useForm } from "react-hook-form";
import { StepDescription, StepTitle } from "../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { CardsFields } from "./cardsFields";
import { Grid, Spinner } from "presentation/components";
import useService from "main/hooks/useService";
import {
  getResalesAvailableOperatorsCards,
  postRegisterResaleOperatorsCards,
  putEditResaleOperatorsCards
} from "@/services/resalesServices";
import { useEffect, useRef } from "react";

const defaultFieldValues = {
  operatorId: '',
  operatorCardId: '',
  processType: '',
  user: '',
  password: '',
}
export const BindCards = ({
  resaleData,
  setResaleData,
  onCancel,
  goToNextStep,
  isEditing,
  isSkipingSave
}) => {
  const uploadedData = useRef({});
  const [
    fetchOperatorsCardsState,
    fetchOperatorsCardsRequest
  ] = useService(getResalesAvailableOperatorsCards);
  const operatorsCardsData = fetchOperatorsCardsState.response?.data;

  useEffect(() => {
    fetchOperatorsCardsRequest();
  }, []);

  const {
    control,
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch
  } = useForm({
    defaultValues: resaleData
  });

  isSkipingSave(isEditing && isDirty);

  const {
    fields,
    append,
    remove
  } = useFieldArray({
    control: control,
    name: 'operatorsData'
  });

  useEffect(() => {
    if (fields.length === 0) append(defaultFieldValues);
  }, []);


  const addOperatorAndCardName = () => {
    const bindedCardsWithNames = uploadedData.current.map(bindedCard => {
      const operator = operatorsCardsData.find(
        operators => operators.id === bindedCard.operatorId
      );
      const card = operator.operatorCards.find(card => card.id === bindedCard.operatorCardId)
      return {
        ...bindedCard,
        operatorName: operator.corporateName,
        operatorCardName: card.name,
      }
    });
    return bindedCardsWithNames;
  };

  const serviceToBeUsed = isEditing ?
    putEditResaleOperatorsCards : postRegisterResaleOperatorsCards;
  const [operatorCardsState, operatorsCardsRequest] =
    useService(serviceToBeUsed, {
      onCompleted: () => {
        setResaleData(state => ({
          ...state,
          operatorsData: addOperatorAndCardName()
        }))
        goToNextStep();
      }
    });

  const onSubmit = (data) => {
    if (isDirty) {
      const payload = data.operatorsData;
      delete payload.operatorId;
      uploadedData.current = payload;
      operatorsCardsRequest(resaleData.id, payload);
    } else if (!isDirty && isEditing) {
      goToNextStep();
    }
  };

  const renderAddressesFields = () => {
    const fieldsToRender = fields.map((field, index) => {
      const fieldBaseName = `operatorsData.${index}.`;
      const fieldErrors = errors?.operatorsData?.[index];
      return (
        <CardsFields
          operatorsCardsData={operatorsCardsData || []}
          index={index}
          key={field.id}
          fieldBaseName={fieldBaseName}
          fieldErrors={fieldErrors}
          control={control}
          register={register}
          remove={remove}
          watch={watch}
          setValue={setValue}
          isEditing={isEditing}
        />
      );
    });

    return fieldsToRender;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        fluid
        padding="0"
      >
        <Grid.Row>
          <Grid.Col sm="content">
            <StepTitle>Vincular Cartões</StepTitle>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col sm="content">
            <StepDescription>Vincule cartões para a Revenda</StepDescription>
          </Grid.Col>
        </Grid.Row>

        {operatorCardsState.loading ?
          <Spinner.Box >
            <Spinner style={{ margin: "32px auto" }} />
          </Spinner.Box>
          :
          <>
            {renderAddressesFields()}

            <Grid.Row>
              <Grid.Col>
                <Button buttonType="borderless" onClick={() => append(defaultFieldValues)}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                  Adicionar cartão
                </Button>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row justify="end">
              <Grid.Col sm="content">
                <Button buttonType="text"
                  onClick={onCancel}
                >Cancelar</Button>
              </Grid.Col>
              <Grid.Col sm="content">
                <Button>Continuar</Button>
              </Grid.Col>
            </Grid.Row>
          </>
        }
      </Grid>
    </form>
  );
};