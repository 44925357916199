import { useMutation, useQueryClient } from "@tanstack/react-query"
import { CompanyRatesDataTracking, IUseModificationTrackerReturn } from "./useModificationTracker"
import { listSeparator } from "./utils/listSeparator"
import api from "@/infra/api"
import { IUseResaleDataReturn } from "./useResale"
import { useNotifications } from "@/main/hooks"
import { IMessage } from "@/main/store"
import errorHandler from "@/main/utils/errorHandler"
import { useEffect } from "react"

const removePercentMaskIfHas = (value: string) => value.replaceAll(',', '.').replace('%', '')

type RateToSendToApi = {
  resaleId: string,
  companyId: string,
  days: number,
  rate: number,
  id?: string
}

interface UseUpdateTermConfigurationProps {
  values: IUseModificationTrackerReturn['modifications']
  resale: IUseResaleDataReturn['resaleData']
}

export interface IUseUpdateTermConfigurationReturn {
  isLoadingCompaniesChanges: boolean,
  isLoadingResaleChanges: boolean,
  isSucceed: boolean,
  saveChanges: () => void,
}

const rateUpdateConfig = (errorHandler) => ({
  mutationFn: ({ payload }: {
    payload: Array<RateToSendToApi>
  }) => api.put(`ResaleInterestRate/UpdateList`, payload),
  onError: errorHandler
})
const rateCreateConfig = (errorHandler) => ({
  mutationFn: ({ payload }: {
    payload: Array<RateToSendToApi>
  }) => api.post(`ResaleInterestRate/CreateList`, payload),
  onError: errorHandler
})

export const useUpdateTermConfiguration = ({
  values,
  resale
}: UseUpdateTermConfigurationProps): IUseUpdateTermConfigurationReturn => {
  const queryClient = useQueryClient()
  const notifications = useNotifications()

  const __handleReqError = ({ response }) => notifications.push(errorHandler(response) as IMessage[])

  // Map the rates to send only the data tha the api care about
  const __mapCompanyRatesToApi = ({ list, isUpdate }: {
    isUpdate?: boolean,
    list: Array<{
      resaleId?: string, companyId?: string, days?: number | string, rate?: string | number, id?: string
    }>
  }): RateToSendToApi[] => {
    return list.map((item) => {
      const apiRate = {
        resaleId: resale.id,
        companyId: item.companyId,
        days: Number(item.days),
        rate: Number(removePercentMaskIfHas(String(item.rate))),
      }
      return isUpdate ? { ...apiRate, id: item.id } : apiRate
    })
  }

  /*
  *************************************************
  RESALE TERM SALE TOGGLE PERMISSION
  *************************************************
  */
  const __saveResaleTogglePermission = useMutation({
    mutationFn: ({ payload }: {
      payload: {
        id: string;
        corporateName: string;
        tradeName: string;
        email: string;
        site: string;
        allowFinancedSales: boolean;
      }
    }) => api.put(`resales/edit/general-data`, payload),
    onError: __handleReqError
  })
  //TODO should review this call (maybe all this calls, we should use the BFF as a BFF)
  // Toggle the allowFinancedSales on resale general data info
  const updateResaleTermSalePermission = () => {
    if (typeof values.allowFinancedSales.value === 'boolean' && Boolean(resale))
      __saveResaleTogglePermission.mutate({
        payload: {
          allowFinancedSales: values.allowFinancedSales.value,
          id: resale.id,
          corporateName: resale.corporateName,
          email: resale.email,
          site: resale.site,
          tradeName: resale.tradeName
        },
      }, {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['resaleData'] })
      })
  }

  /*
  *************************************************
  RESALE TERM SALE DEFAULT RATES
  *************************************************
  */

  const __createResaleRates = useMutation({ ...rateCreateConfig(__handleReqError) })
  const __updateResaleRates = useMutation({ ...rateUpdateConfig(__handleReqError) })

  const updateResaleTermSaleDefaultRates = () => {
    const formValues = values.defaultFinancedRates.value
    if (!values.defaultFinancedRates.value) return

    const { toCreate, toUpdate } = listSeparator({
      list: formValues, groups: {
        toCreate: ({ item }) => !item.id,
        toUpdate: ({ item }) => Boolean(item.id),
      }
    })
    const createPayload = __mapCompanyRatesToApi({ list: toCreate })
    const updatePayload = __mapCompanyRatesToApi({ list: toUpdate, isUpdate: true })
    if (createPayload.length > 0)
      __createResaleRates.mutate({ payload: createPayload })

    if (updatePayload.length > 0)
      __updateResaleRates.mutate({ payload: updatePayload })
  }
  /*
 *************************************************
  RESALE COMPANY PERMISSION TOGGLE
 *************************************************
 */

  const __saveCompaniesTogglePermission = useMutation({
    mutationFn: ({ payload }: {
      payload: Array<{ allowFinancedSales: boolean, companyId: string }>
    }) => api.patch(`ConfigureFinancedSales`, payload),
    onError: __handleReqError
  })

  const updateResaleCompanyTermSalePermission = ({ values }: { values: CompanyRatesDataTracking[] }) => {
    if (!values) return
    const companiesToTogglePermission = values.filter(company => company.shouldSaveAllowFinancedSales)
    if (companiesToTogglePermission.length === 0) return
    const payload = companiesToTogglePermission.map(company => ({
      allowFinancedSales: company.allowFinancedSales, companyId: company.id
    }))
    __saveCompaniesTogglePermission.mutate({ payload })
  }

  /*
  *************************************************
   RATES UPDATE/CREATE
  *************************************************
  */

  const __createCompanyRates = useMutation(rateCreateConfig(__handleReqError))

  const __updateCompanyRates = useMutation(rateUpdateConfig(__handleReqError))

  const __deleteCompanyRates = useMutation({
    mutationFn: ({ payload }: {
      payload: string[]
    }) => api.delete(`ResaleInterestRate/DeleteList`, { data: payload }),
    onError: __handleReqError
  })

  // Get only the rates from the companies
  const __getAllRates = (companiesConfiguredRates: CompanyRatesDataTracking[]) => {
    if (!companiesConfiguredRates) return []
    return companiesConfiguredRates.reduce((companiesRates, companyData) => {
      const rateWithDefaultFlag = [7, 14, 21, 28].reduce((finalRates, days) => {
        const coreRate = companyData.companyInterestRates.find((rate) => rate.days === days)
        const formRateDayValue = companyData[`${days}days`]
        if (!coreRate && !formRateDayValue) return finalRates
        const baseRate = {
          rate: companyData[`${days}days`],
          days: days,
          companyId: companyData.id,
          resaleId: resale.id,
          defaultInterestRate: companyData.defaultInterestRate,
          shouldBeCreated: !coreRate?.companyId,
          shouldBeDeleted: companyData.shouldDeleteRates,
          id: coreRate?.id,
        }
        if (coreRate) return [...finalRates, { ...coreRate, ...baseRate }]
        return [...finalRates, baseRate]
      }, [])
      return [...companiesRates, ...rateWithDefaultFlag]
    }, [])
  }


  // update all rates that have been modified in the companies table
  // if the rate does not exist, it will be created
  const updateAllCompaniesRates = ({ values }: { values: CompanyRatesDataTracking[] }) => {
    if (!values) return
    const allConfiguredRates = __getAllRates(values)
    const separatedGroups = listSeparator({
      list: allConfiguredRates, groups: {
        toUpdate: ({ item }) => !item.shouldBeCreated && !item.shouldBeDeleted,
        toCreate: ({ item }) => item.shouldBeCreated && !item.defaultInterestRate && !item.shouldBeDeleted,
        toDelete: ({ item }) => item.shouldBeDeleted && item.id && item.companyId,
      }
    })
    const createPayload = __mapCompanyRatesToApi({ list: separatedGroups.toCreate })
    const updatePayload = __mapCompanyRatesToApi({ list: separatedGroups.toUpdate, isUpdate: true })
    const deletePayload = separatedGroups.toDelete.map(rate => rate.id)
    if (createPayload.length > 0)
      __createCompanyRates.mutate({ payload: createPayload })
    if (updatePayload.length > 0)
      __updateCompanyRates.mutate({ payload: updatePayload })
    if (deletePayload.length > 0)
      __deleteCompanyRates.mutate({ payload: deletePayload },
      )
  }

  // Requisitions Loadings
  const isLoadingCompaniesChanges = [
    __saveCompaniesTogglePermission,
    __createCompanyRates,
    __deleteCompanyRates,
    __updateCompanyRates
  ].some(mutation => mutation.isPending)

  const isLoadingResaleChanges = [
    __saveResaleTogglePermission,
    __updateResaleRates,
    __createResaleRates,
  ].some(mutation => mutation.isPending)

  const arrayGroup = [
    __saveCompaniesTogglePermission,
    __createCompanyRates,
    __updateCompanyRates,
    __saveResaleTogglePermission,
    __deleteCompanyRates,
    __updateResaleRates,
  ]
  const isSucceed =
    //todo configure to handle errors
    arrayGroup.every(mutation => !mutation.isError) &&
    arrayGroup.every(mutation => !mutation.isPending) &&
    arrayGroup.some(mutation => mutation.isSuccess)


  useEffect(() => {
    const hasAnyError = arrayGroup.filter(mutation => mutation.isError)
    if (hasAnyError.length > 0) hasAnyError.forEach(mutation => mutation.reset())
    if (hasAnyError.length === 0 && isSucceed) {
      queryClient.invalidateQueries({ queryKey: ['companiesFinancedSales'] })
      queryClient.invalidateQueries({ queryKey: ['resaleDefaultRates'] })
    }
  }, [isSucceed])

  // Call all changes functions
  const saveChanges = () => {
    updateResaleCompanyTermSalePermission({ values: values.companiesConfiguredRates.value })
    updateAllCompaniesRates({ values: values.companiesConfiguredRates.value })
    updateResaleTermSalePermission()
    updateResaleTermSaleDefaultRates()
  }

  return {
    isLoadingCompaniesChanges,
    isLoadingResaleChanges,
    saveChanges,
    isSucceed
  }
}