import { all, takeLatest, call, put } from 'redux-saga/effects';
import api from '../../../infra/api';
import { finishOperatorInSuccess, finishOperatorFailure } from './actions';

function* getReviewOperators({ payload }) {
  const { id } = payload;

  try {
    const response = yield call(
      api.get,
      `/operators/${id}/registration/review`
    );

    const review = response.data;

    yield put(finishOperatorInSuccess(review));
  } catch (error) {
    yield put(finishOperatorFailure());
  }
}

export default all([
  takeLatest('@finishOperator/FINISH_IN_REQUEST', getReviewOperators),
]);
