import { Grid, Spinner } from "presentation/components";
import { rules } from "./rules/generalDataRules";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import { Button, Input, styled, Tooltip } from "@stationkim/components-lib";
import { Controller, useForm } from "react-hook-form";
import { LabelPlusToolTipWrapper, StepDescription, StepTitle } from "./styles";
import useService from "main/hooks/useService";
import { postRegisterResaleGeneralData, putEditResaleGeneralData } from "@/services/resalesServices";
import { useEffect, useRef } from "react";

export const GeneralData = ({
  resaleData,
  setResaleData,
  abortRegister,
  goToNextStep,
  isEditing,
  isSkipingSave
}) => {
  const uploadedData = useRef({});
  const {
    control,
    register,
    reset,
    formState: { errors, isDirty },
    handleSubmit
  } = useForm({
    defaultValues: resaleData
  });

  useEffect(() => {
    if (!resaleData) return;
    reset(resaleData)
  }, [resaleData]);

  isSkipingSave(isEditing && isDirty);

  const [registerGeneralDataState, registerGeneralDataRequest] =
    useService(postRegisterResaleGeneralData, {
      onCompleted: (response) => {
        const id = response.data
        setResaleData(state => ({
          ...state,
          ...uploadedData.current,
          id: id
        }));
        goToNextStep();
      }
    });

  const [editGeneralDataState, editGeneralDataRequest] =
    useService(putEditResaleGeneralData, {
      onCompleted: () => {
        setResaleData(state => ({
          ...state,
          ...uploadedData.current,
        }));
        goToNextStep()
      },
    });

  const isLoading =
    registerGeneralDataState.loading ||
    editGeneralDataState.loading;

  const FormError = ({ name }) => {
    return (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) =>
          <Input.Message>{message}</Input.Message>
        }
      />
    );
  };

  const onSubmit = (data) => {
    if (isDirty) {
      const {
        cnpj,
        corporateName,
        tradeName,
        email,
        stateRegistration,
        municipalRegistration,
        site } = data;

      const payload = {
        cnpj,
        corporateName,
        tradeName,
        email,
        stateRegistration,
        municipalRegistration,
        site
      };
      uploadedData.current = payload;
      isEditing ? editGeneralDataRequest({
        ...payload,
        id: data.id,
      }) :
        registerGeneralDataRequest(payload);
    } else if (!isDirty && isEditing) {
      goToNextStep()
    };
  };

  const Textfixes = styled('span', {
    color: '$gray400'
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        fluid
        padding="0"
      >
        <Grid.Row>
          <Grid.Col sm="content">
            <StepTitle>Dados Gerais da Revenda</StepTitle>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col sm="content">
            <StepDescription>Por favor, preencha as Informações da Revenda</StepDescription>
          </Grid.Col>
        </Grid.Row>

        {isLoading ?
          <Spinner.Box >
            <Spinner style={{ margin: "32px auto" }} />
          </Spinner.Box>
          :
          <>
            <Grid.Row align="start">
              <Grid.Col md={3.5}>
                <Controller
                  control={control}
                  name="cnpj"
                  render={({ field: { value } }) => (
                    <Input.Root
                      state={errors?.cnpj ? 'error' : null}
                      full
                      blocked
                    >
                      <Input.Label>
                        CNPJ
                      </Input.Label>
                      <Input.Field>
                        <Input.Masked
                          mask="00.000.000/0000-00"
                          placeholder="00.000.000/0000-00"
                          value={value}
                        />
                      </Input.Field>
                    </Input.Root>
                  )}
                />
              </Grid.Col>
              <Grid.Col md={3.5}>
                <Input.Root
                  state={errors?.tradeName ? 'error' : null}
                  full
                >
                  <Input.Label>
                    Nome Fantasia
                  </Input.Label>
                  <Input.Field>
                    <Input.Input
                      {...register('tradeName', rules.tradeName)}
                      placeholder="Nome Fantasia"
                      maxLength={40}
                    />
                  </Input.Field>
                  <FormError name="tradeName" />
                </Input.Root>
              </Grid.Col>
              <Grid.Col md={3.5}>
                <Input.Root
                  state={errors?.corporateName ? 'error' : null}
                  full
                >
                  <Input.Label>
                    Razão Social
                  </Input.Label>
                  <Input.Field>
                    <Input.Input
                      {...register('corporateName', rules.corporateName)}
                      placeholder="Razão Social"
                      maxLength={70}
                    />
                  </Input.Field>
                  <FormError name="corporateName" />
                </Input.Root>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row align="start">
              <Grid.Col md={3.5}>
                <Controller
                  control={control}
                  name="stateRegistration"
                  rules={rules.stateRegistration}
                  render={({ field: { value, onChange } }) => (
                    <Input.Root
                      state={errors?.stateRegistration ? 'error' : null}
                      full
                    >
                      <Input.Label>
                        Inscrição Estadual
                      </Input.Label>
                      <Input.Field>
                        <Input.Masked
                          mask={Number}
                          value={String(value)}
                          onChange={onChange}
                          maxLength={14}
                          placeholder="Inscrição Estadual"
                        />
                      </Input.Field>
                      <FormError name="stateRegistration" />
                    </Input.Root>

                  )} />
              </Grid.Col>
              <Grid.Col md={3.5}>
                <Input.Root
                  state={errors?.municipalRegistration ? 'error' : null}
                  full
                >
                  <Input.Label>
                    Inscrição Municipal
                  </Input.Label>
                  <Input.Field>
                    <Input.Input
                      {...register('municipalRegistration', rules.municipalRegistration)}
                      maxLength={11}
                      placeholder="Inscrição Municipal"
                    />
                  </Input.Field>
                  <FormError name="municipalRegistration" />
                </Input.Root>
              </Grid.Col>
              <Grid.Col md={3.5}>
                <Input.Root
                  state={errors?.email ? 'error' : null}
                  full
                >
                  <Input.Label>
                    E-mail
                  </Input.Label>
                  <Input.Field>
                    <Input.Input
                      {...register('email', rules.email)}
                      placeholder="E-mail da Revenda"
                    />
                  </Input.Field>
                  <FormError name="email" />
                </Input.Root>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col md={3.5}>
                <Controller
                  control={control}
                  name="site"
                  rules={rules.site}
                  render={({ field: { value, onChange } }) => (
                    <Input.Root
                      state={errors?.site ? 'error' : null}
                      full
                    >
                      <LabelPlusToolTipWrapper>
                        <Input.Label>
                          Site
                        </Input.Label>
                        <Tooltip.Root>
                          <Tooltip.Trigger>
                            <FontAwesomeIcon icon={faCircleExclamation} />
                          </Tooltip.Trigger>
                          <Tooltip.Content
                            side="bottom"
                            align="start"
                          >
                            Informe URL que será usada para o<br />
                            acesso dos RH&rsquo;s.
                          </Tooltip.Content>
                        </Tooltip.Root>
                      </LabelPlusToolTipWrapper>
                      <Input.Field style={{
                        gap: 0,
                        justifyContent: 'flex-start'
                      }}>
                        <Textfixes>https://</Textfixes>
                        <Input.Input
                          style={{
                            flex: 1,
                            maxWidth: (value?.length + 1) * 8 + "px",
                            minWidth: '64px',
                            fontWeight: 'var(--font-weight-bold)',
                            textTransform: 'lowercase',
                          }}
                          placeholder="revenda"
                          value={value}
                          onChange={e => onChange(String(e.target.value).replaceAll(/[^a-z0-9]/gi, ""))}
                        />
                        <Textfixes>.stationkim.com/</Textfixes>
                      </Input.Field>
                      <FormError name="site" />
                    </Input.Root>
                  )}
                />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row justify="end">
              <Grid.Col sm="content">
                <Button
                  buttonType="text"
                  onClick={e => {
                    e.preventDefault();
                    abortRegister();
                  }}
                >Cancelar</Button>
              </Grid.Col>
              <Grid.Col sm="content">
                <Button>Continuar</Button>
              </Grid.Col>
            </Grid.Row>
          </>
        }
      </Grid>
    </form>
  );
};