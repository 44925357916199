import { StatusContainer, StatusDot, StatusName } from './styles';

const ResaleStatus = ({ children, status }) => {
  const colors = [
    'var(--color-status-05)',
    'var(--color-status-01)',
    'var(--color-status-02)',
    'var(--color-status-03)',
  ];
  return (
    <StatusContainer>
      <StatusDot color={colors[status]} />
      <StatusName>{children}</StatusName>
    </StatusContainer>
  );
};

export default ResaleStatus;
