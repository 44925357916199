type GroupFunction<T> = ({ item }: { item: T }) => boolean;

interface ListSeparatorProps<T> {
  list: T[];
  groups: Record<string, GroupFunction<T>>;
  exclusive?: boolean
}

export const listSeparator = <T>({ list, groups, exclusive = true }: ListSeparatorProps<T>): Record<string, T[]> => {
  const groupsKeys = Object.keys(groups);
  const groupsLists: Record<string, T[]> = groupsKeys.reduce((final, actual) => {
    final[actual] = [];
    return final;
  }, {} as Record<string, T[]>);

  list.forEach((item) => {
    for (const key of groupsKeys) {
      if (groups[key]({ item })) {
        groupsLists[key].push(item);
        if (exclusive) break
      }
    }
  });

  return groupsLists;
}

