import { Dropzone, ErrorMessage, ErrorWrapper, Grid, Spinner } from "presentation/components";
import { Button, Label } from "@stationkim/components-lib";
import { Controller, useForm } from "react-hook-form";
import { StepDescription, StepSubtitle, StepTitle } from "./styles";
import Textarea from "presentation/components/input/textarea";
import { useEffect, useRef, useState } from "react";
import useService from "main/hooks/useService";
import { useDispatch } from "react-redux";
import { pushNotification } from "@/store/modules/notification/actions";
import { NOTIFICATION_TYPE } from "main/utils/constants";
import InputDate from "presentation/components/input/date";
import {
  postRegisterResaleDocuments,
  putEditResaleDocuments
} from "@/services/resalesServices";
import { rules } from "./rules/comercialDataRules";

export const ComercialData = ({
  resaleData,
  setResaleData,
  onCancel,
  goToNextStep,
  isEditing,
  isSkipingSave
}) => {
  const uploadedData = useRef({});
  const fileChangesCounter = useRef(0);

  const [uploadingFile, setUploadingFile] = useState([]);
  const [isFileRequiredError, setIsFileRequiredError] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const serviceToBeUsed = isEditing ?
    putEditResaleDocuments : postRegisterResaleDocuments;
  const [documentsState, documentsRequest] =
    useService(serviceToBeUsed, {
      onCompleted: () => {
        setResaleData(state => {
          const { File } = uploadedData.current;
          delete uploadedData.current.File;
          return ({
            ...state,
            document: {
              ...uploadedData.current,
              fileName: File.name,
            },
          })
        });
        goToNextStep();
      }
    });
  const {
    control,
    register,
    formState: { errors, isDirty },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: resaleData
  });

  isSkipingSave((isEditing && isDirty) || fileChangesCounter.current !== 0);


  const initialDate = watch('document.startOfValidity');
  const dispatch = useDispatch();

  function handleDrop(file, rejected) {
    if (rejected.length > 0) {
      setUploadError("Permitido somente arquivos .PDF")
    }
    if (file.length < 1) return;
    setUploadingFile(file[0]);
    setIsFileRequiredError(false);
    setUploadError(null);
    fileChangesCounter.current += 1;
  };

  const validateFile = () => {
    if (!uploadingFile) {
      dispatch(pushNotification({
        type: NOTIFICATION_TYPE.WARNING,
        content: "Para prosseguir envie o arquivo."
      }));
      return false;
    }
  };

  useEffect(() => {
    if (resaleData.document?.fileName) {
      const file = new File([""], resaleData.document?.fileName);
      setUploadingFile(file);
    };
  }, []);

  const onSubmit = (data) => {
    if (isDirty || fileChangesCounter.current !== 0) {
      if (!validateFile()) {
        const {
          startOfValidity,
          endOfValidity,
          notes
        } = data.document;

        const payload = new FormData();

        payload.append("File", uploadingFile);
        payload.append("startOfValidity",
          typeof startOfValidity === 'string'
            ? startOfValidity : startOfValidity.toISOString()
        );
        payload.append("endOfValidity",
          typeof endOfValidity === 'string'
            ? endOfValidity : endOfValidity.toISOString()
        );
        payload.append("notes", notes);
        uploadedData.current = Object.fromEntries(payload);
        documentsRequest(resaleData.id, payload);
      };
    } else if (!isDirty && isEditing) {
      goToNextStep();
    }
  };

  return (
    <form onSubmit={(e) => {
      setIsFileRequiredError(uploadingFile.length <= 0);
      handleSubmit(onSubmit)(e);
    }}>
      <Grid
        fluid
        padding="0"
      >
        <Grid.Row>
          <Grid.Col sm="content">
            <StepTitle>Contrato</StepTitle>
          </Grid.Col>
        </Grid.Row>
        {documentsState.loading ? <Spinner.Box><Spinner /></Spinner.Box> :
          <>
            <Grid.Row>
              <Grid.Col sm="content">
                <StepDescription>
                  Anexe uma cópia do contrato e
                  informe o período de vigência contratual
                </StepDescription>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col md={8}>
                <Dropzone.Line2
                  accept={{
                    'text/pdf': ['.pdf'],
                  }}
                  onDrop={handleDrop}
                  formatsLabel="pdf"
                  multiple={false}
                  maxSize={5000000}
                  uploadingFile={uploadingFile}
                  error={uploadError || isFileRequiredError}
                />
                {uploadError ?
                  <ErrorMessage>{uploadError}</ErrorMessage>
                  : null
                }
                {isFileRequiredError ?
                  <ErrorMessage>Campo obrigatório</ErrorMessage>
                  : null
                }
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm="content">
                <StepSubtitle>Período de vigência</StepSubtitle>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row align="start">
              <Grid.Col md={4}>
                <ErrorWrapper error={errors?.document?.startOfValidity}>
                  <Label style={{ display: 'block', marginBottom: '8px' }}>Início</Label>
                  <Controller
                    rules={rules.startOfValidity}
                    control={control}
                    name="document.startOfValidity"
                    render={({ field: { onChange, value, ref } }) => (
                      <InputDate
                        value={value ? new Date(value) : null}
                        onChange={onChange}
                        getInputRef={ref}
                      />
                    )}
                  />
                  {errors?.document?.startOfValidity && (
                    <ErrorMessage>{errors?.document?.startOfValidity.message}</ErrorMessage>
                  )}
                </ErrorWrapper>
              </Grid.Col>
              <Grid.Col md={4}>
                <ErrorWrapper error={errors?.document?.endOfValidity}>
                  <Label style={{ display: 'block', marginBottom: '8px' }}>Fim</Label>
                  <Controller
                    control={control}
                    name="document.endOfValidity"
                    rules={rules.endOfValidity}
                    render={({ field: { onChange, value, ref } }) => (
                      <InputDate
                        value={value ? new Date(value) : null}
                        onChange={onChange}
                        getInputRef={ref}
                        minDate={new Date(initialDate)}
                      />
                    )}
                  />
                  {errors?.document?.endOfValidity && (
                    <ErrorMessage>{errors?.document?.endOfValidity.message}</ErrorMessage>
                  )}
                </ErrorWrapper>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col sm="content">
                <StepSubtitle>Observações</StepSubtitle>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm="content">
                <StepDescription>Insira aqui observações ou cometários a respeito da Revenda</StepDescription>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col md={8}>
                <ErrorWrapper error={errors?.document?.notes}>
                  <Textarea
                    {...register("document.notes")}
                  />
                  {errors?.document?.notes && (
                    <ErrorMessage>{errors?.document?.notes.message}</ErrorMessage>
                  )}
                </ErrorWrapper>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row justify="end">
              <Grid.Col sm="content">
                <Button
                  buttonType="text"
                  onClick={onCancel}
                >
                  Cancelar
                </Button>
              </Grid.Col>
              <Grid.Col sm="content">
                <Button >
                  Continuar
                </Button>
              </Grid.Col>
            </Grid.Row>
          </>
        }
      </Grid>
    </form>
  );
};