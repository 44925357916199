import { useRef } from 'react'
import { useAtom } from 'jotai'
import { IMessage, NotificationsStore } from '@/main/store'

export const useNotifications = () => {
  const [notifications, setNotifications] = useAtom(NotificationsStore)
  const timeoutRef = useRef(null)

  const push = (message: IMessage | Array<IMessage>) => {
    let newNotifications = [...notifications]
    if (Array.isArray(message)) newNotifications = [...newNotifications, ...message]
    else newNotifications.push(message)
    setNotifications(newNotifications)
  }

  const removeOldest = () => {
    if (notifications.length === 0) return
    setNotifications((state) => {
      const newNotifications = [...state]
      newNotifications.shift()
      return newNotifications
    })
    clearTimeout(timeoutRef.current)
    timeoutRef.current = null
  }

  const removeOldestInSeconds = (seconds = 10) => {
    if (timeoutRef.current) return
    timeoutRef.current = setTimeout(() => {
      removeOldest()
    }, seconds * 1000)
  }

  return {
    messages: notifications,
    push,
    removeOldest,
    removeOldestInSeconds,
    total: notifications.length,
  }
}
