import {
  StyledBodyText,
  StyledFilterSubtitle,
  StyledH3,
  StyledHelpSubtitle,
  StyledLabel,
  StyledMenuText,
  StyledSection,
  StyledSubtitle,
  StyledSubtitleOne,
  StyledSubtitleThree,
  StyledSubtitleTwo,
  StyledTitle,
} from './styles';

const Text = StyledBodyText;

Text.Title = StyledTitle;
Text.Section = StyledSection;
Text.H3 = StyledH3;
Text.Subtitle = StyledSubtitle;
Text.FilterSubtitle = StyledFilterSubtitle;
Text.Menu = StyledMenuText;
Text.SubtitleOne = StyledSubtitleOne;
Text.SubtitleTwo = StyledSubtitleTwo;
Text.SubtitleThree = StyledSubtitleThree;
Text.Label = StyledLabel;
Text.HelpSubtitle = StyledHelpSubtitle;

export default Text;
