import { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Content, Panel } from 'presentation/components';
import Data from './steps/data';

const RegisterFees = () => {
  const [position] = useState(0);

  const steps = [{ name: 'DADOS', value: 0 }];

  return (
    <Content>
      <Panel>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={position} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel style={{ cursor: 'pointer' }}>
                  {label.name}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {position === 0 && <Data />}
      </Panel>
    </Content>
  );
};

export default RegisterFees;
