import { ErrorMessage, ErrorWrapper, Grid, Input, Modal, Spinner, Text } from "presentation/components";
import Button from "presentation/components/buttonV2";
import { Controller, useForm } from "react-hook-form";
import { Form, ModalLoadingWrapper, ModalTitle } from "./styles";
import generalDataSchema from "./schemas/generalDataSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import useService from "main/hooks/useService";
import { pushNotification } from "@/store/modules/notification/actions";
import successHandler from "main/utils/successHandler";
import { useDispatch } from "react-redux";
import { putEditOperatorGeneralData } from "@/services/operatorsServices";

const GeneralDataModal = ({ operator, onClose, open }) => {
  const dispatch = useDispatch();
  const defaultValues = operator;

  const [editGeneralDataState, editGeneralDataRequest] = useService(putEditOperatorGeneralData, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler("Dados gerais alterados!")));
      onClose(true);
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
    resolver: yupResolver(generalDataSchema),
  });

  const onSubmit = (data) => {
    if (!isDirty) {
      onClose();
      return;
    }
    const payload = {
      cnpj: data.cnpj,
      corporateName: data.corporateName,
      tradeName: data.tradeName,
      stateRegistration: data.stateRegistration,
      municipalRegistration: data.municipalRegistration,
      email: data.email,
      site: data.site,
      documentNotes: data.documentNotes,
      status: data.status,
      creditProcessingDays: data.creditProcessingDays,
    }
    editGeneralDataRequest(operator.id, payload);
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      showCloseIcon={false}
    >
      <ModalTitle>Editar Dados Gerais da Empresa</ModalTitle>
      <Form onSubmit={handleSubmit(onSubmit)} id="operator">
        <Grid fluid padding="0" spaceBetweenRows="32px">
          {editGeneralDataState.loading ?
            <Grid.Row>
              <Grid.Col>
                <ModalLoadingWrapper>
                  <Spinner />
                </ModalLoadingWrapper>
              </Grid.Col>
            </Grid.Row>
            :
            <>
              <Grid.Row>
                <Grid.Col xs={12}>
                  <Text.Label>CNPJ</Text.Label>
                  <Controller
                    control={control}
                    name="cnpj"
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Masked
                        placeholder="00.000.000/0000-00"
                        mask={"*"}
                        format="##.###.###/####-##"
                        value={value}
                        getInputRef={ref}
                        disabled
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col xs={12}>
                  <ErrorWrapper error={errors.email}>
                    <Text.Label>E-mail</Text.Label>
                    <Input
                      control={control}
                      {...register("email")}
                    />
                    {errors.email && (
                      <ErrorMessage>{errors.email.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.corporateName}>
                    <Text.Label>Razão Social</Text.Label>
                    <Input
                      placeholder="Razão social"
                      maxLength="90"
                      {...register("corporateName")}
                    />
                    {errors.corporateName && (
                      <ErrorMessage>{errors.corporateName.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.tradeName}>
                    <Text.Label>Nome Fantasia</Text.Label>
                    <Input
                      placeholder="Nome Fantasia"
                      maxLength="40"
                      {...register("tradeName")}
                    />
                    {errors.tradeName && (
                      <ErrorMessage>{errors.tradeName.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={6}>
                  <Text.Label>Inscrição Municipal</Text.Label>
                  <Input
                    maxLength="11"
                    placeholder="00000000000"
                    name="municipalRegistration"
                    disabled
                    {...register("municipalRegistration")}
                  />
                </Grid.Col>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.stateRegistration}>
                    <Text.Label>Inscrição Estadual</Text.Label>
                    <Input
                      maxLength="14"
                      placeholder="000000000"
                      name="stateRegistration"
                      disabled
                      {...register("stateRegistration")}
                    />
                    {errors.stateRegistration && (
                      <ErrorMessage>{errors.stateRegistration.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={6}>
                  <Text.Label>Código da empresa</Text.Label>
                  <Input
                    disabled
                    {...register("code")}
                  />
                </Grid.Col>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.site}>
                    <Text.Label>Site</Text.Label>
                    <Input
                      {...register("site")}
                      placeholder="https://"
                    />
                    {errors.site && (
                      <ErrorMessage>{errors.site.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row justify="between">
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.creditProcessingDays}>
                    <Text.Label>Dias para crédito</Text.Label>
                    <Controller
                      control={control}
                      name='creditProcessingDays'
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Input
                            placeholder="00"
                            type='number'
                            value={value}
                            onChange={(e) => {
                              const newValue = Number(e.target.value)
                              if (newValue >= 0 && newValue < 16)
                                onChange(newValue)
                            }}
                          />
                        )
                      }}
                    />
                    {errors.creditProcessingDays && (
                      <ErrorMessage>{errors.creditProcessingDays.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row justify="between">
                <Grid.Col sm="content">
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      onClose();
                    }}
                    variant="text"
                  >Cancelar</Button>
                </Grid.Col>
                <Grid.Col sm="content">
                  <Button>Salvar</Button>
                </Grid.Col>
              </Grid.Row>
            </>
          }
        </Grid>
      </Form>
    </Modal >
  );
}

export default GeneralDataModal;