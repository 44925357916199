export const IconChange = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.2344 10.7891C17.7422 10.9453 18.0156 11.4531 17.8594 11.9609C16.7656 15.6719 13.3281 18.25 9.46094 18.25C6.375 18.25 3.52344 16.5703 1.96094 13.9531V17.9375C1.96094 18.4844 1.57031 18.875 1.02344 18.875C0.515625 18.875 0.0859375 18.4844 0.0859375 17.9375V11.6875C0.0859375 11.1797 0.515625 10.75 1.02344 10.75H7.27344C7.82031 10.75 8.21094 11.1797 8.21094 11.6875C8.21094 12.2344 7.78125 12.625 7.27344 12.625H3.32812C4.5 14.8906 6.84375 16.375 9.46094 16.375C12.5078 16.375 15.2422 14.3438 16.0625 11.4141C16.2188 10.9062 16.7266 10.6328 17.2344 10.7891ZM17.9375 0.125C18.4453 0.125 18.875 0.554688 18.875 1.0625V7.3125C18.875 7.85938 18.4453 8.25 17.9375 8.25H11.6875C11.1406 8.25 10.75 7.85938 10.75 7.3125C10.75 6.80469 11.1406 6.375 11.6875 6.375H15.5938C14.4219 4.14844 12.0781 2.625 9.5 2.625C6.45312 2.625 3.71875 4.69531 2.85938 7.58594C2.74219 8.09375 2.23438 8.36719 1.72656 8.21094C1.21875 8.09375 0.945312 7.54688 1.0625 7.07812C2.15625 3.36719 5.63281 0.75 9.5 0.75C12.5859 0.75 15.4375 2.46875 17 5.08594V1.0625C17 0.554688 17.3906 0.125 17.9375 0.125Z"
        fill="#1565C0"
      />
    </svg>
  )
}
