import { useScreenClass } from 'react-grid-system';
import { CustomCol } from './styles';

const Col = ({
  children,
  marginsFor = [],
  margin = '16px 0 0 0',
  ...props
}) => {
  const screenClass = useScreenClass();

  return (
    <CustomCol
      margin={marginsFor.includes(screenClass) ? margin : 'initial'}
      {...props}
    >
      {children}
    </CustomCol>
  );
};

export default Col;
