import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// eslint-disable-next-line
export default (reducers) => {
  return persistReducer(
    {
      key: 'root-admin',
      storage,
      whitelist: ['auth', 'user'],
    },
    reducers
  );
};
