import {
  Content,
  ErrorMessage,
  ErrorWrapper,
  Grid,
  Input,
  Panel,
  Spinner,
  Text
} from "presentation/components";
import Button from "presentation/components/buttonV2";
import { InfoModal } from "presentation/compositions";
import useService from "main/hooks/useService";
import routesPaths from "presentation/routes/routesPaths";
import history from "@/services/history";
import { getCardsForSelect, postCreateNewCardFee } from "@/services/operatorsServices";
import { pushNotification } from "@/store/modules/notification/actions";
import { BENEFIT_TYPES, OPERATOR_CARD_FEE_FREQUENCY } from "main/utils/constants";
import { benefitTypes } from "main/utils/options";
import successHandler from "main/utils/successHandler";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BsExclamationCircle } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { useDispatch } from "react-redux";
import schema from "./schema";
import { CheckBoxWrapper, Form, StepsBall, StepWrapper } from "./styles";

const RegisterOperatorCardFee = ({ location }) => {
  const dispatch = useDispatch();
  const { operator } = location.state;
  const [isCardSelectVisible, setIsCardSelectVisible] = useState();
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);
  const [cards, setCards] = useState([]);

  const [fetchCardsState, fetchCardsRequest] = useService(getCardsForSelect, {
    onCompleted: (response) => {
      setCards(response.data);
    },
    silent: true
  });
  const [createCardFeeState, createCardFeeRequest] = useService(postCreateNewCardFee, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler("Tarifa criada com sucesso!")));
      history.push({
        pathname: routesPaths.operators.visualize,
        state: { operatorId: operator.id }
      });
    },
  });

  useEffect(() => {
    if (!operator) history.goBack();
    else fetchCardsRequest(operator.id);
    // eslint-disable-next-line
  }, []);

  const {
    control,
    formState: { errors, isDirty },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      operatorId: `${operator?.tradeName} - ${operator?.code}`
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (!isDirty) return;
    const payload = {
      ...data,
      operatorId: operator.id,
    };
    createCardFeeRequest(payload);
  };

  return (
    <>
      <Content>
        <Panel noPadding>
          <Grid padding="32px 16px 16px 16px">
            <Grid.Row>
              <Grid.Col>
                <StepWrapper>
                  <Text.SubtitleTwo>DADOS</Text.SubtitleTwo>
                  <StepsBall>
                    <FaCheck />
                  </StepsBall>
                </StepWrapper>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Text.Title>Dados da Tarifa</Text.Title>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Text>Por favor, informe os dados</Text>
              </Grid.Col>
            </Grid.Row>
          </Grid>
          <Form onSubmit={handleSubmit(onSubmit)} id="newCardFeeForm">
            <Grid spaceBetweenRows="32px">
              {createCardFeeState.loading ? <Spinner.Box><Spinner /></Spinner.Box> :
                <>
                  <Grid.Row  >
                    <Grid.Col xs={12} sm={6} md={3}>
                      <Text.Label>Operadora</Text.Label>
                      <Input
                        disabled
                        {...register("operatorId")}
                      />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={3} marginsFor={['xs']}>
                      <ErrorWrapper error={errors.benefit}>
                        <Text.Label>Tipo de Benefício</Text.Label>
                        <Controller
                          control={control}
                          name="benefit"
                          render={({ field: { value, onChange, ref } }) =>
                            <Input.Select
                              inputRef={ref}
                              value={benefitTypes.find(option => option.value === value)}
                              placeholder="Selecione..."
                              onChange={(e) => {
                                onChange(e.value);
                                setIsCardSelectVisible(e.value === BENEFIT_TYPES.TRANSPORT);
                              }}
                              isClearable={false}
                              options={benefitTypes}
                            />
                          }
                        />
                        {errors.benefit &&
                          <ErrorMessage>{errors.benefit.message}</ErrorMessage>
                        }
                      </ErrorWrapper>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={3} marginsFor={['xs', 'sm']}>
                      <ErrorWrapper error={errors.description}>
                        <Text.Label>Descrição</Text.Label>
                        <Input
                          maxLength={40}
                          {...register("description")}
                        />
                        {errors.description &&
                          <ErrorMessage>{errors.description.message}</ErrorMessage>
                        }
                      </ErrorWrapper>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col xs={12} sm={6} md={3}>
                      <ErrorWrapper error={errors.value}>
                        <Text.Label>Valor</Text.Label>
                        <Controller
                          control={control}
                          name="value"
                          render={({ field: { value, onChange, ref } }) =>
                            <Input.Decimal
                              allowNegative={false}
                              value={value >= 0 && value < 1000 ? value : 0}
                              prefix="R$ "
                              onValueChange={e => onChange(e.floatValue)}
                              isAllowed={(values) => {
                                const { floatValue } = values;
                                return !floatValue ? true : floatValue < 1000;
                              }}
                            />
                          }
                        />
                        {errors.value &&
                          <ErrorMessage>{errors.value.message}</ErrorMessage>
                        }
                      </ErrorWrapper>
                    </Grid.Col>
                    {isCardSelectVisible &&
                      <Grid.Col xs={12} sm={6} md={3} marginsFor={['xs']}>
                        <ErrorWrapper error={errors.operatorCardId}>
                          <Text.Label>Cartão Vinculado</Text.Label>
                          <Controller
                            control={control}
                            name="operatorCardId"
                            render={({ field: { value, onChange } }) =>
                              <Input.Select
                                placeholder="Selecione..."
                                value={cards.find(option => option.id === value)}
                                onChange={e => onChange(e.id)}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                isLoading={fetchCardsState.loading}
                                isClearable={false}
                                options={cards}
                              />
                            }
                          />
                          {errors.operatorCardId &&
                            <ErrorMessage>{errors.operatorCardId.message}</ErrorMessage>
                          }
                        </ErrorWrapper>
                      </Grid.Col>
                    }
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col>
                      <ErrorWrapper error={errors.frequency}>
                        <Text.Label>Periodicidade</Text.Label>
                        <CheckBoxWrapper>
                          <Controller
                            control={control}
                            name="frequency"
                            render={({ field: { value, onChange } }) =>
                              <>
                                <Input.CheckBox
                                  controlled
                                  checked={value === OPERATOR_CARD_FEE_FREQUENCY.DAILY}
                                  onClick={() => onChange(OPERATOR_CARD_FEE_FREQUENCY.DAILY)}
                                />
                                <Text.Label>Diário</Text.Label>
                                <Input.CheckBox
                                  controlled
                                  checked={value === OPERATOR_CARD_FEE_FREQUENCY.WEEKLY}
                                  onClick={() => onChange(OPERATOR_CARD_FEE_FREQUENCY.WEEKLY)}
                                />
                                <Text.Label>Semanal</Text.Label>
                                <Input.CheckBox
                                  controlled
                                  checked={value === OPERATOR_CARD_FEE_FREQUENCY.BIWEEKLY}
                                  onClick={() => onChange(OPERATOR_CARD_FEE_FREQUENCY.BIWEEKLY)}
                                />
                                <Text.Label>Quinzenal</Text.Label>
                                <Input.CheckBox
                                  controlled
                                  checked={value === OPERATOR_CARD_FEE_FREQUENCY.MONTHLY}
                                  onClick={() => onChange(OPERATOR_CARD_FEE_FREQUENCY.MONTHLY)}
                                />
                                <Text.Label>Mensal</Text.Label>
                              </>
                            }
                          />
                        </CheckBoxWrapper>
                        {errors.frequency &&
                          <ErrorMessage>{errors.frequency.message}</ErrorMessage>
                        }
                      </ErrorWrapper>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row justify="end">
                    <Grid.Col sm={2}>
                      <Button variant="text" onClick={(e) => {
                        e.preventDefault();
                        setIsCancelConfirmationOpen(true);
                      }}>Cancelar</Button>
                    </Grid.Col>
                    <Grid.Col sm={3} md={2.5} lg={2} xxl={1.5} marginsFor={['xs']}>
                      <Button form="newCardFeeForm">Continuar</Button>
                    </Grid.Col>
                  </Grid.Row>
                </>
              }
            </Grid>
          </Form>
        </Panel>
      </Content>
      <InfoModal
        open={isCancelConfirmationOpen}
        onClose={() => setIsCancelConfirmationOpen(false)}
      >
        <InfoModal.IconContainer color="var(--color-primary-01)">
          <BsExclamationCircle />
        </InfoModal.IconContainer>
        <InfoModal.Title>DESEJA CANCELAR O CADASTRO DE TARIFA?</InfoModal.Title>
        <InfoModal.Content>
          Ao clicar em confirmar os dados informados serão perdidos e você será direcionado para tela anterior.
        </InfoModal.Content>
        <InfoModal.ButtonsWrapper>
          <Button onClick={() => setIsCancelConfirmationOpen(false)} variant="text">
            Cancelar
          </Button>
          <Button
            variant="ghost"
            onClick={() =>
              history.push({
                pathname: routesPaths.operators.visualize,
                state: { operatorId: operator.id, tab: 2 }
              })
            }
          >
            Confirmar
          </Button>
        </InfoModal.ButtonsWrapper>
      </InfoModal>
    </>
  );
};

export default RegisterOperatorCardFee;