import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import errorHandler from 'main/utils/errorHandler';
import { pushNotification } from '@/store/modules/notification/actions';
import api from '@/infra/api';
import { TitleFee, Label, Flex, Text } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { OPERATOR_CARD_STATUS } from 'main/utils/constants';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from 'presentation/components/buttonV2';
import routesPaths from 'presentation/routes/routesPaths';
import ModalStatus from './components/modalStatus';
import ModalCancel from './components/modalCancel';
import successHandler from 'main/utils/successHandler';
import history from '@/services/history';
import {
  Content,
  Panel,
  PageHeader,
  Spinner,
  Grid,
  Switch,
  StatusTag,
  Input,
  ErrorMessage,
  Message,
} from 'presentation/components';

const VisualizeAndEditFees = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [incidence, setIncidence] = useState(null);
  const [errorIncidence, setErrorIncidence] = useState(false);
  const [obligatory, setObligatory] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState(false);
  const [warning, setWarning] = useState(false);
  const [validate, setValidate] = useState(false);

  const schema = yup.object({
    name: yup
      .string()
      .max(100, 'Campo limitado a 100 caracteres')
      .required('Campo obrigatório'),
    type: yup.number().required('Campo obrigatório'),
    criterion: yup.number().required('Campo obrigatório'),
    franchiseMinimal: yup.number().required('Campo obrigatório'),
    valueType: yup.number().required('Campo obrigatório'),
    application: yup.number().required('Campo obrigatório'),
    calculus: yup.number().required('Campo obrigatório'),
  });

  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
  } = useForm({ resolver: yupResolver(schema) });

  const getFeesId = async () => {
    setLoading(true);

    try {
      const response = await api.get(`/Fee/${props.location.state?.id}`);
      setInfo(response?.data);
      setValue('name', response?.data?.name);
      setValue('type', response?.data?.type);
      setValue('criterion', response?.data?.criterion);
      setValue('franchiseMinimal', response?.data?.franchiseMinimal);
      setValue('valueType', response?.data?.valueType);
      setValue('application', response?.data?.application);
      setValue('calculus', response?.data?.calculus);
      setIncidence(response?.data?.incidence);
      setObligatory(response?.data?.obligatory);
      setLoading(false);
    } catch (e) {
      dispatch(pushNotification(errorHandler(e?.response)));
      setLoading(false);
    }
  };

  useMemo(() => {
    if (props?.location?.state?.id) getFeesId();
    // eslint-disable-next-line
  }, []);

  const typeFess = [
    { label: 'Hospedagem', value: 1 },
    { label: 'Setup', value: 2 },
    { label: 'Sustentação', value: 3 },
    { label: 'Uso do Sistema', value: 4 },
  ];

  const criterion = [
    { label: 'Global', value: 1 },
    { label: 'Cartão', value: 2 },
    { label: 'Revendas', value: 3 },
    { label: 'Colaborador', value: 4 },
    { label: 'Produtos Operadora', value: 5 },
    { label: 'Produtos Dealer', value: 6 },
  ];

  const frank = [
    { label: 'Existe', value: 1 },
    { label: 'Não Existe', value: 2 },
  ];

  const typeValue = [
    { label: 'Porcentagem', value: 1 },
    { label: 'Reais', value: 2 },
    { label: 'Reais + Porcentagem', value: 3 },
  ];

  const application = [
    { label: 'Revendas', value: 1 },
    { label: 'Parceiros de Negócio', value: 2 },
    { label: 'Todos', value: 3 },
  ];

  const calc = [
    { label: 'Valor', value: 1 },
    { label: 'Unitário', value: 2 },
  ];

  const alterStatus = async () => {
    setLoading(true);

    const value = info.status === 1 ? 2 : 1;

    try {
      setStatus(false);
      await api.patch(`/Fee/${props.location.state.id}/activate/${value}`);
      setInfo({ ...info, status: value });
      setLoading(false);
    } catch (e) {
      dispatch(pushNotification(errorHandler(e?.response)));
      setLoading(false);
      setStatus(false);
    }
  };

  useMemo(() => {
    if (info?.status === 2) {
      setEdit(false);
    }
  }, [info]);

  const save = async (data) => {
    setLoading(true);

    if (!incidence) {
      setLoading(false);
      setErrorIncidence(true);
      setValidate(true);
      return;
    }

    try {
      data.obligatory = obligatory;
      data.incidence = incidence;
      data.status = info.status;

      await api.put(`/Fee/${props.location.state.id}`, data);
      setLoading(false);
      dispatch(pushNotification(successHandler('Taxa editada com sucesso')));
      history.push('/taxas');
    } catch (e) {
      dispatch(pushNotification(errorHandler(e?.response)));
      setLoading(false);
    }
  };

  useMemo(() => {
    if (warning) {
      setTimeout(() => {
        setWarning(false);
      }, 3000);
    }
  }, [warning]);

  useMemo(() => {
    if (validate) {
      if (!incidence) {
        setErrorIncidence(true);
      } else {
        setErrorIncidence(false);
      }
    }
  }, [incidence, validate]);

  useMemo(() => {
    if (props?.location?.state?.mode) {
      setEdit(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Content>
        <Panel>
          <PageHeader style={{ width: "100%" }}  >
            Taxa {info?.name}
          </PageHeader>
          {loading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '40px',
              }}
            >
              <Spinner />
            </div>
          )}
          {!loading && (
            <Grid>
              <Grid.Row>
                <Grid.Col lg={10}>
                  <TitleFee>
                    Taxa {info?.name}
                    <FontAwesomeIcon
                      onClick={() =>
                        info.status === 1 ? setEdit(true) : setWarning(true)
                      }
                      icon={faPen}
                      style={{
                        marginLeft: '10px',
                        color: '#000',
                        fontSize: '14px',
                        cursor: 'pointer',
                      }}
                    />
                  </TitleFee>
                </Grid.Col>
                <Grid.Col lg={2}>
                  <div style={{ display: 'flex' }}>
                    <Switch
                      onChange={() => setStatus(true)}
                      checked={info?.status === OPERATOR_CARD_STATUS.INACTIVE}
                    />
                    <div style={{ marginLeft: '10px', marginTop: '-10px' }}>
                      <StatusTag color={info?.status === 1 ? 'green' : 'red'}>
                        {OPERATOR_CARD_STATUS[info?.status]}
                      </StatusTag>
                    </div>
                  </div>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col lg={12}>
                  <form onSubmit={handleSubmit(save)}>
                    <Grid padding={'30px 0 '}>
                      <Grid.Row>
                        <Grid.Col xs={12} md={12} lg={3}>
                          <Label>Nome</Label>
                          <Input
                            maxLength="101"
                            {...register('name')}
                            disabled={edit ? false : true}
                          />
                          {errors.name && (
                            <ErrorMessage>
                              {errors.name?.message}
                            </ErrorMessage>
                          )}
                        </Grid.Col>
                        <Grid.Col xs={12} md={12} lg={3}>
                          <Label>Tipo de taxa</Label>
                          <Controller
                            control={control}
                            name="type"
                            render={({ field: { onChange, value, ref } }) => (
                              <Input.Select
                                placeholder="Selecione"
                                isDisabled={edit ? false : true}
                                value={typeFess.find(
                                  (option) => option.value === value
                                )}
                                inputRef={ref}
                                onChange={(e) => onChange(e?.value)}
                                options={typeFess}
                              />
                            )}
                          />
                          {errors.type && (
                            <ErrorMessage>
                              {errors.type?.message}
                            </ErrorMessage>
                          )}
                        </Grid.Col>
                        <Grid.Col xs={12} md={12} lg={3}>
                          <Label>Critério</Label>
                          <Controller
                            control={control}
                            name="criterion"
                            render={({ field: { onChange, value, ref } }) => (
                              <Input.Select
                                placeholder="Selecione"
                                isDisabled={edit ? false : true}
                                value={criterion.find(
                                  (option) => option.value === value
                                )}
                                inputRef={ref}
                                onChange={(e) => onChange(e?.value)}
                                options={criterion}
                              />
                            )}
                          />
                          {errors.criterion && (
                            <ErrorMessage>
                              {errors.criterion?.message}
                            </ErrorMessage>
                          )}
                        </Grid.Col>
                        <Grid.Col xs={12} md={12} lg={3}>
                          <Label>Franquia mínima</Label>
                          <Controller
                            control={control}
                            name="franchiseMinimal"
                            render={({ field: { onChange, value, ref } }) => (
                              <Input.Select
                                placeholder="Selecione"
                                isDisabled={edit ? false : true}
                                value={frank.find(
                                  (option) => option.value === value
                                )}
                                inputRef={ref}
                                onChange={(e) => onChange(e?.value)}
                                options={frank}
                              />
                            )}
                          />
                          {errors.franchiseMinimal && (
                            <ErrorMessage>
                              {errors.franchiseMinimal?.message}
                            </ErrorMessage>
                          )}
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row style={{ marginTop: '40px' }}>
                        <Grid.Col xs={12} md={12} lg={3}>
                          <Label>Tipo valor</Label>
                          <Controller
                            control={control}
                            name="valueType"
                            render={({ field: { onChange, value, ref } }) => (
                              <Input.Select
                                placeholder="Selecione"
                                isDisabled={edit ? false : true}
                                value={typeValue.find(
                                  (option) => option.value === value
                                )}
                                inputRef={ref}
                                onChange={(e) => onChange(e?.value)}
                                options={typeValue}
                              />
                            )}
                          />
                          {errors.valueType && (
                            <ErrorMessage>
                              {errors.valueType?.message}
                            </ErrorMessage>
                          )}
                        </Grid.Col>
                        <Grid.Col xs={12} md={12} lg={3}>
                          <Label>Aplicação</Label>
                          <Controller
                            control={control}
                            name="application"
                            render={({ field: { onChange, value, ref } }) => (
                              <Input.Select
                                placeholder="Selecione"
                                isDisabled={edit ? false : true}
                                value={application.find(
                                  (option) => option.value === value
                                )}
                                inputRef={ref}
                                onChange={(e) => onChange(e?.value)}
                                options={application}
                              />
                            )}
                          />
                          {errors.application && (
                            <ErrorMessage>
                              {errors.application?.message}
                            </ErrorMessage>
                          )}
                        </Grid.Col>
                        <Grid.Col xs={12} md={12} lg={3}>
                          <Label>Cálculo</Label>
                          <Controller
                            control={control}
                            name="calculus"
                            render={({ field: { onChange, value, ref } }) => (
                              <Input.Select
                                placeholder="Selecione"
                                isDisabled={edit ? false : true}
                                value={calc.find(
                                  (option) => option.value === value
                                )}
                                inputRef={ref}
                                onChange={(e) => onChange(e?.value)}
                                options={calc}
                              />
                            )}
                          />
                          {errors.calculus && (
                            <ErrorMessage>
                              {errors.calculus?.message}
                            </ErrorMessage>
                          )}
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row style={{ marginTop: '40px' }}>
                        <Grid.Col xs={12} md={12} lg={3}>
                          <Flex>
                            <Text>
                              Incidência
                              <FontAwesomeIcon
                                icon={faCircleInfo}
                                style={{
                                  marginLeft: '5px',
                                  color: '#875ede',
                                }}
                              />
                            </Text>
                          </Flex>

                          <Flex>
                            <Input.RadioButton
                              controlled
                              disabled={edit ? false : true}
                              checked={incidence === 1 ? true : false}
                              onClick={() =>
                                setIncidence(incidence === 1 ? 0 : 1)
                              }
                            />
                            <p style={{ paddingLeft: '10px' }}>
                              Créditos Estocados
                            </p>
                          </Flex>
                          <div style={{ marginTop: '10px' }} />
                          <Flex>
                            <Input.RadioButton
                              controlled
                              disabled={edit ? false : true}
                              checked={incidence === 2 ? true : false}
                              onClick={() =>
                                setIncidence(incidence === 2 ? 0 : 2)
                              }
                            />
                            <p style={{ paddingLeft: '10px' }}>
                              Valor total Faturado
                            </p>
                          </Flex>
                          {errorIncidence && (
                            <ErrorMessage>Campo obrigatório</ErrorMessage>
                          )}
                        </Grid.Col>
                        <Grid.Col xs={12} md={12} lg={3}>
                          <Flex>
                            <Input.CheckBox
                              checked={obligatory}
                              disabled={edit ? false : true}
                              onClick={() => setObligatory(!info.obligatory)}
                            />
                            <p style={{ paddingLeft: '10px' }}>
                              Taxa Obrigatória
                            </p>
                            <FontAwesomeIcon
                              icon={faCircleInfo}
                              style={{ marginLeft: '5px', color: '#875ede' }}
                            />
                          </Flex>
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row
                        justify="flex-end"
                        style={{ marginTop: '40px' }}
                      >
                        {edit && (
                          <Grid.Col xs={12} md={12} lg={4}>
                            <div style={{ display: 'flex' }}>
                              <Button
                                variant="text"
                                type="button"
                                onClick={() => setCancel(true)}
                              >
                                Cancelar
                              </Button>
                              <div style={{ marginLeft: '10px' }} />
                              <Button type="submit">Salvar Alterações</Button>
                            </div>
                          </Grid.Col>
                        )}
                        {!edit && (
                          <Grid.Col xs={12} md={12} lg={2}>
                            <Button
                              type="button"
                              onClick={() =>
                                history.push(routesPaths.fees.base)
                              }
                            >
                              Voltar
                            </Button>
                          </Grid.Col>
                        )}
                      </Grid.Row>
                    </Grid>
                  </form>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          )}
        </Panel>
      </Content>
      {status && (
        <ModalStatus
          open={status}
          close={() => setStatus(false)}
          confirm={() => alterStatus(info.status)}
          type={info.status}
        />
      )}
      {cancel && (
        <ModalCancel
          open={cancel}
          close={() => setCancel(false)}
          confirm={() => history.push(routesPaths.fees.base)}
        />
      )}
      {warning && (
        <Message onXClick={() => setWarning(false)} type="warning">
          Para editar uma taxa é necessário que ela esteja ativa
        </Message>
      )}
    </>
  );
};

export default VisualizeAndEditFees;
