import {
  Content,
  ErrorMessage,
  ErrorWrapper,
  Grid,
  Input,
  Panel,
  Spinner,
  Text
} from "presentation/components";
import Button from "presentation/components/buttonV2";
import { InfoModal } from "presentation/compositions";
import useService from "main/hooks/useService";
import routesPaths from "presentation/routes/routesPaths";
import history from "@/services/history";
import { postCreateNewCard } from "@/services/operatorsServices";
import { pushNotification } from "@/store/modules/notification/actions";
import { OPERATOR_CARD_STATUS } from "main/utils/constants";
import { benefitTypes } from "main/utils/options";
import successHandler from "main/utils/successHandler";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BsExclamationCircle } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { useDispatch } from "react-redux";
import schema from "./schema";
import { Form, StepsBall, StepWrapper } from "./styles";

const RegisterOperatorCard = ({ location }) => {
  const dispatch = useDispatch();
  const { operator } = location.state;
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);

  const returnToOperatorCards = () =>
    history.push(`${routesPaths.operators.visualize}#operatorId="${operator.id}"&operatorTab=1`);

  const [createCardFeeState, createCardFeeRequest] = useService(postCreateNewCard, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler("Cartão criado com sucesso!")));
      returnToOperatorCards()
    },
  });

  useEffect(() => {
    if (!operator) history.goBack();
    // eslint-disable-next-line
  }, []);

  const {
    control,
    formState: { errors, isDirty },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      operatorId: `${operator?.tradeName} - ${operator?.code}`
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (!isDirty) return;
    const payload = {
      ...data,
      status: OPERATOR_CARD_STATUS.ACTIVE,
    };
    createCardFeeRequest(operator.id, payload);
  };

  return (
    <>
      <Content>
        <Panel noPadding>
          <Grid padding="32px 16px 16px 16px">
            <Grid.Row>
              <Grid.Col>
                <StepWrapper>
                  <Text.SubtitleTwo>DADOS</Text.SubtitleTwo>
                  <StepsBall>
                    <FaCheck />
                  </StepsBall>
                </StepWrapper>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Text.Title>Dados do Cartão</Text.Title>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Text>Por favor, informe os dados</Text>
              </Grid.Col>
            </Grid.Row>
          </Grid>
          <Form onSubmit={handleSubmit(onSubmit)} id="newCardFeeForm">
            <Grid spaceBetweenRows="32px">
              {createCardFeeState.loading ? <Spinner.Box><Spinner /></Spinner.Box> :
                <>
                  <Grid.Row  >
                    <Grid.Col xs={12} sm={6} md={4} xl={3}>
                      <Text.Label>Operadora</Text.Label>
                      <Input
                        disabled
                        {...register("operatorId")}
                      />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={4} xl={3} marginsFor={['xs']}>
                      <ErrorWrapper error={errors.name}>
                        <Text.Label>Nome</Text.Label>
                        <Input
                          maxLength={30}
                          {...register("name")}
                        />
                        {errors.name &&
                          <ErrorMessage>{errors.name.message}</ErrorMessage>
                        }
                      </ErrorWrapper>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col xs={12} sm={6} md={4} xl={3}>
                      <ErrorWrapper error={errors.benefitType}>
                        <Text.Label>Tipo de Benefício</Text.Label>
                        <Controller
                          control={control}
                          name="benefitType"
                          render={({ field: { value, onChange, ref } }) =>
                            <Input.Select
                              inputRef={ref}
                              value={benefitTypes.find(option => option.value === value)}
                              placeholder="Selecione..."
                              onChange={(e) => {
                                onChange(e.value);
                              }}
                              isClearable={false}
                              options={benefitTypes}
                            />
                          }
                        />
                        {errors.benefitType &&
                          <ErrorMessage>{errors.benefitType.message}</ErrorMessage>
                        }
                      </ErrorWrapper>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row justify="end">
                    <Grid.Col sm={2}>
                      <Button variant="text" onClick={(e) => {
                        e.preventDefault();
                        setIsCancelConfirmationOpen(true);
                      }}>Cancelar</Button>
                    </Grid.Col>
                    <Grid.Col sm={3} md={2.5} lg={2} xxl={1.5} marginsFor={['xs']}>
                      <Button form="newCardFeeForm">Continuar</Button>
                    </Grid.Col>
                  </Grid.Row>
                </>
              }
            </Grid>
          </Form>
        </Panel>
      </Content>
      <InfoModal
        open={isCancelConfirmationOpen}
        onClose={() => setIsCancelConfirmationOpen(false)}
      >
        <InfoModal.IconContainer color="var(--color-primary-01)">
          <BsExclamationCircle />
        </InfoModal.IconContainer>
        <InfoModal.Title>DESEJA CANCELAR O CADASTRO DE CARTÃO?</InfoModal.Title>
        <InfoModal.Content>
          Ao clicar em confirmar os dados informados serão perdidos e você será direcionado para tela anterior.
        </InfoModal.Content>
        <InfoModal.ButtonsWrapper>
          <Button onClick={() => setIsCancelConfirmationOpen(false)} variant="text">
            Cancelar
          </Button>
          <Button
            variant="ghost"
            onClick={returnToOperatorCards}
          >
            Confirmar
          </Button>
        </InfoModal.ButtonsWrapper>
      </InfoModal >
    </>
  );
};

export default RegisterOperatorCard;