import { BREAK_POINTS } from "main/utils/constants";
import styled from "styled-components";

export const Form = styled.form`
  min-width: 500px;
  > div > div{
    margin-bottom: 32px;
    @media (max-width: ${BREAK_POINTS.sm}) {
      margin-bottom: 0px;
    } 
  }
  > div > div:last-of-type{
    margin-bottom: 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 8px;
  }
`;

export const ModalTitle = styled.h2`
  font-weight: var(--font-weight-bold);
  color: #2C2D5C;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
  @media (max-width: ${BREAK_POINTS.sm}) {
    margin-bottom: 0px;
  }
`;

export const ModalLoadingWrapper = styled.div`
  min-height: 400px;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EntrieWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;

  button{
    all: unset;
    margin-left: 8px;
    cursor: pointer;
  }
`;