export const companies = [
  {
    label: "Venda a prazo liberada",
    text: "Venda a prazo liberada",
  },
  {
    label: "Venda a prazo não liberada",
    text: "Venda a prazo não liberada",
  },
  {
    label: "Novas empresas",
    text: "Novas empresas",
  },
]
