import { getResalesCardsFees } from '@/services/resalesServices';
import { all, takeLatest, call, put } from 'redux-saga/effects';
import { resalesCardsFeesInSuccess, resalesCardsFeesFailure } from './actions';

function* fetchResalesCardsFees({ payload }) {
  const { response } = yield call(getResalesCardsFees, payload.query);
  if (response) yield put(resalesCardsFeesInSuccess(response.data));
  else yield yield put(resalesCardsFeesFailure());
}

export default all([
  takeLatest('@resalesCardsFees/RESALES_TAXES_IN_REQUEST', fetchResalesCardsFees),
]);
