import { useState } from 'react';
import { Table, Input } from 'presentation/components';
import { Header } from '../styles';

const TableList = ({ props, position }) => {
  const {
    allClick,
    checkedItems,
    checkboxClick,
    displayName,
    filter,
    isAllChecked,
    //disabledAllChecked,
    list,
    loading,
  } = props;
  const filteredItems = list.filter((item) =>
    item[displayName]?.toLowerCase().includes(filter?.toLowerCase() || '')
  );

  const [verifyDisabledCheckbox, setVerifyDisabledCheckbox] = useState(true);

  const checkedVerify = (item) => {
    return (
      (checkedItems && checkedItems.includes(item.innerId)) ||
      (item.obligatory && true)
    );
  };

  const checkedAllVerify = () => {
    if (position === 'left')
      return isAllChecked || list.includes((e) => !e.obligatory);
    else if (position === 'right') {
      const filterObligatoryAssigned = list.filter(
        (e) => !e.obligatory && !e.assigned
      ).length;
      if (filterObligatoryAssigned <= 0) return true;
      else {
        if (verifyDisabledCheckbox) setVerifyDisabledCheckbox(false);
        return checkedItems.length > 0;
      }
    }
  };

  return (
    <>
      <Table maxHeight="450px" loading={loading}>
        <thead>
          <Table.Tr>
            <Table.Th colSpan={2} style={{ borderBottom: '1px solid #aaa' }}>
              <Header>
                {position === 'left' && (
                  <span className="check">
                    <Input.CheckBox
                      controlled
                      checked={checkedAllVerify()}
                      onClick={allClick}
                    />{' '}
                    Todos
                  </span>
                )}
                {position === 'right' && (
                  <>
                    <span className="check">
                      <Input.CheckBox
                        controlled
                        checked={checkedAllVerify()}
                        disabled={verifyDisabledCheckbox}
                        onClick={allClick}
                      />{' '}
                      Todos
                    </span>
                  </>
                )}

                <span>Total: {list.length}</span>
              </Header>
            </Table.Th>
          </Table.Tr>
        </thead>
        <tbody>
          {list &&
            filteredItems.map((item, index) => (
              <Table.Tr key={index}>
                <Table.Td width="10%" style={{ padding: '12px 0px 12px 16px' }}>
                  <span>
                    <Input.CheckBox
                      controlled
                      checked={checkedVerify(item)}
                      style={{ cursor: 'pointer' }}
                      onClick={() => checkboxClick(item.innerId)}
                      disabled={item.obligatory}
                    />
                  </span>
                </Table.Td>
                <Table.Td>{item[displayName]}</Table.Td>
              </Table.Tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default TableList;
