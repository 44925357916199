export function finishOperatorInRequest(id) {
  return {
    type: '@finishOperator/FINISH_IN_REQUEST',
    payload: { id },
  };
}

export function finishOperatorInSuccess(review) {
  return {
    type: '@finishOperator/FINISH_IN_SUCCESS',
    payload: { review },
  };
}

export function finishOperatorFailure() {
  return {
    type: '@finishOperator/FINISH_FAILURE',
  };
}
