 type TCategory = "EFeeType" | "EFeeValueType" | "EFeeIncidence" | "ERecurrence" | "EFeeCriterion";

const enumParse = (propsFee: number, category: TCategory ) => {
  let formatEnum = "";

  const ECategory = {
    EFeeType: "EFeeType",
    EFeeValueType: "EFeeValueType",
    EFeeIncidence: "EFeeIncidence",
    ERecurrence: "ERecurrence",
    EFeeCriterion: "EFeeCriterion"
  }

  const ERecurrence =
  {
    Only: 1,
    Weekly: 2,
    Monthly: 3,
    Yearly: 4
  }

  const EFeeType = {
    Hosting: 1,  
    Setup: 2,
    Sustention: 3,
    SystemUsage: 4,
  }

  const EFeeValueType = 
  {
    Percentage : 1,
    Real : 2,
    PercentageAndReal : 3,
  }

  const EFeeIncidence =
  {
    CreditsStored: 1,
    TotalInvoiced: 2,
  }

  const EFeeCriterion = 
    {
      Global : 1,
      Card : 2,
      Resale : 3,
      Employee : 4,
      OperatorProduct : 5,
      DealerProduct: 6,
    }

  const parseEnumFeeType = (props: number) => {
    let formatParse = "";
    switch (props) {
      case EFeeType.Hosting:
        formatParse = "Hospedagem";
        break;
      case EFeeType.Setup:
        formatParse = "Setup";
        break;
      case EFeeType.Sustention:
        formatParse = "Sustentação";
        break;
      case EFeeType.SystemUsage:
        formatParse = "Uso do Sistema";
        break;
      default:
        break;
    }
    return formatParse
  }

  const parseEFeeValueType = (props: number) => {
    switch (props) {
      case EFeeValueType.Percentage:
        formatEnum = "Porcentagem";
        break;
      case EFeeValueType.Real:
        formatEnum = "Reais";
        break;
      case EFeeValueType.PercentageAndReal:
        formatEnum = "Reais + Porcentagem";
        break;
      default:
        break;
    }
    return formatEnum
  }

  const parseEFeeIncidence = (props: number) => {
    switch (props) {
      case EFeeIncidence.CreditsStored:
        formatEnum = "Créditos estocados"
        break;
      case EFeeIncidence.TotalInvoiced:
        formatEnum = "Valor total faturado"
        break;
      
      default:
        break;
    }
    return formatEnum;
  }

  const parseERecurrence =  (props: number) => {
    switch (props){
    case ERecurrence.Only:
      formatEnum = "Única";
      break;
    case ERecurrence.Weekly:
      formatEnum = "Semanal";
      break;
    case ERecurrence.Monthly:
      formatEnum = "Mensal";
      break;
    case ERecurrence.Yearly:
      formatEnum = "Anual";
      break;
    }
    return formatEnum;
  }
  

  const parseEFeeCriterion = (props: number) => {
    switch (props){
      case EFeeCriterion.Global:
        formatEnum = "Global";
        break;
      case EFeeCriterion.Card:
        formatEnum = "Cartão";
        break;
      case EFeeCriterion.Resale:
        formatEnum = "Revenda";
        break;
      case EFeeCriterion.Employee:
        formatEnum = "Colaborador";
        break;
      case EFeeCriterion.OperatorProduct:
        formatEnum = "Produtos Operadora";
        break;
      case EFeeCriterion.DealerProduct:
        formatEnum = "Produtos Dealer";
        break;
      }
      return formatEnum;
  } 

  
  const FormatParse = () => {
    switch (category) {
      case ECategory.EFeeType:
        formatEnum = parseEnumFeeType(propsFee);
        break;
      case ECategory.EFeeValueType:
        formatEnum = parseEFeeValueType(propsFee);
        break;
      case ECategory.EFeeIncidence:
        formatEnum = parseEFeeIncidence(propsFee);
        break;
      case ECategory.ERecurrence: 
        formatEnum = parseERecurrence(propsFee);
        break;
      case ECategory.EFeeCriterion: 
        formatEnum = parseEFeeCriterion(propsFee);
        break;
        
      default:
        break;
    }
    return formatEnum
  }
  
  return FormatParse();
}

export default enumParse;
