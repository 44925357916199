import css from "@emotion/styled"
import { styled } from "@mui/material";

export const Container = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '32px',
  transition: 'all .25s linear'
}))

export const FilterButton = styled('div')(({ theme }) => ({
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: "pointer",
  padding: '0 16px',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightSemiBold
}))

export const CentralizedCell = styled('div')(({ theme }) => ({
  alignItems: 'center',
  width: '100%'
}))

export const DateContent = css.div`
  display: flex;
  align-items: center;
  margin-right: 6px;

  p {
    border-radius: 4px;
    background: var(--Grey-100, #e7ebf0);
    padding: 3px 8px;
    color: var(--Grey-700, #616161);
    font-size: 13px;
    font-weight: 500;
  }
`

export const NewCompany = css.small`
  border-radius: 2px;
  background: var(--Error-Dark, #c62828);
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 0px 4px;
  display: flex;
  align-items:center;
`

export const ContainerGrid = css.div`
  width: 100%;
  padding: 0 16px;
  overflow: auto;
`

export const Tags = css.div`
display: flex;
align-items: center;
flex-wrap: wrap;
gap: 8px;

button {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 999px;
  background: var(--Grey-100, #e7ebf0);
  border: 0;
  cursor: pointer;

  p {
    padding-right: 12px;
    border-right: 1px solid #9e9e9e;
    color: var(--Grey-900, #212121);
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
  }

  span {
    color: var(--Common-Dark, #616161);
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
  }

  svg {
    cursor: pointer;
  }
}
`