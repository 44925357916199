import { OverflowingContainer, StyledLine } from './styles';

const Line = ({ color = '#999999', overflow }) => {
  return overflow ? (
    <OverflowingContainer>
      <StyledLine color={color} />
    </OverflowingContainer>
  ) : (
    <StyledLine color={color} />
  );
};

export default Line;
