import styled from 'styled-components';

export const StatusContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
`;

export const StatusDot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-right: 8px;
`;

export const StatusName = styled.span``;
