import { Body, Container } from './styles';

const Background = ({ children, ...props }) => {

  return (
    <Body {...props}>
      <Container >{children}</Container>
    </Body>
  );
};

export default Background;
