import moment from "moment"

import { ICompany } from "../../../hooks/utils/interfaces/ICompany"

export const checkCompany = ({ company, filter }: { company: ICompany["data"][0]; filter: string }) => {
  switch (filter) {
    case "Novas empresas": {
      const today = moment()
      const checkDays = moment(today).diff(company?.creationDate, "days")

      return checkDays <= 15
    }
    case "Venda a prazo liberada": {
      return company?.allowFinancedSales
    }
    case "Venda a prazo não liberada": {
      return !company?.allowFinancedSales
    }
  }
}
