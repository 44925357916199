import { ErrorWrapper, Modal, Spinner, Grid } from "presentation/components";
import Button from "presentation/components/buttonV2";
import { Controller, useForm } from "react-hook-form";
import { Form, ModalLoadingWrapper, ModalTitle } from "./styles";
import useService from "main/hooks/useService";
import { useDispatch } from "react-redux";
import { pushNotification } from "@/store/modules/notification/actions";
import successHandler from "main/utils/successHandler";
import OldErrorMessage from "presentation/components/errorMessage";
import {
  getResalesAvailableOperatorsCards,
  postValidateDealerLogin,
  putEditResaleOperatorsCards
} from "@/services/resalesServices";
import { NOTIFICATION_TYPE, OPERATOR_CARD_PROCESS_TYPE } from "main/utils/constants";
import { useEffect, useRef } from "react";
import { Input, Label } from "@stationkim/components-lib";
import Select from "presentation/components/input/select";
import { operatorCardProcessType } from "main/utils/options";
import { rules } from "presentation/pages/resales/register/steps/rules/bindCardsRules";
import { ErrorMessage } from "@hookform/error-message";

const FormError = ({ name, errors }) => {
  if (!errors) return;
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) =>
        <Input.Message>{message}</Input.Message>
      }
    />
  );
};

const BindedCardsModal = ({
  cardIndex = null,
  resaleData,
  onClose,
  open
}) => {

  const dispatch = useDispatch();
  const isEditing = cardIndex !== null;
  const resaleBindedCards = resaleData?.operatorsData;

  const {
    control,
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch
  } = useForm({
    mode: 'onChange',
    defaultValues: resaleBindedCards[cardIndex]
  });

  const [
    selectedOperator,
    situationType,
    login,
    password
  ] = watch([
    'operatorId',
    'processType',
    'user',
    'password',
  ]);

  const [
    fetchOperatorsCardsState,
    fetchOperatorsCardsRequest
  ] = useService(getResalesAvailableOperatorsCards);
  const operatorsCardsData = fetchOperatorsCardsState.response?.data || [];

  const [
    credentialValidationState,
    credentialValidationRequest,
  ] = useService(postValidateDealerLogin, {
    onCompleted: (res) => {
      validCredentialForDealer.current = res.data
      if (res.data) {
        dispatch(pushNotification(successHandler("Conexão com barramento realizada com sucesso.")));
        setValue('dealerCanPass', "true", { shouldValidate: true });
      }
      else {
        dispatch(pushNotification({
          type: NOTIFICATION_TYPE.WARNING,
          content: "Login e Senha não foram localizados ou estão incorretos."
        }))
      }
    }
  });

  const [editCardState, editCardRequest] = useService(putEditResaleOperatorsCards, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler(`Cartão ${isEditing ? "alterado" : "adicionado"}!`)));
      onClose(true);
    },
  });

  const validCredentialForDealer = useRef(false);
  const initialPassword = useRef(password || '');
  const initialSituation = useRef(situationType);

  const isPasswordNeeded = password !== initialPassword.current ||
    initialSituation.current !== situationType;

  useEffect(() => { fetchOperatorsCardsRequest() }, []);

  useEffect(() => {
    setValue('dealerCanPass', "", { shouldValidate: true });
  }, [login, password]);

  const onSubmit = (data) => {
    if (!isDirty) {
      onClose();
      return;
    }

    const payload = resaleBindedCards;
    const newData = {
      user: data.user,
      password: data.password,
      processType: data.processType,
      operatorId: data.operatorId,
      operatorCardId: data.operatorCardId,
    };
    if (isEditing) {
      payload[cardIndex] = {
        ...payload[cardIndex],
        ...newData,
      }
    } else {
      payload.push(newData);
    };

    editCardRequest(resaleData.id, payload);
  };

  return (
    <Modal
      open={open}
      showCloseIcon={false}
      onClose={onClose}
    >
      <ModalTitle>{isEditing ? "Editar Cartão" : "Adicionar Cartão"}</ModalTitle>
      <Form onSubmit={handleSubmit(onSubmit)} id="companyData">
        <Grid fluid padding="0" spaceBetweenRows="32px">
          {editCardState.loading ? <ModalLoadingWrapper><Spinner /></ModalLoadingWrapper> :
            <>
              <Grid.Row>
                <Grid.Col md={4}>
                  <Controller
                    control={control}
                    name={'operatorId'}
                    rules={rules.operatorId}
                    render={({ field: { value, onChange } }) => (
                      <ErrorWrapper error={errors?.["operatorId"]}>
                        <Label style={{ display: 'block', marginBottom: "8px" }}>Operadora</Label>
                        <Select
                          isClearable={false}
                          isDisabled={isEditing}
                          value={operatorsCardsData.find(option => option.id === value)}
                          onChange={option => {
                            onChange(option.id);
                            setValue("operatorCardId", undefined, { shouldValidate: true });
                          }}
                          getOptionLabel={option => option.tradeName}
                          getOptionValue={option => option.id}
                          placeholder="Selecione..."
                          options={operatorsCardsData}
                        />
                        {!errors ? null :
                          <ErrorMessage
                            errors={errors}
                            name={"id"}
                            render={({ message }) => <OldErrorMessage>{message}</OldErrorMessage>}
                          />}
                      </ErrorWrapper>
                    )}
                  />
                </Grid.Col>
                {!selectedOperator ? null :
                  <>
                    <Grid.Col md={4}>
                      <Controller
                        control={control}
                        name={'operatorCardId'}
                        rules={rules.operatorCardId}
                        render={({ field: { value, onChange } }) => {
                          const choosedOperator = operatorsCardsData.find(
                            operator => operator.id === selectedOperator
                          );
                          if (!choosedOperator) return;
                          const choosedOperatorCards = choosedOperator.operatorCards;
                          return (
                            <ErrorWrapper error={errors?.["operatorCardId"]}>
                              <Label style={{ display: 'block', marginBottom: "8px", whiteSpace: 'nowrap' }}>Cartão da Operadora</Label>
                              <Select
                                isClearable={false}
                                isDisabled={isEditing}
                                defaultValue={1}
                                value={choosedOperatorCards.find(option => option.id === value) || {}}
                                onChange={option => onChange(option.id)}
                                placeholder="Selecione..."
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                options={choosedOperatorCards}
                              />
                              {!errors ? null :
                                <ErrorMessage
                                  errors={errors}
                                  name={"operatorCardId"}
                                  render={({ message }) => <OldErrorMessage>{message}</OldErrorMessage>}
                                />}
                            </ErrorWrapper>
                          )
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Controller
                        control={control}
                        name={'processType'}
                        rules={rules.processType}
                        render={({ field: { value, onChange } }) => (
                          <ErrorWrapper error={errors?.["processType"]}>
                            <Label style={{ display: 'block', marginBottom: "8px" }}>Situação</Label>
                            <Select
                              isClearable={false}
                              defaultValue={1}
                              value={operatorCardProcessType.find(option => option.value === value)}
                              onChange={option => onChange(option.value)}
                              placeholder="Selecione..."
                              options={operatorCardProcessType}
                            />
                            {!errors ? null :
                              <ErrorMessage
                                errors={errors}
                                name={"processType"}
                                render={({ message }) => <OldErrorMessage>{message}</OldErrorMessage>}
                              />}
                          </ErrorWrapper>
                        )}
                      />
                    </Grid.Col>
                  </>
                }
              </Grid.Row>
              {situationType === OPERATOR_CARD_PROCESS_TYPE.DEALER ?
                <Grid.Row>
                  <Grid.Col md={4}>
                    <Input.Root
                      state={errors?.user ? 'error' : null}
                      full
                    >
                      <Input.Label>
                        Login/E-mail
                      </Input.Label>
                      <Input.Field>
                        <Input.Input
                          {...register('user', rules.user)}
                          placeholder="Login/E-mail"
                          maxLength={150}
                        />
                      </Input.Field>
                      <FormError errors={errors} name="user" />
                    </Input.Root>
                  </Grid.Col>
                  <Grid.Col md={4}>
                    <Input.Root
                      state={errors?.password ? 'error' : null}
                      full
                    >
                      <Input.Label>
                        Senha
                      </Input.Label>
                      <Input.Field>
                        <Input.Input
                          {...register('password', {
                            required: {
                              value: !isEditing || isPasswordNeeded,
                              message: "Campo obrigatório"
                            }
                          })}
                          placeholder="Senha"
                          maxLength={150}
                          type='password'
                        />
                      </Input.Field>
                      <FormError errors={errors} name="password" />
                    </Input.Root>
                  </Grid.Col>
                  <Grid.Col md={4} >
                    <Button
                      full
                      style={{ marginTop: '24px' }}
                      disabled={credentialValidationState.loading}
                      onClick={() => {
                        credentialValidationRequest({
                          operatorId: selectedOperator,
                          user: login,
                          password,
                        })
                      }}
                    >
                      {
                        credentialValidationState.loading ? <Spinner variant="sm" /> :
                          "Testar Conexão"
                      }
                    </Button>
                    {!errors ? null :
                      <ErrorMessage
                        errors={errors}
                        name={"dealerCanPass"}
                        render={({ message }) => <OldErrorMessage>{message}</OldErrorMessage>}
                      />}

                    <input
                      disabled
                      style={{
                        maxHeight: 0,
                        maxWidth: 0,
                        position: "fixed",
                        opacity: 0,
                      }}
                      {...register('dealerCanPass', {
                        required: {
                          value: situationType === OPERATOR_CARD_PROCESS_TYPE.DEALER
                            && (!isEditing || isPasswordNeeded),
                          message: "Execute o teste de conexão"
                        }
                      })}
                    />
                  </Grid.Col>
                </Grid.Row> : null
              }
            </>
          }
          <Grid.Row justify="end">
            <Grid.Col sm="content">
              <Button
                onClick={() => onClose(false)}
                variant="text"
              >Cancelar</Button>
            </Grid.Col>
            <Grid.Col sm="content">
              <Button>Salvar</Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Form>
    </Modal >
  );
}

export default BindedCardsModal;