import { Button, ButtonIcon } from './styles';

const Simple = ({ children, icon, size, variant, width, ...props }) => {
  return (
    <Button size={size} variant={variant} width={width} {...props}>
      {icon && <ButtonIcon className="icon" name={icon} />}
      {children}
    </Button>
  );
};

export default Simple;
