import { Switch } from "react-router-dom"
import Route from "./Route"
import Login from "presentation/pages/login"
import Home from "presentation/pages/home"
import Password from "presentation/pages/password"
import Operators from "presentation/pages/operators/register"
import DataOperators from "presentation/pages/operators/register/steps"
import routesPaths from "presentation/routes/routesPaths"
import { ListOperators } from "presentation/pages/operators/list/listOperators"
import OperatorVisualization from "@/presentation/pages/operators/visualizeAndEdit/operatorVisualization"
import RegisterOperatorCardFee from "presentation/pages/operators/visualizeAndEdit/operatorCardFees/register"
import VisualizeAndEditOperatorCardFee from "presentation/pages/operators/visualizeAndEdit/operatorCardFees/visualizeAndEdit"
import RegisterOperatorCard from "presentation/pages/operators/visualizeAndEdit/operatorCards/register"
import { VisualizeAndEditOperatorCard } from "presentation/pages/operators/visualizeAndEdit/operatorCards/visualizeAndEdit"
import ListResales from "presentation/pages/resales/list"
import Fees from "presentation/pages/fees/list"
import RegisterFees from "presentation/pages/fees/register"
import VisualizeAndEditFees from "presentation/pages/fees/visualizeAndEdit"
import { RegisterResale } from "presentation/pages/resales/register"
import ResaleVisualization from "presentation/pages/resales/visualizeAndEdit"

import SelectFee from "presentation/pages/resales/visualizeAndEdit/operatorCardFees/selectFee/selectFee"
import RegisterFee from "presentation/pages/resales/visualizeAndEdit/operatorCardFees/registerFee/registerFee"
import VisualizeAndEditResalesCardFee from "presentation/pages/resales/visualizeAndEdit/operatorCardFees/visualizeAndEdit"
import { StructuredPage } from "@stationkim/front-ui"
import { faBus, faHome, faTable, faTableColumns, faUserGroup } from "@fortawesome/free-solid-svg-icons"
import { faChartBar } from "@fortawesome/free-regular-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import { signOut } from "@/store/modules/auth/actions"
import { Notification } from "../components"
import { Layout } from "../pages/layout/layout/layout"
import { NewLayout } from "../pages/layout/newLayout/newLayout"

const Routes = () => {
  const routesPathsMap = [
    { path: "/", component: Home },
    { path: routesPaths.layout.base, component: Layout },
    { path: routesPaths.layout.new_layout, component: NewLayout },
    { path: routesPaths.operators.checkCnpjForRegister, component: Operators },
    { path: routesPaths.operators.register, component: DataOperators },
    { path: routesPaths.operators.visualize, component: OperatorVisualization },
    { path: routesPaths.operators.base, component: ListOperators },
    { path: routesPaths.operators.register_card_fee, component: RegisterOperatorCardFee },
    { path: routesPaths.operators.visualize_card_fee, component: VisualizeAndEditOperatorCardFee },
    { path: routesPaths.operators.register_card, component: RegisterOperatorCard },
    { path: routesPaths.operators.visualize_card, component: VisualizeAndEditOperatorCard },
    { path: routesPaths.resales.base, component: ListResales },
    { path: routesPaths.resales.register, component: RegisterResale },
    { path: routesPaths.resales.visualize, component: ResaleVisualization },
    { path: routesPaths.resales.visualize_card_fee, component: VisualizeAndEditResalesCardFee },
    { path: routesPaths.resales.new_select_fee, component: RegisterFee },
    { path: routesPaths.resales.register_card_fee, component: SelectFee },
    { path: routesPaths.resales.checkCnpjForRegister, component: RegisterResale },
    { path: routesPaths.fees.base, component: Fees },
    { path: routesPaths.fees.register, component: RegisterFees },
    { path: routesPaths.fees.visualize, component: VisualizeAndEditFees },
  ]

  const account = useSelector(state => state.auth)
  const dispatch = useDispatch()
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/recuperar_senha" exact component={Password} />
      <StructuredPage
        headerProps={{
          onLogout: () => dispatch(signOut()),
          user: {
            userName: account?.user?.name,
          },
          destinations: [
            { displayName: "Início", icon: faHome, pathname: "/" },
            { displayName: "Revendas", icon: faUserGroup, pathname: "/revendas" },
            { displayName: "Operadoras", icon: faBus, pathname: "/operadoras" },
            //{ displayName: 'Produtos', icon: faCreditCard, pathname: '/produtos' },
            { displayName: "Gestão de Taxas", icon: faChartBar, pathname: "/taxas" },
            { displayName: "Layouts", icon: faTableColumns, pathname: "/layout" },
            //{ displayName: 'Financeiro', icon: faChartArea, pathname: '/financeiro' },
          ],
        }}
      >
        <Notification />
        {routesPathsMap.map((element, key) => (
          <Route key={`key-${key}`} path={element.path} component={element.component} exact isProtected />
        ))}
      </StructuredPage>
    </Switch>
  )
}

export default Routes
