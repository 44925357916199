import { Button } from 'presentation/components';
import { Spinner } from 'presentation/components';
import Modal from 'react-responsive-modal';
import { ButtonsWrapper, Content, LoadingContainer } from '../styles';

const TwoOptions = ({
  open,
  onClose,
  onLeftClick,
  leftText = 'Não',
  onRightClick,
  rightText = 'Sim',
  children,
  loading = false,
  ...props
}) => {
  return (
    <Modal open={open} onClose={onClose} center {...props}>
      {loading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <Content>
          {children}
          <ButtonsWrapper>
            <Button variant="outlined" onClick={onLeftClick}>
              {leftText}
            </Button>
            <Button onClick={onRightClick}>{rightText}</Button>
          </ButtonsWrapper>
        </Content>
      )}
    </Modal>
  );
};

export default TwoOptions;
