import { useMemo, useState } from 'react';
import api from '@/infra/api';
import history from '@/services/history';
import { pushNotification } from '@/store/modules/notification/actions';
import { useDispatch } from 'react-redux';
import errorHandler from 'main/utils/errorHandler';
import {
  Input,
  Button,
  Icon,
  Spacing,
  Spinner,
  Notification,
} from 'presentation/components';
import {
  Main,
  Body,
  Content,
  Title,
  Label,
  ForgetPassword,
  Text,
} from './styles';

const Senha = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [next, setNext] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const requestSenha = async (ev) => {
    ev.preventDefault();
    setLoading(true);

    try {
      await api.post(`/passwords/email?email=${email}`);
      setNext(true);
      setLoading(false);
    } catch (e) {
      dispatch(pushNotification(errorHandler(e?.response)));
      setError(true);
      setLoading(false);
    }
  };

  useMemo(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  }, [error]);

  return (
    <>
      <Main>
        <Body>
          <Notification />
          <Content>
            {!next && !loading && (
              <>
                <Title>Recupere sua senha</Title>
                <Text>
                  Você receberá um link por email para cadastrar uma nova senha.
                </Text>
                <form onSubmit={requestSenha}>
                  <Label>E-mail</Label>
                  <Input
                    type="email"
                    required={true}
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value)}
                  />
                  <Spacing top="30px" />
                  <Button type="submit" width="100%">
                    Recuperar senha
                  </Button>
                  <ForgetPassword onClick={() => history.push('/login')}>
                    Acessar minha conta
                  </ForgetPassword>
                </form>
              </>
            )}
            {next && !loading && (
              <>
                <Title>Link enviado</Title>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Icon name="ok" />
                </div>
                <Text>
                  Acesse seu email de cadastro e entre no link enviado para
                  cadastrar uma nova senha.
                </Text>
                <Button onClick={() => history.push('/login')}>
                  Acessar minha conta
                </Button>
                <div style={{ marginBottom: '30px' }} />
              </>
            )}
            <div style={{ display: 'flex', margin: '0 auto' }}>
              {loading && <Spinner />}
            </div>
          </Content>
        </Body>
      </Main>
    </>
  );
};

export default Senha;
