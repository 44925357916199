import api from "@/infra/api";
import apiCallHandler from "./apiCallHandler";

export const getResales = (query = '') => {
  return apiCallHandler(() => api.get(`/resales${query}`));
};

export const getResaleExistenseByCnpj = (cnpj) => {
  return apiCallHandler(() => api.get(`/resales/registration/${cnpj}/exists`));
};

export const getResaleById = (resaleId) => {
  return apiCallHandler(() => api.get(`/resales/${resaleId}`));
};

export const getResalesAvailableOperatorsCards = () => {
  return apiCallHandler(() => api.get(`/resales/GetAllOperatorActiveWithCard`));
};

export const postValidateDealerLogin = (payload) => {
  return apiCallHandler(() => api.post(`/resales/ValidateLogin`, payload));
};

export const postRegisterResaleGeneralData = (payload) => {
  return apiCallHandler(() => api.post(`/resales/registration/general-data`, payload));
};

export const putEditResaleGeneralData = (payload) => {
  return apiCallHandler(() => api.put(`/resales/edit/general-data`, payload));
};

export const postRegisterResaleContacts = (resaleId, payload) => {
  return apiCallHandler(() => api.post(`/resales/registration/contacts?id=${resaleId}`, payload));
};

export const putEditResaleContacts = (resaleId, payload) => {
  return apiCallHandler(() => api.put(`/resales/edit/contacts?id=${resaleId}`, payload));
};

export const postRegisterResaleAddresses = (resaleId, payload) => {
  return apiCallHandler(() => api.post(`/resales/registration/addresses?id=${resaleId}`, payload));
};

export const putEditResaleAddresses = (resaleId, payload) => {
  return apiCallHandler(() => api.put(`/resales/edit/addresses?id=${resaleId}`, payload));
};

export const postRegisterResaleOperatorsCards = (resaleId, payload) => {
  return apiCallHandler(() => api.post(`/resales/registration/OperatorsCards?id=${resaleId}`, payload));
};

export const putEditResaleOperatorsCards = (resaleId, payload) => {
  return apiCallHandler(() => api.put(`/resales/edit/OperatorsCards?id=${resaleId}`, payload));
};

export const postRegisterResaleDocuments = (resaleId, payload) => {
  return apiCallHandler(() => api.post(`/resales/registration/documents?resaleId=${resaleId}`, payload));
};

export const putEditResaleDocuments = (resaleId, payload) => {
  return apiCallHandler(() => api.put(`/resales/edit/documents?resaleId=${resaleId}`, payload));
};

export const putFinalizeResaleRegister = (resaleId) => {
  return apiCallHandler(() => api.put(`/resales/edit/finalise-registration?resaleId=${resaleId}`));
};

export const getConfigurationFee = (entityId) => {
  return apiCallHandler(() => api.get(`/ResaleFeeConfiguration/FeesAvailable?EntityType=1&EntityId=${entityId}`));
};

export const postConfigurationFee = (entityId) => {
  return apiCallHandler(() => api.post(`/ResaleFeeConfiguration/createPending`, entityId))
}

export const putActivateDeactivateResale = (resaleId, isActive) => {
  return apiCallHandler(() => api.put(`/resales/edit/Activate-Deactivate?resaleId=${resaleId}&active=${isActive}`));
};

export const putPublishResale = (resaleId) => {
  return apiCallHandler(() => api.put(`/resales/edit/publish-registration?resaleId=${resaleId}`));
};


export const getResaleFeeConfiguration = (query) => {
  return apiCallHandler(() => api.get(`ResaleFeeConfiguration${query}`))
  //return apiCallHandler(() => api.get(`ResaleFeeConfiguration/fcebd3df-9869-480f-8d0c-199c2088be42`)) mocado para teste devido a valores serem apagados na base.
}

export const patchResaleChangeCardFeeStatus = (cardFeeId, status) => {
  return apiCallHandler(() => api.patch(`/Fee/${cardFeeId}/activate/${status}`));
};

export const getResalesCardsFees = (query = '') => {
  return apiCallHandler(() =>
    api.get(`/Fee${query}`)
  );
};

export const patchAllowOrderBroker = (resaleId, value) => {
  return apiCallHandler(() =>
    api.patch(`/resales/${resaleId}/broker/${value}`)
  );
};
