import {
  Grid,
  Line,
  Spinner,
} from "presentation/components";
import Button from "presentation/components/buttonV2";
import routesPaths from "presentation/routes/routesPaths";
import history from "@/services/history";
import { LoadingWrapper } from "../styles";
import GeneralData from "./generalData";
import Addresses from "./addresses";
import Contacts from "./contacts";
import BuyingRules from "./buyingRules";
import Files from "./files";

const OperatorData = ({ operator, isLoading, refresh }) => {
  return (
    <>
      {isLoading || !operator ? <LoadingWrapper><Spinner /></LoadingWrapper> :
        <>
          <Grid padding="1px 16px 16px 16px" spaceBetweenRows="32px">
            <GeneralData
              operator={operator}
              onEdited={refresh}
            />
            <Grid.Row>
              <Grid.Col>
                <Line color="#DEDCDC" />
              </Grid.Col>
            </Grid.Row>
            <Addresses
              operator={operator}
              onEdited={refresh}
            />
            <Grid.Row>
              <Grid.Col>
                <Line color="#DEDCDC" />
              </Grid.Col>
            </Grid.Row>
            <Contacts
              operator={operator}
              onEdited={refresh}
            />
            <Grid.Row>
              <Grid.Col>
                <Line color="#DEDCDC" />
              </Grid.Col>
            </Grid.Row>
            <BuyingRules
              operator={operator}
              onEdited={refresh}
            />
            <Grid.Row>
              <Grid.Col>
                <Line color="#DEDCDC" />
              </Grid.Col>
            </Grid.Row>
            <Files
              operator={operator}
              onEdited={refresh}
            />
            <Grid.Row justify="end">
              <Grid.Col xs={12} sm={6} md={3} lg={3}>
                <Button
                  onClick={() => history.push(routesPaths.operators.base)}
                >Voltar</Button>
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </>
      }
    </>
  );

};

export default OperatorData;