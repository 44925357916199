import { useState } from "react"

export const useFilter = () => {
  const [filter, setFilter] = useState({
    companies: [],
    date: {
      from: "",
      to: "",
    },
    status: [],
  })

  const removeFilter = ({ tag }: { tag: string }) => {
    setFilter({
      companies: [],
      date: {
        from: "",
        to: "",
      },
      status: [],
    })
  }

  return {
    filter,
    setFilter,
    removeFilter,
  }
}
