import styled from "styled-components";

export const StyledIconButton = styled.button`
  height: 24px;
  width: 24px;
  border: none;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
  color: #2C2D5C;
`;