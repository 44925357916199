import produce from 'immer';

const INITIAL_STATE = {
  signed: false,
  token: null,
  user: null,
  loading: false,
  signedFailured: false,
  refresh: null,
};

export default function auth(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  return produce(state, (draft) => {
    switch (type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@auth/SIGN_IN_SUCCESS': {
        draft.signed = true;
        draft.token = payload.token;
        draft.user = payload.user;
        draft.refresh = payload.refresh;
        draft.signedFailured = false;
        draft.loading = false;
        break;
      }

      case '@auth/SIGN_FAILURE': {
        draft.loading = false;
        draft.signedFailured = payload.response;
        break;
      }

      case '@auth/SIGN_IN_REJECTION': {
        draft.signedFailured = {
          data: {
            errors: [{ mensagem: 'Usuário ou Senha incorretos', type: 2 }],
          },
        };
        draft.loading = false;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.signed = false;
        draft.token = null;
        draft.signedFailured = false;
        break;
      }

      default:
    }
  });
}
