import api from '@/infra/api';
import apiCallHandler from './apiCallHandler';

export const getOperators = (query = '') => {
  return apiCallHandler(() => api.get(`/operators${query}`));
};

export const getOperator = (operatorId, query = '') => {
  return apiCallHandler(() =>
    api.get(`/operators/${operatorId}/edit${query}`)
  );
};

export const getOperatorCards = (operatorId, query = '') => {
  return apiCallHandler(() =>
    api.get(`/operators/${operatorId}/card${query}`)
  );
};

export const getOperatorsCardsFees = (query = '') => {
  return apiCallHandler(() =>
    api.get(`/products${query}`)
  );
};

export const getOperatorCardFee = (cardFeeId) => {
  return apiCallHandler(() =>
    api.get(`/products/${cardFeeId}/edit`)
  );
};

export const getOperatorCard = (operatorId, cardId) => {
  return apiCallHandler(() =>
    api.get(`/operators/${operatorId}/card/${cardId}`)
  );
};

export const getOperatorCardVinculatedFees = (cardId) => {
  return apiCallHandler(() =>
    api.get(`/operators/cards/${cardId}/fees`)
  );
};

export const getCardsForSelect = (operatorId) => {
  return apiCallHandler(() => api.get(`/products/${operatorId}/selects/cards`));
};

export const postCreateNewCard = (operatorId, payload) => {
  return apiCallHandler(() => api.post(`/operators/${operatorId}/card`, payload));
};

export const postCreateNewCardFee = (payload) => {
  return apiCallHandler(() => api.post(`/products`, payload));
};

export const putEditOperatorGeneralData = (operatorId, payload) => {
  return apiCallHandler(() => api.put(`/operators/${operatorId}/edit/general-data`, payload));
};

export const putEditOperatorAddresses = (operatorId, payload) => {
  return apiCallHandler(() => api.put(`/operators/edit/addresses?id=${operatorId}`, payload));
};

export const putEditOperatorContacts = (operatorId, payload) => {
  return apiCallHandler(() => api.put(`/operators/edit/contacts?id=${operatorId}`, payload));
};

export const putEditOperatorBuyingRules = (operatorId, payload) => {
  return apiCallHandler(() => api.put(`/operators/edit/buying-rules?id=${operatorId}`, payload));
};

export const putEditFiles = (payload) => {
  return apiCallHandler(() => api.put(`/operators/edit/documents`, payload));
};

export const putEditOperatorCard = (operatorId, cardId, payload) => {
  return apiCallHandler(() => api.put(`/operators/${operatorId}/card/${cardId}`, payload));
};

export const putEditOperatorCardFee = (cardFeeId, payload) => {
  return apiCallHandler(() => api.put(`/products/${cardFeeId}/edit`, payload));
};

export const deleteOperatorAddress = (addressId) => {
  return apiCallHandler(() => api.delete(`/operators/registration/addresses/${addressId}`));
};

export const deleteOperatorContact = (contactId) => {
  return apiCallHandler(() => api.delete(`/operators/registration/contacts/${contactId}`));
};

export const deleteOperatorBuyingRule = (ruleId) => {
  return apiCallHandler(() => api.delete(`/operators/registration/buying-rule/${ruleId}`));
};

export const patchOperatorChangeStatus = (operatorId, status) => {
  return apiCallHandler(() => api.patch(`/operators/${operatorId}/edit/activation?status=${status}`));
};

export const patchOperatorChangeCardStatus = (operatorId, cardId, status) => {
  return apiCallHandler(() => api.patch(`/operators/${operatorId}/card/${cardId}/activate/${status}`));
};

export const patchOperatorChangeCardFeeStatus = (cardFeeId, status) => {
  return apiCallHandler(() => api.patch(`/products/${cardFeeId}/activation?status=${status}`));
};