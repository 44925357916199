import styled, { css, keyframes } from "styled-components"

export const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  position: relative;
  margin-top: 32px;
`

export const Dot = styled.div`
  background-color: ${({ disabled }) => (disabled ? "rgba(158, 158, 158, 1)" : "rgba(25, 118, 210, 1)")};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  color: #fff;
  font-size: 12px;
  margin-right: 6px;
`

export const DashedLine = styled.div`
  border-top: 1px solid var(--color-secundary-06);
  width: calc(100% - 5rem);
  position: absolute;
  z-index: 0;
`

const animation = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
		transform: rotate(360deg);
	}
`

export const Step = styled.div`
  border: none;
  border-radius: 50%;
  box-shadow: 0px 0.1875rem 0.375rem rgba(0, 0, 0, 0);
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  color: var(--color-secundary-10);

  svg,
  p {
    color: var(--color-secundary-10);
  }

  svg {
    width: 24px;
    margin-right: 6px;
  }

  label {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
    background-color: #fff;
  }

  ${({ navigable }) => navigable && "cursor: pointer;"}

  ${props =>
    props.status === "notstarted" &&
    css`
      color: var(--color-secundary-13);
      background-color: var(--color-secundary-13);

      label {
        font-weight: 400;
      }
    `}

  ${props =>
    props.status === "ongoing" &&
    css`
      color: var(--color-secundary-12);
      background-color: var(--main-color);

      svg {
        animation: ${animation} 2s linear infinite;
      }
    `}

    ${props =>
    props.status === "done" &&
    css`
      color: var(--color-secundary-11);
      background-color: var(--main-color);
    `}
`
