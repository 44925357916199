import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

export const Title = styled.h1`
  font-size: 20px;
  color: #672ed7;
  margin-top: 20px;
`;

export const Text = styled.p`
  color: #74767c;
  text-align: center;
  margin-top: 20px;
`;

export const Flex = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
`;

export const BodyLoading = styled.div`
  display: flex;
  justify-content: center;
`;
