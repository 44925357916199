import { Content, Panel, Spinner } from "presentation/components";
import useService from "main/hooks/useService";
import { getResaleById } from "@/services/resalesServices";
import { useEffect, useState } from "react";
import { Steps } from "./steps";
import { CheckCNPJ } from "./steps/checkCNPJ";

const defaultValues = {
  cnpj: "",
  site: "",
  tradeName: "",
  corporateName: "",
  stateRegistration: "",
  municipalRegistration: "",
  email: "",
  Notes: "",
  document: {
    notes: "",
    startOfValidity: null,
    endOfValidity: null,
  },
  addresses: [{
    cep: "",
    addressType: 1,
    state: "",
    city: "",
    laneName: "",
    addressName: "",
    number: "",
    complement: "",
  }],
  contacts: [{
    name: '',
    phone: '',
    email: '',
    contactType: 1,
    role: '',
    extension: '',
  }],
  operatorsData: [{
    operatorId: '',
    operatorCardId: '',
    processType: '',
    user: '',
    password: '',
  }],
}

export const RegisterResale = ({ location }) => {
  const resaleIdToContinue = location.state?.resaleIdToContinue;
  const [resaleData, setResaleData] = useState(defaultValues);
  const [isRegistering, setIsRegistering] = useState(false);

  const [fetchResaleState, fetchResaleRequest] = useService(getResaleById, {
    onCompleted: (response) => setResaleData(response.data)
  });

  const abortRegister = () => {
    setResaleData(defaultValues);
    setIsRegistering(false);
  }

  useEffect(() => {
    if (resaleIdToContinue) {
      fetchResaleRequest(resaleIdToContinue);
      setIsRegistering(true);
    }
  }, []);

  const render = () => {
    if (fetchResaleState.loading) return (
      <Spinner.Box>
        <Spinner />
      </Spinner.Box>
    );
    else if (isRegistering) return (
      <Steps
        resaleData={resaleData}
        setResaleData={setResaleData}
        abortRegister={abortRegister}
      />
    );
    else return (
      <CheckCNPJ
        startRegister={(newCnpj) => {
          setResaleData(state => ({ ...state, cnpj: newCnpj }));
          setIsRegistering(true);
        }}
      />
    );
  };

  return (
    <Content>
      <Panel style={{ padding: '16px' }}>
        {render()}
      </Panel>
    </Content>
  );
};