export function operatorsCardsFeesInRequest(query) {
  return {
    type: '@operatorsCardsFees/OPERATORS_TAXES_IN_REQUEST',
    payload: { query },
  };
}

export function operatorsCardsFeesInSuccess(taxes) {
  return {
    type: '@operatorsCardsFees/OPERATORS_TAXES_IN_SUCCESS',
    payload: { taxes },
  };
}

export function operatorsCardsFeesFailure() {
  return {
    type: '@operatorsCardsFees/OPERATORS_TAXES_FAILURE',
  };
}
