import { StyledTd } from './styles';

const Td = ({ align = 'left', children, ...props }) => {
  return (
    <StyledTd align={align} {...props}>
      {children}
    </StyledTd>
  );
};

export default Td;
