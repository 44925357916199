const routesPaths = {
  operators: {
    base: "/operadoras",
    checkCnpjForRegister: "/operadoras/cadastro",
    register: "/operadoras/cadastro/novo",
    visualize: "/operadoras/dados_da_operadora",
    register_card_fee: "/operadoras/dados_da_operadora/nova_tarifa",
    visualize_card_fee: "/operadoras/dados_da_operadora/tarifa",
    register_card: "/operadoras/dados_da_operadora/novo_cartao",
    visualize_card: "/operadoras/dados_da_operadora/cartao",
  },
  fees: {
    base: "/taxas",
    register: "/taxas/cadastro/novo",
    visualize: "/taxas/dados_da_taxa",
  },
  resales: {
    base: "/revendas",
    checkCnpjForRegister: "/revendas/cadastro",
    register: "/revendas/cadastro/novo",
    visualize: "/revendas/dados_da_revenda",
    visualize_card_fee: "/revendas/dados_da_revenda/tarifa",
    new_select_fee: "/revendas/dados_da_revenda/nova_taxa",
    register_card_fee: "/revendas/dados_da_revenda/recargas",
  },
  layout: {
    base: "/layout",
    new_layout: "/layout/novo",
  },
}
export default routesPaths
