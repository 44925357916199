import { useAtom, useAtomValue } from 'jotai';
import { atomWithHash } from 'jotai-location';
import { useQueryClient } from '@tanstack/react-query';
import { PageTitle, useDocumentTitle } from '@stationkim/front-ui';
import OperatorData from './operatorData';
import {
  Content,
  Panel,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from 'presentation/components';
import OperatorCardFees from './operatorCardFees/operatorCardFees';
import { OperatorCards } from './operatorCards/operatorCards';
import { useOperator } from './hooks/useOperator';
import { operatorIdAtom } from '@/main/store';
import { useEffect } from 'react';

const operatorTabAtom = atomWithHash('operatorTab', 0)

const OperatorVisualization = () => {
  const operatorId = useAtomValue(operatorIdAtom)
  const [tabIndex, setTabIndex] = useAtom(operatorTabAtom)
  const { operator, isLoading: isOperatorLoading } = useOperator({ operatorId })
  const queryClient = useQueryClient()
  const documentTitle = useDocumentTitle()

  useEffect(() => {
    if (operator)
      documentTitle.change({ title: 'Operadora - ' + operator?.tradeName })
  }, [operator])

  return (
    <Content>
      <Panel noPadding>
        <PageTitle pageTitle={operator?.tradeName ?? 'Operadora'} />
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
        >
          <TabList style={{ marginTop: 1, marginLeft: 16 }}>
            <Tab>Dados da Operadora</Tab>
            <Tab disabled={!operator}>Cartões</Tab>
            <Tab disabled={!operator}>Tarifas</Tab>
          </TabList>
          <TabPanel padding="32px 0 0 0">
            <OperatorData
              operator={operator}
              isLoading={isOperatorLoading}
              refresh={() => queryClient.invalidateQueries({ queryKey: ['operator'] })}
            />
          </TabPanel>
          <TabPanel padding="32px 0 0 0">
            <OperatorCards
              operator={{ ...operator, id: operatorId }}
            />
          </TabPanel>
          <TabPanel padding="32px 0 0 0">
            <OperatorCardFees
              operator={{ ...operator, id: operatorId }}
            />
          </TabPanel>
        </Tabs>
      </Panel>
    </Content>
  );
};

export default OperatorVisualization;
