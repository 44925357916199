import { validateEmail } from "validations-br";

export const rules = {
  fullName: {
    required: { value: true, message: "Campo obrigatório" },
  },
  email: {
    validate: {
      isEmailValid: e => validateEmail(e) || e.length === 0 || "E-mail inválido",
    },
  },
  contactType: {
    required: { value: true, message: "Campo obrigatório" },
  },
  extension:{
    maxLength: { value: 5, message: "Preencha o campo completo" },
  },
  phone: {
    validate: {
      isComplete: value => {
        if (!value) return true;
        const isEmpty = value.length === 0;
        const isCompleted = value.length >= 10;
        return isEmpty || isCompleted || "Insira o telefone completo"
      },
    }
  }
};