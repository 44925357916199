import React, { useRef, useState } from "react";
import _ from "lodash";
import { Grid, IconButton, Text } from "presentation/components";
import { AddEntrieTextButton, CategorieTitleWrapper, EntriesCounter, OperatorInformation } from "../styles";
import { ADDRESSES_TYPES } from "main/utils/constants";
import AddressModal from "./editionModals/addressModal";
import { FaPencilAlt, FaRegTrashAlt } from "react-icons/fa";
import useService from "main/hooks/useService";
import { deleteOperatorAddress } from "@/services/operatorsServices";
import { useDispatch } from "react-redux";
import { pushNotification } from "@/store/modules/notification/actions";
import successHandler from "main/utils/successHandler";
import { returnValueOrMessageIfIsEmpty } from "main/utils/functionUtils";
const emptyMessage = "Não informado";

const Addresses = ({
  operator,
  onEdited = () => null
}) => {

  const dispatch = useDispatch();
  const [isAddressModalFormOpen, setIsAddressModalFormOpen] = useState(false);
  const [, deleteAddressRequest] = useService(deleteOperatorAddress, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler("Endereço removido")));
      onEdited();
    },
  });
  const addressIndexToEdit = useRef(null);
  const isMultiLine = operator.addresses?.length > 1;

  const defineAddressToEdit = (addressIndex) => {
    addressIndexToEdit.current = addressIndex;
    setIsAddressModalFormOpen(true);
  }

  const addressesRender = (operator) => {
    if (!(operator.addresses?.length > 0)) return;
    const mainAddressIndex = operator.addresses.findIndex(address => address.addressType === ADDRESSES_TYPES.MAIN);
    const addressesToRender = [...operator.addresses];
    if (mainAddressIndex) {
      [addressesToRender[0], addressesToRender[mainAddressIndex]] = [addressesToRender[mainAddressIndex], addressesToRender[0]];
    }
    return addressesToRender.map((address, index) => (
      <React.Fragment key={_.uniqueId()}>
        {isMultiLine &&
          <Grid.Row>
            <Grid.Col>
              <EntriesCounter>
                <Text.SubtitleTwo>{index + 1}.</Text.SubtitleTwo>
                <IconButton onClick={() => defineAddressToEdit(index)}><FaPencilAlt /></IconButton>
                {address.addressType !== ADDRESSES_TYPES.MAIN &&
                  <IconButton onClick={() => deleteAddressRequest(address.id)}><FaRegTrashAlt /></IconButton>
                }
              </EntriesCounter>
            </Grid.Col>
          </Grid.Row>
        }
        <Grid.Row>
          <Grid.Col xs={12} sm={6} md={3} lg={3}>
            <Text.SubtitleTwo>CEP</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(address?.cep, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
            <Text.SubtitleTwo>Estado</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(address?.state, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs", "sm"]}>
            <Text.SubtitleTwo>Cidade</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(address?.city, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs", "sm"]}>
            <Text.SubtitleTwo>Nome do Endereço</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(address?.name, emptyMessage)}</OperatorInformation>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col xs={12} sm={6} md={3} lg={3}>
            <Text.SubtitleTwo>Endereço {index + 1}</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(address?.laneName, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
            <Text.SubtitleTwo>Número</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(address?.number, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs", "sm"]}>
            <Text.SubtitleTwo>Tipo de Endereço</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(ADDRESSES_TYPES[address.addressType], emptyMessage)}</OperatorInformation>
          </Grid.Col>
        </Grid.Row>
      </React.Fragment>
    ));
  };

  return (
    <>
      <Grid.Row>
        <Grid.Col xs={12}>
          <CategorieTitleWrapper>
            <Text.Subtitle>{isMultiLine ? "Endereços" : "Endereço"}</Text.Subtitle>
            {(!isMultiLine && operator.addresses?.length > 0) ?
              <IconButton onClick={() => defineAddressToEdit(0)}><FaPencilAlt /></IconButton>
              : null
            }
          </CategorieTitleWrapper>
        </Grid.Col>
      </Grid.Row>
      {addressesRender(operator)}
      <Grid.Row>
        <Grid.Col xs={12}>
          <AddEntrieTextButton onClick={() => setIsAddressModalFormOpen(true)}>Adicionar endereço</AddEntrieTextButton>
        </Grid.Col>
      </Grid.Row>
      {isAddressModalFormOpen &&
        <AddressModal
          open={isAddressModalFormOpen}
          addressIndex={addressIndexToEdit.current}
          operator={operator}
          onClose={(hasEdited) => {
            setIsAddressModalFormOpen(false);
            addressIndexToEdit.current = null;
            hasEdited && onEdited();
          }}
        />
      }
    </>
  );
};

export default Addresses;