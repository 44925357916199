import api from '@/infra/api'
import { IFetchHook } from '@stationkim/front-ui'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

const defaultValue = {
  data: [],
  pageNumber: 1,
  pageSize: 10,
  totalItens: 0,
  totalPages: 1,
}

interface IApiReturn {
  data: Array<{
    cnpj: string
    code: string
    id: string
    status: number
    tradeName: string
  }>
  pageNumber: number
  pageSize: number
  totalItens: number
  totalPages: number
}

export const useOperators = ({ filter, pagination, immutableQuery }: IFetchHook) => {
  const { data, ...rest } = useQuery<AxiosResponse<IApiReturn>>({
    queryKey: ['operators', filter, pagination, immutableQuery],
    queryFn: () =>
      api.get(`/operators`, {
        params: { ...pagination, ...filter, ...immutableQuery },
      }),
  })

  return {
    operators: data?.data || defaultValue,
    ...rest,
  }
}
