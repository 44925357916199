import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import notifications from './notification/reducer';
import operatorCard from './cards/reducer';
import operatorsCardsFees from './operatorsCardsFees/reducer';
import resalesCardsFees from './resalesCardsFees/reducer';
import operatorsReview from './finishOperators/reducer';
import fees from './fees/reducer';

export default combineReducers({
  auth,
  user,
  notifications,
  operatorCard,
  operatorsCardsFees,
  operatorsReview,
  fees,
  resalesCardsFees,
});
