import { Button } from 'presentation/components';
import Spinner from 'presentation/components/spinner';
import Modal from 'react-responsive-modal';
import { ButtonWrapper, Content, LoadingContainer } from '../styles';

const OneOption = ({
  open,
  onClose,
  onOptionClick,
  optionText = 'Ok',
  children,
  loading = false,
  buttonPositon = 'center',
  ...props
}) => {
  return (
    <Modal open={open} onClose={onClose} center {...props}>
      {loading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <Content>
          {children}
          <ButtonWrapper buttonPositon={buttonPositon}>
            <Button onClick={onOptionClick}>{optionText}</Button>
          </ButtonWrapper>
        </Content>
      )}
    </Modal>
  );
};

export default OneOption;
