import styled from 'styled-components';

export const StatusDot = styled.div`
  border-radius: 50%;
  margin-right: 8px;
  height: 8px;
  width: 8px;
  background-color: ${({ status }) =>
    status === 1 ? 'var(--color-status-01)' : 'var(--color-status-02)'};
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TextCenter = styled.div`
  text-align: center;
  margin: auto;
`;

export const Form = styled.form`
  label {
    display: block;
    margin-bottom: 8px;
  }
`;
