import { RatesProvider } from "./provider/RatesProvider"
import { TableRates } from "./components/Table/TableRates"
import { ControlButtons } from "./components/controlButtons/controlButtons"
import { FinancedDefaultRates } from "./components/financedDefaultRates/financedDefaultRates"

interface TermProps {
  id: string
}

export const Term: React.FC<TermProps> = ({ id }) => {
  return (
    <RatesProvider id={id}>
      <FinancedDefaultRates />
      <TableRates />
      <ControlButtons />
    </RatesProvider>
  )
}
