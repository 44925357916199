import React from 'react'
import { Body } from './styles'

const Content = ({ children, limit, props }: { children?: any; limit?: string; props?: any }) => {
  return (
    <Body limit={limit} {...props}>
      {children}
    </Body>
  )
}

export default Content
