import { useState, useEffect } from 'react';
import { Input, Spacing, Button, Notification, Spinner } from 'presentation/components';
import history from '@/services/history';
import { Main, Body, Title, Label, ForgetPassword, Content } from './styles';
import { pushNotification } from '@/store/modules/notification/actions';
import { signOut } from '@/store/modules/auth/actions';
import { useSelector, useDispatch } from 'react-redux';
import { signInRequest } from '@/store/modules/auth/actions';
import errorHandler from 'main/utils/errorHandler';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const requestLogin = (ev) => {
    ev.preventDefault();
    dispatch(signInRequest(email, password));
  };

  useEffect(() => {
    if (auth.signedFailured && !auth.loading) {
      dispatch(pushNotification(errorHandler(auth.signedFailured.response)));
      dispatch(signOut());
    }
    //eslint-disable-next-line
  }, [auth]);

  return (
    <Main>
      <Notification />
      <Body>
        {auth.loading && (
          <div style={{ display: 'flex', margin: '0 auto' }}>
            <Spinner />
          </div>
        )}
        {!auth.loading && (
          <>
            <Title>Acesse aqui sua conta</Title>
            <form onSubmit={requestLogin}>
              <Content>
                <Label>E-mail</Label>
                <Input
                  type="email"
                  required={true}
                  value={email}
                  onChange={(ev) => setEmail(ev.target.value)}
                />
                <Spacing top="30px" />
                <Label>Senha</Label>
                <Input
                  type="password"
                  required={true}
                  value={password}
                  onChange={(ev) => setPassword(ev.target.value)}
                />
                <Spacing top="30px" />
                <Button type="submit">Entrar</Button>
                <ForgetPassword
                  onClick={() => history.push('/recuperar_senha')}
                >
                  Esqueci minha senha
                </ForgetPassword>
              </Content>
            </form>
          </>
        )}
      </Body>
    </Main>
  );
};

export default Login;
