import { StyledStatusTag } from "./styles";

const colors = {
  green: {
    bkg: "#e9fff5",
    dotBkg: "#00e077",
  },
  red: {
    bkg: "#ffe9e3",
    dotBkg: "#e02e00",
  },
  yellow: {
    bkg: "#fff8e1",
    dotBkg: "#fecd2b",
  }
};

const StatusTag = ({
  color = "green",
  customColor = null,
  children,
  props
}) => {
  return (
    <StyledStatusTag
      color={customColor ?? colors[color]}
      customColor={customColor}
      {...props}
    >{children}</StyledStatusTag>
  );
};

export default StatusTag;