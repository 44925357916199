import { getOperatorCards } from '@/services/operatorsServices';
import { all, takeLatest, call, put } from 'redux-saga/effects';
import { operatorCardsInSuccess, operatorCardsFailure } from './actions';

function* getCardsOperators({ payload }) {
  const { error, response } = yield call(
    getOperatorCards,
    payload.operatorId,
    payload.query
  );

  const cards = response.data;
  if (response)
    yield put(operatorCardsInSuccess(cards));
  else
    yield put(operatorCardsFailure(error.response));
}

export default all([
  takeLatest('@operatorCards/OPERATOR_CARDS_IN_REQUEST', getCardsOperators),
]);
