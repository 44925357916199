import {
  BENEFIT_TYPES,
  OPERATORS_STATUS,
  ADDRESSES_TYPES,
  OPERATOR_BUYING_RULE_TYPES,
  CONTACT_TYPES,
  OPERATOR_CARD_FEE_STATUS,
  OPERATOR_CARD_PROCESS_TYPE,
} from './constants';

export const states = [
  { label: 'AC', value: 'AC' },
  { label: 'AL', value: 'AL' },
  { label: 'AM', value: 'AM' },
  { label: 'AP', value: 'AP' },
  { label: 'BA', value: 'BA' },
  { label: 'CE', value: 'CE' },
  { label: 'DF', value: 'DF' },
  { label: 'ES', value: 'ES' },
  { label: 'GO', value: 'GO' },
  { label: 'MA', value: 'MA' },
  { label: 'MT', value: 'MT' },
  { label: 'MS', value: 'MS' },
  { label: 'MG', value: 'MG' },
  { label: 'PA', value: 'PA' },
  { label: 'PB', value: 'PB' },
  { label: 'PR', value: 'PR' },
  { label: 'PE', value: 'PE' },
  { label: 'PI', value: 'PI' },
  { label: 'RJ', value: 'RJ' },
  { label: 'RN', value: 'RN' },
  { label: 'RO', value: 'RO' },
  { label: 'RS', value: 'RS' },
  { label: 'RR', value: 'RR' },
  { label: 'SC', value: 'SC' },
  { label: 'SE', value: 'SE' },
  { label: 'SP', value: 'SP' },
  { label: 'TO', value: 'TO' },
];

export const branches = [
  { label: 'Atendimento ao Cliente', value: '47' },
  { label: 'Biologia', value: '43' },
  { label: 'Compras', value: '41' },
  { label: 'Contabilidade', value: '42' },
  { label: 'Informática', value: '40' },
  { label: 'Sociologia', value: '44' },
  { label: 'Televendas', value: '45' },
  { label: 'Vendas', value: '39' },
];

export const numberOfBeneficiaries = [
  { label: 'Individual/Autônomo', value: 1 },
  { label: '2 a 10 colaboradores', value: 2 },
  { label: '11 a 50 colaboradores', value: 3 },
  { label: '51 a 100 colaboradores', value: 4 },
  { label: '101 a 500 colaboradores', value: 5 },
  { label: 'Mais de 500 colaboradores', value: 6 },
];

export const productStatus = [
  { label: 'ATIVO', value: '1' },
  { label: 'INATIVO', value: '2' },
  { label: 'BLOQUEADO', value: '3' },
];

export const benefitTypes = [
  { label: 'Vale Refeição', value: BENEFIT_TYPES.FOOD },
  { label: 'Vale Alimentação', value: BENEFIT_TYPES.SNACK },
  { label: 'Vale Combustível', value: BENEFIT_TYPES.FUEL },
  { label: 'Vale Transporte Eletrônico', value: BENEFIT_TYPES.TRANSPORT },
  { label: 'Vale Transporte Papel', value: BENEFIT_TYPES.TRANSPORT_PAPER },
];

export const typeFees = [
  { label: 'Hospedagem', value: 1 },
  { label: 'Setup', value: 2 },
  { label: 'Sustentação', value: 3 },
  { label: 'Uso do Sistema', value: 4 },
];

export const typeValueFees = [
  { label: 'Porcentagem', value: 1 },
  { label: 'Reais', value: 2 },
  { label: 'Reais + Porcentagem', value: 3 },
];

export const typeApplicationFees = [
  { label: 'Revendas', value: 1 },
  { label: 'Parceiros de Negócio', value: 2 },
  { label: 'Todos', value: 3 },
];

export const orderStatus = [
  { label: 'Aguardando pagamento', value: '3' },
  { label: 'Cancelado', value: '16' },
  { label: 'Concluído', value: '15' },
  { label: 'Disponível para retirada', value: '10' },
  { label: 'Em andamento', value: '6' },
  { label: 'Enviando produto', value: '11' },
  { label: 'Rascunho', value: '0' },
];

export const operatorCardFeeStatus = [
  { label: 'Ativo', value: OPERATOR_CARD_FEE_STATUS.ACTIVE },
  { label: 'Inativo', value: OPERATOR_CARD_FEE_STATUS.INACTIVE },
];

export const operatorsStatus = [
  { label: 'Ativo', value: OPERATORS_STATUS.ACTIVE },
  { label: 'Inativo', value: OPERATORS_STATUS.INACTIVE },
  { label: 'Pendente', value: OPERATORS_STATUS.PENDING },
];

export const addressesTypes = [
  { label: 'Coleta de bilhete', value: ADDRESSES_TYPES.TICKET },
  { label: 'Coleta de cartão', value: ADDRESSES_TYPES.CARD },
  { label: 'Principal', value: ADDRESSES_TYPES.MAIN },
  { label: 'Outro', value: ADDRESSES_TYPES.OTHER },
];

export const contactsTypes = [
  { label: 'Comercial', value: CONTACT_TYPES.COMMERCIAL },
  { label: 'Financeiro', value: CONTACT_TYPES.FINANCIAL },
  { label: 'Jurídico', value: CONTACT_TYPES.LEGAL },
  { label: 'Principal', value: CONTACT_TYPES.MAIN },
  { label: 'RH', value: CONTACT_TYPES.RH },
  { label: 'Outro', value: CONTACT_TYPES.OTHER },
];

export const operatorCardBuyingRulesTypes = [
  { label: 'Cartão', value: OPERATOR_BUYING_RULE_TYPES.CARD },
  { label: 'VT Papel', value: OPERATOR_BUYING_RULE_TYPES.PHYSICAL },
  { label: 'Recarga', value: OPERATOR_BUYING_RULE_TYPES.RECHARGE },
];

export const operatorCardProcessType = [
  { label: 'Broker', value: OPERATOR_CARD_PROCESS_TYPE.BROKER },
  { label: 'Dealer', value: OPERATOR_CARD_PROCESS_TYPE.DEALER },
];