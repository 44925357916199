import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';

const styles = {
  borderRadius: '5px',
  modal: {
    maxWidth: '1000px',
    minWidth: '400px',
    width: '80%',
    margin: '0',
  },
};

const InfoModalContainer = ({ children, onClose, open, showCloseIcon = false, ...props }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      showCloseIcon={showCloseIcon}
      styles={styles}
      center
      {...props}
    >
      {children}
    </Modal>
  );
};

export default InfoModalContainer;
