import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useAtomValue } from "jotai";
import { atomWithHash } from "jotai-location";
import { atomWithHashAndName, AutoTable, Button, FilterDialog, FormSelect, Grid, Icon, TableFooterPagination, useFilter, usePagination } from "@stationkim/front-ui";

import routesPaths from "presentation/routes/routesPaths";
import history from "@/services/history";
import { FORM_MODE, NOTIFICATION_TYPE, OPERATOR_CARD_FEE_STATUS } from "main/utils/constants";
import { pushNotification } from "@/store/modules/notification/actions";
import { getTableConfiguration } from "./utils/getTableConfiguration";
import { useOperatorCardsFees } from "./hooks/useOperatorCardsFees";
import { operatorIdAtom } from "@/main/store";
import { operatorCardFeeStatus } from "@/main/utils/options";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "@/infra/api";
import successHandler from "@/main/utils/successHandler";
import { StatusToggleDialog } from "../../components/statusToggleDialog";

const initialFilter = { sort: 'code', desc: false }
const filterAtom = atomWithHashAndName<object>('operatorsFilter', initialFilter)
const paginationAtom = atomWithHash<object>('operatorsPagination', null)

const OperatorCardFees = ({ operator }) => {
  const dispatch = useDispatch();
  const operatorId = useAtomValue(operatorIdAtom)
  const { filter, resetFilter, updateFilter } = useFilter({ filterAtom, initialValue: initialFilter })
  const { pagination, goToPage, setPageSize } = usePagination({ paginationAtom: paginationAtom })
  const { operatorCardsFees, isFetching: isOperatorCardsFeesLoading } = useOperatorCardsFees({ operatorId, filter, pagination })
  const queryClient = useQueryClient()
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [cardFee, setCardFee] = useState(null);
  const isChangeStatusConfirmationOpen = Boolean(cardFee)
  const isCardFeeActive = cardFee?.status === 1

  const filterForm = useForm({ defaultValues: { status: '' } });

  const operatorCardFeeStatusMutation = useMutation({
    mutationFn: ({
      cardFeeId,
      status
    }: {
      cardFeeId: string
      status: number
    }) => api.patch(`/products/${cardFeeId}/activation?status=${status}`),
  })

  const generateLink = (cardFeeId, mode) => {
    return (
      `${routesPaths.operators.visualize_card_fee}#operatorId="${operatorId}"&cardFeeId="${cardFeeId}"`
      + `&operatorCardFeesFormMode="${mode}"&operatorInfo=${JSON.stringify({ tradeName: operator.tradeName, code: operator.code })}`
    )
  }

  const sendToCardFeeVisualization = (cardFeeId, mode) => {
    history.push(generateLink(cardFeeId, mode))
  }


  useEffect(() => {
    if (filter)
      for (const key in filter) {
        filterForm.setValue(key as any, filter[key] ?? '')
      }
  }, [filter])

  return (
    <>
      <Grid container spacing='16px' padding={'0px 32px 0'} >
        <Grid item xs={12} sm={6} md='auto'>
          <Button
            onClick={() => setIsFilterOpen(true)}
            startIcon={<Icon icon={faFilter} />}
            variant="outlined"
            fullWidth
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md='auto' sx={{ marginLeft: 'auto' }}>
          <Button
            disabled={!operator.tradeName}
            variant="contained"
            fullWidth
            onClick={() => {
              history.push({
                pathname: routesPaths.operators.register_card_fee,
                state: {
                  operator
                }
              })
            }}
          >
            Nova Tarifa
          </Button>
        </Grid>
        <Grid item xs={12}>
          <AutoTable
            link={({ row }) => generateLink(row.id, FORM_MODE.VIEW)}
            rows={operatorCardsFees?.data}
            isLoading={isOperatorCardsFeesLoading}
            columns={getTableConfiguration({
              onEdit: ({ card }) => {
                if (card.status === OPERATOR_CARD_FEE_STATUS.ACTIVE)
                  sendToCardFeeVisualization(card.id, FORM_MODE.EDIT)
                else dispatch(pushNotification({
                  type: NOTIFICATION_TYPE.WARNING,
                  content: "Para editar uma tarifa é necessário que ela esteja ativa."
                }));
              },
              onStatusChange: ({ card }) => {
                setCardFee(card);
              }
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <TableFooterPagination
            totalItems={operatorCardsFees.totalItens}
            rowsPerPage={operatorCardsFees.pageSize}
            totalPages={operatorCardsFees.totalPages}
            page={operatorCardsFees.pageNumber}
            onRowsPerPageChange={(e: any) => setPageSize(e.target.value)}
            onPageChange={(_e, value) => goToPage(Number(value))}
          />
        </Grid>
      </Grid>

      <FilterDialog
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onClean={() => {
          resetFilter()
          setIsFilterOpen(false)
        }}
        form={filterForm}
        onFilter={(filter) => {
          updateFilter(filter)
          setIsFilterOpen(false)
        }}
      >
        <FormSelect
          name="status"
          placeholder="Selecione"
          options={operatorCardFeeStatus}
        />
      </FilterDialog>
      <StatusToggleDialog
        open={isChangeStatusConfirmationOpen}
        togglingItem="tarifa"
        isActive={isCardFeeActive}
        onClose={() => setCardFee(null)}
        onConfirm={() =>
          operatorCardFeeStatusMutation.mutate({
            cardFeeId: cardFee.id,
            status: isCardFeeActive ? 2 : 1
          }, {
            onSuccess: () => {
              setCardFee(null)
              dispatch(pushNotification(successHandler(`Tarifa ${isCardFeeActive ? "inativada" : "ativada"}!`)));
              queryClient.invalidateQueries({ queryKey: ['operatorCardsFees'] })
            }
          })
        }
      />
    </>
  );
};

export default OperatorCardFees;