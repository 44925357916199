import { getOperatorsCardsFees } from '@/services/operatorsServices';
import { all, takeLatest, call, put } from 'redux-saga/effects';
import { operatorsCardsFeesInSuccess, operatorsCardsFeesFailure } from './actions';

function* fetchOperatorsCardsFees({ payload }) {
  const { response } = yield call(getOperatorsCardsFees, payload.query);
  if (response) yield put(operatorsCardsFeesInSuccess(response.data));
  else yield yield put(operatorsCardsFeesFailure());
}

export default all([
  takeLatest('@operatorsCardsFees/OPERATORS_TAXES_IN_REQUEST', fetchOperatorsCardsFees),
]);
