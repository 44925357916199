import styled from 'styled-components';

export const SearchContainer = styled.div`
  position: relative;

  svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
    top: 14px;
    left: 10px;
    position: absolute;
    color: var(--color-primary-03);
  }
`;

export const Field = styled.input`
  background-color: #ececec;
  border: 1px solid #999999;
  border-radius: 6px;
  font-size: 1rem;
  color: #666666;
  line-height: 24px;
  padding-left: 40px;
  padding-right: 20px;
  height: 48px;
  width: 100%;
  outline: none;

  ::placeholder {
    color: #666666;
  }
`;
