import { ErrorWrapper, Grid } from "presentation/components";
import Select from "presentation/components/input/select";
import useDebounce from "main/hooks/useDebouce";
import useService from "main/hooks/useService";
import { getAddressInformationByCEP } from "@/services/externalServices";
import { addressesTypes } from "main/utils/options";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import { Input, Label } from "@stationkim/components-lib";
import React from "react";
import { Controller } from "react-hook-form";
import { rules } from "../rules/addressesRules";

const FormError = ({ name, fieldErrors }) => {
  if (!fieldErrors) return;
  return (
    <ErrorMessage
      errors={fieldErrors}
      name={name}
      render={({ message }) =>
        <Input.Message>{message}</Input.Message>
      }
    />
  );
};

export const AddressFields = ({
  index,
  fieldBaseName,
  fieldErrors,
  setValue,
  control,
  register,
  remove
}) => {
  const debounce = useDebounce();
  const [getAddressDataState, getAddressDataRequest] = useService(getAddressInformationByCEP, {
    onCompleted: response => {
      if (!response.data.error) {
        const address = response.data;
        setValue(fieldBaseName + "laneName", address.logradouro, { shouldValidate: true });
        setValue(fieldBaseName + "city", address.localidade, { shouldValidate: true });
        setValue(fieldBaseName + "state", address.uf, { shouldValidate: true });
      }
    },
  });

  const requestAddressData = (cepValue) => {
    const isComplete = cepValue.length === 8;
    if (isComplete) {
      getAddressDataRequest(cepValue);
    }
  };

  return (
    <React.Fragment >
      {index !== 0 ?
        <Grid.Row>
          <Grid.Col style={{ display: 'flex', gap: '16px', margin: '32px 0' }}>
            Informe o endereço
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ cursor: 'pointer' }}
              onClick={() => remove(index)}
            />
          </Grid.Col>
        </Grid.Row> : null
      }
      <Grid.Row align="start">
        <Grid.Col md={3.5}>
          <Controller
            control={control}
            rules={rules.cep}
            name={fieldBaseName + 'cep'}
            render={({ field: { value, onChange } }) => (
              <Input.Root
                state={fieldErrors?.cep ? 'error' : null}
                full
              >
                <Input.Label>
                  CEP
                </Input.Label>
                <Input.Field>
                  <Input.Masked
                    mask='00000-000'
                    maxLength={150}
                    placeholder="000000-000"
                    value={value}
                    onChange={e => {
                      const inputValue = e.target.value;
                      const unmasked = inputValue.replace("-", "");
                      onChange(unmasked);
                      debounce(() => requestAddressData(unmasked), 200);
                    }}
                  />
                </Input.Field>
                <FormError fieldErrors={fieldErrors} name="cep" />
              </Input.Root>
            )}
          />
        </Grid.Col>
        <Grid.Col md={3.5}>
          <Input.Root
            state={fieldErrors?.laneName ? 'error' : null}
            full
            blocked={getAddressDataState.loading ||
              !!getAddressDataState.response?.data?.logradouro
            }
          >
            <Input.Label>
              Endereço
            </Input.Label>
            <Input.Field>
              <Input.Input
                {...register(fieldBaseName + 'laneName', { ...rules.laneName })}
                placeholder="Endereço"
                maxLength={120}
              />
            </Input.Field>
            <FormError fieldErrors={fieldErrors} name="laneName" />
          </Input.Root>
        </Grid.Col>
        <Grid.Col md={2}>
          <Input.Root
            state={fieldErrors?.number ? 'error' : null}
            full
          >
            <Input.Label>
              Número
            </Input.Label>
            <Input.Field>
              <Input.Input
                {...register(fieldBaseName + 'number', rules.number)}
                placeholder="N°"
                maxLength={5}
              />
            </Input.Field>
            <FormError fieldErrors={fieldErrors} name="number" />
          </Input.Root>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row align="start">
        <Grid.Col md={3.5}>
          <Input.Root
            state={fieldErrors?.complement ? 'error' : null}
            full
          >
            <Input.Label>
              Complemento
            </Input.Label>
            <Input.Field>
              <Input.Input
                {...register(fieldBaseName + "complement", { ...rules.complement })}
                placeholder="Complemento"
                maxLength={90}
              />
            </Input.Field>
            <FormError fieldErrors={fieldErrors} name="complement" />
          </Input.Root>
        </Grid.Col>
        <Grid.Col md={3.5}>
          <Input.Root
            state={fieldErrors?.city ? 'error' : null}
            full
            blocked
          >
            <Input.Label>
              Cidade
            </Input.Label>
            <Input.Field>
              <Input.Input
                {...register(fieldBaseName + 'city', rules.city)}
                placeholder="Cidade"
              />
            </Input.Field>
            <FormError fieldErrors={fieldErrors} name="state" />
          </Input.Root>
        </Grid.Col>
        <Grid.Col md={2}>
          <Input.Root
            state={fieldErrors?.state ? 'error' : null}
            full
            blocked
          >
            <Input.Label>
              Estado
            </Input.Label>
            <Input.Field>
              <Input.Input
                {...register(fieldBaseName + 'state', rules.state)}
                placeholder="Cidade"
              />
            </Input.Field>
            <FormError fieldErrors={fieldErrors} name="state" />
          </Input.Root>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col md={3.5}>
          <Input.Root
            state={fieldErrors?.addressName ? 'error' : null}
            full
          >
            <Input.Label>
              Nome do Endereço
            </Input.Label>
            <Input.Field>
              <Input.Input
                {...register(fieldBaseName + 'addressName', rules.addressName)}
                placeholder="Nome do Endereço"
                maxLength={30}
              />
            </Input.Field>
            <FormError fieldErrors={fieldErrors} name="addressName" />
          </Input.Root>
        </Grid.Col>
        <Grid.Col md={3.5}>
          <Controller
            control={control}
            name={fieldBaseName + 'addressType'}
            rules={rules.addressType}
            render={({ field: { value, onChange } }) => (
              <ErrorWrapper>
                <Label style={{ display: 'block', marginBottom: "8px" }}>Tipo de Endereço</Label>
                <Select
                  isDisabled={index === 0}
                  isClearable={false}
                  defaultValue={1}
                  value={addressesTypes.find(option => option.value === value)}
                  onChange={option => onChange(option.value)}
                  placeholder="Selecione..."
                  options={addressesTypes}
                />
                {!fieldErrors ? null :
                  <ErrorMessage
                    errors={fieldErrors}
                    name={"addressType"}
                  />}
              </ErrorWrapper>
            )}
          />
          <FormError fieldErrors={fieldErrors} name="addressType" />
        </Grid.Col>
      </Grid.Row>
    </React.Fragment>
  )
};