export function resalesCardsFeesInRequest(query) {
  return {
    type: '@resalesCardsFees/RESALES_TAXES_IN_REQUEST',
    payload: { query },
  };
}

export function resalesCardsFeesInSuccess(taxes) {
  return {
    type: '@resalesCardsFees/RESALES_TAXES_IN_SUCCESS',
    payload: { taxes },
  };
}

export function resalesCardsFeesFailure(error) {
  return {
    type: '@resalesCardsFees/RESALES_TAXES_FAILURE',
    payload: { error },
  };
}
