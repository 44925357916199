import { forwardRef } from 'react';
import { StyledTextarea } from './styles';

const Textarea = forwardRef(({
  height = "148px",
  resize = "none",
  ...props
}, ref) => {
  return <StyledTextarea
    ref={ref}
    height={height}
    resize={resize}
    {...props}
  />;
});
export default Textarea;
