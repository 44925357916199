import { useState, useMemo } from 'react';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import api from '@/infra/api';
import errorHandler from 'main/utils/errorHandler';
import { pushNotification } from '@/store/modules/notification/actions';
import { useDispatch } from 'react-redux';
import Modal from '../../components/modalCancel';
import ModalLoading from '../../components/modalLoading';
import { Input, Button, ErrorMessage, Notification, Icon } from 'presentation/components';
import {
  Body,
  Title,
  SubTitle,
  Space,
  Label,
  FlexRight,
  Limited,
  SessionAddAddress,
  IconAdd,
  TextAdd,
} from './styles';
import { CONTACT_TYPES, NOTIFICATION_TYPE } from 'main/utils/constants';

const Contact = ({ setPosition, id }) => {
  const [loading, setLoading] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [ids, setIds] = useState([]);

  const screenClass = useScreenClass();
  const dispatch = useDispatch();

  const typesContact = [
    { label: 'Principal', value: 1 },
    { label: 'RH', value: 2 },
    { label: 'Financeiro', value: 3 },
    { label: 'Comercial', value: 4 },
    { label: 'Jurídico', value: 5 },
    { label: 'Outro', value: 6 },
  ];

  const schemaContact = yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required('Campo obrigatório')
        .max(30, 'Campo limitado a 30 caracteres'),
      phone: yup.string("").when('email', {
        is: (value) => !value?.length,
        then: yup.string("").required('Telefone ou E-mail são obrigatórios'),
      }),
      extension: yup.string().max(5, 'Campo limitado a 5 caracteres'),
      email: yup
        .string()
        .email('Email inválido')
        .max(100, 'Campo limitado a 100 caracteres')
        .when('phone', {
          is: (value) => !value?.length,
          then: yup.string().required('Telefone ou E-mail são obrigatórios'),
        }),
      role: yup.string().max(50, 'Campo limitado a 50 caracteres'),
      contactType: yup.number().integer().strict(),
    }, ['email', 'phone'])
  );

  const schema = yup.object().shape({
    abortEarly: false,
    contacts: schemaContact,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      contacts: [
        {
          contactType: 1,
        },
      ],
    },
  });

  const contactsFieldArray = useFieldArray({
    control: control,
    name: 'contacts',
  });

  const onSubmit = async (data) => {

    const mainAddresses = data.contacts.filter((contact) => contact.contactType === CONTACT_TYPES.MAIN);
    const hasMainAddress = mainAddresses.length > 0;
    const hasMoreThanOneMainAddress = mainAddresses.length > 1;

    if (!hasMainAddress) {
      dispatch(pushNotification({
        type: NOTIFICATION_TYPE.ERROR,
        content: "É necessário ter ao menos um contato principal."
      }));
      return;
    };
    if (hasMoreThanOneMainAddress) {
      dispatch(pushNotification({
        type: NOTIFICATION_TYPE.ERROR,
        content: "Só é possível ter um contato principal."
      }));
      return;
    };

    try {
      setLoading(true);
      await api.post(`/operators/registration/contacts?id=${id}`, data.contacts);
      setLoading(false);
      setPosition(3);
    } catch (e) {
      dispatch(pushNotification(errorHandler(e?.response)));
      setLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/operators/${id}/registration/contacts`);

      if (response?.data?.length) {
        contactsFieldArray.remove(0);

        const reverse = await response.data.reverse();

        let arrayIds = [];

        // eslint-disable-next-line
        await reverse.map((values) => {
          arrayIds.push({ id: values.id });
          contactsFieldArray.append({
            name: values.name,
            phone: values.phone || "",
            extension: values.extension,
            email: values.email,
            role: values.role,
            contactType: values.contactType,
            id: values.id,
          });
        });
        setIds(arrayIds);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useMemo(() => {
    if (id) getData();
    // eslint-disable-next-line
  }, [id]);

  const removeLine = async (index) => {
    const idLine = ids[index];
    setLoading(true);

    if (idLine?.id) {
      try {
        await api.delete(
          `operators/registration/contacts/${idLine.id}?operatorId=${id}`
        );
        contactsFieldArray.remove(index);
        setLoading(false);
        return;
      } catch (e) {
        dispatch(pushNotification(errorHandler(e?.response)));
        setLoading(false);
      }
    } else {
      contactsFieldArray.remove(index);
      setLoading(false);
    }
  };

  return (
    <Body>
      <Notification />
      <Title>Informações de contato</Title>
      <SubTitle>Informe o contato principal</SubTitle>
      <Space />
      {loading && (
        <ModalLoading open={loading} close={() => setLoading(false)} />
      )}

      <form onSubmit={handleSubmit(onSubmit)} id="contactInformation">
        <Container fluid style={{ padding: 0 }}>
          {contactsFieldArray.fields.map((_, index) => {
            const hasError = !!errors.contacts?.[index];

            return (
              <>
                <Col xs={12}>
                  {index !== 0 && (
                    <div
                      style={{
                        display: 'flex',
                        marginTop: '50px',
                        marginBottom: '20px',
                      }}
                    >
                      <p style={{ marginTop: '4px' }}>Informe o contato</p>
                      <Icon
                        name="trash"
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeLine(index)}
                      />
                    </div>
                  )}
                </Col>
                <Row
                  align="center"
                  gutterWidth={16}
                  justify={
                    ['xs', 'sm', 'md', 'lg'].includes(screenClass)
                      ? 'center'
                      : 'start'
                  }
                >
                  <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
                    <Label>Nome</Label>
                    <Input
                      maxLength="30"
                      {...register(`contacts.${index}.name`, {
                        required: true,
                      })}
                    />

                    {hasError && (
                      <ErrorMessage>
                        {errors.contacts[index]?.name?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                  <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
                    <Label>E-mail corporativo</Label>
                    <Input
                      maxLength="100"
                      {...register(`contacts.${index}.email`, {
                        required: true,
                      })}
                    />
                    {hasError && (
                      <ErrorMessage>
                        {errors.contacts[index]?.email?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                  <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
                    <Label>Cargo</Label>
                    <Input
                      maxLength="50"
                      {...register(`contacts.${index}.role`, {
                        required: true,
                      })}
                    />
                    {hasError && (
                      <ErrorMessage>
                        {errors.contacts[index]?.role?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                </Row>
                <Space />
                <Row
                  align="center"
                  gutterWidth={16}
                  justify={
                    ['xs', 'sm', 'md', 'lg'].includes(screenClass)
                      ? 'center'
                      : 'start'
                  }
                >
                  <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
                    <Label>Telefone</Label>
                    <Controller
                      control={control}
                      name={`contacts.${index}.phone`}
                      render={({ field: { onChange, value, ref } }) => (
                        <Input.Masked
                          placeholder="(00) 0 0000-0000"
                          mask={''}
                          format="(##) # ####-####"
                          value={value}
                          getInputRef={ref}
                          onChange={(e) => { onChange(e.target.value.replace(/[^0-9]/g, '')) }}
                        />
                      )}
                    />

                    {hasError && (
                      <ErrorMessage>
                        {errors.contacts[index]?.phone?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                  <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
                    <Label>Ramal</Label>
                    <Input
                      maxLength="5"
                      {...register(`contacts.${index}.extension`, {
                        required: true,
                      })}
                    />
                    {hasError && (
                      <ErrorMessage>
                        {errors.contacts[index]?.extension?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                  <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
                    <Label>Tipo de Contato</Label>
                    <Controller
                      control={control}
                      name={`contacts.${index}.contactType`}
                      render={({ field: { onChange, value, ref } }) => (
                        <Input.Select
                          value={typesContact.find(
                            (option) => option.value === value
                          )}
                          isClearable={false}
                          inputRef={ref}
                          onChange={(e) => onChange(e?.value)}
                          options={typesContact}
                          disabled
                        />
                      )}
                    />
                    {hasError && (
                      <ErrorMessage>
                        {errors.contacts[index]?.contactType?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                </Row>
              </>
            );
          })}

          <Space />
          <Row>
            <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
              <SessionAddAddress
                onClick={() =>
                  contactsFieldArray.append({
                    contactType: 2,
                    email: '',
                    extension: '',
                    name: '',
                    phone: '',
                    role: '',
                  })
                }
              >
                <IconAdd active={true}>+</IconAdd>
                <TextAdd active={true}>Adicionar Contato</TextAdd>
              </SessionAddAddress>
            </Col>
          </Row>
          <Row>
            <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
              <FlexRight>
                <Limited>
                  <Button
                    variant="outlined"
                    type="button"
                    onClick={() => setCancel(true)}
                  >
                    Cancelar
                  </Button>
                  <div style={{ marginLeft: '40px' }} />
                  <Button type="submit" form="contactInformation">
                    Continuar
                  </Button>
                </Limited>
              </FlexRight>
            </Col>
          </Row>
        </Container>
      </form>

      {cancel && (
        <Modal
          open={cancel}
          close={() => setCancel(false)}
          confirm={() => setPosition(1)}
        />
      )}
    </Body>
  );
};

export default Contact;
