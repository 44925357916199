export const applyMask = ({
  mask,
  text,
  maskChar = '#',
}: {
  mask: string | undefined
  text: string | undefined
  maskChar?: string
}) => {
  if (!mask || !text) return ''
  let newString = mask
  for (let i = 0; i < text?.length; i++) {
    if (mask[i] === text[i]) continue
    newString = newString.replace(maskChar, text[i])
  }
  return newString.split('#')[0]
}

export const autoAbbreviate = ({ string }: { string: string }) => {
  const names = string.split(' ')
  const abbreviation = names.reduce((final: string, word: string) => final + word[0], '')
  return abbreviation
}

export const bytesToHumanFileSize = (bytes: number) => {
  if (!bytes) return ''
  if (bytes === 0) return '0.00 B'
  const e = Math.floor(Math.log(bytes) / Math.log(1024))
  return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B'
}

export const fieldDoesExistOnHash = (field: string | Array<string>) => {
  const hash = window.location.hash.replaceAll('&', '=').replace('#', '=')
  const fields = typeof field === 'string' ? [field] : field
  return fields.every(field => hash.includes(`=${field}=`))
}

export const downloadFile = ({ download, href }: { download: any; href?: any }) => {
  const element = document.createElement('a')
  element.setAttribute('href', href)
  download && element.setAttribute('download', download)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export const formatMoney = (value = 0) => {
  const valueToBeFormatted = Number(value) ? value : 0
  return parseFloat(String(valueToBeFormatted)).toLocaleString('pt-br', { minimumFractionDigits: 2 })
}

export const getLocaleDateString = ({ date, locale = 'pt-BR' }: { date: string; locale?: string }) => {
  if (!date || date === '') return null
  return new Intl.DateTimeFormat(locale).format(new Date(date))
}

export const hasWidthOverflow = ({ element }: { element: any }): boolean | null => {
  if (!element) return null
  const rect = element.getBoundingClientRect()
  const scrollWidth = element.scrollWidth
  return scrollWidth > Math.ceil(rect.width)
}

export const monetaryAbbreviator = ({ value }: { value: number }) => {
  const valueDividedByOneThousand = value / 1000
  if (valueDividedByOneThousand >= 100000) return `${valueDividedByOneThousand / 100000} bi`
  if (valueDividedByOneThousand >= 1000) return `${valueDividedByOneThousand / 1000} mi`
  if (valueDividedByOneThousand >= 10) return `${valueDividedByOneThousand} k`
  return value
}


export const sortArrayByNestedValue = ({ array, getValue }: { array: Array<any>; getValue: (option: any) => any }) => {
  return [...array].sort((a, b) => {
    if (getValue(a) > getValue(b)) return 1
    if (getValue(a) < getValue(b)) return -1
    return 0
  })
}
