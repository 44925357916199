import { useState } from "react"

import { Panel, Content, PageHeader, Spacing, Input, Button, ErrorMessage } from "presentation/components"
import { Col, Container, Row } from "react-grid-system"
import { ButtonWrapper, ViewParagraph } from "../../styles"
import history from "@/services/history"
import * as yup from "yup"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import enumParse from "main/utils/enumParse"

import { ModalStepDataLoss } from "presentation/pages/resales/modals/modalStepDataLoss"
import ModalFeeRange from "presentation/pages/resales/modals/modalFeeRange"
import { BsFillGearFill } from "react-icons/bs"
import { formatRealValue } from "./functions"
import { StyledInput } from "presentation/pages/resales/modals/styles"
import NumberFormat from "react-number-format"
import moment from "moment"
import { useDispatch } from "react-redux"

import { pushNotification } from "store/modules/notification/actions"
import errorHandler from "main/utils/errorHandler"
import successHandler from "main/utils/successHandler"
import routesPaths from "presentation/routes/routesPaths"
import api from "@/infra/api"

const RegisterFee = () => {
  const dispatch = useDispatch()
  const [selectIndexTab, setSelectIndexTab] = useState(0)
  const [isDataLossModalOpen, setIsDataLossModalOpen] = useState(false)

  const responseFee = JSON.parse(history.location.state?.data)
  const responseFeeData = responseFee.response.data

  const [selectedDate, setSelectedDate] = useState({ initialDate: null, finalDate: null })

  const operatorCard = [
    { label: "Única", value: 1 },
    { label: "Semanal", value: 2 },
    { label: "Mensal", value: 3 },
    { label: "Anual", value: 4 },
  ]

  const ERecurrence = {
    Unica: 1,
    Semanal: 2,
    Mensal: 3,
    Anual: 4,
  }

  const EFranchiseMinimal = {
    Exist: 1,
    Notexist: 2,
  }

  const EFeeValueType = {
    Percentage: 1,
    Real: 2,
    PercentageAndReal: 3,
  }

  const [real, setReal] = useState()

  const parseLocaleString = parcel => {
    if (real?.target?.value) {
      const parcelValue = real?.target?.value
        ? parseFloat(real?.target?.value.replace("R$ ", "").replace(".", "").replace(",", ".")) / parcel
        : responseFeeData[selectIndexTab]?.parcelValue / parcel
      return parcelValue?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      })
    } else {
      const parcelActual = responseFeeData[selectIndexTab]?.realValue / parcel
      if (parcelActual)
        return parcelActual.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
    }
  }

  const [parcelValueSelect, setparcelValueSelect] = useState()
  const optionsDiscunt = []
  for (let i = 1; i <= 12; i++) {
    optionsDiscunt.push({
      label: `${i}x de ${parseLocaleString(i)}`,
      value: i,
    })
  }

  const form = {
    row1: [
      { text: "Nome", name: "name", disabled: true, default: "name" },
      {
        text: "Tipo de Taxa",
        name: "typeFee",
        disabled: true,
        enum: { eType: "type", category: "EFeeType" },
      },
      {
        text: "Incidência",
        name: "incidence",
        disabled: true,
        enum: { eType: "incidence", category: "EFeeIncidence" },
      },
      {
        text: "Franquia mínima (R$)",
        name: "franchiseMinimal",
        type: "Decimal",
        prefix: "R$ ",
        placeholder: "00,00",
      },
    ],
    row2: [
      {
        text: "Tipo Valor",
        name: "typeValue",
        disabled: true,
        enum: { eType: "valueType", category: "EFeeValueType" },
      },
      {
        text: "Recorrência",
        name: "recurrence",
        type: "Select",
        placeholder: "Selecione",
        options: operatorCard,
        isClearable: false,
        enum: { eType: "recurrence", category: "ERecurrence" },
      },
      { text: "Desconto (%)", name: "discount", type: "number", min: 0, max: 100 },
    ],
    row3: [
      {
        text: "Reais (R$)",
        name: "realValue",
        type: "Decimal",
        onBlur: value => {
          setReal(value)
        },
        prefix: "R$ ",
        placeholder: "00,00",
        col: responseFeeData[selectIndexTab]?.fee?.valueType !== EFeeValueType.Percentage ? 3 : 0,
      },
      {
        text: "Valor (%)",
        name: "percentageValue",
        type: "number",
        min: 0,
        max: 100,
        col: responseFeeData[selectIndexTab]?.fee?.valueType !== EFeeValueType.Real ? 3 : 0,
      },
      {
        text: "Parcelamento",
        name: "parcel",
        type: "Select",
        options: optionsDiscunt,
        col: responseFeeData[selectIndexTab]?.fee?.valueType === EFeeValueType.Real ? 3 : 0,
      },
      {
        text: "Data Inicial",
        name: "initialDate",
        type: "Date",
        col: 3,
      },
      {
        text: "Data Final",
        name: "finalDate",
        type: "Date",
        col: 3,
      },
    ],
  }

  const feeData = []
  responseFeeData.map(e => {
    return feeData.push({ ...e.fee, form })
  })

  let schemaValidation = {
    recurrence:
      responseFeeData[selectIndexTab]?.recurrence > 0 || responseFeeData[selectIndexTab]?.recurrence
        ? ""
        : yup
          .string()
          .required("Campo obrigatório")
          .matches(/^[^-]+$/, "Número negativo não é válido"),
    discount: yup
      .number()
      .typeError("Digite um número válido")
      .min(0, "O número deve ser maior ou igual a 0")
      .max(100, "O número deve ser menor ou igual a 100"),
    realValue:
      responseFeeData[selectIndexTab]?.realValue > 0 ||
        (responseFeeData[selectIndexTab]?.fee.valueType !== EFeeValueType.Real &&
          responseFeeData[selectIndexTab]?.fee.valueType !== EFeeValueType.PercentageAndReal)
        ? ""
        : yup
          .string()
          .required("Campo obrigatório")
          .matches(/^[^-]+$/, "Número negativo não é válido"),
    franchiseMinimal:
      responseFeeData[selectIndexTab]?.franchiseMinimal > 0 ||
        responseFeeData[selectIndexTab]?.fee.franchiseMinimal === EFranchiseMinimal.Notexist
        ? ""
        : yup
          .string()
          .required("Campo obrigatório")
          .matches(/^[^-]+$/, "Número negativo não é válido"),
    percentageValue:
      responseFeeData[selectIndexTab]?.percentageValue > 0 ||
        (responseFeeData[selectIndexTab]?.fee.valueType !== EFeeValueType.Percentage &&
          responseFeeData[selectIndexTab]?.fee.valueType !== EFeeValueType.PercentageAndReal)
        ? ""
        : yup
          .string()
          .required("Campo obrigatório")
          .matches(/^[^-]+$/, "Número negativo não é válido"),
    initialDate:
      responseFeeData[selectIndexTab]?.discount === 0 ||
        responseFeeData[selectIndexTab]?.discount === null ||
        responseFeeData[selectIndexTab]?.initialDate !== null
        ? ""
        : yup.string().required("Campo Obrigatório"),
  }

  let schemaMap = {}
  feeData.forEach((element, key) => {
    schemaMap = {
      ...schemaMap,
      ...schemaValidation,
      key
    }
  })
  const schema = yup.object(schemaMap)

  const {
    setValue,
    reset,
    register,
    handleSubmit,
    control,
    watch,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const watchValue = watch("realValue")
  const watchDiscount = watch("discount")
  const watchPercentageValue = watch("percentageValue")
  const watchInitialDate = watch("initialDate")
  const watchFinalDate = watch("finalDate")

  const [recurrence, setRecurrence] = useState()

  const [range, setRange] = useState([])

  const [blockDiscount, setBlockDiscount] = useState()
  const save = async data => {
    const feeId = feeData[selectIndexTab].id
    const realValueParse = data?.realValue?.replace("R$ ", "").replace(".", "").replace(",", ".")

    const franchiseMinimal = data?.franchiseMinimal?.replace("R$ ", "").replace(".", "").replace(",", ".")

    const { oneTab, twoTabs } = range
    let rangeTabs = []

    const validateFinalDate = () => {
      if (watchFinalDate === "Invalid date") return null
      else if (watchFinalDate) return moment(data?.finalDate).subtract(1, "day")
      else return responseFeeData[selectIndexTab].finalDate
    }

    const validateIntialDate = () => {
      if (watchInitialDate === "Invalid date") return null
      else if (watchInitialDate) return moment(data?.initialDate).subtract(1, "day")
      else return responseFeeData[selectIndexTab].initialDate
    }

    const verifiyInitialDate = validateIntialDate()
    if (
      (responseFeeData[selectIndexTab].discount > 0 || data.discount > 0) &&
      (verifiyInitialDate === null || verifiyInitialDate === undefined)
    ) {
      setBlockDiscount(true)
      setError("initialDate", { type: "minDate", message: "Desconto aplicado, campo obrigatório" })
      return
    } else {
      setBlockDiscount(false)
      clearErrors("initialDate")
    }

    if (range.length > 0 || range?.oneTab?.length > 0)
      rangeTabs = !range?.twoTabs[0]?.rangeType ? [...oneTab] : [...oneTab, ...twoTabs]

    rangeTabs = rangeTabs.filter(
      (value, index, self) =>
        index === self.findIndex(t => t.rangeType === value.rangeType && t.initialRange === value.initialRange)
    )

    const bodyData = {
      entityType: 1,
      entityId: responseFeeData[selectIndexTab].entityId,
      feeId: feeId,
      recurrence: parseInt(data?.recurrence) || responseFeeData[selectIndexTab].recurrence,
      parcel: parseInt(data?.parcel) || responseFeeData[selectIndexTab].parcel,
      parcelValue: parcelValueSelect || responseFeeData[selectIndexTab].parcelValue,
      discount: parseInt(data?.discount) || responseFeeData[selectIndexTab].discount,
      realValue: (realValueParse && parseFloat(realValueParse)) || responseFeeData[selectIndexTab].realValue,
      percentageValue: parseInt(data?.percentageValue) || responseFeeData[selectIndexTab].percentageValue,
      initialDate: validateIntialDate(),
      finalDate: validateFinalDate(),
      franchiseMinimal:
        responseFeeData[selectIndexTab]?.fee.franchiseMinimal === EFranchiseMinimal.Notexist
          ? 0
          : (franchiseMinimal && parseFloat(franchiseMinimal)) || responseFeeData[selectIndexTab].franchiseMinimal,
      status: 1,
      feeConfigurationRanges:
        (rangeTabs.length > 0 && rangeTabs) || responseFeeData[selectIndexTab].feeConfigurationRanges || [],
    }

    try {
      await api.put(`ResaleFeeConfiguration/${responseFeeData[selectIndexTab].id}`, bodyData)
      reset()
      resetForm()
      setSelectIndexTab(selectIndexTab + 1)
      setRange({ oneTab: [], twoTabs: [] })
      dispatch(pushNotification(successHandler(`Taxa ${selectIndexTab + 1} foi configurada`)))
    } catch (e) {
      resetForm()
      dispatch(pushNotification(errorHandler(e?.response)))
    }
  }

  const resetForm = () => {
    setValue("percentageValue", undefined)
    setValue("discount", undefined)
    setValue("finalDate", undefined)
    setValue("initialDate", undefined)
    setSelectedDate({})
    reset()
  }

  const checkNotInstallmentAndParcelValue = elementInput => {
    return (
      checkRecurrenceHandle() === ERecurrence.Unica &&
      feeData[selectIndexTab].valueType === EFeeValueType.Real &&
      elementInput.name === "parcel"
    )
  }

  const checkRecurrenceHandle = () => {
    return recurrence || responseFeeData[selectIndexTab].recurrence
  }

  const checkNameParcelValueAndValueTypeIsReal = elementInput => {
    return elementInput.name === "percentageValue" && feeData[selectIndexTab].valueType === EFeeValueType.Real
  }

  const checkNameParcelValueAndValueTypeIsPercentage = elementInput => {
    return elementInput.name === "realValue" && feeData[selectIndexTab].valueType === EFeeValueType.Percentage
  }

  const checkNameExistsAndTypeIsNumber = elementInput => {
    return (elementInput.name && !elementInput.type) || elementInput.type === "number"
  }

  const checkNameExistsAndTypeIsDecimal = elementInput => {
    return elementInput.name && elementInput.type === "Decimal"
  }

  const [feeRange, setFeeRange] = useState(false)
  const [erro, setErro] = useState({ value: false, position: null })

  function handleValueChange(index, values, input) {
    if (values > 100) {
      setValue(input, 100)
      setErro({ value: true, position: index })
      return
    } else {
      setValue(input, values)
      setErro({ value: false, postion: index })
      clearErrors(input)
    }
  }

  const verifyFinalDateWithInitialDate = elementInput => {
    if (elementInput.name === "finalDate") {
      if (selectedDate.initialDate) {
        return new Date(selectedDate.initialDate)
      } else if (responseFeeData[selectIndexTab].initialDate) {
        return new Date(responseFeeData[selectIndexTab].initialDate)
      }
    } else return null
  }

  const verifyInitialDateWithFinalDate = elementInput => {
    if (elementInput.name === "initialDate") {
      if (selectedDate.finalDate) {
        return new Date(selectedDate.finalDate)
      } else if (responseFeeData[selectIndexTab].finalDate) {
        return new Date(responseFeeData[selectIndexTab].finalDate)
      }
    } else return null
  }

  const validateParcelValue = element => {
    const valueHandle = element?.target?.value.split(" ")
    if (valueHandle[0] !== "") {
      const valueLast = responseFeeData[selectIndexTab]?.realValue
      const validateRecurrence =
        checkRecurrenceHandle() === ERecurrence.Unica && feeData[selectIndexTab].valueType === EFeeValueType.Real
      const validateValueRealLast = parseFloat(valueHandle[valueHandle.length - 1]) !== valueLast
      const validateTabs = () => {
        if (range?.oneTab?.length === undefined) {
          return responseFeeData[selectIndexTab].feeConfigurationRanges.length === 1 ? true : false
        } else if (range?.oneTab?.length === 1 && range?.twoTabs?.length === 1) return true
        else return false
      }

      if (validateTabs() && validateValueRealLast && validateRecurrence) {
        // setError("parcel", {
        //   type: "value",
        //   message: "Necessário alterar o parcelamento",
        // })
        // setValue("parcel", undefined)
      } else {
        clearErrors("parcel")
      }
    }
  }
  return (
    <>
      <Content>
        <Panel>
          <PageHeader>Nome da Revenda</PageHeader>
          <Spacing top="32px" />
          <h1>Configure suas taxas</h1>
          <Spacing top="32px" />
          <Tabs selectedIndex={selectIndexTab}>
            <TabList>
              {feeData.map((element, key) => (
                <Tab disabled={true} key={`key-${element.id}`}>
                  Taxa {key + 1}
                </Tab>
              ))}
            </TabList>

            {feeData.length > 0 &&
              feeData.map((element, feeKey) =>
                selectIndexTab !== feeKey ? (
                  <TabPanel key={feeKey}>
                    <></>
                  </TabPanel>
                ) : (
                  <form key={`key-${element.id}`} onSubmit={handleSubmit(save)}>
                    <TabPanel>
                      <Container style={{ padding: 0 }}>
                        <Row>
                          {element.form.row1.map((elementInput) => (
                            <Col key={`key-${elementInput.name}`} sm={3}>
                              {EFranchiseMinimal.Notexist === responseFeeData[selectIndexTab]?.fee.franchiseMinimal &&
                                elementInput.name === "franchiseMinimal" ? (
                                ""
                              ) : (
                                <p>{elementInput.text}</p>
                              )}
                              <Controller
                                control={control}
                                name={elementInput.name}
                                render={({ field: { onChange, value, ref } }) => (
                                  <>
                                    {(!elementInput.type || elementInput.type === "number") && (
                                      <Input
                                        {...register(`${elementInput.name}`)}
                                        onChange={e => onChange(e?.value)}
                                        value={value}
                                        ref={ref}
                                        inputRef={ref}
                                        placeholder={
                                          enumParse(
                                            element?.[elementInput?.enum?.eType],
                                            elementInput?.enum?.category
                                          ) ||
                                          (elementInput?.default && element.name)
                                        }
                                        disabled={elementInput?.disabled || selectIndexTab}
                                        prefix={elementInput?.prefix}
                                        options={elementInput?.options}
                                        isClearable={elementInput?.isClearable}
                                        type={elementInput?.type}
                                      />
                                    )}
                                    {elementInput.type === "Decimal" &&
                                      (EFranchiseMinimal.Notexist ===
                                        responseFeeData[selectIndexTab]?.fee.franchiseMinimal ? (
                                        ""
                                      ) : (
                                        <Input.Decimal
                                          allowNegative={false}
                                          onChange={e => onChange(e)}
                                          ref={ref}
                                          inputRef={ref}
                                          prefix={elementInput?.prefix}
                                          placeholder={
                                            responseFeeData[selectIndexTab]?.franchiseMinimal?.toLocaleString(
                                              "pt-br",
                                              {
                                                style: "currency",
                                                currency: "BRL",
                                              }
                                            ) || elementInput?.placeholder
                                          }
                                          value={value}
                                        />
                                      ))}
                                    {errors?.[elementInput.name] && (
                                      <ErrorMessage>{errors?.[elementInput.name].message}</ErrorMessage>
                                    )}
                                  </>
                                )}
                              />
                            </Col>
                          ))}
                        </Row>
                        <Spacing top="32px" />
                        <Row>
                          {element.form.row2.map((elementInput, key) => (
                            <Col key={`key-${elementInput.name}`} sm={3}>
                              <p>{elementInput.text}</p>
                              <Controller
                                control={control}
                                name={elementInput.name}
                                render={({ field: { onChange, value, ref } }) => (
                                  <>
                                    {(!elementInput.type || elementInput.type === "number") && (
                                      <>
                                        <StyledInput>
                                          <NumberFormat
                                            options={elementInput?.options}
                                            isClearable={elementInput?.isClearable}
                                            suffix={"%"}
                                            max={100}
                                            min={10}
                                            onChange={e => onChange(e?.value)}
                                            disabled={elementInput?.disabled || blockDiscount}
                                            placeholder={
                                              elementInput?.name === "discount" &&
                                                responseFeeData[selectIndexTab]?.discount
                                                ? `${responseFeeData[selectIndexTab]?.discount}%`
                                                : enumParse(
                                                  element?.[elementInput?.enum?.eType],
                                                  elementInput?.enum?.category
                                                ) ||
                                                (elementInput?.default && element.name)
                                            }
                                            value={
                                              elementInput.name === "discount" && watchDiscount
                                                ? watchDiscount
                                                : elementInput?.value
                                            }
                                            onValueChange={values =>
                                              handleValueChange(key, values.floatValue, "discount")
                                            }
                                            allowNegative={false}
                                            isNumericString
                                            name={element.name}
                                            ref={register}
                                            inputRef={ref}
                                            {...register(`${elementInput.name}`, {
                                              required: true,
                                              min: 0,
                                              max: 100,
                                            })}
                                          />
                                        </StyledInput>
                                        {erro.value && erro.position === key && (
                                          <span style={{ color: "#e02e00", fontSize: "12px" }}>
                                            Máximo permitido até 100%
                                          </span>
                                        )}
                                      </>
                                    )}

                                    {elementInput.type === "Select" && (
                                      <Input.Select
                                        {...register(`${elementInput.name}`)}
                                        onChange={e => {
                                          setRecurrence(e?.value)
                                          onChange(e?.value)
                                        }}
                                        inputRef={ref}
                                        disabled={elementInput?.disabled}
                                        prefix={elementInput?.prefix}
                                        options={elementInput?.options}
                                        isClearable={elementInput?.isClearable}
                                        type={elementInput?.type}
                                        placeholder={
                                          enumParse(
                                            responseFeeData[selectIndexTab].recurrence,
                                            elementInput?.enum?.category
                                          ) ||
                                          enumParse(
                                            element?.[elementInput?.enum?.eType],
                                            elementInput?.enum?.category
                                          ) ||
                                          (elementInput?.default && element.name)
                                        }
                                        value={operatorCard.find(option => option.value === value)}
                                      />
                                    )}
                                    {/* {elementInput.type === "Select" &&
                                        Object.keys(errors).length > 0 &&
                                        responseFeeData[selectIndexTab]?.fee?.valueType === EFeeValueType.Real &&
                                        (
                                          <StyledInput>
                                            <NumberFormat disabled={true} type="text" placeholder="Única" />
                                          </StyledInput>
                                        )} */}

                                    {errors?.[elementInput.name] && (
                                      <ErrorMessage>{errors?.[elementInput.name].message}</ErrorMessage>
                                    )}
                                  </>
                                )}
                              />
                            </Col>
                          ))}
                        </Row>
                        <Spacing top="32px" />
                        <Row>
                          {element.form.row3.map((elementInput, key) => (
                            <Col
                              key={`key-${key}`}
                              sm={elementInput.col}
                              style={{ display: elementInput.col === 0 ? "none" : "" }}
                            >
                              <p>
                                {(elementInput.type === "Select" &&
                                  !checkNotInstallmentAndParcelValue(elementInput)) ||
                                  (elementInput.name === "parcel" &&
                                    ((range?.oneTab?.length === undefined &&
                                      responseFeeData[selectIndexTab].feeConfigurationRanges.length > 1) ||
                                      range?.oneTab?.length > 1 ||
                                      range?.twoTabs?.length > 1)) ||
                                  checkNameParcelValueAndValueTypeIsReal(elementInput) ||
                                  checkNameParcelValueAndValueTypeIsPercentage(elementInput) ||
                                  (elementInput.type === "Date" &&
                                    (((responseFeeData[selectIndexTab]?.discount === 0 ||
                                      !responseFeeData[selectIndexTab]?.discount) &&
                                      !watchDiscount) ||
                                      watchDiscount === 0))
                                  ? ""
                                  : elementInput.text}
                              </p>

                              <Controller
                                control={control}
                                name={elementInput.name}
                                render={({ field: { onChange, value, ref } }) => (
                                  <>
                                    {checkNameExistsAndTypeIsNumber(elementInput) &&
                                      (checkNameParcelValueAndValueTypeIsReal(elementInput) ? (
                                        ""
                                      ) : (
                                        <StyledInput>
                                          <NumberFormat
                                            options={elementInput?.options}
                                            isClearable={elementInput?.isClearable}
                                            suffix={"%"}
                                            max={100}
                                            min={10}
                                            onChange={e => onChange(e?.value)}
                                            disabled={elementInput?.disabled}
                                            placeholder={
                                              elementInput?.name === "percentageValue" &&
                                                responseFeeData[selectIndexTab]?.percentageValue
                                                ? `${responseFeeData[selectIndexTab]?.percentageValue}%`
                                                : enumParse(
                                                  element?.[elementInput?.enum?.eType],
                                                  elementInput?.enum?.category
                                                ) ||
                                                (elementInput?.default && element.name)
                                            }
                                            value={
                                              elementInput.name === "percentageValue" && watchPercentageValue
                                                ? watchPercentageValue
                                                : elementInput?.value
                                            }
                                            onValueChange={values =>
                                              handleValueChange(key, values.floatValue, "percentageValue")
                                            }
                                            allowNegative={false}
                                            isNumericString
                                            name={element.name}
                                            ref={register}
                                            inputRef={register}
                                            {...register(`${elementInput.name}`, {
                                              required: true,
                                              min: 0,
                                              max: 100,
                                            })}
                                          />
                                        </StyledInput>
                                      ))}
                                    {checkNameExistsAndTypeIsDecimal(elementInput) &&
                                      (checkNameParcelValueAndValueTypeIsPercentage(elementInput) ? (
                                        ""
                                      ) : (
                                        <Input.Decimal
                                          allowNegative={false}
                                          onBlur={element => {
                                            validateParcelValue(element)
                                            elementInput.name === "realValue" && elementInput?.onBlur(element)
                                          }}
                                          onChange={e => {
                                            validateParcelValue(e)
                                            onChange(e)
                                          }}
                                          ref={ref}
                                          inputRef={ref}
                                          prefix={elementInput?.prefix}
                                          placeholder={
                                            responseFeeData[selectIndexTab]?.realValue?.toLocaleString("pt-br", {
                                              style: "currency",
                                              currency: "BRL",
                                            }) || elementInput?.placeholder
                                          }
                                          value={value}
                                        />
                                      ))}
                                    {elementInput.type === "Select" &&
                                      (!checkNotInstallmentAndParcelValue(elementInput) ||
                                        (range?.oneTab?.length === undefined &&
                                          responseFeeData[selectIndexTab].feeConfigurationRanges.length > 1) ||
                                        range?.oneTab?.length > 1 ||
                                        range?.twoTabs?.length > 1 ? (
                                        ""
                                      ) : (
                                        <Input.Select
                                          {...register(`${elementInput.name}`)}
                                          onChange={e => {
                                            elementInput.name === "parcel" &&
                                              setparcelValueSelect(formatRealValue(e?.label))
                                            onChange(e?.value)
                                            clearErrors("parcel")
                                          }}
                                          inputRef={ref}
                                          disabled={elementInput?.disabled}
                                          prefix={elementInput?.prefix}
                                          options={elementInput?.options}
                                          isClearable={elementInput?.isClearable}
                                          type={elementInput?.type}
                                          placeholder={
                                            `${responseFeeData[selectIndexTab]?.parcel > 1
                                              ? responseFeeData[selectIndexTab]?.parcel
                                              : 1
                                            }x de ${(watchValue &&
                                              (
                                                parseFloat(
                                                  watchValue
                                                    ?.replace("R$ ", "")
                                                    ?.replace(".", "")
                                                    ?.replace(",", ".")
                                                ) /
                                                (responseFeeData[selectIndexTab]?.parcel > 1
                                                  ? responseFeeData[selectIndexTab]?.parcel
                                                  : 1)
                                              ).toLocaleString("pt-br", {
                                                style: "currency",
                                                currency: "BRL",
                                              })) ||
                                            responseFeeData[selectIndexTab]?.realValue?.toLocaleString("pt-br", {
                                              style: "currency",
                                              currency: "BRL",
                                            }) ||
                                            responseFeeData[selectIndexTab]?.parcelValue?.toLocaleString("pt-br", {
                                              style: "currency",
                                              currency: "BRL",
                                            })
                                            }` ||
                                            enumParse(
                                              responseFeeData[selectIndexTab].recurrence,
                                              elementInput?.enum?.category
                                            ) ||
                                            enumParse(
                                              element?.[elementInput?.enum?.eType],
                                              elementInput?.enum?.category
                                            ) ||
                                            (elementInput?.default && element.name)
                                          }
                                          value={elementInput?.options.find(option => option.value === value)}
                                        />
                                      ))}
                                    {elementInput.type === "Date" &&
                                      (((responseFeeData[selectIndexTab]?.discount === 0 ||
                                        !responseFeeData[selectIndexTab]?.discount) &&
                                        !watchDiscount) ||
                                        watchDiscount === 0)
                                      ? ""
                                      : elementInput.type === "Date" && (
                                        <Input.Date
                                          {...register(`${elementInput.name}`)}
                                          value={
                                            (elementInput.name === "initialDate"
                                              ? watchInitialDate
                                              : watchFinalDate) ||
                                            (elementInput.name === "finalDate" &&
                                              responseFeeData[selectIndexTab]?.finalDate
                                              ? new Date(responseFeeData[selectIndexTab]?.finalDate)
                                              : null) ||
                                            moment(new Date(value)).format("DD/MM/YYYY")
                                          }
                                          placeholder={
                                            elementInput.name === "initialDate" &&
                                              responseFeeData[selectIndexTab]?.initialDate
                                              ? moment(
                                                new Date(responseFeeData[selectIndexTab]?.initialDate)
                                              ).format("DD/MM/YYYY")
                                              : "dd/mm/aaaa"
                                          }
                                          inputRef={ref}
                                          minDate={verifyFinalDateWithInitialDate(elementInput)}
                                          maxDate={verifyInitialDateWithFinalDate(elementInput)}
                                          onChange={e => {
                                            onChange(moment(e).add(1, "day").format("YYYY-MM-DD"))
                                            setSelectedDate({
                                              ...selectedDate,
                                              [elementInput.name]: moment(e).add(1, "day").format("YYYY-MM-DD"),
                                            })
                                            setBlockDiscount(false)
                                          }}
                                        />
                                      )}
                                    {errors?.[elementInput.name] && (
                                      <ErrorMessage>{errors?.[elementInput.name].message}</ErrorMessage>
                                    )}
                                  </>
                                )}
                              />
                            </Col>
                          ))}
                        </Row>

                        <Row>
                          <Col sm={3}>
                            <ViewParagraph onClick={() => setFeeRange(true)}>
                              <BsFillGearFill /> Visualizar intervalos
                            </ViewParagraph>
                          </Col>
                        </Row>

                        <Row nogutter>
                          <Col sm={12}>
                            <ButtonWrapper>
                              <Button onClick={() => setIsDataLossModalOpen(true)} variant="action-outlined">
                                Cancelar
                              </Button>
                              <Button type="submit" variant="action" disabled={Object.keys(errors).length > 0}>
                                {feeData.length === selectIndexTab + 1 ? "Concluir" : "Continuar"}
                              </Button>
                            </ButtonWrapper>
                          </Col>
                        </Row>
                      </Container>
                    </TabPanel>
                  </form>
                )
              )}
            {feeData.length < selectIndexTab + 1 && (
              <>
                <Spacing top="32px" />
                <Row nogutter>
                  <Col sm={9}>
                    <h2>Todas as taxas foram configuradas</h2>
                  </Col>
                  <Col sm={3}>
                    <ButtonWrapper>
                      <Button
                        onClick={() => {
                          history.push({
                            pathname: routesPaths.resales.visualize,
                            state: { resaleId: responseFeeData[0].entityId, tab: 1 },
                          })
                        }}
                        variant="action-outlined"
                      >
                        Ok
                      </Button>
                    </ButtonWrapper>
                  </Col>
                </Row>
              </>
            )}
          </Tabs>
        </Panel>
      </Content>
      <ModalStepDataLoss
        open={isDataLossModalOpen}
        onClose={() => setIsDataLossModalOpen(false)}
        onConfirm={() =>
          history.push({
            pathname: routesPaths.resales.visualize,
            state: { resaleId: responseFeeData[0].entityId, tab: 1 },
          })
        }
      />
      {feeRange && (
        <ModalFeeRange
          data={{ feeData: feeData[selectIndexTab], responseFeeData: responseFeeData[selectIndexTab] }}
          open={feeRange}
          setRange={setRange}
          range={range}
          onClose={() => setFeeRange(false)}
          onConfirm={() => history.push("/revendas")}
        />
      )}
    </>
  )
}

export default RegisterFee
