import { styled } from "@stationkim/components-lib";

export const ContentContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  maxWidth: '388px',
  minWidth: '250px',
  margin: 'auto',
  padding: '52px 0'
});

export const ButtonLine = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
});

export const StyledForm = styled('form', {
  width: '100%',
  margin: '86px 0',
});

export const InfoText = styled('p', {
  textAlign: 'center',
  fontSize: '$4',
  marginTop: '68px',
});

export const StepTitle = styled('h1', {
  fontWeight: '$bold',
  color: '$primary300'
});

export const StepSubtitle = styled('h2', {
  fontWeight: '$bold',
  color: '$primary300'
})

export const StepDescription = styled('p', {
  color: '$gray500',
  margin: '16px 0',
});

export const StepContainer = styled('div', {
  marginTop: '40px',
});

export const LabelPlusToolTipWrapper = styled('div', {
  display: 'flex',
  gap: "$6",
  'svg': {
    color: '$primary200'
  }
});