import { styled } from "@mui/material";
import { Box } from "@stationkim/front-ui";

export const FloatingButtonsContainer = styled(Box)<{ visible?: boolean }>(({ theme, visible }) => ({
  position: 'fixed',
  bottom: visible ? '16px' : '-200px',
  left: '50%',
  transform: 'translateX(-50%)',
  background: theme.palette.common.white,
  padding: '16px',
  borderRadius: '4px',
  boxShadow: theme.shadows[5],
  zIndex: 1,
  transition: 'all .25s linear',
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  width: 'min(80%, 700px)',
}))