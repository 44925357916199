import { MouseEvent, useState } from "react";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons"
import { Box, Icon, Menu } from "@stationkim/front-ui"

export const ActionsMenu = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      onClick={e => e.stopPropagation()}
    >
      <Icon icon={faEllipsis} onClick={handleClick as any} sx={{ fontSize: '28px' }} />
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          padding: '8px 16px',
          width: '120px',
          margin: 0
        }}
      >
        {children}
      </Menu>
    </Box>
  )
}