import React, { useRef, useState } from "react";
import _ from "lodash";
import { Grid, IconButton, Text } from "presentation/components";
import { AddEntrieTextButton, CategorieTitleWrapper, EntriesCounter, OperatorInformation } from "../styles";
import { CONTACT_TYPES } from "main/utils/constants";
import { applyMask, returnValueOrMessageIfIsEmpty } from "main/utils/functionUtils";
import ContactModal from "./editionModals/contactModal";
import { FaPencilAlt, FaRegTrashAlt } from "react-icons/fa";
import { deleteOperatorContact } from "@/services/operatorsServices";
import useService from "main/hooks/useService";
import { pushNotification } from "@/store/modules/notification/actions";
import successHandler from "main/utils/successHandler";
import { useDispatch } from "react-redux";

const emptyMessage = "Não informado";

const Contacts = ({
  operator,
  onEdited = () => null
}) => {

  const dispatch = useDispatch();
  const [isContactModalFormOpen, setIsContactModalFormOpen] = useState(false);
  const contactIndexToEdit = useRef(null);
  const isMultiLine = operator.contacts?.length > 1;
  const [, deleteContactRequest] = useService(deleteOperatorContact, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler("Contato removido")));
      onEdited();
    },
  });
  const defineContactToEdit = (addressIndex) => {
    contactIndexToEdit.current = addressIndex;
    setIsContactModalFormOpen(true);
  }

  const contactsRender = (operator) => {
    if (!operator.contacts || operator.contacts?.length <= 0) return;
    const mainContactIndex = operator.contacts.findIndex(contact => contact.contactType === CONTACT_TYPES.MAIN);
    const contactsToRender = [...operator.contacts];
    if (mainContactIndex) {
      [contactsToRender[0], contactsToRender[mainContactIndex]] = [contactsToRender[mainContactIndex], contactsToRender[0]];
    }
    return contactsToRender.map((contact, index) => (
      <React.Fragment key={_.uniqueId()}>
        {isMultiLine &&
          <Grid.Row>
            <Grid.Col>
              <EntriesCounter>
                <Text.SubtitleTwo>{index + 1}.</Text.SubtitleTwo>
                <IconButton onClick={() => defineContactToEdit(index)}><FaPencilAlt /></IconButton>
                {contact.contactType !== CONTACT_TYPES.MAIN &&
                  <IconButton onClick={() => deleteContactRequest(contact.id)}><FaRegTrashAlt /></IconButton>
                }
              </EntriesCounter>
            </Grid.Col>
          </Grid.Row>
        }
        <Grid.Row >
          <Grid.Col xs={12} sm={6} md={3} lg={3}>
            <Text.SubtitleTwo>Nome completo</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(contact?.name, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
            <Text.SubtitleTwo>E-mail</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(contact?.email, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs", "sm"]}>
            <Text.SubtitleTwo>Cargo</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(contact?.role, emptyMessage)}</OperatorInformation>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col xs={12} sm={6} md={3} lg={3}>
            <Text.SubtitleTwo>Tipo de contato</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(CONTACT_TYPES[contact.contactType], emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
            <Text.SubtitleTwo>Telefone</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(applyMask("(##) ####-#####", contact?.phone), emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs", "sm"]}>
            <Text.SubtitleTwo>Ramal</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(contact?.extension, emptyMessage)}</OperatorInformation>
          </Grid.Col>
        </Grid.Row>
      </React.Fragment>
    ));
  };

  return (
    <>
      <Grid.Row>
        <Grid.Col xs={12} >
          <CategorieTitleWrapper>
            <Text.Subtitle>{isMultiLine ? "Contatos" : "Contato"}</Text.Subtitle>
            {(!isMultiLine && operator.contacts?.length > 0) ?
              <IconButton onClick={() => defineContactToEdit(0)}><FaPencilAlt /></IconButton>
              : null
            }
          </CategorieTitleWrapper>
        </Grid.Col>
      </Grid.Row>
      {contactsRender(operator)}
      <Grid.Row>
        <Grid.Col xs={12}>
          <AddEntrieTextButton onClick={() => setIsContactModalFormOpen(true)}>Adicionar contato</AddEntrieTextButton>
        </Grid.Col>
      </Grid.Row>
      {isContactModalFormOpen &&
        <ContactModal
          open={isContactModalFormOpen}
          contactIndex={contactIndexToEdit.current}
          operator={operator}
          onClose={(hasEdited) => {
            setIsContactModalFormOpen(false);
            contactIndexToEdit.current = null;
            hasEdited === true && onEdited();
          }}
        />
      }
    </>
  );
};

export default Contacts;