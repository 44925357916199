export const rules = {
  cep: {
    required: { value: true, message: "Campo obrigatório" },
  },
  laneName: {
    required: { value: true, message: "Campo obrigatório" },
  },
  number: {
    required: { value: true, message: "Campo obrigatório" },
  },
  city: {
    required: { value: true, message: "Campo obrigatório" },
  },
  state: {
    required: { value: true, message: "Campo obrigatório" },
  },
  addressType: {
    required: { value: true, message: "Campo obrigatório" },
  },
  addressName: {
  },
};