import { getFees } from '@/services/feesServices';
import { all, takeLatest, call, put } from 'redux-saga/effects';
import { feesInSuccess, feesFailure } from './actions';

function* get({ payload }) {
  try {
    const { response } = yield call(getFees, payload.query);
    const fees = response?.data;
    if (response) yield put(feesInSuccess(fees));
  } catch (e) {
    yield put(feesFailure(e.response));
  }
}

export default all([takeLatest('@fees/FEES_IN_REQUEST', get)]);
