import { useState } from 'react';
import api from '@/infra/api';
import history from '@/services/history';
import Modal from './components/modalPending';
import { useForm } from 'react-hook-form';
import ModalAlready from './components/modalAlreadyExists';
import {
  Content,
} from 'presentation/components';
import routesPaths from 'presentation/routes/routesPaths';
import { Box, Button, CircularProgress, Form, FormMaskedTextField, Grid, Typography } from '@stationkim/front-ui';
import { Paper } from '@mui/material';
import { validateCNPJ } from 'validations-br';

const Operators = () => {
  const [cnpj, setCnpj] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(null);
  const [status, setStatus] = useState(null);
  const [already, setAlready] = useState(false);

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      cnpj: '',
    },
  });

  const confirm = () => {
    history.push({
      pathname: routesPaths.operators.register,
      state: { id, status, cnpj },
    });
  };

  const requestCnpj = async (data) => {
    setLoading(true);

    try {
      const numsStr = data.cnpj.replace(/[^0-9]/g, '');
      setCnpj(numsStr);

      const response = await api.get(
        `/operators/registration/${numsStr}/exists`
      );

      setLoading(false);

      const step = response?.data?.registrationStatus
        ? response.data.registrationStatus
        : 0;

      if (response.status === 204) {
        history.push({
          pathname: routesPaths.operators.register,
          state: { cnpj: data.cnpj },
        });
      } else {
        if (response.data.registrationStatus === 6) {
          setAlready(true);
        } else {
          setId(response?.data?.id);
          setStatus(step);
          setOpenModal(true);
        }
      }
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <Content>
        <Paper sx={{ padding: '32px' }}>
          <Box sx={{ maxWidth: '416px', flexDirection: 'column', display: 'flex', alignItems: 'center', margin: 'auto' }}>
            {loading ? <CircularProgress /> :
              <>
                <Typography variant='h1' sx={{ textAlign: 'center', marginBottom: '32px' }}>Cadastrar nova Operadora</Typography>
                <Typography sx={{ textAlign: 'center', marginBottom: '32px' }}>
                  Por favor, informe o CNPJ da operadora que deseja cadastrar
                </Typography>
                <Form form={form} onSubmit={data => requestCnpj(data)}>
                  <FormMaskedTextField
                    name='cnpj'
                    mask={{ mask: '00.000.000/0000-00' }}
                    placeholder='00.000.000/0000-00'
                    rules={{
                      validate: (value) => {
                        return validateCNPJ(value) ? true : 'CNPJ Invalido'
                      }
                    }}
                    label='CNPJ'
                  />
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={() => history.push('/operadoras')}
                      fullWidth
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button variant="contained" type="submit" fullWidth>Continuar</Button>
                  </Grid>
                </Form>
              </>}
          </Box>
        </Paper>
      </Content>
      {openModal && (
        <Modal
          open={openModal}
          close={() => setOpenModal(false)}
          confirm={confirm}
        />
      )}
      {already && (
        <ModalAlready
          open={already}
          close={() => setAlready(false)}
          confirm={() => history.push('/operadoras')}
        />
      )}
    </>
  );
};

export default Operators;
