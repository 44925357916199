import React, { useRef, useState } from "react";
import _ from "lodash";
import { Grid, IconButton, Text } from "presentation/components";
import { OperatorInformation, EntriesCounter, AddEntrieTextButton, CategorieTitleWrapper } from "../styles";
import { NOTIFICATION_TYPE, OPERATOR_BUYING_RULE_TYPES } from "main/utils/constants";
import BuyingRulesModal from "./editionModals/buyingRulesModal";
import { formatMoney, returnValueOrMessageIfIsEmpty } from "main/utils/functionUtils";
import { FaPencilAlt } from "react-icons/fa";
import { pushNotification } from "@/store/modules/notification/actions";
import { useDispatch } from "react-redux";

const emptyMessage = "Não informado";

const BuyingRules = ({
  operator,
  onEdited = () => null
}) => {

  const dispatch = useDispatch();
  const isEditing = useRef(true);

  const [isBuyingRulesModalFormOpen, setIsBuyingRulesModalFormOpen] = useState(false);

  const buyingRulesRender = (operator) => {
    if (!(operator?.buyingRules?.length > 0)) return;
    return operator.buyingRules.map((rule, index) => (
      <React.Fragment key={_.uniqueId()}>
        <Grid.Row align="end">
          <Grid.Col xs={12} sm={6} md={3} lg={3} >
            <EntriesCounter><Text.SubtitleTwo>{index + 1}.</Text.SubtitleTwo></EntriesCounter>
            <Text.SubtitleTwo>Tipo de regra</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(OPERATOR_BUYING_RULE_TYPES[rule.type], emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
            <Text.SubtitleTwo>{rule.type === OPERATOR_BUYING_RULE_TYPES.RECHARGE ? "Dias para Crédito" : "Prazo para coleta"}</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(rule.value, emptyMessage)}</OperatorInformation>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col xs={12} sm={6} md={3} lg={3}>
            <Text.SubtitleTwo>Valor Mínimo</Text.SubtitleTwo>
            <OperatorInformation>R$ {returnValueOrMessageIfIsEmpty(formatMoney(rule.minimumPurchase), emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
            <Text.SubtitleTwo>Valor Máximo</Text.SubtitleTwo>
            <OperatorInformation>R$ {returnValueOrMessageIfIsEmpty(formatMoney(rule.maximumPurchase), emptyMessage)}</OperatorInformation>
          </Grid.Col>
        </Grid.Row>
      </React.Fragment>
    ));
  };

  return (
    <>
      <Grid.Row>
        <Grid.Col sm={12}>
          <CategorieTitleWrapper>
            <Text.Subtitle>Regras de Compra</Text.Subtitle>
            {operator?.buyingRules?.length > 0 ?
              <IconButton onClick={() => {
                isEditing.current = true;
                setIsBuyingRulesModalFormOpen(true);
              }}>
                <FaPencilAlt />
              </IconButton>
              : null}
          </CategorieTitleWrapper>
        </Grid.Col>
      </Grid.Row>
      {buyingRulesRender(operator)}
      <Grid.Row>
        <Grid.Col>
          <AddEntrieTextButton
            isDisabled={operator.buyingRules.length >= 3}
            onClick={() => {
              if (operator.buyingRules.length >= 3) {
                dispatch(pushNotification({
                  type: NOTIFICATION_TYPE.WARNING,
                  content: "O número máximo de regras de compra foi atingido",
                  title: "Aviso"
                }));
                return;
              };
              isEditing.current = false;
              setIsBuyingRulesModalFormOpen(true);
            }}
          >
            Adicionar regra
          </AddEntrieTextButton>
        </Grid.Col>
      </Grid.Row>
      {isBuyingRulesModalFormOpen &&
        <BuyingRulesModal
          open={isBuyingRulesModalFormOpen}
          operator={operator}
          isEditing={isEditing.current}
          onClose={(hasEdited) => {
            setIsBuyingRulesModalFormOpen(false);
            hasEdited === true && onEdited();
          }}
        />
      }
    </>
  );
};

export default BuyingRules;