import { faExclamationCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { useTheme } from "@mui/material"
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Icon } from "@stationkim/front-ui"

interface StatusToggleDialogProps extends DialogProps {
  isActive: boolean
  onConfirm: () => void
  isLoading?: boolean
  togglingItem?: 'cartão' | 'tarifa'
}

export const StatusToggleDialog = ({ togglingItem = 'cartão', isActive, onClose, onConfirm, isLoading, ...props }: StatusToggleDialogProps) => {
  const theme = useTheme()
  const color = isActive ? theme.palette.error.main : theme.palette.warning.main

  return (
    <Dialog
      {...props}
      onClose={isLoading ? null : onClose}
    >
      <Icon icon={isActive ? faExclamationTriangle : faExclamationCircle} sx={{ height: '64px', color }} />
      <DialogTitle sx={{ margin: '32px auto', color }}>ATENÇÃO</DialogTitle>

      <DialogContent sx={{ minWidth: '348px' }}>
        {isLoading ?
          <Box sx={{ padding: '32px', textAlign: 'center' }}><CircularProgress /></Box> :
          `Você tem certeza que deseja ${isActive ? "inativar" : "reativar"} esse ${togglingItem}?`
        }
      </DialogContent>
      <DialogActions sx={{ display: 'flex', gap: '32px', marginTop: '32px' }}>
        <Button
          onClick={(e) => onClose(e, 'escapeKeyDown')}
          fullWidth
          disabled={isLoading}
        >
          Cancelar
        </Button>
        <Button
          variant="outlined"
          fullWidth
          color={isActive ? 'error' : 'warning'}
          disabled={isLoading}
          onClick={() => onConfirm()}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}