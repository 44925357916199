import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Button, Dialog } from "@stationkim/components-lib";
import {
  ButtonLine,
  ModalContent,
  ModalIcon,
  ModalIconFrame,
  StyledDialogDescription,
  StyledDialogTitle
} from "./styles";
import { patchAllowOrderBroker } from "services/resalesServices";
import useService from "main/hooks/useService";
import { Spinner } from "presentation/components";
import { useDispatch } from "react-redux";
import { pushNotification } from "store/modules/notification/actions";
import successHandler from "main/utils/successHandler";

export const ModalConfirmAllowBrokerOrder = ({
  open,
  resale,
  isBrokerOderAllowed,
  onClose,
  onConfirm,
}) => {

  const dispatch = useDispatch();
  const [
    toggleAllowBrokerCardOrderState,
    toggleAllowBrokerCardOrderRequest
  ] = useService(patchAllowOrderBroker, {
    onCompleted: () => {
      dispatch(
        pushNotification(
          successHandler(`Pedido broker ${isBrokerOderAllowed ? "inativado" : "ativado"}`)
        )
      )
      onConfirm(!isBrokerOderAllowed);
      onClose();
    }
  });

  const confirmAllowBrokerOderToggle = () => {
    toggleAllowBrokerCardOrderRequest(resale.id, !resale.allowBrokerOrder);
  };

  return (
    <Dialog.Root
      open={open}
    >
      <Dialog.Content >
        <ModalContent>
          {toggleAllowBrokerCardOrderState.loading ?
            <Spinner /> :
            <>
              <ModalIconFrame>
                <ModalIcon
                  icon={faTriangleExclamation}
                  css={{ color: '$error200' }}
                />
              </ModalIconFrame>
              <StyledDialogTitle
                css={{ color: '$error200' }}
              >
                DESEJA {isBrokerOderAllowed ? "INATIVAR" : "ATIVAR"} O PEDIDO DE CARTÃO BROKER?
              </StyledDialogTitle>
              <StyledDialogDescription>
                Ao {isBrokerOderAllowed ? "inativar" : "ativar"},
                a empresa <strong>{resale?.tradeName}</strong> {isBrokerOderAllowed ? "não " : ""}
                vai poder solicitar <br />cartões broker 1º e 2º via.
              </StyledDialogDescription>
              <ButtonLine>
                <Button buttonType="text" onClick={() => onClose(false)}>Cancelar</Button>
                <Button buttonType="outlined" color="error" onClick={confirmAllowBrokerOderToggle}>Confirmar</Button>
              </ButtonLine>
            </>
          }
        </ModalContent>
      </Dialog.Content>
    </Dialog.Root>
  );
};