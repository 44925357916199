import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BsExclamationCircle, BsExclamationTriangle } from "react-icons/bs";
import { FaPencilAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import schema from "./schema";
import { useAtom, useAtomValue } from "jotai";
import { atomWithHash } from "jotai-location";
import {
  Content,
  ErrorMessage,
  ErrorWrapper,
  Grid,
  IconButton,
  Input,
  Line,
  PageHeader,
  Panel,
  Spinner,
  StatusTag,
  Switch,
  Text
} from "presentation/components";
import Button from "presentation/components/buttonV2";
import { InfoModal } from "presentation/compositions";
import useService from "main/hooks/useService";
import routesPaths from "presentation/routes/routesPaths";
import history from "@/services/history";
import successHandler from "main/utils/successHandler";
import { getCardsForSelect, getOperatorCardFee, patchOperatorChangeCardFeeStatus, putEditOperatorCardFee } from "@/services/operatorsServices";
import { pushNotification } from "@/store/modules/notification/actions";
import { BENEFIT_TYPES, FORM_MODE, NOTIFICATION_TYPE, OPERATOR_CARD_FEE_FREQUENCY, OPERATOR_CARD_FEE_STATUS } from "main/utils/constants";
import { benefitTypes } from "main/utils/options";
import { CheckBoxWrapper, Form, TitleWrapper } from "./styles";
import { operatorIdAtom } from "@/main/store";
import { fieldDoesExistOnHash } from "@/main/utils/functions";
import { useDocumentTitle } from "@stationkim/front-ui";

const formModeAtom = atomWithHash('operatorCardFeesFormMode', FORM_MODE.VIEW)
const cardFeeIdAtom = atomWithHash('cardFeeId', null)
const operatorInfoAtom = atomWithHash('operatorInfo', {})

const VisualizeAndEditOperatorCardFee = ({ location }) => {
  const dispatch = useDispatch();
  const cardFeeId = useAtomValue(cardFeeIdAtom)
  const operatorId = useAtomValue(operatorIdAtom)
  const operator = useAtomValue(operatorInfoAtom)
  const [mode, setMode] = useAtom(formModeAtom);
  const [isCardSelectVisible, setIsCardSelectVisible] = useState();
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);
  const [isChangeStatusConfirmationOpen, setIsChangeStatusConfirmationOpen] = useState(false);
  const [cardFee, setCardFee] = useState();
  const [cards, setCards] = useState([]);
  const isCardFeeActive = cardFee?.status === OPERATOR_CARD_FEE_STATUS.ACTIVE;
  const isEditing = mode === FORM_MODE.EDIT;
  const {
    control,
    formState: { errors, isDirty },
    register,
    handleSubmit,
    reset
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const documentTitle = useDocumentTitle()

  const [fetchCardFeeState, fetchCardFeeRequest] = useService(getOperatorCardFee, {
    onCompleted: (response) => {
      reset({
        ...response.data,
        operatorId: `${operator?.tradeName} - ${operator?.code}`
      });
      setCardFee(response.data);
      setIsCardSelectVisible(response.data.benefit === BENEFIT_TYPES.TRANSPORT);
    },
  });

  const [fetchCardsState, fetchCardsRequest] = useService(getCardsForSelect, {
    onCompleted: (response) => {
      setCards(response.data);
    },
    silent: true
  });

  const [changeStatusState, changeStatusRequest] = useService(patchOperatorChangeCardFeeStatus, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler(`Tarifa ${isCardFeeActive ? "inativada" : "ativada"}!`)));
      setIsChangeStatusConfirmationOpen(false);
      fetchCardFeeRequest(cardFeeId);
    },
  });

  const goBackToCardFeeList = () => {
    history.push(`${routesPaths.operators.visualize}#operatorId="${operatorId}"&operatorTab=${2}`)
  };

  const [editCardFeeState, editCardFeeRequest] = useService(putEditOperatorCardFee, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler("Tarifa editada com sucesso!")));
      goBackToCardFeeList();
    },
  });

  const isLoading = !cardFee || fetchCardFeeState.loading || editCardFeeState.loading;


  useEffect(() => {
    if (operator && cardFee)
      documentTitle.change({ title: `${operator?.tradeName} - ${cardFee?.description}` })
  }, [operator, cardFee])

  useEffect(() => {
    if (!fieldDoesExistOnHash(['operatorId', 'cardFeeId'])) {
      operatorId ? goBackToCardFeeList() : history.replace('/operadoras')
    }
    if (cardFeeId && operatorId) {
      fetchCardFeeRequest(cardFeeId);
      fetchCardsRequest(operatorId);
    }
    // eslint-disable-next-line
  }, [cardFeeId, operatorId]);

  const renderCheckboxes = (value, onChange) => {
    if (!isEditing) return (
      <>
        <Input.CheckBox
          controlled
          checked={true}
        />
        <Text.Label>{OPERATOR_CARD_FEE_FREQUENCY[value]}</Text.Label>
      </>
    );
    else return (
      <>
        <Input.CheckBox
          controlled
          checked={value === OPERATOR_CARD_FEE_FREQUENCY.DAILY}
          onClick={() => onChange(OPERATOR_CARD_FEE_FREQUENCY.DAILY)}
        />
        <Text.Label>Diário</Text.Label>
        <Input.CheckBox
          controlled
          checked={value === OPERATOR_CARD_FEE_FREQUENCY.WEEKLY}
          onClick={() => onChange(OPERATOR_CARD_FEE_FREQUENCY.WEEKLY)}
        />
        <Text.Label>Semanal</Text.Label>
        <Input.CheckBox
          controlled
          checked={value === OPERATOR_CARD_FEE_FREQUENCY.BIWEEKLY}
          onClick={() => onChange(OPERATOR_CARD_FEE_FREQUENCY.BIWEEKLY)}
        />
        <Text.Label>Quinzenal</Text.Label>
        <Input.CheckBox
          controlled
          checked={value === OPERATOR_CARD_FEE_FREQUENCY.MONTHLY}
          onClick={() => onChange(OPERATOR_CARD_FEE_FREQUENCY.MONTHLY)}
        />
        <Text.Label>Mensal</Text.Label>
      </>
    )
  }

  const cancelEdition = () => {
    setMode(FORM_MODE.VIEW);
    setIsCancelConfirmationOpen(false);
  }

  const onSubmit = (data) => {
    if (!isDirty) {
      setMode(FORM_MODE.VIEW);
      return;
    };
    const payload = {
      ...data,
      operatorId: operatorId
    };
    editCardFeeRequest(cardFeeId, payload);
  };
  return (
    <>
      <Content>
        <Panel noPadding>
          <Grid >
            <Grid.Row>
              <Grid.Col>
                <PageHeader>{operator?.tradeName ?? "Operadora"}</PageHeader>
              </Grid.Col>
            </Grid.Row>
          </Grid>
          <Line overflow />
          <Form onSubmit={handleSubmit(onSubmit)} id="cardFeeForm">
            <Grid spaceBetweenRows="32px" padding={isLoading ? "32px" : "16px 16px 32px 16px"}>
              {isLoading ? <Spinner.Box><Spinner /></Spinner.Box> :
                <>
                  <Grid.Row align="center">
                    <Grid.Col>
                      <TitleWrapper>
                        <Text.Subtitle>{!isEditing ? "Tarifa" : "Edição de Tarifa"}</Text.Subtitle>
                        {!isEditing &&
                          <IconButton onClick={(e) => {
                            if (isCardFeeActive) {
                              e.preventDefault();
                              setMode(FORM_MODE.EDIT);
                            } else dispatch(pushNotification({
                              type: NOTIFICATION_TYPE.WARNING,
                              content: "Para editar uma tarifa é necessário que ela esteja ativa."
                            }))
                          }}>
                            <FaPencilAlt />
                          </IconButton>
                        }
                      </TitleWrapper>
                    </Grid.Col>
                    {!isEditing &&
                      <>
                        <Grid.Col sm="content">
                          {changeStatusState.loading ? <Spinner variant="sm" /> :
                            <Switch
                              onChange={() => setIsChangeStatusConfirmationOpen(true)}
                              checked={cardFee.status === OPERATOR_CARD_FEE_STATUS.INACTIVE}
                            />
                          }
                        </Grid.Col>
                        <Grid.Col xs={12} sm="content">
                          <StatusTag
                            color={isCardFeeActive ? "green" : "red"}
                          >
                            {OPERATOR_CARD_FEE_STATUS[cardFee?.status]}
                          </StatusTag>
                        </Grid.Col>
                      </>
                    }
                  </Grid.Row>
                  <Grid.Row  >
                    <Grid.Col xs={12} sm={6} md={3}>
                      <Text.Label>Operadora</Text.Label>
                      {isEditing ?
                        <Input
                          disabled
                          {...register("operatorId")}
                        /> :
                        <Text>{operator.tradeName} - {operator.code}</Text>
                      }
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={3}>
                      <Text.Label>Código</Text.Label>
                      {isEditing ?
                        <Input
                          disabled
                          {...register("code")}
                        /> :
                        <Text>{cardFee.code}</Text>
                      }
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={3} marginsFor={['xs', 'sm']}>
                      {isEditing ?
                        <ErrorWrapper error={errors.description}>
                          <Text.Label>Descrição</Text.Label>
                          <Input
                            maxLength={40}
                            {...register("description")}
                          />
                          {errors.description &&
                            <ErrorMessage>{errors.description.message}</ErrorMessage>
                          }
                        </ErrorWrapper> :
                        <>
                          <Text.Label>Descrição</Text.Label>
                          <Text>{cardFee.description}</Text>
                        </>
                      }
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col xs={12} sm={6} md={3} marginsFor={['xs']}>
                      {isEditing ?
                        <ErrorWrapper error={errors.benefit}>
                          <Text.Label>Tipo de Benefício</Text.Label>
                          <Controller
                            control={control}
                            name="benefit"
                            render={({ field: { value, onChange, ref } }) =>
                              <Input.Select
                                inputRef={ref}
                                value={benefitTypes.find(option => option.value === value)}
                                placeholder="Selecione..."
                                onChange={(e) => {
                                  onChange(e.value);
                                  setIsCardSelectVisible(e.value === BENEFIT_TYPES.TRANSPORT);
                                }}
                                isClearable={false}
                                options={benefitTypes}
                              />
                            }
                          />
                          {errors.benefit &&
                            <ErrorMessage>{errors.benefit.message}</ErrorMessage>
                          }
                        </ErrorWrapper> :
                        <>
                          <Text.Label>Descrição</Text.Label>
                          <Text>{BENEFIT_TYPES[cardFee.benefit]}</Text>
                        </>
                      }
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={3}>
                      {isEditing ?
                        <ErrorWrapper error={errors.value}>
                          <Text.Label>Valor (Tarifa)</Text.Label>
                          <Controller
                            control={control}
                            name="value"
                            render={({ field: { value, onChange, ref } }) =>
                              <Input.Decimal
                                allowNegative={false}
                                value={value >= 0 && value < 1000 ? value : 0}
                                prefix="R$ "
                                onValueChange={e => onChange(e.floatValue)}
                                isAllowed={(values) => {
                                  const { floatValue } = values;
                                  return !floatValue ? true : floatValue < 1000;
                                }}
                              />
                            }
                          />
                          {errors.value &&
                            <ErrorMessage>{errors.value.message}</ErrorMessage>
                          }
                        </ErrorWrapper> :
                        <>
                          <Text.Label>Valor (Tarifa)</Text.Label>
                          <Text>{BENEFIT_TYPES[cardFee.value]}</Text>
                        </>
                      }
                    </Grid.Col>
                    {isCardSelectVisible &&
                      <Grid.Col xs={12} sm={6} md={3} marginsFor={['xs']}>
                        {isEditing ?
                          <ErrorWrapper error={errors.operatorCardId}>
                            <Text.Label>Cartão Vinculado</Text.Label>
                            <Controller
                              control={control}
                              name="operatorCardId"
                              render={({ field: { value, onChange } }) =>
                                <Input.Select
                                  placeholder="Selecione..."
                                  value={cards.find(option => option.id === value)}
                                  onChange={e => onChange(e.id)}
                                  getOptionLabel={option => option.name}
                                  getOptionValue={option => option.id}
                                  isLoading={fetchCardsState.loading}
                                  isClearable={false}
                                  options={cards}
                                />
                              }
                            />
                            {errors.operatorCardId &&
                              <ErrorMessage>{errors.operatorCardId.message}</ErrorMessage>
                            }
                          </ErrorWrapper> :
                          <>
                            <Text.Label>Cartão Vinculado</Text.Label>
                            <Text>
                              {cardFee.operatorCardId ?
                                cards.find(option => option.id === cardFee.operatorCardId)?.name : "N/D"}
                            </Text>
                          </>
                        }
                      </Grid.Col>
                    }
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col>
                      <ErrorWrapper error={errors.frequency}>
                        <Text.Label>Periodicidade</Text.Label>
                        <CheckBoxWrapper>
                          <Controller
                            control={control}
                            name="frequency"
                            render={({ field: { value, onChange } }) =>
                              renderCheckboxes(value, onChange)
                            }
                          />
                        </CheckBoxWrapper>
                        {errors.frequency &&
                          <ErrorMessage>{errors.frequency.message}</ErrorMessage>
                        }
                      </ErrorWrapper>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row justify="end">
                    {isEditing ?
                      <>
                        <Grid.Col sm={2}>
                          <Button variant="text" onClick={(e) => {
                            if (isDirty) {
                              e.preventDefault();
                              setIsCancelConfirmationOpen(true);
                            } else cancelEdition();
                          }}>Cancelar</Button>
                        </Grid.Col>
                        <Grid.Col xs={12} marginsFor={['xs']}>
                          <Button form="cardFeeForm">Salvar alterações</Button>
                        </Grid.Col>
                      </> :
                      <Grid.Col xs={12} marginsFor={['xs']}>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            goBackToCardFeeList();
                          }}
                        >
                          Voltar
                        </Button>
                      </Grid.Col>
                    }
                  </Grid.Row>
                </>
              }
            </Grid>
          </Form>
        </Panel>
      </Content>
      <InfoModal
        open={isCancelConfirmationOpen}
        onClose={() => setIsCancelConfirmationOpen(false)}
      >
        <InfoModal.IconContainer color="var(--color-primary-01)">
          <BsExclamationCircle />
        </InfoModal.IconContainer>
        <InfoModal.Title>DESEJA CANCELAR A EDIÇÃO DA TARIFA?</InfoModal.Title>
        <InfoModal.Content>
          Ao clicar em confirmar os dados informados serão perdidos.
        </InfoModal.Content>
        <InfoModal.ButtonsWrapper>
          <Button onClick={() => setIsCancelConfirmationOpen(false)} variant="text">
            Cancelar
          </Button>
          <Button
            variant="ghost"
            onClick={cancelEdition}
          >
            Confirmar
          </Button>
        </InfoModal.ButtonsWrapper>
      </InfoModal>
      <InfoModal
        open={isChangeStatusConfirmationOpen}
        showCloseIcon={true}
        onClose={() => setIsChangeStatusConfirmationOpen(false)}
        closeOnOverlayClick={!changeStatusState.loading}
        closeOnEsc={!changeStatusState.loading}
      >
        {changeStatusState.loading ? <Spinner.Box padding="32px"><Spinner /></Spinner.Box > :
          <>
            <InfoModal.IconContainer color={isCardFeeActive ? "var(--color-status-02)" : "var(--color-status-01)"}>
              {isCardFeeActive ? <BsExclamationTriangle /> : <BsExclamationCircle />}
            </InfoModal.IconContainer>
            <InfoModal.Title color={isCardFeeActive ? "var(--color-status-02)" : "var(--color-status-01)"}>ATENÇÃO</InfoModal.Title>
            <InfoModal.Content>
              {`Você tem certeza que deseja ${isCardFeeActive ? "inativar" : "reativar"} essa tarifa?`}
            </InfoModal.Content>
            <InfoModal.ButtonsWrapper>
              <Button onClick={() => setIsChangeStatusConfirmationOpen(false)} variant="text">
                Cancelar
              </Button>
              <Button
                variant="ghost"
                predefTheme={isCardFeeActive ? "red" : "green"}
                onClick={() => changeStatusRequest(cardFeeId, isCardFeeActive ? OPERATOR_CARD_FEE_STATUS.INACTIVE : OPERATOR_CARD_FEE_STATUS.ACTIVE)}
              >
                Confirmar
              </Button>
            </InfoModal.ButtonsWrapper>
          </>
        }
      </InfoModal>
    </ >
  );
};

export default VisualizeAndEditOperatorCardFee;