import Modal from 'react-modal';
import { Icon, Button } from 'presentation/components';
import { Body, Title, Text, Flex } from './styles';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ModalCancel = ({ open, close, confirm }) => {
  return (
    <Modal isOpen={open} onRequestClose={close} style={customStyles}>
      <Body>
        <Icon name="warning" style={{ color: '#672ed7' }} />
        <Title>PERDA DE DADOS</Title>
        <Text>
          Atenção, ao sair do cadastro os dados dessa etapa do <br />
          cadastro serão perdidos. Você pode continuar de onde <br />
          parou em outro momento
          <br />
        </Text>
        <Flex>
          <Button variant="outlined" onClick={() => close()}>
            Cancelar
          </Button>
          <div style={{ marginLeft: '40px' }} />
          <Button type="submit" onClick={() => confirm()}>
            Continuar
          </Button>
        </Flex>
      </Body>
    </Modal>
  );
};

export default ModalCancel;
