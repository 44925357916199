import React, { useEffect, useState } from "react"
import { Button, Dialog } from "@stationkim/components-lib"
import { Content, StyledInput } from "./styles"
import { useForm, useFieldArray, Controller } from "react-hook-form"

import { Col, Container, Row } from "react-grid-system"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { Input, Spacing, Text } from "presentation/components"
import { ButtonWrapper, ViewParagraph } from "../visualizeAndEdit/styles"
import { IoMdAddCircle } from "react-icons/io"
import { BsTrash } from "react-icons/bs"

import NumberFormat from "react-number-format"

import enumParse from "main/utils/enumParse"

const ModalFeeRange = ({ open, onClose, data, setRange, range }) => {
  const [dataState, setDataState] = useState(data)

  const EFeeRangeType = {
    Percentage: 1,
    Real: 2,
  }
  const typeRealData = dataState.responseFeeData.feeConfigurationRanges.filter(e => e.rangeType === EFeeRangeType.Real)
  const typePercentageData = dataState.responseFeeData.feeConfigurationRanges.filter(
    e => e.rangeType === EFeeRangeType.Percentage
  )

  const typeRealDataDrop = typeRealData?.map(({ feeConfigurationId, id, ...keepAttrs }) => keepAttrs)
  const typePercentageDataDrop = typePercentageData?.map(({ feeConfigurationId, id, ...keepAttrs }) => keepAttrs)

  const EFeeValueType = {
    Percentage: 1,
    Real: 2,
    PercentageAndReal: 3,
  }

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oneTab:
        range?.oneTab?.length > 0
          ? range.oneTab
          : typePercentageDataDrop.length > 0 || typeRealDataDrop.length > 0
            ? typePercentageDataDrop.length > 0
              ? typePercentageDataDrop
              : typeRealDataDrop
            : [{ value: 0, initialRange: 1, finalRange: 1, disabled: false, rangeType: null }],
      twoTabs:
        range?.twoTabs?.length > 0
          ? range.twoTabs
          : typePercentageDataDrop.length > 0
            ? typeRealDataDrop
            : [{ value: 0, initialRange: 1, finalRange: 1, disabled: false, rangeType: null }],
    },
  })

  const {
    fields: fieldsOneTab,
    append: appendOneTab,
    remove: removeOneTab,
  } = useFieldArray({
    control,
    name: "oneTab",
  })

  const {
    fields: fiedsListTwoTabs,
    append: appendTwoTabs,
    remove: removeTwoTabs,
  } = useFieldArray({
    control,
    name: "twoTabs",
  })

  const watchOneTab = watch("oneTab")
  const watchTwoTabs = watch("twoTabs")

  const onSubmit = submitData => {
    setRange(submitData)
    reset()
    onClose()
  }

  useEffect(() => {
    reset()
    setDataState(data)
  }, [open])

  const validateMinValueOneTab = (value, index) => {
    const lastItemOneTab = fieldsOneTab[fieldsOneTab.length - 1]

    const minValueOneTab = watch(`oneTab[${index}].initialRange`, lastItemOneTab.initialRange)
    const lastValueOneTab = watch(`oneTab[${index}].finalRange`, lastItemOneTab.initialRange)

    if (lastValueOneTab < minValueOneTab) {
      setError(`oneTab[${index}].finalRange`, { type: "minValue", message: "Inválido" })
      return false
    } else {
      return true
    }
  }

  const validateMinValueTwoTabs = (value, index) => {
    const lastItemTwoTabs = fiedsListTwoTabs[fiedsListTwoTabs.length - 1]

    const minValueTwoTabs = watch(`twoTabs[${index}].initialRange`, lastItemTwoTabs.initialRange)
    const lastValueTwoTabs = watch(`twoTabs[${index}].finalRange`, lastItemTwoTabs.initialRange)

    if (lastValueTwoTabs < minValueTwoTabs) {
      setError(`twoTabs[${index}].finalRange`, { type: "minValue", message: "Inválido" })
      return false
    } else {
      return true
    }
  }

  function updateArrayTabs(arrayValues, newValue, index) {
    const newArrayValues = [
      ...arrayValues.slice(0, index),
      {
        ...arrayValues[index],
        finalRange: newValue.floatValue,
      },
      ...arrayValues.slice(index + 1),
    ]

    for (let i = index + 1; i < newArrayValues.length; i++) {
      newArrayValues[i].initialRange = newArrayValues[i - 1].finalRange + 1
      newArrayValues[i].finalRange = newArrayValues[i].initialRange + 1
    }
    newArrayValues[0].initialRange = 1
    return newArrayValues
  }

  function handleValuefinalRangeChangeOneTab(values, index) {
    const previousItems = [...fieldsOneTab.slice(0, parseInt(index) + 1)]
    const preparepreviousItems = [{ ...previousItems[previousItems.length - 1], finalRange: values.floatValue }]
    const previous = [...fieldsOneTab.slice(0, parseInt(index)), ...preparepreviousItems]
    const checkPreviousItems = previous.filter(e => {
      return e.initialRange > values.floatValue || e.finalRange > values.floatValue
    })

    if (checkPreviousItems.length > 0) {
      setError(`twoTabs[${index}].finalRange`, { type: "error", message: "Inválido" })
      setError(`oneTab[${index}].finalRange`, { type: "minValue", message: "Inválido" })
      return
    } else {
      const updateArray = updateArrayTabs(watchOneTab, values, index)
      reset({
        oneTab: updateArray,
        twoTabs: [...watchTwoTabs],
      })
    }
  }

  function handleValuefinalRangeChangeTwoTab(values, index) {
    const previousItems = [...fiedsListTwoTabs.slice(0, parseInt(index) + 1)]
    const preparepreviousItems = [{ ...previousItems[previousItems.length - 1], finalRange: values.floatValue }]
    const previous = [...fiedsListTwoTabs.slice(0, parseInt(index)), ...preparepreviousItems]
    const checkPreviousItems = previous.filter(e => {
      return e.initialRange > values.floatValue || e.finalRange > values.floatValue
    })

    if (checkPreviousItems.length > 0) {
      setError(`oneTab[${index}].finalRange`, { type: "error", message: "Inválido" })
      setError(`twoTabs[${index}].finalRange`, { type: "minValue", message: "Inválido" })
      return
    } else {
      const updateArray = updateArrayTabs(watchTwoTabs, values, index)
      reset({
        oneTab: [...watchOneTab],
        twoTabs: updateArray,
      })
    }
  }

  function handleValueChangeOneTab(values, index) {
    const checkValue = values.formattedValue.split(" ")
    if (checkValue[0] !== "R$" && values.floatValue > 100) {
      setError(`oneTab[${index}].finalRange`, { type: "error", message: "Inválido" })
      setError(`twoTabs[${index}].finalRange`, { type: "minValue", message: "Inválido" })
      clearErrors(`oneTab[${index}].finalRange`)
      clearErrors(`twoTabs[${index}].finalRange`)
      return
    }
    const floatValue = values.floatValue || 0
    setValue(`oneTab[${index}].value`, floatValue)
  }

  function handleValueChangeTwoTabs(values, index) {
    const floatValue = values.floatValue || 0
    setValue(`twoTabs[${index}].value`, floatValue)
  }

  return (
    <Dialog.Root open={open}>
      <Dialog.Content>
        <Content>
          <Row>
            <Col>
              <Text.Subtitle>Intervalos</Text.Subtitle>
              <div
                style={{
                  zIndex: 9,
                  position: "absolute",
                  right: "0px",
                  top: "0px",
                }}
              >
                <p>Critério</p>
                <Input type="text" value={enumParse(dataState.feeData.criterion, "EFeeCriterion")} disabled />
              </div>
            </Col>
          </Row>
          <Tabs>
            <Spacing top="32px" />
            <TabList>
              {(dataState.feeData.valueType === 1 || dataState.feeData.valueType === 3) && (
                <Tab disabled={Object.keys(errors).length === 0 ? false : true}>Porcentagem</Tab>
              )}
              {(dataState.feeData.valueType === 2 || dataState.feeData.valueType === 3) && (
                <Tab disabled={Object.keys(errors).length === 0 ? false : true}>Reais</Tab>
              )}
            </TabList>

            <form
              onSubmit={handleSubmit(data => {
                if (validateMinValueOneTab() && Object.keys(errors).length === 0) {
                  onSubmit(data)
                }
              })}
            >
              <TabPanel>
                <div
                  className="overflow-scroll"
                  style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: "286px",
                    padding: "18px",
                  }}
                >
                  <Container style={{ padding: 0 }}>
                    {fieldsOneTab.map((item, index) => {
                      return (
                        <>
                          <Spacing top="32px" />
                          <Row>
                            <Col>
                              {dataState.feeData.valueType !== EFeeValueType.PercentageAndReal &&
                                dataState.feeData.valueType === EFeeValueType.Real ? (
                                <p>
                                  <b>{index + 1}. </b>Valor R$
                                </p>
                              ) : (
                                <p>
                                  <b>{index + 1}. </b>Valor %
                                </p>
                              )}
                              <StyledInput>
                                <NumberFormat
                                  required
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  decimalScale={
                                    dataState.feeData.valueType !== EFeeValueType.PercentageAndReal &&
                                      dataState.feeData.valueType === EFeeValueType.Real
                                      ? 2
                                      : 0
                                  }
                                  fixedDecimalScale={true}
                                  prefix={
                                    dataState.feeData.valueType !== EFeeValueType.PercentageAndReal &&
                                      dataState.feeData.valueType === EFeeValueType.Real
                                      ? "R$ "
                                      : ""
                                  }
                                  suffix={
                                    dataState.feeData.valueType !== EFeeValueType.PercentageAndReal &&
                                      dataState.feeData.valueType === EFeeValueType.Real
                                      ? ""
                                      : "%"
                                  }
                                  placeholder={
                                    `${watchOneTab[index].value}` > 0
                                      ? `${watchOneTab[index].value.toLocaleString("pt-br", {
                                        style: "currency",
                                        currency: "BRL",
                                      })}`
                                      : dataState.feeData.valueType !== EFeeValueType.PercentageAndReal &&
                                        dataState.feeData.valueType === EFeeValueType.Real
                                        ? "R$ 00,00"
                                        : "0%"
                                  }
                                  value={
                                    item > 0
                                      ? item.value
                                      : `${watchOneTab[index].value}` > 0
                                        ? `${watchOneTab[index].value}`
                                        : 0
                                  }
                                  onValueChange={values => handleValueChangeOneTab(values, index)}
                                  allowNegative={false}
                                  isNumericString
                                  name={`oneTab[${index}].value`}
                                  ref={register}
                                  {...register(`oneTab[${index}].value`, { required: true, min: 0 })}
                                />
                              </StyledInput>
                            </Col>
                            <Col>
                              <p>De</p>
                              <Controller
                                render={({ field }) => (
                                  <Input
                                    text="number"
                                    disabled={true}
                                    {...field}
                                    value={index === 0 ? 1 : watchOneTab[index].initialRange}
                                  />
                                )}
                                name={`oneTab[${index}].initialRange`}
                                control={control}
                              />
                            </Col>
                            <Col>
                              <p>Até</p>
                              <Controller
                                render={({ field }) => (
                                  <>
                                    <StyledInput>
                                      <NumberFormat
                                        required
                                        value={item > 0 ? item.finalRange : `${watchOneTab[index].finalRange}`}
                                        onValueChange={values => handleValuefinalRangeChangeOneTab(values, index)}
                                        allowNegative={false}
                                        isNumericString
                                        name={`oneTab[${index}].finalRange`}
                                        ref={register}
                                        {...register(`oneTab[${index}].finalRange`)}
                                        {...field}
                                      />
                                    </StyledInput>
                                  </>
                                )}
                                name={`oneTab[${index}].finalRange`}
                                control={control}
                              />
                              {Object.keys(errors).length > 0 &&
                                errors?.oneTab[index]?.finalRange?.type === "minValue" && (
                                  <span style={{ color: "red" }}>{errors?.oneTab[index]?.finalRange?.message}</span>
                                )}
                            </Col>
                            <Col style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                              {Object.keys(errors).length === 0 && watchOneTab.length > 1 && (
                                <BsTrash
                                  onClick={() => removeOneTab(index)}
                                  size="37px"
                                  color="red"
                                  enableBackground={true}
                                  style={{
                                    background: "#fafafa",
                                    padding: "9px",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        </>
                      )
                    })}
                  </Container>
                </div>
                <Container>
                  <Row>
                    <Col>
                      <Spacing top="32px" />
                      {Object.keys(errors).length > 0 ? (
                        ""
                      ) : (
                        <ViewParagraph
                          onClick={() => {
                            if (
                              validateMinValueOneTab(fieldsOneTab[fieldsOneTab.length - 1], fieldsOneTab.length - 1)
                            ) {
                              const checkValueType =
                                dataState.feeData.valueType === 3
                                  ? EFeeRangeType.Percentage
                                  : dataState.feeData.valueType
                              const lastIndex = watchOneTab.length > 0 ? watchOneTab.length - 1 : 0

                              setValue(`oneTab[${lastIndex}].disabled`, true)
                              setValue(`oneTab[${lastIndex}].rangeType`, checkValueType)

                              appendOneTab({
                                value: 0,
                                rangeType: checkValueType,
                                initialRange: parseInt(watchOneTab[lastIndex]?.finalRange) + 1,
                                finalRange: parseInt(watchOneTab[lastIndex]?.finalRange) + 1,
                              })
                            }
                          }}
                        >
                          <IoMdAddCircle />
                          Adicionar Intervalo
                        </ViewParagraph>
                      )}
                    </Col>
                  </Row>
                  <Spacing top="32px" />
                  <Row nogutter>
                    <Col sm={12}>
                      <ButtonWrapper>
                        <Button variant="action-outlined" onClick={onClose}>
                          Voltar
                        </Button>
                        <Button
                          type="submit"
                          variant="action"
                          disabled={Object.keys(errors).length === 0 ? false : true}
                        >
                          Salvar
                        </Button>
                      </ButtonWrapper>
                    </Col>
                  </Row>
                </Container>
              </TabPanel>
              <TabPanel>
                <div
                  className="overflow-scroll"
                  style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: "286px",
                    padding: "18px",
                  }}
                >
                  <Container style={{ padding: 0 }}>
                    {fiedsListTwoTabs.map((item, index) => {
                      return (
                        <>
                          <Spacing top="32px" />
                          <Row>
                            <Col>
                              <p>
                                <b>{index + 1}.</b> Valor R$
                              </p>
                              <StyledInput>
                                <NumberFormat
                                  required
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  prefix="R$ "
                                  value={
                                    item > 0
                                      ? item?.value
                                      : `${watchTwoTabs[index]?.value}` > 0
                                        ? `${watchTwoTabs[index]?.value}`
                                        : 0
                                  }
                                  onValueChange={values => handleValueChangeTwoTabs(values, index)}
                                  allowNegative={false}
                                  isNumericString
                                  name={`twoTabs[${index}].value`}
                                  ref={register}
                                  {...register(`twoTabs[${index}].value`, { required: true, min: 0 })}
                                />
                              </StyledInput>
                            </Col>
                            <Col>
                              <p>De</p>
                              <Controller
                                render={({ field }) => (
                                  <Input
                                    text="number"
                                    disabled
                                    {...field}
                                    value={index === 0 ? 1 : watchTwoTabs[index].initialRange}
                                  />
                                )}
                                name={`twoTabs[${index}].initialRange`}
                                control={control}
                              />
                            </Col>
                            <Col>
                              <p>Até</p>
                              <Controller
                                render={({ field }) => (
                                  <StyledInput>
                                    <NumberFormat
                                      required
                                      value={item > 0 ? item.finalRange : `${watchTwoTabs[index].finalRange}`}
                                      onValueChange={values => handleValuefinalRangeChangeTwoTab(values, index)}
                                      allowNegative={false}
                                      isNumericString
                                      name={`twoTabs[${index}].finalRange`}
                                      ref={register}
                                      {...register(`twoTabs[${index}].finalRange`)}
                                      {...field}
                                    />
                                  </StyledInput>
                                )}
                                name={`twoTabs[${index}].finalRange`}
                                control={control}
                              />
                              {Object.keys(errors).length > 0 &&
                                errors?.twoTabs[index]?.finalRange?.type === "minValue" && (
                                  <span>{errors?.oneTab[index]?.finalRange?.message}</span>
                                )}
                            </Col>
                            <Col style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                              {Object.keys(errors).length === 0 && watchTwoTabs.length > 1 && (
                                <BsTrash
                                  onClick={() => removeTwoTabs(index)}
                                  size="37px"
                                  color="red"
                                  enableBackground={true}
                                  style={{
                                    background: "#fafafa",
                                    padding: "9px",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        </>
                      )
                    })}
                  </Container>
                </div>
                <Container>
                  <Row>
                    <Col>
                      <Spacing top="32px" />
                      {Object.keys(errors).length > 0 ? (
                        ""
                      ) : (
                        <ViewParagraph
                          onClick={() => {
                            if (
                              validateMinValueTwoTabs(
                                fiedsListTwoTabs[fiedsListTwoTabs.length - 1],
                                fiedsListTwoTabs.length - 1
                              )
                            ) {
                              const checkValueType =
                                dataState.feeData.valueType === 3 ? EFeeRangeType.Real : dataState.feeData.valueType
                              const lastIndex = watchTwoTabs.length > 0 ? watchTwoTabs.length - 1 : 0

                              setValue(`twoTabs[${lastIndex}].disabled`, true)
                              setValue(`twoTabs[${lastIndex}].rangeType`, checkValueType)

                              appendTwoTabs({
                                value: 0,
                                rangeType: checkValueType,
                                initialRange: parseInt(watchTwoTabs[lastIndex]?.finalRange) + 1,
                                finalRange: parseInt(watchTwoTabs[lastIndex]?.finalRange) + 1,
                              })
                            }
                          }}
                        >
                          <IoMdAddCircle />
                          Adicionar Intervalo
                        </ViewParagraph>
                      )}
                    </Col>
                  </Row>
                  <Spacing top="32px" />
                  <Row nogutter>
                    <Col sm={12}>
                      <ButtonWrapper>
                        <Button variant="action-outlined" onClick={onClose}>
                          Voltar
                        </Button>
                        <Button
                          type="submit"
                          variant="action"
                          disabled={Object.keys(errors).length === 0 ? false : true}
                        >
                          Salvar
                        </Button>
                      </ButtonWrapper>
                    </Col>
                  </Row>
                </Container>
              </TabPanel>
            </form>
            <Spacing top="32px" />
          </Tabs>
        </Content>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default ModalFeeRange
