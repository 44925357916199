import styled from 'styled-components';

export const Body = styled.div`
  margin-top: 40px;
`;

export const Title = styled.h1`
  color: #28252e;
  font-size: 27px;
`;

export const SubTitle = styled.p`
  font-weight: 200;
  padding: 15px 0;
  color: #4f4e55;
`;

export const Space = styled.div`
  margin-top: 40px;
`;

export const Label = styled.div`
  margin-bottom: 10px;
  white-space: nowrap;
`;

export const FlexRight = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const Limited = styled.div`
  width: 400px;
  display: flex;
`;

export const TextMessage = styled.p`
  font-size: 14px;
  color: #b3b0ba;
  margin-top: 60px;
`;

export const SessionAddAddress = styled.div`
  display: flex;
  margin-top: 50px;
  cursor: pointer;
`;

export const IconAdd = styled.div`
  border-radius: 50%;
  color: #fff;
  background-color: ${(props) => (props.active ? '#672ed7' : '#672ed75c')};
  width: 18px;
  text-align: center;
  margin-right: 5px;
`;

export const TextAdd = styled.p`
  color: ${(props) => (props.active ? '#672ed7' : '#672ed75c')};
  font-weight: bold;
`;

export const TextArea = styled.textarea`
  border: 1px solid var(--color-secundary-06);
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: var(--font-weight-regular);
  padding: 0 1rem;
  outline: none;
  height: 120px;
  width: 100%;

  ::placeholder {
    color: var(--color-primary-03);
  }
`;

export const TitleSession = styled.h5`
  font-size: 16px;
  padding-right: 5px;
  margin-bottom: 20px;
`;

export const Flex = styled.div`
  display: flex;
  cursor: pointer;
`;

export const Key = styled.p`
  font-weight: bold;
  padding-bottom: 5px;
  font-size: 14px;
`;

export const KeyContent = styled.p`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis
`;

export const Line = styled.div`
  border-bottom: 1px solid #dcdcdc;
  margin: 20px 0;
`;
