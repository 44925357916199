import BDropzone from './dropzone';
import Progress from './progress';
import Dropline from './dropline';
import Dropline2 from './dropline2';

const Dropzone = BDropzone;
Dropzone.Progress = Progress;
Dropzone.Line = Dropline;
Dropzone.Line2 = Dropline2;

export default Dropzone;
