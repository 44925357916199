import { FilterDialog, FilterDialogProps as FilterProps, FormSelect } from "@stationkim/front-ui"
import { operatorsStatus } from "@/main/utils/options"

export const Filter = ({
  ...props
}: FilterProps) => {
  return (
    <FilterDialog  {...props}  >
      <FormSelect
        name="status"
        label='Filtrar status por'
        placeholder="Definir Status"
        options={operatorsStatus}
      />
    </FilterDialog>
  )
}