import { Text } from "@/presentation/components"

import { IconChange } from "./assets/IconChange"
import { IconPlan } from "./assets/IconPlan"

import { ContainerPlans, Plan } from "../styles"

export const Plans = ({ plans }) => {
  return (
    <>
      <Text.Subtitle>Plano de empresas</Text.Subtitle>

      <ContainerPlans>
        {plans.map(plan => (
          <Plan key={plan.id}>
            <IconPlan />
            <p>{plan.description}</p>
          </Plan>
        ))}

        {plans?.length ? (
          <button>
            Alterar <IconChange />
          </button>
        ) : null}
      </ContainerPlans>
    </>
  )
}
