import styled from 'styled-components';

export const Label = styled.label`
  display: inline-block;
  margin-bottom: 8px;
`;

export const Line = styled.div`
  border-top: 1px solid #999999;
  width: 1000vw;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
