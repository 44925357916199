import * as yup from "yup";

const generalDataSchema = yup.object().shape({
  abortEarly: false,
  corporateName: yup
    .string()
    .max(90, "Campo limitado a 90 caracteres")
    .required("Razão social é um campo requerido"),
  tradeName: yup
    .string()
    .max(40, "Campo limitado a 40 caracteres")
    .required("Razão social é um campo requerido"),
  email: yup
    .string()
    .email("É preciso que seja um e-mail valido")
    .required("E-mail é um campo requerido"),
  site: yup
    .string()
    .required("E-mail é um campo requerido"),
});

export default generalDataSchema;