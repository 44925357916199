import styled from 'styled-components';

export const Title = styled.p`
  font-size: 1.125rem;
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-01);
  margin-bottom: 32px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 32px;
  button + button {
    margin-left: 32px;
  }
`;
