import styled from 'styled-components';

export const StyledTh = styled.th`
  height: 48px;
  padding: 12px 16px;
  line-height: 24px;
  text-align: ${({ align }) => align};
  white-space: ${({ wrap }) => wrap};
  font-weight: var(--font-weight-bold);
`;

export const OrderContainer = styled.div`
  position: relative;
  flex-direction: column;
  display: inline-block;
  margin-left: 8px;
  height: 17px;
  width: 8px;
  vertical-align: middle;
`;

export const ArrowUp = styled.button`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  outline: none;
  border-top: none;
  border-bottom: 6px solid black;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-radius: 4px;
  margin-bottom: 8px;
`;

export const ArrowDown = styled.button`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  background: none;
  outline: none;
  border-top: 6px solid black;
  border-bottom: none;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-radius: 4px;
`;
