import history from "@/services/history"
import Button from "presentation/components/buttonV2"
import { TbFilter } from "react-icons/tb"

import { Grid, Panel, Content, PageHeader, Search } from "@/presentation/components"

import { LayoutStyles } from "./styles"

export const Layout = () => {
  return (
    <Content>
      <Panel noPadding>
        <Grid padding="16px 16px 0 16px">
          <Grid.Row align="center" justify="between">
            <Grid.Col md={12} lg="content">
              <PageHeader>Layout de planilhas</PageHeader>
            </Grid.Col>
          </Grid.Row>
        </Grid>
        <Grid padding="16px 16px 0 16px">
          <Grid.Row align="center" justify="between">
            <Grid.Col md={8}>
              <LayoutStyles.SearchContent>
                <Search placeholder="Pesquisar na tabela abaixo..." />
                <button>
                  <TbFilter />
                  Filtrar
                </button>
              </LayoutStyles.SearchContent>
            </Grid.Col>
            <Grid.Col display="flex" justify="end" md="content">
              <LayoutStyles.NewLayout onClick={() => history.push("/layout/novo")}>Novo Layout</LayoutStyles.NewLayout>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Panel>
    </Content>
  )
}
