import { useState } from 'react';
import FloatingList from '../../floatingList';
import Icon from '../../icon';
import { Button } from './styles';
import useOnClickOutside from 'main/hooks/useOnClickOutside';

const DropDown = ({
  arrow,
  children,
  icon,
  label,
  size,
  variant,
  ...props
}) => {
  const [isListVisible, setIsListVisible] = useState(false);
  const clickOut = useOnClickOutside(() => {
    setIsListVisible(false);
  });
  function handleOnClick() {
    setIsListVisible(!isListVisible);
  }

  return (
    <Button
      size={size}
      ref={clickOut}
      variant={variant}
      onClick={handleOnClick}
      {...props}
    >
      {icon && <Icon name={icon} />}
      {label}
      {arrow && <Icon name="arrow-down" />}
      <FloatingList visible={isListVisible}>{children}</FloatingList>
    </Button>
  );
};

export default DropDown;
