import { useContext, useState } from "react"
import { GridProps } from "@mui/material"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@stationkim/front-ui"
import { RatesContext } from "../../provider/RatesProvider"
import { useFloatingComponent } from "./useFloatingComponent"
import { FloatingButtonsContainer } from "./styles"

interface ButtonGroupProps {
  id?: string,
  disabled?: boolean,
  disableSave?: boolean,
  gridItemConfig?: GridProps,
  onConfirm?: () => void
  onCancel?: () => void
}

const ButtonGroup = ({
  id,
  disabled,
  disableSave,
  gridItemConfig = { xs: 12, sm: 6, md: 'auto' },
  onCancel = () => null,
  onConfirm = () => null
}: ButtonGroupProps) => {
  return (
    <Grid container spacing='16px' justifyContent='end' direction={{ xs: "column-reverse", sm: 'row' }}  >
      <Grid item {...gridItemConfig}>
        <Button variant='outlined' size="small" sx={{ filter: 'grayscale(100)' }} disabled={disabled} fullWidth onClick={onCancel}>Cancelar</Button>
      </Grid>
      <Grid item {...gridItemConfig}>
        <Button variant='contained' size="small" disabled={disabled || disableSave} fullWidth id={id} onClick={onConfirm}>Salvar Alterações</Button>
      </Grid>
    </Grid>
  )
}
interface FloatingControlButtonsProps extends Pick<ButtonGroupProps, 'onCancel' | 'onConfirm'> {
  shouldAppear: boolean
  disableSave: boolean
}

const FloatingControlButtons = ({ shouldAppear, disableSave, onCancel, onConfirm }: FloatingControlButtonsProps) => {
  return (
    <FloatingButtonsContainer visible={shouldAppear}>
      <Grid container spacing='16px' alignItems='center' justifyContent='center' >
        <Grid item xs={12} md='auto'>
          <Typography variant="body2" sx={{ whiteSpace: { lg: 'nowrap' }, display: 'inline-block' }}>
            Você possui alterações não salvas, deseja salva-las agora?
          </Typography>
        </Grid>
        <Grid item xs={12} md='auto'>
          <ButtonGroup gridItemConfig={{ xs: 12, sm: 6, md: 'auto' }} onCancel={onCancel} onConfirm={onConfirm} disableSave={disableSave} />
        </Grid>
      </Grid>
    </FloatingButtonsContainer>
  )
}

export const ControlButtons = () => {
  const [isCanceling, setIsCanceling] = useState(false)
  const buttonId = 'refControlButton'
  const {
    cancelAllModification,
    saveChanges,
    isLoadingCompaniesChanges,
    isLoadingResaleChanges,
    modificationStatus,
    modifications,
    isSaveable,
  } = useContext(RatesContext)

  const { isOutOfView } = useFloatingComponent({ componentId: buttonId })
  const handlePrimaryCancel = () => setIsCanceling(true)
  const onClose = () => setIsCanceling(false)
  const handleCancel = () => {
    cancelAllModification()
    setIsCanceling(false)
  }
  const disabled =
    (isLoadingCompaniesChanges ||
      isLoadingResaleChanges ||
      modificationStatus !== 'pending') &&
    !modifications.companiesConfiguredRates.isOpenField

  const disableSave = modificationStatus !== 'pending' || !isSaveable
  return (
    <>
      <FloatingControlButtons
        shouldAppear={isOutOfView && !disabled}
        onCancel={handlePrimaryCancel}
        onConfirm={saveChanges}
        disableSave={disableSave}
      />
      <ButtonGroup id={buttonId}
        disabled={disabled}
        onCancel={handlePrimaryCancel}
        onConfirm={saveChanges}
        disableSave={disableSave}
      />
      <Dialog open={isCanceling}>
        <DialogTitle>Cancelar alterações</DialogTitle>
        <DialogContent sx={{ margin: '32px 0px' }}>
          Deseja cancelar as alterações realizadas?
        </DialogContent>
        <DialogActions>
          <Grid container spacing='16px' direction={{ xs: 'column-reverse', sm: 'row' }}>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" onClick={onClose} fullWidth>
                Não
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="outlined" onClick={handleCancel} fullWidth>
                Sim
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}