export const minTwoDigits = (number) => {
  return number ? ('0' + number).slice(-2) : '00';
};

export const hideDocument = (document) => {
  if (!document) return;
  const hidedDoc = `***.${document.slice(3, 6)}.${document.slice(6, 9)}-**`;
  return hidedDoc;
};

export const removeSpecialCharacters = (string) =>
  string?.replace(/[^0-9|a-z]/gi, '');

export const reduceBigTextLines = (text, limit) => {
  return text.length > limit ? text.slice(0, limit) + ' . . .' : text;
};

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

export const formatMoney = (value = 0) =>
  parseFloat(value).toLocaleString('pt-br', { minimumFractionDigits: 2 });

export const organizeArrayByNestedValue = (array, value) => {
  return [...array].sort((a, b) => {
    if (value(a) > value(b)) return 1;
    if (value(a) < value(b)) return -1;
    return 0;
  });
};

export const dateToApiStandards = (date) => {
  if (date) return date.toISOString().split('T')[0];
};

export const applyMask = (mask, string, maskChar = '#') => {
  const stringCanBeMasked = string?.length >= (mask.split(maskChar)?.length - 1);
  if (!stringCanBeMasked) return false;
  let newString = mask;
  for (let i = 0; i < string.length; i++) {
    if (mask[i] === string[i]) continue;
    newString = newString.replace(maskChar, string[i]);
  }
  return newString.split('#')[0];
};

export const createIntervalStringWithDates = (initialDate, finalDate) => {
  let intervalString = '';
  if (initialDate && !finalDate) intervalString = `>=${initialDate}`;
  if (!initialDate && finalDate) intervalString = `<=${finalDate}`;
  if (initialDate && finalDate) intervalString = `${initialDate}><${finalDate}`;
  return intervalString;
};

export const getDatesFromIntervalString = (dateString = '') => {
  let initialDate;
  let finalDate;
  if (dateString?.includes('>=')) {
    const extractedDate = dateString.replace('>=', '');
    initialDate = extractedDate;
  } else if (dateString?.includes('<=')) {
    const extractedDate = dateString.replace('<=', '');
    finalDate = extractedDate;
  } else if (dateString?.includes('><')) {
    const dates = dateString.split('><');
    initialDate = dates[0];
    finalDate = dates[1];
  }
  return {
    initialDate,
    finalDate,
  };
};

export const removeObjectEmptyItems = (object) => {
  let newObject = {};
  for (let key in object) {
    const isEmpty =
      object[key] === null || object[key] === undefined || object[key] === '';
    if (!isEmpty) newObject[key] = object[key];
  }
  return newObject;
};

export const restartCSSAnimation = (element) => {
  element.style.animation = 'none';
  void element.offsetWidth;
  element.style.animation = null;
};

export const returnValueOrMessageIfIsEmpty = (value, message) => {
  if ((!value && value !== 0) || value === "") return message;
  return value;
};