import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, styled } from "@stationkim/components-lib";

export const ButtonLine = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
});

export const ModalIconFrame = styled('div', {
  height: '128px',
  width: '128px',
  backgroundColor: '$gray100',
  borderRadius: '$pill',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '32px'
});

export const ModalIcon = styled(FontAwesomeIcon, {
  height: '78px',
  width: '78px',
});

export const Content = styled('div', {
  maxWidth: '600px',
  minWidth: 'min(600px, 80vw)',
  padding: '0 32px',
  textAlign: "left",
  display: 'flex',
  alignItems: 'left',
  justifyContent: 'left',
  flexDirection: 'column',
  '> div:last-child': {
    width: '100%',
  }
});

export const ModalContent = styled('div', {
  maxWidth: '600px',
  minWidth: 'min(600px, 80vw)',
  padding: '0 32px',
  textAlign: "center",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  '> div:last-child': {
    width: '80%',
  }
});

export const StyledDialogTitle = styled(Dialog.Title, {
  marginBottom: '40px',
  fontWeight: '$bold'
});

export const StyledDialogDescription = styled(Dialog.Description, {
  fontSize: '$4',
  marginBottom: '64px'
});

export const StyledInput  = styled('div', {
  'input': {
    border: '1px solid var(--color-secundary-06)',
    borderRadius: '0.25rem',
    fontSize: '0.875rem',
    fontWeight: 'var(--font-weight-regular)',
    padding: '0 1rem',
    outline: 'none',
    height: '2.5rem',
    width: '100%'
  }
});