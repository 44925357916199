import styled from "@emotion/styled"

const NewLayout = styled.button`
  width: 190px;
  height: 48px;
  background-color: rgba(25, 118, 210, 1);
  border-radius: 4px;
  border: none;
  cursor: pointer;

  font-size: 14px;
  font-weight: 500;
  color: #fff;
`

const SearchContent = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  input {
    width: 380px;
    height: 48px;
    background: transparent;
    border: 1px solid rgba(158, 158, 158, 1);
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 136px;
    height: 48px;
    color: rgba(158, 158, 158, 1);
    border: 1px solid rgba(158, 158, 158, 1);
    border-radius: 4px;
    cursor: pointer;

    font-size: 18px;
    font-weight: 600;
  }
`

export const LayoutStyles = {
  NewLayout,
  SearchContent,
}
