export const status = {
  0: "Criado",
  1: "Aguardando Aprovação",
  2: "Aprovado",
  3: "Rejeitado",
  4: "Aguardando Migração",
  5: "Migrando",
  6: "Erro de Migração",
  7: "Aguardando Operador",
  8: "Sincronizando",
  9: "Erro de Sincronização",
}
