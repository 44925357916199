import { CustomContainer } from './styles';

const Container = ({
  children,
  padding = '16px',
  spaceBetweenRows = '16px',
  ...props
}) => {
  return (
    <CustomContainer
      fluid
      padding={padding}
      spacebetweenrows={spaceBetweenRows}
      {...props}
    >
      {children}
    </CustomContainer>
  );
};

export default Container;
