import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Dialog } from "@stationkim/components-lib";
import {
  ButtonLine,
  ModalContent,
  ModalIcon,
  ModalIconFrame,
  StyledDialogDescription,
  StyledDialogTitle
} from "./styles";

export const ModalSkipSaveConfimation = ({
  open,
  onClose,
  onConfirm
}) => {
  return (
    <Dialog.Root
      open={open}
    >
      <Dialog.Content >
        <ModalContent>
          <ModalIconFrame>
            <ModalIcon
              icon={faExclamationCircle}
              css={{ color: '$atention200' }}
            />
          </ModalIconFrame>
          <StyledDialogTitle
            css={{ color: '$atention200' }}
          >
            PERDA DE DADOS
          </StyledDialogTitle>
          <StyledDialogDescription>
            Existem dados não salvos, caso prossiga eles serão perdidos. Deseja continuar?
          </StyledDialogDescription>
          <ButtonLine>
            <Button buttonType="text" onClick={onClose}>Cancelar</Button>
            <Button buttonType="outlined" color="atention" onClick={onConfirm}>Confirmar</Button>
          </ButtonLine>
        </ModalContent>
      </Dialog.Content>
    </Dialog.Root>
  );
};