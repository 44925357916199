import Spinner from 'presentation/components/spinner';
import { StyledTable, TableContainer } from './styles';

const Table = ({ children, loading, visibleRows = 10 }) => {
  const rowHeigth = 48;
  const maxHeight = (visibleRows + 1) * rowHeigth + 'px';
  return (
    <TableContainer maxHeight={maxHeight} loading={loading ? 'hidden' : 'auto'}>
      {loading ? <Spinner /> : <StyledTable>{children}</StyledTable>}
    </TableContainer>
  );
};

export default Table;
