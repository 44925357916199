import { useState, useMemo } from 'react';
import { Title, Label, Flex, Text } from '../styles';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import Button from 'presentation/components/buttonV2';
import api from '@/infra/api';
import { useDispatch } from 'react-redux';
import errorHandler from 'main/utils/errorHandler';
import { pushNotification } from '@/store/modules/notification/actions';
import ModalCancel from './components/modelCancel';
import history from '@/services/history';
import successHandler from 'main/utils/successHandler';
import { Notification, Grid, Input, ErrorMessage, Spinner } from 'presentation/components';

const Data = () => {
  const [incidence, setIncidence] = useState(null);
  const [errorIncidence, setErrorIncidence] = useState(false);
  const [loading, setLoading] = useState(false);
  const [obligatory, setObligatory] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [validate, setValidate] = useState(false);

  const dispatch = useDispatch();

  const schema = yup.object({
    name: yup
      .string()
      .max(100, 'Campo limitado a 100 caracteres')
      .required('Campo obrigatório'),
    type: yup.number().required('Campo obrigatório'),
    criterion: yup.number().required('Campo obrigatório'),
    franchiseMinimal: yup.number().required('Campo obrigatório'),
    valueType: yup.number().required('Campo obrigatório'),
    application: yup.number().required('Campo obrigatório'),
    calculus: yup.number().required('Campo obrigatório'),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const save = async (data) => {
    setLoading(true);

    if (!incidence) {
      setLoading(false);
      setErrorIncidence(true);
      setValidate(true);
      return;
    }

    try {
      data.obligatory = obligatory;
      data.status = 1;
      data.incidence = incidence;

      await api.post('/Fee', data);
      setLoading(false);
      dispatch(pushNotification(successHandler('Taxa Adicionada com sucesso')));
      history.push('/taxas');
    } catch (e) {
      dispatch(pushNotification(errorHandler(e?.response)));
      setLoading(false);
    }
  };

  useMemo(() => {
    if (validate) {
      if (!incidence) {
        setErrorIncidence(true);
      } else {
        setErrorIncidence(false);
      }
    }
  }, [incidence, validate]);

  const typeFess = [
    { label: 'Hospedagem', value: 1 },
    { label: 'Setup', value: 2 },
    { label: 'Sustentação', value: 3 },
    { label: 'Uso do Sistema', value: 4 },
  ];

  const criterion = [
    { label: 'Global', value: 1 },
    { label: 'Cartão', value: 2 },
    { label: 'Revendas', value: 3 },
    { label: 'Colaborador', value: 4 },
    { label: 'Produtos Operadora', value: 5 },
    { label: 'Produtos Dealer', value: 6 },
  ];

  const frank = [
    { label: 'Existe', value: 1 },
    { label: 'Não Existe', value: 2 },
  ];

  const typeValue = [
    { label: 'Porcentagem', value: 1 },
    { label: 'Reais', value: 2 },
    { label: 'Reais + Porcentagem', value: 3 },
  ];

  const application = [
    { label: 'Revendas', value: 1 },
    { label: 'Parceiros de Negócio', value: 2 },
    { label: 'Todos', value: 3 },
  ];

  const calc = [
    { label: 'Valor', value: 1 },
    { label: 'Unitário', value: 2 },
  ];

  return (
    <>
      <Notification />
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '40px',
          }}
        >
          <Spinner />
        </div>
      )}
      {!loading && (
        <>
          <Title>Dados da Taxa</Title>
          <form onSubmit={handleSubmit(save)}>
            <Grid padding={'30px 0 '}>
              <Grid.Row>
                <Grid.Col xs={12} md={12} lg={3}>
                  <Label>Nome</Label>
                  <Input maxLength="101" {...register('name')} />
                  {errors.name && (
                    <ErrorMessage>{errors.name?.message}</ErrorMessage>
                  )}
                </Grid.Col>
                <Grid.Col xs={12} md={12} lg={3}>
                  <Label>Tipo de taxa</Label>
                  <Controller
                    control={control}
                    name="type"
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Select
                        placeholder="Selecione"
                        value={typeFess.find(
                          (option) => option.value === value
                        )}
                        inputRef={ref}
                        onChange={(e) => onChange(e?.value)}
                        options={typeFess}
                      />
                    )}
                  />
                  {errors.type && (
                    <ErrorMessage>{errors.type?.message}</ErrorMessage>
                  )}
                </Grid.Col>
                <Grid.Col xs={12} md={12} lg={3}>
                  <Label>Critério</Label>
                  <Controller
                    control={control}
                    name="criterion"
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Select
                        placeholder="Selecione"
                        value={criterion.find(
                          (option) => option.value === value
                        )}
                        inputRef={ref}
                        onChange={(e) => onChange(e?.value)}
                        options={criterion}
                      />
                    )}
                  />
                  {errors.criterion && (
                    <ErrorMessage>{errors.criterion?.message}</ErrorMessage>
                  )}
                </Grid.Col>
                <Grid.Col xs={12} md={12} lg={3}>
                  <Label>Franquia mínima</Label>
                  <Controller
                    control={control}
                    name="franchiseMinimal"
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Select
                        placeholder="Selecione"
                        value={frank.find((option) => option.value === value)}
                        inputRef={ref}
                        onChange={(e) => onChange(e?.value)}
                        options={frank}
                      />
                    )}
                  />
                  {errors.franchiseMinimal && (
                    <ErrorMessage>
                      {errors.franchiseMinimal?.message}
                    </ErrorMessage>
                  )}
                </Grid.Col>
              </Grid.Row>
              <Grid.Row style={{ marginTop: '40px' }}>
                <Grid.Col xs={12} md={12} lg={3}>
                  <Label>Tipo valor</Label>
                  <Controller
                    control={control}
                    name="valueType"
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Select
                        placeholder="Selecione"
                        value={typeValue.find(
                          (option) => option.value === value
                        )}
                        inputRef={ref}
                        onChange={(e) => onChange(e?.value)}
                        options={typeValue}
                      />
                    )}
                  />
                  {errors.valueType && (
                    <ErrorMessage>{errors.valueType?.message}</ErrorMessage>
                  )}
                </Grid.Col>
                <Grid.Col xs={12} md={12} lg={3}>
                  <Label>Aplicação</Label>
                  <Controller
                    control={control}
                    name="application"
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Select
                        placeholder="Selecione"
                        value={application.find(
                          (option) => option.value === value
                        )}
                        inputRef={ref}
                        onChange={(e) => onChange(e?.value)}
                        options={application}
                      />
                    )}
                  />
                  {errors.application && (
                    <ErrorMessage>{errors.application?.message}</ErrorMessage>
                  )}
                </Grid.Col>
                <Grid.Col xs={12} md={12} lg={3}>
                  <Label>Cálculo</Label>
                  <Controller
                    control={control}
                    name="calculus"
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Select
                        placeholder="Selecione"
                        value={calc.find((option) => option.value === value)}
                        inputRef={ref}
                        onChange={(e) => onChange(e?.value)}
                        options={calc}
                      />
                    )}
                  />
                  {errors.calculus && (
                    <ErrorMessage>{errors.calculus?.message}</ErrorMessage>
                  )}
                </Grid.Col>
              </Grid.Row>
              <Grid.Row style={{ marginTop: '40px' }}>
                <Grid.Col xs={12} md={12} lg={3}>
                  <Flex>
                    <Text>
                      Incidência
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        style={{ marginLeft: '5px', color: '#875ede' }}
                      />
                    </Text>
                  </Flex>

                  <Flex>
                    <Input.RadioButton
                      controlled
                      checked={incidence === 1 ? true : false}
                      onClick={() => setIncidence(incidence === 1 ? 0 : 1)}
                    />
                    <p style={{ paddingLeft: '10px' }}>Créditos Estocados</p>
                  </Flex>
                  <div style={{ marginTop: '10px' }} />
                  <Flex>
                    <Input.RadioButton
                      controlled
                      checked={incidence === 2 ? true : false}
                      onClick={() => setIncidence(incidence === 2 ? 0 : 2)}
                    />
                    <p style={{ paddingLeft: '10px' }}>Valor total Faturado</p>
                  </Flex>
                  {errorIncidence && (
                    <ErrorMessage>Campo obrigatório</ErrorMessage>
                  )}
                </Grid.Col>
                <Grid.Col xs={12} md={12} lg={3}>
                  <Flex>
                    <Input.CheckBox
                      checked={obligatory}
                      onClick={() => setObligatory(!obligatory)}
                    />
                    <p style={{ paddingLeft: '10px' }}>Taxa Obrigatória</p>
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      style={{ marginLeft: '5px', color: '#875ede' }}
                    />
                  </Flex>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row justify="flex-end" style={{ marginTop: '40px' }}>
                <Grid.Col xs={12} md={12} lg={4}>
                  <div style={{ display: 'flex' }}>
                    <Button
                      variant="text"
                      type="button"
                      onClick={() => setCancel(true)}
                    >
                      Cancelar
                    </Button>
                    <div style={{ marginLeft: '10px' }} />
                    <Button type="submit">Concluir</Button>
                  </div>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </form>
        </>
      )}
      {cancel && (
        <ModalCancel
          open={cancel}
          close={() => setCancel(false)}
          confirm={() => history.push('/taxas')}
        />
      )}
    </>
  );
};

export default Data;
