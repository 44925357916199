import { useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Box, Checkbox, MaskedTextField, Skeleton, Typography } from "@stationkim/front-ui"
import { DaysRateContainer, FakeInput, FormFieldsContainer } from "./styles"
import { RatesContext } from "../../provider/RatesProvider"
import { useTermFormModificationHandler } from "../../hooks/useTermFormModificationHandler"
import { enforcePercentMaskRemoval } from "../utils/enforcePercentMaskRemoval"

const daysTermAvailable: [7, 14, 21, 28] = [7, 14, 21, 28]


const fieldMask = {
  mask: [
    {
      mask: '',
    },
    {
      mask: 'num%',
      overwrite: true,
      lazy: false,
      blocks: {
        num: {
          mask: Number,
          scale: 2,
          min: 0,
          max: 100,
          normalizeZeros: true,
          placeholderChar: "0",
          radix: ',',
          mapToRadix: ['.'],
        },
      },
    }
  ]
}

export const FinancedDefaultRates = () => {
  const {
    isFinancedSalesAllowed,
    resaleDefaultRates,
    isLoadingResaleData,
    isLoadingDefaultRates,
    isLoadingResaleChanges,
    modificationStatus,
    isSucceed,
    modifications,
    setModification
  } = useContext(RatesContext)
  const [allowFinancedSales, setAllowFinancedSales] = useState<boolean>(false)

  const form = useForm()
  const values = form.watch()

  const checkCanBeSaved = () => {
    const formKeys = Object.keys(values)
    const hasAtLeastOneOverZero = formKeys.some(key => enforcePercentMaskRemoval(values[key].rate) > 0)
    return hasAtLeastOneOverZero
  }
  const canBeSaved = checkCanBeSaved()

  const renderDaysFields = ({ isLoading, error }: { isLoading: boolean, error: boolean }) => {
    return daysTermAvailable.map((days, index) => {
      return (
        <Controller
          control={form.control}
          name={`${days}days.rate`}
          key={'daysTerm' + index}
          render={({ field: { onChange, value, ref } }) => (
            <DaysRateContainer>
              <FakeInput>{days} dias</FakeInput>
              {isLoading ?
                <Skeleton width='96px' height='49px' variant='rounded' /> :
                <MaskedTextField
                  ref={ref}
                  id={`${days}days.rate`}
                  onChange={onChange}
                  value={String(value)}
                  placeholder="00,00%"
                  mask={fieldMask}
                  error={error}
                  unmask
                />
              }
            </DaysRateContainer>
          )}
        />
      )
    })
  }

  //handle the allow checkbox changes and trigger visibility of the cancel/save buttons
  const handleChangeAllowFinanced = (newValue: boolean) => {
    setAllowFinancedSales(newValue)
    setModification({
      target: "allowFinancedSales",
      modification: {
        value: newValue === isFinancedSalesAllowed ? null : newValue
      }
    })
  }

  //check if can be saved
  useEffect(() => {
    if (modifications.defaultFinancedRates.saveable !== canBeSaved)
      setModification({
        target: 'defaultFinancedRates', modification: {
          saveable: canBeSaved
        }
      })
  }, [values])

  //initialize the days terms fields
  useEffect(() => {
    if (resaleDefaultRates)
      form.reset(resaleDefaultRates)
  }, [resaleDefaultRates])

  //initialize the allow financed checkbox
  useEffect(() => {
    setAllowFinancedSales(isFinancedSalesAllowed)
  }, [isFinancedSalesAllowed])

  //reset to inicial state when cancel
  useEffect(() => {
    if (modificationStatus === "canceled") {
      setAllowFinancedSales(isFinancedSalesAllowed)
      form.reset()
    }
  }, [modificationStatus])

  // update the form default values when save with success
  useEffect(() => {
    if (isSucceed) {
      const actualValues = form.getValues()
      form.reset(actualValues)
    }
  }, [isSucceed])

  //handle all changes on fields to trigger the visibility of cancel/save button
  useTermFormModificationHandler({ form, setModification, target: 'defaultFinancedRates' })
  const shouldShowForm = allowFinancedSales && !isLoadingResaleData
  const isLoadingVisibleData = isLoadingResaleData || isLoadingResaleChanges || isLoadingDefaultRates

  return (
    <>
      <Typography
        variant="body1"
        sx={theme => ({
          fontWeight: theme.typography.fontWeightSemiBold,
          marginBottom: '8px'
        })}
      >
        Pagamento a prazo
      </Typography>
      {
        isLoadingVisibleData ?
          <>
            <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
              <Skeleton height='24px' variant='rounded' />
              <Skeleton height='24px' variant='rounded' />
              <Skeleton height='24px' width='60%' variant='rounded' />
            </Box>
          </> :
          <Typography variant="body2" sx={theme => ({ color: theme.palette.grey[700] })}>
            Permite esta revenda selecionar a opção “à prazo” na hora de finalizar um pedido. Essa permissão dá um “crédito” para a operadora, e posterga a data de pagamento para o dia selecionado em 7, 14, 21 ou 28 dias. As taxas configuradas se tornam padrão, mas é possível configurar individualmente para cada empresa selecionada.
          </Typography >
      }
      <Box sx={{ margin: '16px 0' }}>
        <label htmlFor="allowFinanced" style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Checkbox
            disabled={isLoadingVisibleData}
            name='allowFinanced'
            id='allowFinanced'
            checked={allowFinancedSales}
            onChange={(_, checked) => handleChangeAllowFinanced(checked)}
          />
          Permitir opção a Prazo
        </label>
      </Box>

      < form >
        <FormFieldsContainer sx={{ maxHeight: shouldShowForm ? '520px' : 0, marginBottom: '16px' }}>
          <DaysRateContainer sx={{ marginBottom: '8px' }}>
            <div>
              <Typography variant="body2">
                Prazo
              </Typography>
            </div>
            <div>
              <Typography variant="body2">
                Taxa padrão
              </Typography>
            </div>
          </DaysRateContainer>
          {renderDaysFields({ isLoading: isLoadingVisibleData, error: !canBeSaved })}
          {!canBeSaved &&
            <Typography sx={(theme) => ({ color: theme.palette.error.main })}>
              Pelo menos uma das taxas deve ter valor superior a 0.
            </Typography>
          }
        </FormFieldsContainer>
      </form >
    </>
  )
}