import useService from 'main/hooks/useService';
import routesPaths from 'presentation/routes/routesPaths';
import history from '@/services/history';
import { useEffect, useState } from 'react';
import { ResaleData } from './resaleData';
import {
  Content,
  Grid,
  PageHeader,
  Panel,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from 'presentation/components';
import { Hidden, useScreenClass } from 'react-grid-system';
import { getResaleById } from '@/services/resalesServices';
import ResaleFees from './operatorCardFees';
import { Term } from './term/term';

const ResaleVisualization = ({ location }) => {
  const [resale, setResale] = useState(null);
  const [tabIndex, setTabIndex] = useState(location.state?.tab ?? 0);
  const resaleId = location.state.resaleId;
  const screenClass = useScreenClass();

  const [fetchResaleState, fetchResaleRequest] = useService(getResaleById, {
    onCompleted: (response) => setResale(response.data),
  });

  useEffect(() => {
    if (location.state.resaleId) fetchResaleRequest(resaleId);
    else history.push(routesPaths.resales.base);
    // eslint-disable-next-line
  }, []);

  return (
    <Content>
      <Panel noPadding>
        <Grid
          padding={
            (['xs', 'sm', 'md'].includes(screenClass) && tabIndex !== 0) || (screenClass === "lg" && tabIndex === 1)
              ? '16px 16px 16px 16px'
              : '16px 16px 0 16px'
          }
        >
          <Grid.Row align="center" justify="between">
            <Grid.Col md={12} lg="content">
              <PageHeader>{resale?.tradeName ?? 'Revenda'}</PageHeader>
            </Grid.Col>
            <Hidden xs sm md>
              <Grid.Col />
            </Hidden>
            {tabIndex === 1 &&
              <Grid.Col
                xs={12}
                sm={12}
                lg={6}
                xl={3}
                marginsFor={['xs', 'sm']}
                id="resale-data-portal-space-00"
              />
            }
            {tabIndex > 0 &&
              <>
                <Grid.Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={tabIndex === 1 ? 6 : 2}
                  xl={2}
                  marginsFor={['xs', 'sm', 'md']}
                  id="resale-data-portal-space-01"
                />
                <Grid.Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={tabIndex === 1 ? 6 : 2}
                  xl={3}
                  marginsFor={['xs', 'sm', 'md']}
                  id="resale-data-portal-space-02"
                />
              </>
            }
          </Grid.Row>
        </Grid>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
        >
          <TabList style={{ marginTop: 1, marginLeft: 16 }}>
            <Tab>Dados da Revenda</Tab>
            <Tab disabled={!resale}>Taxas</Tab>
            <Tab>Pagamento à Prazo</Tab>

          </TabList>
          <TabPanel padding="32px 16px 16px 16px">
            <ResaleData
              resaleData={resale}
              isLoading={fetchResaleState.loading}
              refresh={() => fetchResaleRequest(resaleId)}
            />
          </TabPanel>
          <TabPanel padding="32px 16px 16px 16px">
            <ResaleFees
              operator={{ ...resale, id: resaleId }}
              isLoading={fetchResaleRequest.loading}
            />
          </TabPanel>

          <TabPanel padding="32px 16px 16px 16px">
            <Term id={resaleId} />
          </TabPanel>
        </Tabs>
      </Panel>
    </Content>
  );
};

export default ResaleVisualization;
