import styled from 'styled-components';

export const StyledTbody = styled.tbody`
  tr:nth-child(odd) > td {
    background-color: #f5f6fa;
  }

  tr > td:first-child {
    border-radius: 6px 0 0 6px;
  }
  tr > td:last-child {
    border-radius: 0 6px 6px 0;
  }
  tr > td:only-child {
    border-radius: 6px;
  }

  tr:hover > td {
    background-color: #f1f0ff !important;
  }
`;
