import { cnpjMask } from "./cnpjMask"
import { status } from "./status"
import { CentralizedCell, DateContent, NewCompany } from "../styles"
import { checkLabel } from "./checkNewCompany"
import { Box, Checkbox, FormTableVirtualizedProps, MaskedTextField } from "@stationkim/front-ui"

const fieldMask = {
  mask: [
    {
      mask: '',
    },
    {
      mask: 'num%',
      overwrite: true,
      lazy: false,
      blocks: {
        num: {
          mask: Number,
          scale: 2,
          min: 0,
          max: 100,
          normalizeZeros: true,
          placeholderChar: "0",
          radix: ',',
          mapToRadix: ['.'],
        },
      },
    }
  ]
}


const generateRateColumnByDay = (days: number) => {
  const dayKey = `${days}days`
  return ({
    title: `${days} DIAS`,
    dataKey: dayKey,
    key: dayKey,
    editable: ({ rowData }) => rowData.allowFinancedSales,
    width: 120,
    resizable: false,
    fieldComponent: <MaskedTextField mask={fieldMask} size="small" unmask />,
    align: 'center',
    dataGetter: ({ rowData }) => {
      return (
        <CentralizedCell>
          {rowData[dayKey] && rowData.allowFinancedSales ? String(rowData[dayKey]).replaceAll('%', '') + '%' : '-'}
        </CentralizedCell>
      )
    },
  })
}

export const getTableConfiguration = ({ headerCheckBox }: { headerCheckBox: React.ReactNode }): FormTableVirtualizedProps['columns'] => {
  return [
    {
      title: '',
      dataKey: 'allowFinancedSales',
      key: 'allowFinancedSales',
      resizable: false,
      width: 80,
      frozen: 'left',
      editable: true,
      alwaysOpen: true,
      fieldComponent: <Checkbox />,
      headerRenderer: () => headerCheckBox,
      sortable: true,
    },
    {
      title: "CNPJ",
      dataKey: 'cnpj',
      key: 'cnpj',
      minWidth: 150,
      dataGetter: ({ rowData }) => cnpjMask(rowData.cnpj),
    },
    {
      title: "Nome da Empresa",
      dataKey: 'corporateName',
      key: 'corporateName',
      minWidth: 170,
      width: 170,
      sortable: true,
      frozen: 'left',
    },
    {
      title: "Data de cadastro",
      dataKey: 'creationDate',
      key: 'creationDate',
      minWidth: 150,
      width: 150,
      sortable: true,
      dataGetter: ({ rowData }) => (
        <Box sx={{ display: 'flex', gap: '8px', width: '100%' }}>
          <DateContent>
            <p>{new Date(rowData.creationDate).toLocaleDateString("pt-BR")}</p>
          </DateContent>
          {
            checkLabel({ creationDate: rowData.creationDate }) ?
              <NewCompany>{<small>NOVA</small>}</NewCompany>
              : null
          }
        </Box>
      ),
    },
    {
      title: "Taxa",
      dataKey: 'defaultInterestRate',
      key: 'defaultInterestRate',
      width: 120,
      dataGetter: ({ rowData }) => {
        if (rowData.allowFinancedSales)
          return rowData.defaultInterestRate ? 'Padrão' : 'Personalizada'
        return '-'
      }
    },
    generateRateColumnByDay(7),
    generateRateColumnByDay(14),
    generateRateColumnByDay(21),
    generateRateColumnByDay(28),
    {
      title: "Status",
      dataKey: 'status',
      width: 180,
      key: 'status',
      align: 'center',
      dataGetter: ({ rowData }) => <CentralizedCell>{status[rowData.status]}</CentralizedCell>
    },
  ]
}
