import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import './config/ReactotronConfig';
import history from './services/history';
import { store, persistor } from './store';
import Routes from 'presentation/routes';
import { QueryProvider, ThemeProvider } from './config/providers';

const App = () => {
  return (
    <ThemeProvider>
      <QueryProvider>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Router history={history}>
              <Routes />
            </Router>
          </PersistGate>
        </Provider>
      </QueryProvider>
    </ThemeProvider>
  );
};

export default App;
