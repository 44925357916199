import {
  Content,
  ErrorMessage,
  ErrorWrapper,
  Grid,
  IconButton,
  Input,
  Line,
  PageHeader,
  Panel,
  Spinner,
  StatusTag,
  Switch,
  Text,
} from "presentation/components"
import Button from "presentation/components/buttonV2"
import { InfoModal } from "presentation/compositions"
import useService from "main/hooks/useService"
import routesPaths from "presentation/routes/routesPaths"
import history from "@/services/history"
import { getResaleFeeConfiguration } from "@/services/resalesServices"

import { pushNotification } from "@/store/modules/notification/actions"
import { BENEFIT_TYPES, FORM_MODE, NOTIFICATION_TYPE, OPERATOR_CARD_FEE_FREQUENCY } from "main/utils/constants"
import successHandler from "main/utils/successHandler"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useState } from "react"
import { useScreenClass } from "react-grid-system"
import { Controller, useForm } from "react-hook-form"
import { BsExclamationCircle, BsExclamationTriangle, BsFillGearFill } from "react-icons/bs"
import { FaPencilAlt } from "react-icons/fa"
import { useDispatch } from "react-redux"
import schema from "./schema"
import { CheckBoxWrapper, Form, TitleWrapper } from "./styles"
import {
  RESALES_STATUS,
  RESALE_INCIDENCE,
  RESALE_RECURRENCE,
  RESALE_TYPEFEE,
} from "main/utils/constants"
import moment from "moment"
import { patchResaleChangeCardFeeStatus } from "services/resalesServices"
import { ModalResaleActivateDeactivate } from "presentation/pages/resales/modals/modalResaleActivateDeactivate"
import { ViewParagraph } from "../../styles"

const VisualizeAndEditResalesCardFee = ({ location }) => {
  const dispatch = useDispatch()
  const screenClass = useScreenClass()
  const { cardFeeId, operator } = location.state
  const [mode, setMode] = useState(location.state?.mode || FORM_MODE.VIEW)
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false)
  const [isChangeStatusConfirmationOpen, setIsChangeStatusConfirmationOpen] = useState(false)
  const [cardFee, setCardFee] = useState()
  const [cards] = useState([])
  const [isCardFeeActive, setIsCardFeeActive] = useState(cardFee?.status === RESALES_STATUS.ACTIVE)
  const isEditing = mode === FORM_MODE.EDIT
  const {
    control,
    formState: { errors, isDirty },
    register,
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  })

  const [fetchCardFeeState, fetchCardFeeRequest] = useService(getResaleFeeConfiguration, {
    onCompleted: response => {
      reset({
        ...response.data,
        resaleId: `${operator?.tradeName} - ${operator?.code}`,
      })
      setIsCardFeeActive(response.data.status)
      setCardFee(response.data)
    },
  })

  // const [fetchCardsState, fetchCardsRequest] = useService(getCardsForSelect, {
  //   onCompleted: (response) => {
  //     setCards(response.data);
  //   },
  //   silent: true
  // });

  const [changeStatusState, changeStatusRequest] = useService(patchResaleChangeCardFeeStatus, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler(`Taxa ${isCardFeeActive ? "inativada" : "ativada"}!`)))
      setIsChangeStatusConfirmationOpen(false)
      fetchCardFeeRequest(cardFeeId)
    },
  })

  // const [editCardFeeState, editCardFeeRequest] = useService(putEditOperatorCardFee, {
  //   onCompleted: () => {
  //     dispatch(pushNotification(successHandler("Taxa editada com sucesso!")));
  //     history.push({
  //       pathname: routesPaths.operators.visualize,
  //       state: { operatorId: operator.id, tab: 2 }
  //     });
  //   },
  // });

  //const isLoading = !cardFee || fetchCardFeeState.loading || editCardFeeState.loading;
  const isLoading = !cardFee || fetchCardFeeState.loading

  useEffect(() => {
    if (cardFeeId.length === 0) {
      dispatch(
        pushNotification(
          successHandler(
            `Desculpe, ocorreu um erro. O GUID retornado do BFF foi apagado na base e agora está vazio. Parece que a base de dados foi corrompida. Por favor, entre em contato com o administrador da base de dados para resolver esse problema.`
          )
        )
      )
      goBackToCardFeeList()
    }

    if (!operator.id) history.goBack()
    fetchCardFeeRequest(`/${cardFeeId}`)
    // eslint-disable-next-line
  }, [])

  const renderCheckboxes = (value, onChange) => {
    if (!isEditing)
      return (
        <>
          <Input.CheckBox controlled checked={true} />
          <Text.Label>{OPERATOR_CARD_FEE_FREQUENCY[value]}</Text.Label>
        </>
      )
    else
      return (
        <>
          <Input.CheckBox
            controlled
            checked={value === OPERATOR_CARD_FEE_FREQUENCY.DAILY}
            onClick={() => onChange(OPERATOR_CARD_FEE_FREQUENCY.DAILY)}
          />
          <Text.Label>Diário</Text.Label>
          <Input.CheckBox
            controlled
            checked={value === OPERATOR_CARD_FEE_FREQUENCY.WEEKLY}
            onClick={() => onChange(OPERATOR_CARD_FEE_FREQUENCY.WEEKLY)}
          />
          <Text.Label>Semanal</Text.Label>
          <Input.CheckBox
            controlled
            checked={value === OPERATOR_CARD_FEE_FREQUENCY.BIWEEKLY}
            onClick={() => onChange(OPERATOR_CARD_FEE_FREQUENCY.BIWEEKLY)}
          />
          <Text.Label>Quinzenal</Text.Label>
          <Input.CheckBox
            controlled
            checked={value === OPERATOR_CARD_FEE_FREQUENCY.MONTHLY}
            onClick={() => onChange(OPERATOR_CARD_FEE_FREQUENCY.MONTHLY)}
          />
          <Text.Label>Mensal</Text.Label>
        </>
      )
  }

  const cancelEdition = () => {
    setMode(FORM_MODE.VIEW)
    setIsCancelConfirmationOpen(false)
  }

  const goBackToCardFeeList = () => {
    history.push({
      pathname: routesPaths.resales.visualize,
      state: { resaleId: operator.id, tab: 1 },
    })
  }

  const onSubmit = () => {
    if (!isDirty) {
      setMode(FORM_MODE.VIEW)
      return
    }
  }
  const [isChangingStatus, setIsChangingStatus] = useState(false)

  const isResaleActive = fetchCardFeeState?.response?.data?.status === RESALES_STATUS.ACTIVE
  // const [changeStatusState, changeStatusRequest] = useService(putActivateDeactivateResale, {
  //   onCompleted: () => {
  //     dispatch(pushNotification(successHandler(`Taxa ${isResaleActive ? "inativada" : "ativada"}!`)))
  //     //onEdited();
  //   },
  // })

  return (
    <>
      <Content>
        <Panel noPadding>
          <Grid>
            <Grid.Row>
              <Grid.Col>
                <PageHeader>{operator?.tradeName ?? "Revenda"}</PageHeader>
              </Grid.Col>
            </Grid.Row>
          </Grid>
          <Line overflow />
          <Form onSubmit={handleSubmit(onSubmit)} id="cardFeeForm">
            <Grid spaceBetweenRows="32px" padding={isLoading ? "32px" : "16px 16px 32px 16px"}>
              {isLoading ? (
                <Spinner.Box>
                  <Spinner />
                </Spinner.Box>
              ) : (
                <>
                  <Grid.Row align="center">
                    <Grid.Col>
                      <TitleWrapper>
                        <Text.Subtitle>{!isEditing ? "Taxa" : "Edição de Taxa"}</Text.Subtitle>
                        {!isEditing && (
                          <IconButton
                            onClick={e => {
                              if (isCardFeeActive) {
                                e.preventDefault()
                                setMode(FORM_MODE.EDIT)
                              } else
                                dispatch(
                                  pushNotification({
                                    type: NOTIFICATION_TYPE.WARNING,
                                    content: "Para editar uma Taxa é necessário que ela esteja ativa.",
                                  })
                                )
                            }}
                          >
                            <FaPencilAlt />
                          </IconButton>
                        )}
                      </TitleWrapper>
                    </Grid.Col>
                    {!isEditing && (
                      <>
                        <Grid.Col sm="content">
                          {changeStatusState.loading ? (
                            <Spinner variant="sm" />
                          ) : (
                            <Switch
                              onChange={() => setIsChangeStatusConfirmationOpen(true)}
                              checked={cardFee?.fee?.status === RESALES_STATUS.INACTIVE}
                            />
                          )}
                        </Grid.Col>
                        <Grid.Col xs={12} sm="content">
                          <StatusTag color={cardFee?.fee?.status === RESALES_STATUS.ACTIVE ? "green" : "red"}>
                            {RESALES_STATUS[cardFee?.fee?.status]}
                          </StatusTag>
                        </Grid.Col>
                      </>
                    )}
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col xs={12} sm={6} md={3}>
                      <Text.Label>Nome</Text.Label>
                      {isEditing ? <Input disabled {...register("name")} /> : <Text>{cardFee?.fee?.name}</Text>}
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={3}>
                      <Text.Label>Tipo de Taxa</Text.Label>
                      {isEditing ? (
                        <Input disabled {...register("type")} />
                      ) : (
                        <Text>{RESALE_TYPEFEE[cardFee?.fee?.type]}</Text>
                      )}
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={3} marginsFor={["xs", "sm"]}>
                      <Text.Label>Incidência</Text.Label>
                      {isEditing ? (
                        <ErrorWrapper error={errors.description}>
                          <Input maxLength={40} {...register("incidence")} />
                          {errors.incidence && <ErrorMessage>{errors.incidence.message}</ErrorMessage>}
                        </ErrorWrapper>
                      ) : (
                        <Text>{RESALE_INCIDENCE[cardFee?.fee?.incidence]}</Text>
                      )}
                    </Grid.Col>
                    {cardFee?.franchiseMinimal ? (
                      <Grid.Col xs={12} sm={6} md={3} marginsFor={["xs", "sm"]}>
                        <Text.Label>Franquia Miníma</Text.Label>
                        {isEditing ? (
                          <ErrorWrapper error={errors.description}>
                            <Input maxLength={40} {...register("franchiseMinimal")} />
                            {errors.incidence && <ErrorMessage>{errors.franchiseMinimal.message}</ErrorMessage>}
                          </ErrorWrapper>
                        ) : (
                          <Text>
                            {cardFee?.franchiseMinimal?.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </Text>
                        )}
                      </Grid.Col>
                    ) : (
                      <></>
                    )}
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col xs={12} sm={6} md={3} marginsFor={["xs"]}>
                      <Text.Label>Tipo de valor</Text.Label>
                      {isEditing ? (
                        <ErrorWrapper error={errors.valueType}>
                          <Controller
                            control={control}
                            name="valueType"
                            render={({ field: { ref } }) => (
                              <Input
                                inputRef={ref}
                              // value={benefitTypes.find(option => option.value === value)}
                              // placeholder="Selecione..."
                              // onChange={e => {
                              //   onChange(e.value)
                              //   setIsCardSelectVisible(e.value === BENEFIT_TYPES.TRANSPORT)
                              // }}
                              // isClearable={false}
                              // options={benefitTypes}
                              />
                            )}
                          />
                          {errors.valueType && <ErrorMessage>{errors.valueType.message}</ErrorMessage>}
                        </ErrorWrapper>
                      ) : (
                        <Text>{BENEFIT_TYPES[cardFee?.fee?.valueType]}</Text>
                      )}
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={3}>
                      <Text.Label>Recorrência</Text.Label>
                      {isEditing ? (
                        <ErrorWrapper error={errors.recurrence}>
                          <Controller
                            control={control}
                            name="recurrence"
                            render={({ field: { value, onChange } }) => (
                              <Input.Decimal
                                allowNegative={false}
                                value={value}
                                prefix="R$ "
                                displayType={isEditing ? null : "text"}
                                renderText={value => <Text>{value}</Text>}
                                onValueChange={e => onChange(e.floatValue)}
                              />
                            )}
                          />
                          {errors.recurrence && <ErrorMessage>{errors.recurrence.message}</ErrorMessage>}
                        </ErrorWrapper>
                      ) : (
                        <Text>{RESALE_RECURRENCE[cardFee?.recurrence]}</Text>
                      )}
                    </Grid.Col>

                    <Grid.Col xs={12} sm={6} md={3} marginsFor={["xs"]}>
                      <Text.Label>Desconto (%)</Text.Label>
                      {isEditing ? (
                        <ErrorWrapper error={errors.discount}>
                          <Controller
                            control={control}
                            name="discount"
                            render={({ field: { value, onChange } }) => (
                              <Input.Select
                                placeholder="Selecione..."
                                value={cards.find(option => option.id === value)}
                                onChange={e => onChange(e.id)}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                //isLoading={fetchCardsState.loading}
                                isClearable={false}
                                options={cards}
                              />
                            )}
                          />
                          {errors.discount && <ErrorMessage>{errors.discount.message}</ErrorMessage>}
                        </ErrorWrapper>
                      ) : (
                        <Text>{cardFee?.discount}</Text>
                      )}
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    {cardFee?.realValue && (
                      <Grid.Col xs={12} sm={6} md={3}>
                        <Text.Label>Reais (R$)</Text.Label>
                        {isEditing ? (
                          <ErrorWrapper error={errors.realValue}>
                            <CheckBoxWrapper>
                              <Controller
                                control={control}
                                name="realValue"
                                render={({ field: { value, onChange } }) => renderCheckboxes(value, onChange)}
                              />
                            </CheckBoxWrapper>
                            {errors.realValue && <ErrorMessage>{errors.realValue.message}</ErrorMessage>}
                          </ErrorWrapper>
                        ) : (
                          <Text>
                            {cardFee?.realValue?.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </Text>
                        )}
                      </Grid.Col>
                    )}
                    {cardFee?.percentageValue && (
                      <Grid.Col xs={12} sm={6} md={3}>
                        <Text.Label>Valor %</Text.Label>
                        {isEditing ? (
                          <ErrorWrapper error={errors.percentageValue}>
                            <CheckBoxWrapper>
                              <Controller
                                control={control}
                                name="percentageValue"
                                render={({ field: { value, onChange } }) => renderCheckboxes(value, onChange)}
                              />
                            </CheckBoxWrapper>
                            {errors.percentageValue && <ErrorMessage>{errors.percentageValue.message}</ErrorMessage>}
                          </ErrorWrapper>
                        ) : (
                          <Text>{cardFee?.percentageValue}</Text>
                        )}
                      </Grid.Col>
                    )}

                    <Grid.Col xs={12} sm={6} md={3}>
                      <Text.Label>Parcelamento</Text.Label>
                      {isEditing ? (
                        <ErrorWrapper error={errors?.parcelValue}>
                          <CheckBoxWrapper>
                            <Controller
                              control={control}
                              name="parcelValue"
                              render={({ field: { value, onChange } }) => renderCheckboxes(value, onChange)}
                            />
                          </CheckBoxWrapper>
                          {errors?.parcelValue && <ErrorMessage>{errors?.parcelValue.message}</ErrorMessage>}
                        </ErrorWrapper>
                      ) : (
                        <>
                          <Text>{`${cardFee?.parcel ?? 1}x de ${cardFee?.parcelValue?.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}`}</Text>
                        </>
                      )}
                    </Grid.Col>

                    {cardFee?.initialDate && (
                      <Grid.Col xs={12} sm={6} md={3}>
                        <Text.Label>Data Inicial</Text.Label>
                        {isEditing ? (
                          <ErrorWrapper error={errors?.initialDate}>
                            <CheckBoxWrapper>
                              <Controller
                                control={control}
                                name="initialDate"
                                render={({ field: { value, onChange } }) => renderCheckboxes(value, onChange)}
                              />
                            </CheckBoxWrapper>
                            {errors?.initialDate && <ErrorMessage>{errors?.initialDate.message}</ErrorMessage>}
                          </ErrorWrapper>
                        ) : (
                          <>
                            <Text>{moment(cardFee?.initialDate).format("DD/MM/YYYY")}</Text>
                          </>
                        )}
                      </Grid.Col>
                    )}
                    {cardFee?.finalDate && (
                      <Grid.Col xs={12} sm={6} md={3}>
                        <Text.Label>Data Final</Text.Label>
                        {isEditing ? (
                          <ErrorWrapper error={errors?.finalDate}>
                            <CheckBoxWrapper>
                              <Controller
                                control={control}
                                name="finalDate"
                                render={({ field: { value, onChange } }) => renderCheckboxes(value, onChange)}
                              />
                            </CheckBoxWrapper>
                            {errors?.finalDate && <ErrorMessage>{errors?.finalDate.message}</ErrorMessage>}
                          </ErrorWrapper>
                        ) : (
                          <Text>{moment(cardFee?.finalDate).format("DD/MM/YYYY")}</Text>
                        )}
                      </Grid.Col>
                    )}
                  </Grid.Row>
                  <Grid.Row justify="start">
                    <Grid.Col xs={12} sm={6} md={6}>
                      <ViewParagraph>
                        <BsFillGearFill /> Visualizar intervalos
                      </ViewParagraph>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row justify="end">
                    {isEditing ? (
                      <>
                        <Grid.Col sm={2}>
                          <Button
                            variant="text"
                            onClick={e => {
                              if (isDirty) {
                                e.preventDefault()
                                setIsCancelConfirmationOpen(true)
                              } else cancelEdition()
                            }}
                          >
                            Cancelar
                          </Button>
                        </Grid.Col>
                        <Grid.Col xs={12} width={["xs"].includes(screenClass) ? null : "232px"} marginsFor={["xs"]}>
                          <Button form="cardFeeForm">Salvar alterações</Button>
                        </Grid.Col>
                      </>
                    ) : (
                      <Grid.Col xs={12} width={["xs"].includes(screenClass) ? null : "134px"} marginsFor={["xs"]}>
                        <Button
                          onClick={e => {
                            e.preventDefault()
                            goBackToCardFeeList()
                          }}
                        >
                          Voltar
                        </Button>
                      </Grid.Col>
                    )}
                  </Grid.Row>
                </>
              )}
              <ModalResaleActivateDeactivate
                open={isChangingStatus}
                isActiving={!isResaleActive}
                onConfirm={() => changeStatusRequest(cardFee?.id, !isResaleActive)}
                onClose={() => {
                  setIsChangingStatus(false)
                }}
              />
            </Grid>
          </Form>
        </Panel>
      </Content>
      <InfoModal open={isCancelConfirmationOpen} onClose={() => setIsCancelConfirmationOpen(false)}>
        <InfoModal.IconContainer color="var(--color-primary-01)">
          <BsExclamationCircle />
        </InfoModal.IconContainer>
        <InfoModal.Title>DESEJA CANCELAR A EDIÇÃO DA TAXA?</InfoModal.Title>
        <InfoModal.Content>Ao clicar em confirmar os dados informados serão perdidos.</InfoModal.Content>
        <InfoModal.ButtonsWrapper>
          <Button onClick={() => setIsCancelConfirmationOpen(false)} variant="text">
            Cancelar
          </Button>
          <Button variant="ghost" onClick={cancelEdition}>
            Confirmar
          </Button>
        </InfoModal.ButtonsWrapper>
      </InfoModal>
      <InfoModal
        open={isChangeStatusConfirmationOpen}
        showCloseIcon={true}
        onClose={() => setIsChangeStatusConfirmationOpen(false)}
        closeOnOverlayClick={!changeStatusState.loading}
        closeOnEsc={!changeStatusState.loading}
      >
        {changeStatusState.loading ? (
          <Spinner.Box padding="32px">
            <Spinner />
          </Spinner.Box>
        ) : (
          <>
            <InfoModal.IconContainer
              color={
                cardFee?.fee?.status === RESALES_STATUS.ACTIVE ? "var(--color-status-02)" : "var(--color-status-01)"
              }
            >
              {cardFee?.fee?.status === RESALES_STATUS.ACTIVE ? <BsExclamationTriangle /> : <BsExclamationCircle />}
            </InfoModal.IconContainer>
            <InfoModal.Title
              color={
                cardFee?.fee?.status === RESALES_STATUS.ACTIVE ? "var(--color-status-02)" : "var(--color-status-01)"
              }
            >
              ATENÇÃO
            </InfoModal.Title>
            <InfoModal.Content>
              {`Você tem certeza que deseja ${cardFee?.fee?.status === RESALES_STATUS.ACTIVE ? "inativar" : "reativar"
                } essa Taxa?`}
            </InfoModal.Content>
            <InfoModal.ButtonsWrapper>
              <Button onClick={() => setIsChangeStatusConfirmationOpen(false)} variant="text">
                Cancelar
              </Button>

              <Button
                variant="ghost"
                predefTheme={cardFee?.fee?.status === RESALES_STATUS.ACTIVE ? "red" : "green"}
                onClick={() =>
                  changeStatusRequest(
                    cardFee?.fee.id,
                    cardFee?.fee?.status === RESALES_STATUS.ACTIVE ? RESALES_STATUS.INACTIVE : RESALES_STATUS.ACTIVE
                  )
                }
              >
                Confirmar
              </Button>
            </InfoModal.ButtonsWrapper>
          </>
        )}
      </InfoModal>
    </>
  )
}

export default VisualizeAndEditResalesCardFee
