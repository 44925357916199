import styled from 'styled-components';

export const StyledTextarea = styled.textarea`
  border: 1px solid var(--color-secundary-06);
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: var(--font-weight-regular);
  padding: 8px;
  outline: none;
  width: 100%;
  resize: ${({resize}) => resize};
  height: ${({height}) => height};
  ::placeholder {
    color: var(--color-primary-03);
  }
`;
