import Modal from 'react-modal';
import { Icon, Button } from 'presentation/components';
import { Body, Title, Text, Flex } from './styles';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ModalCancel = ({ open, close, confirm }) => {
  return (
    <Modal isOpen={open} onRequestClose={close} style={customStyles}>
      <Body>
        <Icon name="warning" style={{ color: '#FFFF00' }} />
        <Title style={{ color: '#FFFF00' }}>CNPJ JÁ CADASTRADO</Title>
        <Text>
          O CNPJ informado já está cadastrado em nosso <br />
          sistema. Por favor, volte para a página de listagem e <br />
          acesse as informações da Operadora
          <br />
        </Text>
        <Flex>
          <Button variant="outlined" onClick={() => close()}>
            Cancelar
          </Button>
          <div style={{ marginLeft: '40px' }} />
          <Button type="submit" onClick={() => confirm()}>
            Continuar
          </Button>
        </Flex>
      </Body>
    </Modal>
  );
};

export default ModalCancel;
