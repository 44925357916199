import { Button } from "@stationkim/components-lib";
import { useFieldArray, useForm } from "react-hook-form";
import { StepDescription, StepTitle } from "../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { AddressFields } from "./addressFields";
import { Grid, Spinner } from "presentation/components";
import { useEffect, useRef } from "react";
import { postRegisterResaleAddresses, putEditResaleAddresses } from "@/services/resalesServices";
import useService from "main/hooks/useService";
import { ADDRESSES_TYPES } from "main/utils/constants";

const defaultFieldValues = {
  cep: "",
  addressType: 1,
  state: "",
  city: "",
  laneName: "",
  addressName: "",
  number: "",
  complement: "",
}

export const Addresses = ({
  resaleData,
  setResaleData,
  onCancel,
  goToNextStep,
  isEditing,
  isSkipingSave
}) => {

  const uploadedData = useRef({});

  const serviceToBeUsed = isEditing ?
    putEditResaleAddresses : postRegisterResaleAddresses;
  const [addressesState, addressesRequest] =
    useService(serviceToBeUsed, {
      onCompleted: () => {
        setResaleData(state => ({
          ...state,
          addresses: uploadedData.current
        }))
        goToNextStep();
      }
    });


  const {
    control,
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setValue
  } = useForm({
    defaultValues: resaleData
  });

  isSkipingSave(isEditing && isDirty);


  const {
    fields,
    append,
    remove,
    swap
  } = useFieldArray({
    control: control,
    name: 'addresses'
  });

  const onSubmit = (data) => {
    if (isDirty) {
      uploadedData.current = data.addresses;
      addressesRequest(resaleData.id, data.addresses);
    } else if (!isDirty && isEditing) {
      goToNextStep();
    }
  };

  const renderAddressesFields = () => {
    const fieldsToRender = fields.map((field, index) => {
      const fieldBaseName = `addresses.${index}.`;
      const fieldErrors = errors?.addresses?.[index];
      return (
        <AddressFields
          index={index}
          key={field.id}
          fieldBaseName={fieldBaseName}
          fieldErrors={fieldErrors}
          setValue={setValue}
          control={control}
          register={register}
          remove={remove}
        />
      );
    });

    return fieldsToRender;
  };

  useEffect(() => {
    const mainContactIndex = fields.findIndex(field =>
      field.addressType === ADDRESSES_TYPES.MAIN
    );
    swap(0, mainContactIndex)
  }, [resaleData])

  useEffect(() => {
    if (fields.length === 0) append(defaultFieldValues);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        fluid
        padding="0"
      >
        <Grid.Row>
          <Grid.Col sm="content">
            <StepTitle>Endereço</StepTitle>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col sm="content">
            <StepDescription>Informe o Endereço principal da Revenda</StepDescription>
          </Grid.Col>
        </Grid.Row>

        {addressesState.loading ?
          <Spinner.Box >
            <Spinner style={{ margin: "32px auto" }} />
          </Spinner.Box>
          :
          <>
            {renderAddressesFields()}

            <Grid.Row>
              <Grid.Col>
                <Button buttonType="borderless" onClick={() => append(defaultFieldValues)}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                  Adicionar endereço
                </Button>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row justify="end">
              <Grid.Col sm="content">
                <Button buttonType="text"
                  onClick={e => {
                    e.preventDefault();
                    onCancel(true);
                  }}
                >Cancelar</Button>
              </Grid.Col>
              <Grid.Col sm="content">
                <Button>Continuar</Button>
              </Grid.Col>
            </Grid.Row>
          </>
        }
      </Grid>
    </form>
  );
};