import { all, takeLatest, call, put } from 'redux-saga/effects';
import { decode } from 'jsonwebtoken';
import axios from 'axios';
import api from '@/infra/api';
import { signInSuccess, signFailure, signOut } from './actions';

function* signIn({ payload }) {
  const { email, password } = payload;

  try {
    const response = yield call(
      axios.post,
      process.env.REACT_APP_IDENTITY_HOST,
      { email, password }
    );

    const user = response?.data?.usuarioToken;
    api.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;
    api.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;
    yield put(
      signInSuccess(response.data.accessToken, response.data.refreshToken, user)
    );
  } catch (error) {
    yield put(signFailure(error));
  }
}

function* setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    const { exp: tokenExpiration } = decode(token);
    if (tokenExpiration <= Math.floor(Date.now() / 1000))
      return yield put(signOut());

    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('persist/REHYDRATE', setToken),
]);
