import routesPaths from "presentation/routes/routesPaths";
import history from "@/services/history";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Button, Dialog } from "@stationkim/components-lib";
import {
  ButtonLine,
  ModalContent,
  ModalIcon,
  ModalIconFrame,
  StyledDialogDescription,
  StyledDialogTitle
} from "./styles";

export const ModalAlreadyExists = ({
  open,
  onClose,
  resaleId
}) => {

  const redirectToVizualization = () => {
    history.push({
      pathname: routesPaths.resales.visualize,
      state: { resaleId }
    })
  };

  return (
    <Dialog.Root
      open={open}
    >
      <Dialog.Content >
        <ModalContent>
          <ModalIconFrame>
            <ModalIcon
              icon={faTriangleExclamation}
              css={{ color: '$atention200' }}
            />
          </ModalIconFrame>
          <StyledDialogTitle
            css={{ color: '$atention200' }}
          >
            CNPJ JÁ CADASTRADO
          </StyledDialogTitle>
          <StyledDialogDescription>
            O CNPJ informado já está cadastrado em nosso sistema.
            Por favor, volte para a página de listagem e acesse as informações Revenda.
          </StyledDialogDescription>
          <ButtonLine>
            <Button buttonType="text" onClick={onClose}>Cancelar</Button>
            <Button buttonType="outlined" color="atention" onClick={redirectToVizualization}>Confirmar</Button>
          </ButtonLine>
        </ModalContent>
      </Dialog.Content>
    </Dialog.Root>
  );
};