import { StyledIconButton } from "./styles";

const IconButton = ({ children, ...props }) => {
  return (
    <StyledIconButton {...props}>
      {children}
    </StyledIconButton >
  )
}

export default IconButton;