import { useMemo, useState } from 'react';
import { Content, Panel } from 'presentation/components';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Geral from './screens/geral';
import Address from './screens/address';
import Contact from './screens/contact';
import Rules from './screens/rules';
import Files from './screens/files';
import Finish from './screens/finish';

const StepsOperators = ({ ...props }) => {
  const [position, setPosition] = useState(0);
  const [id, setId] = useState(null);
  const [location, setLocation] = useState({
    zero: true,
    one: false,
    two: false,
    three: false,
    four: false,
  });

  const screenClass = useScreenClass();

  const steps = [
    { name: 'DADOS GERAIS', value: 0 },
    { name: 'ENDEREÇO', value: 1 },
    { name: 'CONTATO', value: 2 },
    { name: 'REGRAS DE COMPRA', value: 3 },
    { name: 'ARQUIVOS', value: 4 },
  ];

  const { status } = props.location.state;

  useMemo(() => {
    if (props.location.state.id) {
      setId(props.location.state.id);
    }
    // eslint-disable-next-line
  }, []);

  useMemo(() => {
    if (position === 0) setLocation({ ...location, zero: true });
    if (position === 1) setLocation({ ...location, one: true });
    if (position === 2) setLocation({ ...location, two: true });
    if (position === 3) setLocation({ ...location, three: true });
    if (position === 4) setLocation({ ...location, four: true });
    // eslint-disable-next-line
  }, [position]);

  useMemo(() => {
    if (status === 1) {
      setPosition(0);
    } else if (status === 2) {
      setPosition(1);
    } else if (status === 3) {
      setPosition(2);
    } else if (status === 4) {
      setPosition(3);
    } else if (status === 5) {
      setPosition(4);
    }
  }, [status]);

  /*   const alterPosistion = (value) => {
      if (value < position) {
        setPosition(value);
      } else if (value === 0 && location.zero) {
        setPosition(value);
      } else if (value === 1 && location.one) {
        setPosition(value);
      } else if (value === 2 && location.two) {
        setPosition(value);
      } else if (value === 3 && location.three) {
        setPosition(value);
      } else if (value === 4 && location.three) {
        setPosition(value);
      }
    }; */

  return (
    <Content>
      <Panel>
        <Container fluid style={{ padding: 0 }}>
          <Row
            align="center"
            gutterWidth={16}
            justify={
              ['xs', 'sm', 'md', 'lg'].includes(screenClass)
                ? 'center'
                : 'start'
            }
          >
            <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
              {/* <PageHeader>Cartões</PageHeader> */}
            </Col>
          </Row>
        </Container>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={position} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index + "step"}>
                <StepLabel
                  //onClick={() => alterPosistion(label.value)}
                  style={{ cursor: 'pointer' }}
                >
                  {label.name}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {position === 0 && (
          <Geral setPosition={setPosition} setId={setId} {...props} id={id} />
        )}
        {position === 1 && <Address setPosition={setPosition} id={id} />}
        {position === 2 && <Contact setPosition={setPosition} id={id} />}
        {position === 3 && <Rules setPosition={setPosition} id={id} />}
        {position === 4 && <Files setPosition={setPosition} id={id} />}
        {position === 5 && <Finish setPosition={setPosition} id={id} />}
      </Panel>
    </Content>
  );
};

export default StepsOperators;
