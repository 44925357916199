/* eslint-disable react/self-closing-comp */
import { StepsContainer, DashedLine, Step, Dot } from "./style"

const STATUS = {
  NOT_STARTED: "notstarted",
  ONGOING: "ongoing",
  DONE: "done",
}

const Steps = ({ currentStep, steps, ...props }) => {
  currentStep -= 1

  return (
    <StepsContainer style={{ flexDirection: "row", display: "flex" }}>
      {steps.map((step, index) => {
        const status = index === currentStep ? STATUS.ONGOING : index < currentStep ? STATUS.DONE : STATUS.NOT_STARTED

        return (
          <Step
            status={status}
            navigable={step.navigable}
            key={index}
            onClick={step.navigable ? () => step.handleNavigation() : null}
            {...props}
          >
            {currentStep >= index ? <Dot>{index + 1}</Dot> : null}
            {currentStep < index ? <Dot disabled>{index + 1}</Dot> : null}
            <label>{step.name}</label>
          </Step>
        )
      })}
      <DashedLine />
    </StepsContainer>
  )
}

export default Steps
