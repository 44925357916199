import _ from "lodash";
import { Grid, IconButton, Text } from "presentation/components";
import { CategorieTitleWrapper, FileLink, OperatorInformation } from "../styles";
import { useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import FilesModal from "./editionModals/filesModal";
import { OPERATOR_DOCUMENTS_TYPES } from "main/utils/constants";

const Files = ({ operator, onEdited }) => {

  const valueChecker = (value) => (value && value !== "") ? value : "Não Informado";
  const [isFilesModalFormOpen, setIsFilesModalFormOpen] = useState(false);

  const documentsRender = () => {
    if (operator.documents.length === 0) return "Não Informado";
    const employeeLayoutIndex = operator.documents.findIndex(document => document.type === OPERATOR_DOCUMENTS_TYPES.EMPLOYEE_LAYOUT);
    const documentsToRender = [...operator.documents];
    if (employeeLayoutIndex && employeeLayoutIndex !== 0) {
      [documentsToRender[0], documentsToRender[employeeLayoutIndex]] = [documentsToRender[employeeLayoutIndex], documentsToRender[0]];
    }
    return documentsToRender.map((document, index) => (
      <Text key={_.uniqueId()}>
        {index + 1}. <FileLink href={document.temporaryLink} target="blank" rel="noopener">  {document.fileName}</FileLink>
      </Text>
    ));
  };

  return (
    <>
      <Grid.Row>
        <Grid.Col sm={12}>
          <CategorieTitleWrapper>
            <Text.Subtitle>Arquivos</Text.Subtitle>
            <IconButton onClick={() => setIsFilesModalFormOpen(true)}>
              <FaPencilAlt />
            </IconButton>
          </CategorieTitleWrapper>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12}>
          <Text.SubtitleTwo>Arquivo TXT</Text.SubtitleTwo>
          <OperatorInformation>{documentsRender()}</OperatorInformation>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={6} md={3} lg={3}>
          <Text.SubtitleTwo>Observações</Text.SubtitleTwo>
          <OperatorInformation>{valueChecker(operator.documentNotes)}</OperatorInformation>
        </Grid.Col>
      </Grid.Row>
      {isFilesModalFormOpen &&
        <FilesModal
          open={isFilesModalFormOpen}
          operator={operator}
          onClose={(hasEdited) => {
            setIsFilesModalFormOpen(false);
            hasEdited === true && onEdited();
          }}
        />
      }
    </>
  );
};

export default Files;