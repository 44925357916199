export function operatorCardsInRequest(operatorId, query) {
  return {
    type: '@operatorCards/OPERATOR_CARDS_IN_REQUEST',
    payload: { operatorId, query },
  };
}

export function operatorCardsInSuccess(cards) {
  return {
    type: '@operatorCards/OPERATOR_CARDS_IN_SUCCESS',
    payload: { cards },
  };
}

export function operatorCardsFailure() {
  return {
    type: '@operatorCards/OPERATOR_CARDS_FAILURE',
  };
}
