import api from '@/infra/api'
import { IFetchHook } from '@stationkim/front-ui'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

const defaultValue = {
  data: [],
  pageNumber: 1,
  pageSize: 10,
  totalItens: 0,
  totalPages: 1,
}

interface IApiReturn {
  data: Array<{
    code: string | number,
    name: string,
    operatorId: string,
    cnpj: string,
    tradeName: string,
    benefitType: number,
    benefitName: string,
    status: number,
    id: string,
    active: boolean
  }>
  pageNumber: number
  pageSize: number
  totalItens: number
  totalPages: number
}

interface IUseOperatorCards extends IFetchHook {
  operatorId: string
}

export const useOperatorCards = ({ operatorId, filter, pagination, immutableQuery }: IUseOperatorCards) => {
  const { data, ...rest } = useQuery<AxiosResponse<IApiReturn>>({
    enabled: Boolean(operatorId),
    queryKey: ['operatorCards', operatorId, filter, pagination, immutableQuery],
    queryFn: () =>
      api.get(`/operators/${operatorId}/card`, {
        params: { ...pagination, ...filter, ...immutableQuery },
      }),
  })

  return {
    operatorCards: data?.data || defaultValue,
    ...rest,
  }
}