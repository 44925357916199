import * as yup from "yup";

export default yup.object().shape({
  abortEarly: false,
  buyingRules:
    yup.array().of(
      yup.object().shape({
        abortEarly: false,
        type: yup.number().required("Tipo de regra é um campo obrigatório"),
        value: yup.string().required("Prazo é um campo obrigatório"),
        minimumPurchase: yup.string().required("Valor mínimo é um campo obrigatório"),
        maximumPurchase: yup.string().required("Valor máximo é um campo obrigatório"),
      })
    )
});
