import { ErrorWrapper, Grid, Spinner } from "presentation/components";
import Select from "presentation/components/input/select";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import { Button, Input, Label } from "@stationkim/components-lib";
import React, { useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import { rules } from "../rules/bindCardsRules";
import OldErrorMessage from "presentation/components/errorMessage";
import { operatorCardProcessType } from "main/utils/options";
import { NOTIFICATION_TYPE, OPERATOR_CARD_PROCESS_TYPE } from "main/utils/constants";
import useService from "main/hooks/useService";
import { postValidateDealerLogin } from "@/services/resalesServices";
import { pushNotification } from "@/store/modules/notification/actions";
import successHandler from "main/utils/successHandler";
import { useDispatch } from "react-redux";

const FormError = ({ name, fieldErrors }) => {
  if (!fieldErrors) return;
  return (
    <ErrorMessage
      errors={fieldErrors}
      name={name}
      render={({ message }) =>
        <Input.Message>{message}</Input.Message>
      }
    />
  );
};

export const CardsFields = ({
  index,
  fieldBaseName,
  fieldErrors,
  control,
  register,
  remove,
  watch,
  setValue,
  operatorsCardsData,
  isEditing
}) => {

  const dispatch = useDispatch();
  const [
    selectedOperator,
    situationType,
    user,
    password
  ] = watch([
    fieldBaseName + 'operatorId',
    fieldBaseName + 'processType',
    fieldBaseName + 'user',
    fieldBaseName + 'password',
  ]);

  const [
    credentialValidationState,
    credentialValidationRequest,
  ] = useService(postValidateDealerLogin, {
    onCompleted: (res) => {
      validCredentialForDealer.current = res.data
      if (res.data) {
        dispatch(pushNotification(successHandler("Conexão com barramento realizada com sucesso.")));
        setValue(fieldBaseName + 'dealerCanPass', "true", { shouldValidate: true });
      }
      else {
        dispatch(pushNotification({
          type: NOTIFICATION_TYPE.WARNING,
          content: "Login e Senha não foram localizados ou estão incorretos."
        }))
      }
    }
  });
  const validCredentialForDealer = useRef(false);
  const initialPassword = useRef(password);
  const initialSituation = useRef(situationType);

  const isPasswordNeeded = password !== initialPassword.current ||
    initialSituation.current !== situationType
  useEffect(() => {
    setValue(fieldBaseName + 'dealerCanPass', "");
  }, [user, password]);

  return (
    <React.Fragment >
      {index !== 0 ?
        <Grid.Row>
          <Grid.Col style={{ display: 'flex', gap: '16px', margin: '32px 0' }}>
            Vincule cartões para a Revenda
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ cursor: 'pointer' }}
              onClick={() => remove(index)}
            />
          </Grid.Col>
        </Grid.Row> : null
      }
      <Grid.Row>
        <Grid.Col md={3.5}>
          <Controller
            control={control}
            name={fieldBaseName + 'operatorId'}
            rules={rules.operatorId}
            render={({ field: { value, onChange } }) => (
              <ErrorWrapper error={fieldErrors?.["operatorId"]}>
                <Label style={{ display: 'block', marginBottom: "8px" }}>Operadora</Label>
                <Select
                  isClearable={false}
                  value={operatorsCardsData.find(option => option.id === value)}
                  onChange={option => {
                    onChange(option.id);
                    setValue(fieldBaseName + 'operatorCardId', undefined, { shouldValidate: true })
                  }}
                  getOptionLabel={option => option.tradeName}
                  getOptionValue={option => option.id}
                  placeholder="Selecione..."
                  options={operatorsCardsData}
                />
                {!fieldErrors ? null :
                  <ErrorMessage
                    errors={fieldErrors}
                    name={"id"}
                    render={({ message }) => <OldErrorMessage>{message}</OldErrorMessage>}
                  />}
              </ErrorWrapper>
            )}
          />
        </Grid.Col>
        {!selectedOperator ? null :
          <>
            <Grid.Col md={3.5}>
              <Controller
                control={control}
                name={fieldBaseName + 'operatorCardId'}
                rules={rules.operatorCardId}
                render={({ field: { value, onChange } }) => {
                  const choosedOperator = operatorsCardsData.find(
                    operator => operator.id === selectedOperator
                  );
                  if (!choosedOperator) return;
                  const choosedOperatorCards = choosedOperator.operatorCards;
                  return (
                    <ErrorWrapper error={fieldErrors?.["operatorCardId"]}>
                      <Label style={{ display: 'block', marginBottom: "8px" }}>Cartão da Operadora</Label>
                      <Select
                        isClearable={false}
                        defaultValue={1}
                        value={choosedOperatorCards.find(option => option.id === value) || {}}
                        onChange={option => onChange(option.id)}
                        placeholder="Selecione..."
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        options={choosedOperatorCards}
                      />
                      {!fieldErrors ? null :
                        <ErrorMessage
                          errors={fieldErrors}
                          name={"operatorCardId"}
                          render={({ message }) => <OldErrorMessage>{message}</OldErrorMessage>}
                        />}
                    </ErrorWrapper>
                  )
                }}
              />
            </Grid.Col>
            <Grid.Col md={2}>
              <Controller
                control={control}
                name={fieldBaseName + 'processType'}
                rules={rules.processType}
                render={({ field: { value, onChange } }) => (
                  <ErrorWrapper error={fieldErrors?.["processType"]}>
                    <Label style={{ display: 'block', marginBottom: "8px" }}>Situação</Label>
                    <Select
                      isClearable={false}
                      defaultValue={1}
                      value={operatorCardProcessType.find(option => option.value === value)}
                      onChange={option => onChange(option.value)}
                      placeholder="Selecione..."
                      options={operatorCardProcessType}
                    />
                    {!fieldErrors ? null :
                      <ErrorMessage
                        errors={fieldErrors}
                        name={"processType"}
                        render={({ message }) => <OldErrorMessage>{message}</OldErrorMessage>}
                      />}
                  </ErrorWrapper>
                )}
              />
            </Grid.Col>
          </>
        }
      </Grid.Row>
      {situationType === OPERATOR_CARD_PROCESS_TYPE.DEALER ?
        <Grid.Row>
          <Grid.Col md={3.5}>
            <Input.Root
              state={fieldErrors?.user ? 'error' : null}
              full
            >
              <Input.Label>
                Login/E-mail
              </Input.Label>
              <Input.Field>
                <Input.Input
                  {...register(fieldBaseName + 'user', rules.user)}
                  placeholder="Login/E-mail"
                  maxLength={150}
                  autoComplete="off"
                />
              </Input.Field>
              <FormError fieldErrors={fieldErrors} name="user" />
            </Input.Root>
          </Grid.Col>
          <Grid.Col md={3.5}>
            <Input.Root
              state={fieldErrors?.password ? 'error' : null}
              full
            >
              <Input.Label>
                Senha
              </Input.Label>
              <Input.Field>
                <Input.Input
                  {...register(fieldBaseName + 'password', {
                    required: {
                      value: !isEditing || isPasswordNeeded,
                      message: "Campo obrigatório"
                    }
                  })}
                  placeholder={"Senha"}
                  maxLength={150}
                  type='password'
                  autoComplete="off"
                />
              </Input.Field>
              <FormError fieldErrors={fieldErrors} name="password" />
            </Input.Root>
          </Grid.Col>
          <Grid.Col md={2} >
            <Button
              full
              style={{ marginTop: '24px' }}
              disabled={credentialValidationState.loading}
              onClick={() => {
                credentialValidationRequest({
                  operatorId: selectedOperator,
                  user,
                  password,
                })
              }}
            >
              {
                credentialValidationState.loading ? <Spinner variant="sm" /> :
                  "Testar Conexão"
              }
            </Button>
            {!fieldErrors ? null :
              <ErrorMessage
                errors={fieldErrors}
                name={"dealerCanPass"}
                render={({ message }) => <OldErrorMessage>{message}</OldErrorMessage>}
              />}

            <input
              disabled
              style={{
                maxHeight: 0,
                maxWidth: 0,
                position: "fixed",
                opacity: 0,
              }}
              {...register(fieldBaseName + 'dealerCanPass', {
                required: {
                  value: situationType === OPERATOR_CARD_PROCESS_TYPE.DEALER
                    && (!isEditing || isPasswordNeeded),
                  message: "Execute o teste de conexão"
                }
              })}
            />
          </Grid.Col>
        </Grid.Row> : null
      }
    </React.Fragment>
  )
};