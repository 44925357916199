import history from "@/services/history";
import { pushNotification } from "@/store/modules/notification/actions";
import { NOTIFICATION_TYPE } from "main/utils/constants";
import { Step, StepLabel, Stepper } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ModalSkipSaveConfimation } from "../../modals/modalSkipSaveConfimation";
import { ModalStepDataLoss } from "../../modals/modalStepDataLoss";
import { Addresses } from "./adresses";
import { BindCards } from "./bindCards";
import { ComercialData } from "./comercialData";
import { Contacts } from "./contacts";
import { GeneralData } from "./generalData";
import { Review } from "./review";
import { StepContainer } from "./styles";

const steps = [
  {
    label: 'DADOS GERAIS',
    component: GeneralData
  },
  {
    label: 'CONTATO',
    component: Contacts
  },
  {
    label: 'ENDEREÇO',
    component: Addresses
  },
  {
    label: 'VINCULAR CARTÕES',
    component: BindCards
  },
  {
    label: 'DADOS COMERCIAIS',
    component: ComercialData
  },
];

export const Steps = ({
  resaleData,
  setResaleData,
  abortRegister
}) => {
  const dispatch = useDispatch();
  const isSkipingSave = useRef();
  const [activeStep, setActiveStep] = useState(resaleData?.registrationStatus - 1 || 0);
  const lastAllowedStep = useRef(0);
  const targetStepAfterSkipSave = useRef(0);
  const [isDataLossModalOpen, setIsDataLossModalOpen] = useState(false);
  const [isSkipSaveModalOpen, setIsSkipSaveModalOpen] = useState(false);

  const isReviewing = lastAllowedStep.current > 4;
  const isReviewOpen = activeStep > 4;

  useEffect(() => {
    if (activeStep !== 0) return;
    const stepLessOne = resaleData?.registrationStatus - 1;
    let continueOnStep = stepLessOne < 0 ? 0 : stepLessOne;
    if (continueOnStep) {
      setActiveStep(continueOnStep);
      lastAllowedStep.current = continueOnStep;
    };
  }, [resaleData]);

  const goToDesiredStep = (step) => {
    if (step > lastAllowedStep.current)
      dispatch(pushNotification({
        type: NOTIFICATION_TYPE.WARNING,
        content: `Para avançar para a etapa ${steps[step].label}, favor concluir as etapas anteriores primeiro.`
      }));
    else setActiveStep(step);
  };

  const goToNextStep = () => {
    setActiveStep(actualStep => {
      const nextStep = actualStep + 1;
      if (!isReviewing && !(lastAllowedStep.current > activeStep)) lastAllowedStep.current = nextStep;
      return nextStep;
    });
  };

  const ActiveStepComponent = steps[activeStep]?.component;
  return (
    <>
      <Stepper
        activeStep={0}
        alternativeLabel
        style={{ marginTop: '32px' }}
      >
        {steps.map(({ label }, index) => (
          <Step
            key={label}
            onClick={() => {
              if (isSkipingSave.current) {
                targetStepAfterSkipSave.current = index;
                setIsSkipSaveModalOpen(true);
              } else goToDesiredStep(index);
            }}
            completed={index !== activeStep && lastAllowedStep.current > index}
          >
            <StepLabel>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {isReviewing && isReviewOpen ?
        <Review
          resaleData={resaleData}
          isReviewing={isReviewing}
          setActiveStep={setActiveStep}
          onCancel={() => setIsDataLossModalOpen(true)}
        /> :
        <StepContainer>
          <ActiveStepComponent
            resaleData={resaleData}
            setResaleData={setResaleData}
            abortRegister={abortRegister}
            goToNextStep={goToNextStep}
            onCancel={() => setIsDataLossModalOpen(true)}
            isSkipingSave={value => { isSkipingSave.current = value }}
            isEditing={lastAllowedStep.current > activeStep}
          />
        </StepContainer>
      }
      <ModalStepDataLoss
        open={isDataLossModalOpen}
        onClose={() => setIsDataLossModalOpen(false)}
        onConfirm={() => history.push('/revendas')}
      />
      <ModalSkipSaveConfimation
        open={isSkipSaveModalOpen}
        onClose={() => setIsSkipSaveModalOpen(false)}
        onConfirm={() => {
          goToDesiredStep(targetStepAfterSkipSave.current);
          setIsSkipSaveModalOpen(false);
        }}
      />
    </>
  )
}