import { useContext, useEffect, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import { Checkbox, FormTableVirtualized, Grid, Search, Typography } from "@stationkim/front-ui"
import { Container, FilterButton, Tags } from "./styles"
import { getTableConfiguration } from "./utils/tableColumns"
import { cnpjMask } from "./utils/cnpjMask"
import { RatesContext } from "../../provider/RatesProvider"
import { useResaleCompanies } from "../../hooks/useResaleCompanies"
import './utils/temp.css'
import { useTermFormModificationHandler } from "../../hooks/useTermFormModificationHandler"
import { useFilter } from "./hooks/useFilter"
import { FilterIcon } from "./utils/assets/filterIcon"
import { CloseFilterIcon } from "./utils/assets/closeFilterIcon"
import { status } from "./utils/status"
import { checkCompany } from "./utils/checkCompany"
import { Filter } from "../Modal/Filter/Filter"

const formId = 'ratesFormTable'

export const TableRates: React.FC = () => {
  const {
    isFinancedSalesAllowed,
    resaleId,
    isLoadingResaleData,
    isLoadingCompaniesChanges,
    isLoadingResaleChanges,
    isSucceed,
    modifications,
    modificationStatus,
    setModification
  } = useContext(RatesContext)

  const isLoadingData = isLoadingCompaniesChanges || isLoadingResaleChanges

  const { companies, isFetching: isLoading } = useResaleCompanies({ resaleId })
  const { filter, setFilter, removeFilter } = useFilter()
  const [openFilter, setOpenFilter] = useState(false)
  const form = useForm()
  const [search, setSearch] = useState("")
  const [openFields, setOpenFields] = useState<number[]>([])
  const [checkedList, setCheckedList] = useState<Array<string | number>>([])
  const values = useWatch({ control: form.control })
  const isAllChecked = checkedList.length === companies?.length
  const isFiltering = Boolean(filter?.companies?.length || filter?.status?.length || filter?.date?.from || search !== '')

  const handleCheckAllChange = () => {
    const actualValues = form.getValues()
    const newValues = { ...actualValues }
    for (const key in actualValues) {
      newValues[key].allowFinancedSales = isAllChecked ? false : true
    }
    form.reset(newValues, { keepDefaultValues: true })
  }

  const filterFunction = ({ rowData }) => {
    let filteredCompanies = true
    let filteredStatus = true
    let filteredDate = true

    if (filter.companies && filter.companies.length > 0) {
      filteredCompanies = filter.companies.some(filter => checkCompany({ company: rowData, filter }))
    }

    if (filter.status && filter.status.length > 0) {
      filteredStatus = filter.status.includes(status[rowData.status])
    }

    if (filter.date && filter.date.from) {
      const creationDate = new Date(rowData?.creationDate)
      const fromDate = new Date(filter.date.from)
      const toDate = filter.date.to ? new Date(filter.date.to) : new Date()

      filteredDate = creationDate >= fromDate && creationDate <= toDate
    }
    const lowerCnpj = String(rowData.cnpj).toLowerCase()
    const lowerCorporateName = String(rowData.corporateName).toLowerCase()
    const isTheSearched = [lowerCnpj, cnpjMask(lowerCnpj), lowerCorporateName].some(term => term.includes(search))

    return filteredCompanies && filteredStatus && filteredDate && isTheSearched
  }

  //update all checks based on external value (all checkbox from FinancedDefaultRates)
  useEffect(() => {
    const actualValues = form.getValues()
    const checkedArrays = []
    for (const key in actualValues) {
      if (actualValues[key].allowFinancedSales) checkedArrays.push(key)
    }
    setCheckedList(checkedArrays)
  }, [values])

  const [canBeSaved, setCanBeSaved] = useState(true)

  // update column 'taxa' to change btw personalized or not
  // and define when rates need to be deleted
  useEffect(() => {
    const modifiedRowsInitialIndex = Object.keys(form.formState.dirtyFields)
    if (modifiedRowsInitialIndex.length === 0) return
    const actualValues = form.getValues()
    const dirties = form.formState.dirtyFields
    let canSave = false
    for (const key in dirties) {
      const row = actualValues[key]
      const dirtyRow = form.formState.dirtyFields?.[key]
      const ratesFields = ['7days', '14days', '21days', '28days']
      const newValue: {
        defaultInterestRate?: boolean,
        shouldDeleteRates?: boolean,
        shouldSaveAllowFinancedSales?: boolean
      } = {}

      if (
        dirtyRow['allowFinancedSales'] &&
        row.shouldSaveAllowFinancedSales === undefined
      ) newValue['shouldSaveAllowFinancedSales'] = true

      // iterate over all rates do define if it has value
      const allRateIsZeroOrUndef = ratesFields.every(dayRate => {
        const isZero = Number(String(row[dayRate]).replaceAll('%', '')) === 0
        return isZero || row[dayRate] === undefined
      })
      const hasCustomRate = row?.companyInterestRates?.some(rate => rate?.companyId)

      // lock save if trying to go from default to default values
      if (
        dirties[key].allowFinancedSales ||
        (!allRateIsZeroOrUndef || !companies[key].defaultInterestRate)
      ) canSave = true

      // define if it should be show as default or personalized
      const anyRateDirty = ratesFields.some(dayRate => dirtyRow[dayRate])
      if (allRateIsZeroOrUndef && !row.defaultInterestRate)
        newValue['defaultInterestRate'] = true
      else if (anyRateDirty && !allRateIsZeroOrUndef && row.defaultInterestRate === true)
        newValue['defaultInterestRate'] = false
      //define if the companies rates should be deleted or not
      if (
        allRateIsZeroOrUndef &&
        !row.shouldDeleteRates &&
        hasCustomRate
      ) newValue['shouldDeleteRates'] = true
      else if (!allRateIsZeroOrUndef && row.shouldDeleteRates)
        newValue['shouldDeleteRates'] = false
      if (Object.keys(newValue).length > 0) form.setValue(key, { ...row, ...newValue })
    }
    if (canBeSaved !== canSave) setCanBeSaved(canSave)
  }, [values])

  // handle the cancel
  useEffect(() => {
    if (modificationStatus === "canceled") {
      setSearch('')
      setOpenFields([])
      form.reset()
    }
  }, [modificationStatus])

  // update the form default values when save with success
  useEffect(() => {
    if (isSucceed) {
      const actualValues = form.getValues()
      form.reset(actualValues)
      setOpenFields([])
    }
  }, [isSucceed])

  // control when is possible to save or not
  useEffect(() => {
    setModification({ modification: { saveable: canBeSaved }, target: 'companiesConfiguredRates' })
  }, [canBeSaved])

  // unlock the cancel button for open fields
  useEffect(() => {
    const isOpenField = openFields.length > 0
    if (modifications.companiesConfiguredRates.isOpenField !== isOpenField)
      setModification({ modification: { isOpenField }, target: 'companiesConfiguredRates' })
  }, [openFields])
  // track rows modification and trigger the visibility of cancel/save buttons
  useTermFormModificationHandler({
    form, setModification,
    target: 'companiesConfiguredRates',
  })

  const shouldHide = !isFinancedSalesAllowed || modifications.allowFinancedSales.value === false || isLoadingResaleData || !companies
  const isFilterTag = filter.companies.length || filter.status.length || filter.date.from

  return (
    <Container style={{
      marginTop: shouldHide ? 0 : '16px',
    }}>
      <Typography
        variant="body1"
        sx={theme => ({
          fontWeight: theme.typography.fontWeightSemiBold,
          marginBottom: '8px'
        })}
      >
        Empresas
      </Typography>
      <Typography variant="body2" sx={theme => ({ color: theme.palette.grey[700] })}>
        Selecione as empresas da revenda que terão a opção a prazo liberada.
      </Typography >
      <Grid container sx={{ margin: '16px 0 32px 0', overflow: 'hidden' }} alignItems='center' rowSpacing='16px'>
        <Grid item xs={12} md={6} lg={5}>
          <Search placeholder="Buscar por CNPJ ou Nome da Empresa" value={search} onChange={value => setSearch(String(value).toLocaleLowerCase())} />
        </Grid>
        <Grid item>
          <FilterButton onClick={() => setOpenFilter(true)} >
            <FilterIcon /> Filtro
          </FilterButton>
        </Grid>

        <Grid item>
          <Tags>
            {isFilterTag ? (
              <button
                onClick={() => {
                  setOpenFilter(true)
                }}
              >
                <p>Filtros aplicados:</p>
                <span>{filter.companies.length + filter.status.length + (filter.date.from ? 1 : 0)}</span>
                <CloseFilterIcon
                  onClick={e => {
                    e.stopPropagation()
                    removeFilter({ tag: "companies" })
                  }}
                />
              </button>
            ) : null}
          </Tags>
        </Grid>
        <Grid item xs={12} sx={{ overflow: 'hidden' }}>
          <FormTableVirtualized
            id={formId}
            columns={getTableConfiguration({
              headerCheckBox:
                <Checkbox
                  checked={isAllChecked}
                  onClick={e => e.stopPropagation()}
                  onChange={handleCheckAllChange}
                />
            })}
            rows={companies}
            openFields={openFields}
            setOpenFields={setOpenFields}
            isLoading={isLoading || isLoadingData}
            visibleRows={15}
            ignoreFunctionInColumnCompare={false}
            shouldFilter={isFiltering}
            filterFunction={filterFunction}
            form={form}
          />
        </Grid>
      </Grid>
      <Filter setFilterProps={setFilter} open={openFilter} onClose={() => setOpenFilter(false)} filterProps={filter} />
    </Container >
  )
}
