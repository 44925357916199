import styled from 'styled-components';

export const TableContainer = styled.div`
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: ${({ loading }) => loading};
  text-align: center;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
