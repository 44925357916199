import { ErrorMessage, ErrorWrapper, Input, Modal, Text, Spinner, Grid } from "presentation/components";
import Button from "presentation/components/buttonV2";
import { Controller, useForm } from "react-hook-form";
import { Form, ModalLoadingWrapper, ModalTitle } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import useService from "main/hooks/useService";
import { useDispatch } from "react-redux";
import schema from "./schemas/contacts";
import { pushNotification } from "@/store/modules/notification/actions";
import successHandler from "main/utils/successHandler";
import { contactsTypes } from "main/utils/options";
import { CONTACT_TYPES } from "main/utils/constants";
import { putEditResaleContacts } from "@/services/resalesServices";

const ContactModal = ({
  contactIndex = null,
  resaleData,
  onClose,
  open
}) => {

  const dispatch = useDispatch();
  const isEditing = contactIndex !== null;
  const resaleContacts = resaleData?.contacts;
  const defaultValues = isEditing ? resaleContacts[contactIndex] : {};
  const contactsTypesOptions = !isEditing || resaleContacts[contactIndex]?.contactType !== CONTACT_TYPES.MAIN ?
    contactsTypes.filter(option => option.value !== CONTACT_TYPES.MAIN) :
    contactsTypes;
  const [editContactState, editContactRequest] = useService(putEditResaleContacts, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler(`Contato ${isEditing ? "alterado" : "adicionado"}!`)));
      onClose(true);
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (!isDirty) {
      onClose();
      return;
    }

    const payload = resaleContacts;
    const newData = {
      fullName: data.fullName,
      phone: data.phone,
      extension: data.extension,
      email: data.email,
      role: data.role,
      contactType: data.contactType,
    };
    if (isEditing) {
      payload[contactIndex] = {
        ...payload[contactIndex],
        ...newData,
      }
    } else {
      payload.push(newData);
    };

    editContactRequest(resaleData.id, payload);
  };

  return (
    <Modal
      open={open}
      showCloseIcon={false}
      onClose={onClose}
    >
      <ModalTitle>{isEditing ? "Editar Contato" : "Adicionar Contato"}</ModalTitle>
      <Form onSubmit={handleSubmit(onSubmit)} id="companyData">
        <Grid fluid padding="0" spaceBetweenRows="32px">
          {editContactState.loading ? <ModalLoadingWrapper><Spinner /></ModalLoadingWrapper> :
            <>
              <Grid.Row>
                <Grid.Col xs={12}>
                  <ErrorWrapper error={errors.fullName}>
                    <Text.Label>Nome Completo</Text.Label>
                    <Input
                      placeholder="Nome Completo"
                      maxLength="130"
                      {...register("fullName")}
                    />
                    {errors.fullName && (
                      <ErrorMessage>{errors.fullName.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={12}>
                  <ErrorWrapper error={errors?.email}>
                    <Text.Label>E-mail corporativo</Text.Label>
                    <Input
                      maxLength="256"
                      placeholder="colaborador@empresa.com.br"
                      {...register(`email`)}
                    />
                    {errors.email && (
                      <ErrorMessage>{errors.email.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.addressMain?.tradeName}>
                    <Text.Label>Tipo de Contato</Text.Label>
                    <Controller
                      control={control}
                      name="contactType"
                      render={({ field: { onChange, value, ref } }) => (
                        <Input.Select
                          placeholder="Selecione"
                          value={contactsTypesOptions.find(option => option.value === value)}
                          inputRef={ref}
                          onChange={e => onChange(e.value)}
                          options={contactsTypesOptions}
                          isDisabled={value === CONTACT_TYPES.MAIN}
                          isClearable={false}
                        />
                      )}
                    />
                    {errors.addressMain?.tradeName && (
                      <ErrorMessage>{errors.addressMain?.tradeName.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors?.role}>
                    <Text.Label>Cargo</Text.Label>
                    <Input
                      maxLength="256"
                      placeholder="Diretor"
                      {...register(`role`)}
                    />
                    {errors.role && (
                      <ErrorMessage>{errors.role.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.phone}>
                    <Text.Label>Telefone</Text.Label>
                    <Controller
                      control={control}
                      name={"phone"}
                      render={({ field: { onChange, value, ref } }) => (
                        <Input.Masked
                          format="(##) #####-####"
                          placeholder="(00) 00000-0000"
                          value={value}
                          getInputRef={ref}
                          onChange={e => onChange(e.target.value.replaceAll(/[^0-9]/g, ""))}
                        />
                      )}
                    />
                    {errors.phone && (
                      <ErrorMessage>{errors.phone.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.extension}>
                    <Text.Label>Ramal</Text.Label>
                    <Controller
                      control={control}
                      name={"extension"}
                      render={({ field: { onChange, value, ref } }) => (
                        <Input.Masked
                          placeholder="00000"
                          maxLength="5"
                          value={value}
                          getInputRef={ref}
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.extension && (
                      <ErrorMessage>{errors.extension.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row justify="between">
                <Grid.Col sm="content">
                  <Button
                    onClick={onClose}
                    variant="text"
                  >Cancelar</Button>
                </Grid.Col>
                <Grid.Col sm="content">
                  <Button>Salvar</Button>
                </Grid.Col>
              </Grid.Row>
            </>
          }
        </Grid>
      </Form>
    </Modal >
  );
}

export default ContactModal;