export const rules = {
  operatorId: {
    required: { value: true, message: "Campo obrigatório" },
  },
  operatorCardId: {
    required: { value: true, message: "Campo obrigatório" },
  },
  processType: {
    required: { value: true, message: "Campo obrigatório" },
  },
  user: {
    required: { value: true, message: "Campo obrigatório" },
  },
  password: {
    required: { value: true, message: "Campo obrigatório" },
  },
};