import history from "@/services/history";
import { Button, Input } from "@stationkim/components-lib";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ModalAlreadyExists } from "../../modals/modalAlreadyExists";
import { ModalWaitingRegister } from "../../modals/modalWaitingRegister";
import {
  ButtonLine,
  ContentContainer,
  InfoText,
  StyledForm,
  StepTitle
} from "./styles";
import { ErrorMessage } from "@hookform/error-message";
import { rules } from "./rules/generalDataRules";
import useService from "main/hooks/useService";
import { getResaleExistenseByCnpj } from "@/services/resalesServices";
import { RESALES_STATUS } from "main/utils/constants";
import { Spinner } from "presentation/components";

const WHO_IS_OPEN = {
  NO_ONE: 0,
  ALREADY_EXISTS: 1,
  WAITING_REGISTER: 2,
};

export const CheckCNPJ = ({ startRegister }) => {
  const [whoIsOpen, setWhoIsOpen] = useState(WHO_IS_OPEN.NO_ONE);
  const cnpjToRegister = useRef("");
  const resaleIdToContinue = useRef("");
  const [getResaleExistenceState, getResaleExistenceRequest] =
    useService(getResaleExistenseByCnpj, {
      onCompleted: (response) => {
        if (response.data === "") {
          startRegister(cnpjToRegister.current);
        } else if (response.data.status === RESALES_STATUS.PENDING) {
          resaleIdToContinue.current = response.data.id;
          setWhoIsOpen(WHO_IS_OPEN.WAITING_REGISTER);
        } else if (response.data.status === RESALES_STATUS.ACTIVE) {
          resaleIdToContinue.current = response.data.id;
          setWhoIsOpen(WHO_IS_OPEN.ALREADY_EXISTS);
        };
      }
    });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      cnpj: ""
    }
  });

  const handleCancel = () => {
    history.push('/revendas');
  };

  const onSubmit = (data) => {
    cnpjToRegister.current = data.cnpj;
    getResaleExistenceRequest(data.cnpj);
  };

  return (
    <ContentContainer>
      <StepTitle>Cadastrar nova Revenda</StepTitle>
      <InfoText css={{ marginBottom: '8px' }}>Por favor, informe o CNPJ da Revenda que <br />deseja cadastrar</InfoText>
      {getResaleExistenceState.loading ? <Spinner /> :
        <>
          <StyledForm
            id="cnpj-check-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="cnpj"
              rules={rules.cnpj}
              render={({ field: { onChange, value } }) => (
                <Input.Root
                  state={errors?.cnpj ? 'error' : null}
                  full
                >
                  <Input.Label>
                    CNPJ
                  </Input.Label>
                  <Input.Field>
                    <Input.Masked
                      mask="00.000.000/0000-00"
                      placeholder="00.000.000/0000-00"
                      value={value}
                      onAccept={unmaskedValue => onChange(unmaskedValue)}
                      unmask
                      lazy={value?.length === 0}
                    />
                  </Input.Field>
                  <ErrorMessage
                    errors={errors}
                    name="cnpj"
                    render={({ message }) =>
                      <Input.Message>{message}</Input.Message>
                    }
                  />
                </Input.Root>
              )}
            />

          </StyledForm>
          <ButtonLine>
            <Button buttonType="text" onClick={handleCancel}>Cancelar</Button>
            <Button
              form="cnpj-check-form"
            >
              Continuar
            </Button>
          </ButtonLine>
        </>
      }
      <ModalAlreadyExists
        open={whoIsOpen === WHO_IS_OPEN.ALREADY_EXISTS}
        onClose={() => setWhoIsOpen(WHO_IS_OPEN.NO_ONE)}
        resaleId={resaleIdToContinue.current}
      />
      <ModalWaitingRegister
        open={whoIsOpen === WHO_IS_OPEN.WAITING_REGISTER}
        onClose={() => setWhoIsOpen(WHO_IS_OPEN.NO_ONE)}
        resaleId={resaleIdToContinue.current}
      />
    </ContentContainer>
  )
};