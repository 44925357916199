import InfoModalContainer from './modal';
import { ButtonsWrapper, IconContainer, ModalContent, Title } from './styles';

const InfoModal = InfoModalContainer;
InfoModal.IconContainer = IconContainer;
InfoModal.Title = Title;
InfoModal.Content = ModalContent;
InfoModal.ButtonsWrapper = ButtonsWrapper;

export default InfoModal;
