export const routesAlias = {
  "/operadoras/dados_da_operadora": "Dados da Operadora",
  "/operadoras/dados_da_operadora/cartao": "Cartão",
  "/taxas/dados_da_taxa": "Dados da Taxa",
  "/revendas/dados_da_revenda": "Dados  da Revenda",
  "/revendas/dados_da_operadora": "Dados da Operadora",
  "/revendas/dados_da_operadora/recargas": "Recargas",
  "/revendas/dados_da_operadora/nova_taxa": "Nova Taxa",
  "/layout/novo": "Novo Layout",
}
