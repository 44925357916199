import { Tooltip } from '@mui/material';
import { Breadcrumb } from '..';
import { PageHeaderContainer, PageName } from './styles';

const PageHeader = ({ children, ...props }) => {
  return (
    <PageHeaderContainer {...props}>
      <Tooltip title={children}>
        <PageName>{children}</PageName>
      </Tooltip>
      <Breadcrumb />
    </PageHeaderContainer>
  );
};

export default PageHeader;
