import Table from './table';
import Tbody from './tbody';
import Td from './td';
import Th from './th';
import Thead from './thead';
import Tr from './tr';

Table.Thead = Thead;
Table.Tbody = Tbody;
Table.Th = Th;
Table.Tr = Tr;
Table.Td = Td;

export default Table;
