import { useEffect, useState } from "react";
import { useScreenClass } from "react-grid-system";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BsExclamationCircle, BsExclamationTriangle } from "react-icons/bs";
import { FaPencilAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useAtom, useAtomValue } from "jotai";
import { atomWithHash } from "jotai-location";
import {
  Content,
  ErrorMessage,
  ErrorWrapper,
  Grid,
  IconButton,
  Input,
  Line,
  Modal,
  PageHeader,
  Panel,
  Spinner,
  StatusTag,
  Switch,
  Text
} from "presentation/components";
import Button from "presentation/components/buttonV2";
import { InfoModal } from "presentation/compositions";
import useService from "main/hooks/useService";
import routesPaths from "presentation/routes/routesPaths";
import history from "@/services/history";
import { getOperatorCard, getOperatorCardVinculatedFees, patchOperatorChangeCardStatus, putEditOperatorCard } from "@/services/operatorsServices";
import { pushNotification } from "@/store/modules/notification/actions";
import { BENEFIT_TYPES, FORM_MODE, NOTIFICATION_TYPE, OPERATOR_CARD_STATUS } from "main/utils/constants";
import { benefitTypes } from "main/utils/options";
import successHandler from "main/utils/successHandler";
import schema from "./schema";
import { Form, LinkButton, TitleWrapper } from "./styles";
import { useQueryClient } from "@tanstack/react-query";
import { operatorCardIdAtom, operatorIdAtom } from "@/main/store";
import { fieldDoesExistOnHash } from "@/main/utils/functions";
import { useDocumentTitle } from "@stationkim/front-ui";

const formModeAtom = atomWithHash('operatorCardFormMode', FORM_MODE.VIEW)
export const VisualizeAndEditOperatorCard = () => {
  const dispatch = useDispatch();
  const screenClass = useScreenClass();
  const documentTitle = useDocumentTitle()

  const operatorId = useAtomValue(operatorIdAtom)
  const operatorCardId = useAtomValue(operatorCardIdAtom)
  const [mode, setMode] = useAtom(formModeAtom);

  const [vinculatedFees, setVinculatedFees] = useState([]);
  const [isVinculatedFeesModalOpen, setIsVinculatedFeesModalOpen] = useState(false);
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);
  const [isChangeStatusConfirmationOpen, setIsChangeStatusConfirmationOpen] = useState(false);
  const [card, setCardFee] = useState();
  const isCardActive = card?.status === OPERATOR_CARD_STATUS.ACTIVE;
  const isEditing = mode === FORM_MODE.EDIT;
  const {
    control,
    formState: { errors, isDirty },
    register,
    handleSubmit,
    reset
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const queryClient = useQueryClient()

  const [fetchCardState, fetchCardRequest] = useService(getOperatorCard, {
    onCompleted: (response) => {
      reset({
        ...response.data,
        operatorId: `${card?.tradeName} - ${card?.code}`
      });
      setCardFee(response.data);
    },
  });

  const [changeStatusState, changeStatusRequest] = useService(patchOperatorChangeCardStatus, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler(`Cartão ${isCardActive ? "inativado" : "ativado"}!`)));
      setIsChangeStatusConfirmationOpen(false);
      fetchCardRequest(operatorId, operatorCardId);
      queryClient.invalidateQueries({ queryKey: ['operatorCards'] })
    },
  });

  const [fetchVinculatedFeesState, fetchVinculatedFeesRequest] = useService(getOperatorCardVinculatedFees, {
    onCompleted: (response) => {
      setVinculatedFees(response.data);
    },
  });

  const [editCardFeeState, editCardFeeRequest] = useService(putEditOperatorCard, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler("Cartão editado com sucesso!")));
      queryClient.invalidateQueries({ queryKey: ['operatorCards'] })
      returnToOperatorCards()
    },
  });

  const isLoading = !card || fetchCardState.loading || editCardFeeState.loading || fetchVinculatedFeesState.loading;


  const cancelEdition = () => {
    setMode(FORM_MODE.VIEW);
    setIsCancelConfirmationOpen(false);
  }

  const returnToOperatorCards = () =>
    history.push(`${routesPaths.operators.visualize}#operatorId="${operatorId}"&operatorTab=1`);


  const onSubmit = (data) => {
    if (!isDirty) {
      setMode(FORM_MODE.VIEW);
      return;
    };
    const payload = {
      ...data,
      status: card.status
    };
    editCardFeeRequest(operatorId, operatorCardId, payload);
  };

  useEffect(() => {
    if (card)
      documentTitle.change({ title: `${card?.tradeName} - ${card?.name}` })
  }, [card])

  useEffect(() => {
    if (!fieldDoesExistOnHash(['operatorId', 'operatorCardId'])) {
      operatorId ? returnToOperatorCards() : history.replace('/operadoras')
    }
    if (operatorCardId && operatorId) {
      fetchCardRequest(operatorId, operatorCardId);
      fetchVinculatedFeesRequest(operatorCardId);
    }
    // eslint-disable-next-line
  }, [operatorCardId, operatorId]);

  return (
    <>
      <Content>
        <Panel noPadding>
          <Grid >
            <Grid.Row>
              <Grid.Col>
                <PageHeader>{card?.tradeName ?? "Operadora"}</PageHeader>
              </Grid.Col>
            </Grid.Row>
          </Grid>
          <Line overflow />
          <Form onSubmit={handleSubmit(onSubmit)} id="cardFeeForm">
            <Grid spaceBetweenRows="32px" padding={isLoading ? "32px" : "16px 16px 32px 16px"}>
              {isLoading ? <Spinner.Box><Spinner /></Spinner.Box> :
                <>
                  <Grid.Row align="center">
                    <Grid.Col>
                      <TitleWrapper>
                        <Text.Subtitle>{isEditing ? `Editar ${card.name}` : card.name}</Text.Subtitle>
                        {!isEditing &&
                          <IconButton onClick={(e) => {
                            if (isCardActive) {
                              e.preventDefault();
                              setMode(FORM_MODE.EDIT);
                            } else dispatch(pushNotification({
                              type: NOTIFICATION_TYPE.WARNING,
                              content: "Para editar um cartão é necessário que ele esteja ativo."
                            }))
                          }}>
                            <FaPencilAlt />
                          </IconButton>
                        }
                      </TitleWrapper>
                    </Grid.Col>
                    {!isEditing &&
                      <>
                        <Grid.Col sm="content">
                          {changeStatusState.loading ? <Spinner variant="sm" /> :
                            <Switch
                              onChange={() => setIsChangeStatusConfirmationOpen(true)}
                              checked={card.status === OPERATOR_CARD_STATUS.INACTIVE}
                            />
                          }
                        </Grid.Col>
                        <Grid.Col xs={12} sm="content">
                          <StatusTag
                            color={isCardActive ? "green" : "red"}
                          >
                            {OPERATOR_CARD_STATUS[card?.status]}
                          </StatusTag>
                        </Grid.Col>
                      </>
                    }
                  </Grid.Row>
                  <Grid.Row  >
                    <Grid.Col xs={12} sm={6} md={3}>
                      <Text.Label>Operadora</Text.Label>
                      {isEditing ?
                        <Input
                          disabled
                          {...register("operatorId")}
                        /> :
                        <Text>{card.tradeName} - {card.code}</Text>
                      }
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={3}>
                      <Text.Label>Código</Text.Label>
                      {isEditing ?
                        <Input
                          disabled
                          {...register("code")}
                        /> :
                        <Text>{card.code}</Text>
                      }
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={3} marginsFor={['xs', 'sm']}>
                      {isEditing ?
                        <ErrorWrapper error={errors.name}>
                          <Text.Label>Nome</Text.Label>
                          <Input
                            maxLength={40}
                            {...register("name")}
                          />
                          {errors.name &&
                            <ErrorMessage>{errors.name.message}</ErrorMessage>
                          }
                        </ErrorWrapper> :
                        <>
                          <Text.Label>Nome</Text.Label>
                          <Text>{card.name}</Text>
                        </>
                      }
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col xs={12} sm={6} md={3} marginsFor={['xs']}>
                      {isEditing ?
                        <ErrorWrapper error={errors.benefitType}>
                          <Text.Label>Tipo de Benefício</Text.Label>
                          <Controller
                            control={control}
                            name="benefitType"
                            render={({ field: { value, onChange, ref } }) =>
                              <Input.Select
                                inputRef={ref}
                                value={benefitTypes.find(option => option.value === value)}
                                placeholder="Selecione..."
                                onChange={(e) => {
                                  onChange(e.value);
                                }}
                                isClearable={false}
                                options={benefitTypes}
                              />
                            }
                          />
                          {errors.benefitType &&
                            <ErrorMessage>{errors.benefitType.message}</ErrorMessage>
                          }
                        </ErrorWrapper> :
                        <>
                          <Text.Label>Tipo de Benefício</Text.Label>
                          <Text>{BENEFIT_TYPES[card.benefitType]}</Text>
                        </>
                      }
                    </Grid.Col>
                    <Grid.Col xs={12} sm={6} md={3}>
                      <Text.Label>{vinculatedFees.length > 1 ? "Tarifas Vinculadas" : "Tarifa Vinculada"}</Text.Label>
                      {vinculatedFees.length > 1 ?
                        <LinkButton onClick={() => setIsVinculatedFeesModalOpen(true)}>Várias selecionadas</LinkButton> :
                        <Text>{vinculatedFees?.[0]?.description}</Text>
                      }
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row justify="end">
                    {isEditing ?
                      <>
                        <Grid.Col sm={2}>
                          <Button variant="text" onClick={(e) => {
                            if (isDirty) {
                              e.preventDefault();
                              setIsCancelConfirmationOpen(true);
                            } else cancelEdition();
                          }}>Cancelar</Button>
                        </Grid.Col>
                        <Grid.Col xs={12} width={['xs'].includes(screenClass) ? null : "232px"} marginsFor={['xs']}>
                          <Button form="cardFeeForm">Salvar alterações</Button>
                        </Grid.Col>
                      </> :
                      <Grid.Col xs={12} width={['xs'].includes(screenClass) ? null : "134px"} marginsFor={['xs']}>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            returnToOperatorCards()
                          }}
                        >
                          Voltar
                        </Button>
                      </Grid.Col>
                    }
                  </Grid.Row>
                </>
              }
            </Grid>
          </Form>
        </Panel>
      </Content>
      <InfoModal
        open={isCancelConfirmationOpen}
        onClose={() => setIsCancelConfirmationOpen(false)}
      >
        <InfoModal.IconContainer color="var(--color-primary-01)">
          <BsExclamationCircle />
        </InfoModal.IconContainer>
        <InfoModal.Title>DESEJA CANCELAR A EDIÇÃO DO CARTÃO?</InfoModal.Title>
        <InfoModal.Content>
          Ao clicar em confirmar os dados informados serão perdidos.
        </InfoModal.Content>
        <InfoModal.ButtonsWrapper>
          <Button onClick={() => setIsCancelConfirmationOpen(false)} variant="text">
            Cancelar
          </Button>
          <Button
            variant="ghost"
            onClick={cancelEdition}
          >
            Confirmar
          </Button>
        </InfoModal.ButtonsWrapper>
      </InfoModal>
      <InfoModal
        open={isChangeStatusConfirmationOpen}
        showCloseIcon={true}
        onClose={() => setIsChangeStatusConfirmationOpen(false)}
        closeOnOverlayClick={!changeStatusState.loading}
        closeOnEsc={!changeStatusState.loading}
      >
        {changeStatusState.loading ? <Spinner.Box padding="32px"><Spinner /></Spinner.Box > :
          <>
            <InfoModal.IconContainer color={isCardActive ? "var(--color-status-02)" : "var(--color-status-01)"}>
              {isCardActive ? <BsExclamationTriangle /> : <BsExclamationCircle />}
            </InfoModal.IconContainer>
            <InfoModal.Title color={isCardActive ? "var(--color-status-02)" : "var(--color-status-01)"}>ATENÇÃO</InfoModal.Title>
            <InfoModal.Content>
              {`Você tem certeza que deseja ${isCardActive ? "inativar" : "reativar"} esse cartão?`}
            </InfoModal.Content>
            <InfoModal.ButtonsWrapper>
              <Button onClick={() => setIsChangeStatusConfirmationOpen(false)} variant="text">
                Cancelar
              </Button>
              <Button
                variant="ghost"
                predefTheme={isCardActive ? "red" : "green"}
                onClick={() => changeStatusRequest(
                  operatorId,
                  operatorCardId,
                  isCardActive ? false : true
                )}
              >
                Confirmar
              </Button>
            </InfoModal.ButtonsWrapper>
          </>
        }
      </InfoModal>
      <Modal
        open={isVinculatedFeesModalOpen}
        onClose={() => setIsVinculatedFeesModalOpen(false)}
        showCloseIcon={false}
        styles={{ modal: { width: 390 } }}
      >
        <Grid padding="0" spaceBetweenRows="16px">
          <Grid.Row>
            <Grid.Col style={{ marginBottom: 26 }}>
              <Text.Section>Tarifas Vinculadas</Text.Section>
            </Grid.Col>
          </Grid.Row>

          {vinculatedFees.length > 0 &&
            vinculatedFees.map((fee, index) => (
              <Grid.Row key={fee.id}>
                <Grid.Col >
                  <Text.SubtitleTwo style={{ marginBottom: 8 }}>Tarifa {index + 1}.</Text.SubtitleTwo>
                  <Input
                    value={fee.description}
                    disabled
                  />
                </Grid.Col>
              </Grid.Row>
            ))
          }
          <Grid.Row justify="center">
            <Grid.Col xs={8}>
              <Button onClick={() => setIsVinculatedFeesModalOpen(false)}>Continuar</Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Modal>
    </ >
  );
};
