import { Container } from 'react-grid-system';
import styled from 'styled-components';

export const CustomContainer = styled(Container)`
  padding: ${({ padding }) => padding} !important;

  & > div:not(:last-of-type) {
    margin-bottom: ${({ spacebetweenrows }) => spacebetweenrows};
  }
`;
