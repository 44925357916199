import { pushNotification } from '@/store/modules/notification/actions';
import errorHandler from 'main/utils/errorHandler';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

const initialState = {
  error: null,
  loading: false,
  response: null,
};

const defaultConfig = {
  silent: false,
};

const useService = (service, config = defaultConfig) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);

  const request = async (...args) => {
    setState({ ...initialState, loading: true });
    const { error, response } = await service(...args);
    if (response) {
      setState({ ...initialState, response: response });
      if (config.onCompleted) config.onCompleted(response);
    } else {
      setState({ ...initialState, error: error });
      !config.silent &&
        dispatch(pushNotification(errorHandler(error.response)));
    }
    setState((state) => ({ ...state, loading: false }));
  };

  return [state, request];
};

export default useService;
