import usePaginator from 'main/hooks/usePaginator';
import Button from 'presentation/components/buttonV2';
import { Hidden } from 'react-grid-system';
import { TbFilter } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FilterModal } from 'presentation/compositions';
import { typeFees, typeApplicationFees, typeValueFees } from 'main/utils/options';
import { StatusDot, StatusWrapper, TextCenter, Form } from './styles';
import routesPaths from 'presentation/routes/routesPaths';
import history from '@/services/history';
import { operatorCardFeeStatus } from 'main/utils/options';
import { NOTIFICATION_TYPE } from 'main/utils/constants';
import { Controller, useForm } from 'react-hook-form';
import { feesInRequest } from '@/store/modules/fees/actions';
import { pushNotification } from '@/store/modules/notification/actions';
import ModalStatus from './components/modelStatus';
import errorHandler from 'main/utils/errorHandler';
import api from '@/infra/api';
import {
  Grid,
  Line,
  Pagination,
  Table,
  Panel,
  Content,
  DotMenu,
  PageHeader,
  Search,
  Text,
  Input,
} from 'presentation/components';
import {
  OPERATOR_CARD_STATUS,
  CRITERIONFEES,
  APPLICATIONFEES,
  VALUETYPEFEES,
} from 'main/utils/constants';

const Fees = () => {
  const initialQuery = {
    sort: 'code',
    desc: false,
  };
  const dispatch = useDispatch();
  const fees = useSelector((store) => store.fees);

  const paginator = usePaginator({
    query: initialQuery,
  });

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [id, setId] = useState(null);
  const [type, setType] = useState(null);

  useEffect(() => {
    if (paginator.data.doRequest) {
      dispatch(feesInRequest(paginator.buildQuery()));
      paginator.preventRefresh();
    }
    // eslint-disable-next-line
  }, [paginator, dispatch]);

  useEffect(() => {
    paginator.updatePaginator({
      totalItens: fees?.data?.totalItens,
      totalPages: fees?.data?.totalPages,
    });
    // eslint-disable-next-line
  }, [fees]);

  const filterForm = useForm();

  const onRowClick = (id) => {
    history.push({
      pathname: routesPaths.fees.visualize,
      state: { id, mode: false },
    });
  };

  const sendToCardVizualization = (id) => {
    history.push({
      pathname: routesPaths.fees.visualize,
      state: { id, mode: true },
    });
  };

  const handleStatus = (id, type) => {
    setId(id);
    setType(type);
    setStatus(true);
  };

  const confirm = async () => {
    try {
      setStatus(false);
      await api.patch(`/Fee/${id}/activate/${type === 1 ? 2 : 1}`);

      dispatch(feesInRequest(paginator.buildQuery()));
      paginator.preventRefresh();
    } catch (e) {
      dispatch(pushNotification(errorHandler(e?.response)));
      setStatus(false);
    }
  };

  const clearFilterModal = () => {
    filterForm.setValue('Status', '');
    filterForm.setValue('Type', '');
    filterForm.setValue('Application', '');
    filterForm.setValue('ValueType', '');
    paginator.resetFilters();
  };

  return (
    <>
      <Content>
        <Panel noPadding>
          <Grid padding="16px 16px 0 16px">
            <Grid.Row align="center" justify="between">
              <Grid.Col md={12} lg="content">
                <PageHeader>Gestão de taxas</PageHeader>
              </Grid.Col>
              <Hidden xs sm md lg>
                <Grid.Col />
              </Hidden>
              <Grid.Col sm={12} md={12} lg={4} xl={3}>
                <Search
                  search={(value) =>
                    paginator.setFilters({
                      filterValue: value
                        .replaceAll('.', '')
                        .replaceAll('/', '')
                        .replaceAll('-', ''),
                      filterProps: 'code,name',
                    })
                  }
                  placeholder="Buscar taxas"
                />
              </Grid.Col>
              <Grid.Col
                xs={12}
                sm={6}
                md={6}
                lg={2}
                marginsFor={['xs', 'sm', 'md']}
              >
                <Button
                  onClick={() => setIsFilterOpen(true)}
                  icon={<TbFilter />}
                  variant="ghost"
                  theme={{
                    bg: '#F76C39',
                    hoverGhost: '#F76C390D',
                  }}
                >
                  Filtrar
                </Button>
              </Grid.Col>
              <Grid.Col
                xs={12}
                sm={6}
                md={6}
                lg={3}
                xl={2}
                marginsFor={['xs', 'sm', 'md']}
              >
                <Button
                  onClick={() => history.push(routesPaths.fees.register)}
                >
                  Criar nova taxa
                </Button>
              </Grid.Col>
            </Grid.Row>
          </Grid>
          <Hidden xs sm md>
            <Line overflow />
          </Hidden>
          <Grid padding="1px 16px 16px 16px" spaceBetweenRows="32px">
            <Grid.Row>
              <Grid.Col>
                <Table visibleRows={10} loading={fees.loading}>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Código</Table.Th>
                      <Table.Th>Nome</Table.Th>
                      <Table.Th>Critério</Table.Th>
                      <Table.Th>Aplicação</Table.Th>
                      <Table.Th>Tipo de Valor</Table.Th>
                      <Table.Th>Status</Table.Th>
                      <Table.Th />
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {fees.data?.data?.length > 0 ? (
                      fees.data.data.map((fees) => (
                        <Table.Tr
                          key={fees.id}
                          onClick={() => onRowClick(fees.id)}
                          interactable
                        >
                          <Table.Td>{fees.code}</Table.Td>
                          <Table.Td>{fees.name}</Table.Td>
                          <Table.Td>{CRITERIONFEES[fees.criterion]}</Table.Td>
                          <Table.Td>
                            {APPLICATIONFEES[fees.application]}
                          </Table.Td>
                          <Table.Td>{VALUETYPEFEES[fees.valueType]}</Table.Td>

                          <Table.Td>
                            <StatusWrapper>
                              <StatusDot status={fees.status} />
                              {OPERATOR_CARD_STATUS[fees.status]}
                            </StatusWrapper>
                          </Table.Td>
                          <Table.Td onClick={(e) => e.stopPropagation()}>
                            <DotMenu>
                              <DotMenu.Option
                                onClick={() => {
                                  if (
                                    fees.status ===
                                    OPERATOR_CARD_STATUS.ACTIVE
                                  )
                                    sendToCardVizualization(fees.id);
                                  else
                                    dispatch(
                                      pushNotification({
                                        type: NOTIFICATION_TYPE.WARNING,
                                        content:
                                          'Para editar uma taxa é necessário que ela esteja ativa.',
                                      })
                                    );
                                }}
                              >
                                Editar
                              </DotMenu.Option>
                              <DotMenu.Option
                                onClick={() => {
                                  handleStatus(fees.id, fees.status);
                                }}
                              >
                                {fees.status === OPERATOR_CARD_STATUS.ACTIVE
                                  ? 'Inativar'
                                  : 'Ativar'}
                              </DotMenu.Option>
                            </DotMenu>
                          </Table.Td>
                        </Table.Tr>
                      ))
                    ) : (
                      <Table.Tr>
                        <Table.Td colSpan={8}>
                          <TextCenter>Sem registros</TextCenter>
                        </Table.Td>
                      </Table.Tr>
                    )}
                  </Table.Tbody>
                </Table>
              </Grid.Col>
            </Grid.Row>
          </Grid>
          <Hidden xs sm md>
            <Line overflow />
          </Hidden>
          <Grid>
            <Grid.Row align="center">
              <Grid.Col>
                <Pagination.ResultAmount
                  paginator={paginator.data}
                  setPageSize={(newPageSize) =>
                    paginator.setPageSize(newPageSize)
                  }
                />
              </Grid.Col>
              <Grid.Col sm="content">
                <Pagination
                  paginator={paginator.data}
                  setPage={(wantedPage) => paginator.setPage(wantedPage)}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
          <FilterModal
            open={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
            filter={filterForm.handleSubmit((data) => {
              paginator.setFilters(data);
            })}
            clearFilter={() => clearFilterModal()}
          >
            <Form>
              <Grid padding="0">
                <Grid.Row>
                  <Grid.Col lg={6}>
                    <Text.Label>Status</Text.Label>
                    <Controller
                      control={filterForm.control}
                      name="Status"
                      render={({ field: { value, onChange } }) => (
                        <Input.Select
                          value={operatorCardFeeStatus.find(
                            (option) => option.value === value
                          )}
                          onChange={(option) =>
                            onChange(option?.value ?? null)
                          }
                          placeholder="Todos"
                          options={operatorCardFeeStatus}
                        />
                      )}
                    />
                  </Grid.Col>

                  <Grid.Col lg={6}>
                    <Text.Label>Tipo de Taxa</Text.Label>
                    <Controller
                      control={filterForm.control}
                      name="Type"
                      render={({ field: { value, onChange } }) => (
                        <Input.Select
                          value={typeFees.find(
                            (option) => option.value === value
                          )}
                          onChange={(option) =>
                            onChange(option?.value ?? null)
                          }
                          placeholder="Todos"
                          options={typeFees}
                        />
                      )}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col lg={6}>
                    <Text.Label>Aplicação</Text.Label>
                    <Controller
                      control={filterForm.control}
                      name="Application"
                      render={({ field: { value, onChange } }) => (
                        <Input.Select
                          value={typeApplicationFees.find(
                            (option) => option.value === value
                          )}
                          onChange={(option) =>
                            onChange(option?.value ?? null)
                          }
                          placeholder="Todos"
                          options={typeApplicationFees}
                        />
                      )}
                    />
                  </Grid.Col>

                  <Grid.Col lg={6}>
                    <Text.Label>Tipo de valor</Text.Label>
                    <Controller
                      control={filterForm.control}
                      name="ValueType"
                      render={({ field: { value, onChange } }) => (
                        <Input.Select
                          value={typeValueFees.find(
                            (option) => option.value === value
                          )}
                          onChange={(option) =>
                            onChange(option?.value ?? null)
                          }
                          placeholder="Todos"
                          options={typeValueFees}
                        />
                      )}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Grid>
            </Form>
          </FilterModal>
        </Panel>
      </Content>
      {status && (
        <ModalStatus
          open={status}
          close={() => setStatus(false)}
          confirm={() => confirm()}
          type={type}
        />
      )}
    </>
  );
};

export default Fees;
