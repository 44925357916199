export function feesInRequest(query) {
  return {
    type: '@fees/FEES_IN_REQUEST',
    payload: { query },
  };
}

export function feesInSuccess(fees) {
  return {
    type: '@fees/FEES_IN_SUCCESS',
    payload: { fees },
  };
}

export function feesFailure() {
  return {
    type: '@fees/FEES_FAILURE',
  };
}
