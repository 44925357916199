import styled from "@emotion/styled"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  p {
    color: #121212;
    font-size: 18px;
    font-weight: 600;
  }

  button {
    border: 0;
    cursor: pointer;
    background-color: transparent;
  }
`

export const Description = styled.p`
  color: var(--Grey-900, #212121);
  font-size: 12px;
  font-weight: 400;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
      margin-bottom: 6px;
    }
  }
`

export const DateContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    width: 160px;

    > div {
      height: 48px !important;
    }
  }
`

export const ContentButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 100px;

  button {
    width: 155px;
  }
`
