import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import Button from 'presentation/components/buttonV2';
import { ButtonsWrapper, Title } from './styles';

const styles = {
  borderRadius: '5px',
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'relative',
    marginRight: '32px',
  },
  modal: {
    width: '450px',
    margin: '0',
  },
};

const FilterModal = ({ children, onClose, open, filter, clearFilter }) => {
  return (
    <Modal open={open} center onClose={onClose} styles={styles}>
      <Title>Filtrar</Title>
      {children}
      <ButtonsWrapper>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            clearFilter();
            onClose();
          }}
          variant="ghost"
          theme={{
            bg: '#F76C39',
            hoverGhost: '#F76C390D',
          }}
        >
          Limpar
        </Button>
        <Button
          onClick={() => {
            filter();
            onClose();
          }}
        >
          Filtrar
        </Button>
      </ButtonsWrapper>
    </Modal>
  );
};

export default FilterModal;
