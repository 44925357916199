import { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Icon, Notification, Message } from 'presentation/components';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import { finishOperatorInRequest } from '@/store/modules/finishOperators/actions';
import api from '@/infra/api';
import history from '@/services/history';
import { format } from 'cnpj';
import errorHandler from 'main/utils/errorHandler';
import { pushNotification } from '@/store/modules/notification/actions';
import ModalLoading from '../../components/modalLoading';
import {
  Body,
  Title,
  TitleSession,
  Space,
  Flex,
  Key,
  Line,
  FlexRight,
  Limited,
  KeyContent,
} from './styles';
import axios from 'axios';
import { Tooltip } from '@mui/material';

const Finish = ({ setPosition, id }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const screenClass = useScreenClass();
  const review = useSelector((state) => state.operatorsReview);

  const { data } = review;

  useMemo(() => {
    dispatch(finishOperatorInRequest(id));
    // eslint-disable-next-line
  }, []);

  const save = async () => {
    setLoading(true);

    try {
      await api.post(`/operators/${id}/registration/review`);
      setSuccess(true);
      setLoading(false);

      setTimeout(() => {
        history.push('/operadoras');
      }, 3000);
    } catch (e) {
      dispatch(pushNotification(errorHandler(e?.response)));
      setLoading(false);
    }
  };

  useMemo(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    }
  }, [success]);

  const returnTypeAddress = (number) => {
    if (number === 1) return 'Principal';
    if (number === 2) return 'Coleta de Bilhete';
    if (number === 3) return 'Coleta de Cartão';
    if (number === 4) return 'Outro';
    return '';
  };

  const returnTypeContact = (number) => {
    if (number === 1) return 'Principal';
    if (number === 2) return 'RH';
    if (number === 3) return 'Financeiro';
    if (number === 4) return 'Comercial';
    if (number === 5) return 'Jurídico';
    if (number === 6) return 'Outro';
    return '';
  };

  const returnTypeRules = (number) => {
    if (number === 1) return 'Recarga';
    if (number === 2) return 'Cartão';
    if (number === 3) return 'VT Papel';
    return '';
  };

  const downloadFile = (file, name) => {
    axios({
      url: file, //your url
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTLM element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', name); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(file);
    });
  };

  const KeyValue = ({ children }) => {
    return (
      <Tooltip title={children}>
        <KeyContent>
          {children}
        </KeyContent>
      </Tooltip>
    )
  }

  return (
    <>
      <Body>
        {loading && (
          <ModalLoading open={loading} close={() => setLoading(false)} />
        )}
        {review.loading && <ModalLoading open={review.loading} close={null} />}
        {!loading && !review.loading && data && (
          <>
            <Notification />
            <Title>Revisão</Title>
            <Space />
            <Container fluid style={{ padding: 0 }}>
              <Row>
                <Col sm={3}>
                  <Flex>
                    <TitleSession>Dados Gerais da Operadora</TitleSession>
                    <Icon
                      name="edit"
                      style={{ marginTop: '2px' }}
                      onClick={() => setPosition(0)}
                    />
                  </Flex>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <Key>CNPJ</Key>
                  <KeyValue>{format(data?.cnpj ? data?.cnpj : '')}</KeyValue>
                </Col>
              </Row>
              <Space />
              <Row>
                <Col sm={3}>
                  <Key>Razão Social</Key>
                  <KeyValue>{data?.corporateName}</KeyValue>
                </Col>
                <Col sm={3}>
                  <Key>Nome Fantasia</Key>
                  <KeyValue>{data?.tradeName}</KeyValue>
                </Col>
                <Col sm={3}>
                  <Key>Dias para crédito</Key>
                  <KeyValue>{data?.creditProcessingDays}</KeyValue>
                </Col>
              </Row>
              <Space />
              <Row>
                <Col sm={3}>
                  <Key>Inscrição Estadual</Key>
                  <KeyValue>{data?.stateRegistration}</KeyValue>
                </Col>
                <Col sm={3}>
                  <Key>E-mail</Key>
                  <KeyValue>{data?.email}</KeyValue>
                </Col>
              </Row>
              <Space />
              <Row>
                <Col sm={3}>
                  <Key>Inscrição Minucipal</Key>
                  <KeyValue>{data?.municipalRegistration}</KeyValue>
                </Col>
                <Col sm={3}>
                  <Key>Site</Key>
                  <KeyValue>{data?.site}</KeyValue>
                </Col>
              </Row>
              <Line />


              <Row>
                <Col sm={3}>
                  <Flex>
                    <TitleSession>Endereço Principal</TitleSession>
                    <Icon
                      name="edit"
                      style={{ marginTop: '2px' }}
                      onClick={() => setPosition(1)}
                    />
                  </Flex>
                </Col>
              </Row>
              {data?.addresses?.length ?
                data?.addresses.map((address, index) => {
                  return (
                    <>
                      {data?.addresses?.length > 1 ?
                        <Row>
                          <Col sm={3}>
                            <Key>{index + 1}.</Key>
                          </Col>
                        </Row> : null
                      }
                      <Row key={address.id}>
                        <Col sm={3}>
                          <Key>Cep</Key>
                          <KeyValue>{address.cep}</KeyValue>
                        </Col>
                        <Col sm={3}>
                          <Key>Estado</Key>
                          <KeyValue>{address.state}</KeyValue>
                        </Col>
                        <Col sm={3}>
                          <Key>Cidade</Key>
                          <KeyValue>{address.city}</KeyValue>
                        </Col>
                        <Col sm={3}>
                          <Key>Nome do endereço</Key>
                          <KeyValue>{address.name}</KeyValue>
                        </Col>
                      </Row>
                      <Space />
                      <Row>
                        <Col sm={3}>
                          <Key>Endereço</Key>
                          <KeyValue>{address.laneName}</KeyValue>
                        </Col>
                        <Col sm={3}>
                          <Key>Número</Key>
                          <KeyValue>{address.number}</KeyValue>
                        </Col>
                        <Col sm={3}>
                          <Key>Tipo de endereço</Key>
                          <KeyValue>
                            {returnTypeAddress(address.addressType)}
                          </KeyValue>
                        </Col>
                      </Row>
                      <Space />
                      {
                        data?.addresses?.length === 0 ?
                          <Row>
                            <Col sm={3} style={{ color: '#a9a9a9' }}>
                              <KeyValue>Endereço de coleta*</KeyValue>
                            </Col>
                          </Row> : null
                      }
                    </>
                  )
                }) : null
              }
              <Line />
              <Row>
                <Col sm={3}>
                  <Flex>
                    <TitleSession>Contatos</TitleSession>
                    <Icon
                      name="edit"
                      style={{ marginTop: '2px' }}
                      onClick={() => setPosition(2)}
                    />
                  </Flex>
                </Col>
              </Row>
              {data?.contacts?.length
                ? data.contacts.map((contact, index) => (
                  <>
                    {data?.contacts?.length > 1 ?
                      <Row>
                        <Col sm={3}>
                          <Key>{index + 1}.</Key>
                        </Col>
                      </Row> : null
                    }
                    <Row>
                      <Col sm={3}>
                        <Key>Nome completo</Key>
                        <KeyValue>{contact.name}</KeyValue>
                      </Col>
                      <Col sm={3}>
                        <Key>E-mail</Key>
                        <KeyValue>{contact.email}</KeyValue>
                      </Col>
                      <Col sm={3}>
                        <Key>Cargo</Key>
                        <KeyValue>{contact.role}</KeyValue>
                      </Col>
                    </Row>
                    <Space />
                    <Row>
                      <Col sm={3}>
                        <Key>Tipo de contato</Key>
                        <KeyValue>
                          {returnTypeContact(contact.contactType)}
                        </KeyValue>
                      </Col>
                      <Col sm={3}>
                        <Key>Telefone</Key>
                        <KeyValue>{contact.phone}</KeyValue>
                      </Col>
                      <Col sm={3}>
                        <Key>Ramal</Key>
                        <KeyValue>{contact.extension}</KeyValue>
                      </Col>
                    </Row>
                    <Space />
                  </>
                ))
                : null}
              <Line />
              <Row>
                <Col sm={3}>
                  <Flex>
                    <TitleSession>Regras de Compra</TitleSession>
                    <Icon
                      name="edit"
                      style={{ marginTop: '2px' }}
                      onClick={() => setPosition(3)}
                    />
                  </Flex>
                </Col>
              </Row>
              {data?.buyingRules?.length
                ? data.buyingRules.map((data, i) => (
                  <>
                    <Row>
                      <Col sm={3}>
                        <Key>{i + 1}.</Key>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <Key>Tipo de Regra</Key>
                        <KeyValue>{returnTypeRules(data.type)}</KeyValue>
                      </Col>
                      <Col sm={3}>
                        <Key>Prazo para coleta</Key>
                        <KeyValue>{data.value}</KeyValue>
                      </Col>
                    </Row>
                    <Space />
                    <Row>
                      <Col sm={3}>
                        <Key>Valor mínimo</Key>
                        <KeyValue>{data.minimumPurchase}</KeyValue>
                      </Col>
                      <Col sm={3}>
                        <Key>Valor máximo</Key>
                        <KeyValue>{data.maximumPurchase}</KeyValue>
                      </Col>
                    </Row>
                  </>
                ))
                : null}
              <Line />
              <Row>
                <Col sm={3}>
                  <Flex>
                    <TitleSession>Arquivos</TitleSession>
                    <Icon
                      name="edit"
                      style={{ marginTop: '2px' }}
                      onClick={() => setPosition(4)}
                    />
                  </Flex>
                </Col>
              </Row>
              {data?.documents?.length
                ? data.documents.map((data) => (
                  <>
                    {data.type === 1 && (
                      <>
                        <Row>
                          <Col sm={3} style={{ cursor: 'pointer' }}>
                            <Key
                              onClick={() =>
                                downloadFile(
                                  data.temporaryLink,
                                  data.fileName
                                )
                              }
                            >
                              {data.fileName}
                            </Key>
                            <KeyValue>{data.fileName}</KeyValue>
                          </Col>
                        </Row>
                        <Space />
                      </>
                    )}
                  </>
                ))
                : null}
              {data?.documents?.length
                ? data.documents.map((data) => (
                  <>
                    {data.type === 2 && (
                      <>
                        <Row>
                          <Col sm={3} style={{ cursor: 'pointer' }}>
                            <Key
                              onClick={() =>
                                downloadFile(
                                  data.temporaryLink,
                                  data.fileName
                                )
                              }
                            >
                              {data.fileName}
                            </Key>
                            <KeyValue>{data.fileName}</KeyValue>
                          </Col>
                        </Row>
                        <Space />
                      </>
                    )}
                  </>
                ))
                : null}
              <Row>
                <Col sm={3}>
                  <Key>Observações</Key>
                  <KeyValue>
                    {data.documentNotes ? data.documentNotes : ''}
                  </KeyValue>
                </Col>
              </Row>
              <Row>
                <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
                  <FlexRight>
                    <Limited>
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={() => setPosition(4)}
                      >
                        Cancelar
                      </Button>
                      <div style={{ marginLeft: '40px' }} />
                      <Button onClick={() => save()}>Continuar</Button>
                    </Limited>
                  </FlexRight>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </Body>
      {success && (
        <Message onXClick={() => setSuccess(false)} type="ok">
          Operadora cadastrada com sucesso
        </Message>
      )}
    </>
  );
};

export default Finish;
