import { useMemo, useState } from "react"
import { Col, Container, Row, useScreenClass } from "react-grid-system"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import history from "@/services/history"
import api from "@/infra/api"
import errorHandler from "main/utils/errorHandler"
import { pushNotification } from "@/store/modules/notification/actions"
import { useDispatch } from "react-redux"
import ModalCancel from "../../components/modalCancel"
import ModalLoading from "../../components/modalLoading"
import { Input, Button, ErrorMessage, Notification, ErrorWrapper } from "presentation/components"
import { Body, Title, SubTitle, Space, Label, FlexRight, Limited } from "./styles"

const Geral = ({ setPosition, setId, id, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(false)
  const [cancel, setCancel] = useState(false)

  const screenClass = useScreenClass()
  const dispatch = useDispatch()

  const compositeNameRegex = /\w{2,}\s\w{2,}/

  const schema = yup
    .object({
      cnpj: yup.string().required("Campo obrigatório"),
      corporateName: yup
        .string()
        .max(90, "Campo limitado a 90 caracteres")
        .matches(compositeNameRegex, {
          message: "A razão social deve ser composto",
          excludeEmptyString: true,
        })
        .required("Campo obrigatório"),
      tradeName: yup.string().max(40, "Campo limitado a 40 caracteres").required("Campo obrigatório"),
      stateRegistration: yup.string(),
      municipalRegistration: yup
        .string()
        .required("Campo obrigatório")
        .max(14, "Campo limitado a 14 caracteres")
        .test("only-letters-digits", "Apenas letras e números são aceitos", value => !value.match(/[^0-9|a-z]/gi)),
      email: yup
        .string()
        .required("Campo obrigatório")
        .email("E-mail inválido")
        .max(100, "Campo limitado a 100 caracteres"),
      site: yup.string().max(200, "Permitido máximo de 200 caracteres"),
      creditProcessingDays: yup
        .number("")
        .typeError("Dias para crédito é um campo requerido")
        .min(1, "1 dia é o valor mínimo")
        .max(15, "15 dias é o valor máximo")
        .required("Dias para crédito é um campo requerido"),
    })
    .required()

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      cnpj: props?.location?.state?.cnpj ? props.location.state.cnpj : "",
      corporateName: "",
      email: "",
      id: "",
      municipalRegistration: "",
      site: "",
      stateRegistration: "",
      tradeName: "",
      creditProcessingDays: 0,
    },
  })

  const getData = async () => {
    setLoading(true)
    try {
      const response = await api.get(`/operators/${id}/registration/general-data`)
      setValue("cnpj", response.data.cnpj)
      setValue("corporateName", response.data.corporateName)
      setValue("email", response.data.email)
      setValue("id", response.data.id)
      setValue("municipalRegistration", response.data.municipalRegistration)
      setValue("site", response.data.site)
      setValue("stateRegistration", response.data.stateRegistration)
      setValue("tradeName", response.data.tradeName)
      setValue("creditProcessingDays", response.data.creditProcessingDays)

      setLoading(false)
      setEdit(true)
    } catch (e) {
      setLoading(false)
    }
  }

  useMemo(() => {
    if (id) getData()
    // eslint-disable-next-line
  }, [id])

  const onSubmit = async data => {
    setLoading(true)

    if (!edit) {
      try {
        const numsStr = data.cnpj.replace(/[^0-9]/g, "")
        data.cnpj = numsStr
        const response = await api.post("/operators/registration/general-data", data)
        setId(response?.data)
        setLoading(false)
        setPosition(1)
      } catch (e) {
        dispatch(pushNotification(errorHandler(e?.response)))
        setLoading(false)
      }
    } else {
      try {
        await api.put(`/operators/${id}/registration/general-data`, data)
        setId(id)
        setLoading(false)
        setPosition(1)
      } catch (e) {
        dispatch(pushNotification(errorHandler(e?.response)))
        setLoading(false)
      }
    }
  }

  return (
    <>
      <Body>
        <Notification />
        <Title>Dados Gerais da Operadora</Title>
        <SubTitle>Por favor, preencha as Informações da Operadora</SubTitle>
        <Space />
        {loading && <ModalLoading open={loading} close={() => setLoading(false)} />}
        <form
          onSubmit={handleSubmit(onSubmit, () => {
            dispatch(pushNotification({ type: "warning", content: "Alguns dados obrigatórios não foram informados." }))
          })}
        >
          <Container fluid style={{ padding: 0 }}>
            <Row
              align="center"
              gutterWidth={16}
              justify={["xs", "sm", "md", "lg"].includes(screenClass) ? "center" : "start"}
            >
              <Col sm={["xs", "sm"].includes(screenClass) ? 12 : null}>
                <Label>CNPJ</Label>
                <Controller
                  control={control}
                  name="cnpj"
                  render={({ field: { onChange, value, ref } }) => (
                    <Input.Masked
                      placeholder="00.000.000/0000-00"
                      mask={""}
                      format="##.###.###/####-##"
                      value={value}
                      disabled
                      getInputRef={ref}
                      onChange={onChange}
                    />
                  )}
                />

                {errors.cnpj && <ErrorMessage>{errors.cnpj?.message}</ErrorMessage>}
              </Col>
              <Col sm={["xs", "sm"].includes(screenClass) ? 12 : null}>
                <Label>Nome Fantasia</Label>
                <Input maxLength="40" {...register("tradeName")} />
                {errors.tradeName && <ErrorMessage>{errors.tradeName?.message}</ErrorMessage>}
              </Col>
              <Col sm={["xs", "sm"].includes(screenClass) ? 12 : null}>
                <Label>Razão Social</Label>
                <Input maxLength="90" {...register("corporateName")} />
                {errors.corporateName && <ErrorMessage>{errors.corporateName?.message}</ErrorMessage>}
              </Col>
            </Row>
            <Space />
            <Row
              align="center"
              gutterWidth={16}
              justify={["xs", "sm", "md", "lg"].includes(screenClass) ? "center" : "start"}
            >
              <Col sm={["xs", "sm"].includes(screenClass) ? 12 : null}>
                <Label>Inscrição Estadual</Label>
                <Input {...register("stateRegistration")} maxLength="14" />
                {errors.stateRegistration && <ErrorMessage>{errors.stateRegistration?.message}</ErrorMessage>}
              </Col>
              <Col sm={["xs", "sm"].includes(screenClass) ? 12 : null}>
                <Label>Inscrição Municipal</Label>
                <Input maxLength="11" {...register("municipalRegistration")} />
                {errors.municipalRegistration && <ErrorMessage>{errors.municipalRegistration?.message}</ErrorMessage>}
              </Col>
              <Col sm={["xs", "sm"].includes(screenClass) ? 12 : null}>
                <Label>E-mail</Label>
                <Input maxLength="100" {...register("email")} />
                {errors.email && <ErrorMessage>{errors.email?.message}</ErrorMessage>}
              </Col>
            </Row>
            <Space />
            <Row align="center" gutterWidth={16}>
              <Col xs={12} md={4}>
                <Label>Dias para crédito</Label>
                <ErrorWrapper error={errors.creditProcessingDays}>
                  <Controller
                    control={control}
                    name="creditProcessingDays"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Input
                          placeholder="00"
                          type="number"
                          value={value}
                          onChange={e => {
                            const newValue = Number(e.target.value)
                            if (newValue >= 0 && newValue < 16) onChange(newValue)
                          }}
                        />
                      )
                    }}
                  />
                  {errors.creditProcessingDays && <ErrorMessage>{errors.creditProcessingDays.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </Row>
            <Space />
            <Row
              align="center"
              gutterWidth={16}
              justify={["xs", "sm", "md", "lg"].includes(screenClass) ? "center" : "start"}
            >
              <Col sm={["xs", "sm"].includes(screenClass) ? 12 : null}>
                <Label>Site</Label>
                <Input maxLength="200" {...register("site")} />
                {errors.site && <ErrorMessage>{errors.site?.message}</ErrorMessage>}
              </Col>
            </Row>
            <Row>
              <Col sm={["xs", "sm"].includes(screenClass) ? 12 : null}>
                <FlexRight>
                  <Limited>
                    <Button variant="outlined" type="button" onClick={() => setCancel(true)}>
                      Cancelar
                    </Button>
                    <div style={{ marginLeft: "40px" }} />
                    <Button type="submit">Continuar</Button>
                  </Limited>
                </FlexRight>
              </Col>
            </Row>
          </Container>
        </form>
      </Body>
      {cancel && (
        <ModalCancel open={cancel} close={() => setCancel(false)} confirm={() => history.push("/operadoras")} />
      )}
    </>
  )
}

export default Geral
