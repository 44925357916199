import styled from 'styled-components';
import Icon from '../icon';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Row } from 'react-grid-system';
import { BREAK_POINTS } from 'main/utils/constants';

export const HeaderContainer = styled.header`
  background-color: #4c27a0;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
`;

export const Flex = styled.div`
  display: flex;
`;

export const Border1 = styled.div`
  padding: 5px;
  background-color: #f76c39;
  width: 50%;
  z-index: -1;
`;

export const Border2 = styled.div`
  padding: 5px;
  background-color: #f76c39;
  width: 50%;
  z-index: -1;
`;

export const Center = styled.div`
  min-width: min(1440px, 100%);
  color: #fff;
`;

export const InputSearch = styled.input`
  width: 100%;
  background-color: #fff;
  border: none;
  padding: 9px;
  border-radius: 4px;
  margin-top: 7px;
`;

export const Logo = styled.img``;

export const SessionPeople = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 2px dotted #fff;
  border-right: 2px dotted #fff;
  width: 146%;
  margin-left: -30px;
`;

export const Name = styled.p`
  color: #fff;
  font-size: 12px;
  margin-left: 12px;
  white-space: nowrap;
`;

export const MenuContainer = styled.nav`
  background-color: #fff;
  border-radius: 4px;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: -37px;
  overflow: hidden;

  & > div > div > div {
    padding: 0 !important;
  }
`;

export const StyledRow = styled(Row)`
  position: relative;
  transition: all .1s linear;

  &:before{
    transition: all .5s ease-in-out;
    content: " ";
    width: 65px;
    height: 12px;
    border-radius: 8px;
    background-color: #F76C39;
    position: absolute;
    bottom: 10px;
    transform: translateX(-50%);
    left: ${({ activeoption }) => `calc(((100% / 6) * ${activeoption}) + (100% / 12))`};
    @media (max-width: ${BREAK_POINTS.sm}) {
      left: 50%;
      top: ${({ activeoption }) => `calc(((100% / 6) * ${activeoption + 1}) - 30px)`};
    }
  }
`;

export const NavLink = styled(RouterNavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  color: inherit;
  &.active {
    color: #F76C39;
    fill: #F76C39;
    position: relative;
  }
  svg{
    height: 20px;
    width: 20px;
  }
`;

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  color: inherit;

  &:hover {
    background-color: #48f06b;
    color: #fff;
  }
`;

export const Text = styled.p`
  font-weight: bold;
  color: inherit;
  white-space: nowrap;
`;

export const IconOpt = styled(Icon)`
  fill: inherit;
  height: 30px;
`;

export const LineFooter = styled.div`
  padding: 5px;
  background-color: #f76c39;
`;

export const FooterContainer = styled.footer`
  background-color: #4c27a0;
  padding: 30px 0;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  overflow: auto;
`;

export const JustiCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const CentralMotivers = styled.p`
  text-align: center;
  font-weight: var(--font-weight-bold);
  font-size: 1.125rem;
`;

export const Exit = styled.p`
  margin: 0 30px;
`;

export const Contact = styled.div`
  svg {
    margin-right: 8px;
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  div + div {
    margin-left: 40px;
  }
  a {
    color: var(--color-primary-05);
  }
`;
