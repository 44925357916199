import { ErrorMessage, ErrorWrapper, Input, Modal, Text, Spinner, Grid } from "presentation/components";
import Button from "presentation/components/buttonV2";
import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form, ModalLoadingWrapper, ModalTitle } from "./styles";
import addresses from "./schemas/addresses";
import { yupResolver } from "@hookform/resolvers/yup";
import useService from "main/hooks/useService";
import { pushNotification } from "@/store/modules/notification/actions";
import { useDispatch } from "react-redux";
import successHandler from "main/utils/successHandler";
import { addressesTypes } from "main/utils/options";
import { getAddressInformationByCEP } from "@/services/externalServices";
import useDebounce from "main/hooks/useDebouce";
import { ADDRESSES_TYPES } from "main/utils/constants";
import { putEditOperatorAddresses } from "@/services/operatorsServices";

const AddressModal = ({
  addressIndex = null,
  operator,
  onClose,
  open
}) => {
  const dispatch = useDispatch();
  const debounce = useDebounce();
  const isEditing = addressIndex !== null;
  const defaultValues = isEditing ? operator?.addresses[addressIndex] : {};
  const actualAddressInfo = useRef(null);
  const addressTypesOptions = !isEditing || operator?.addresses[addressIndex]?.addressType !== ADDRESSES_TYPES.MAIN ?
    addressesTypes.filter(option => option.value !== ADDRESSES_TYPES.MAIN) :
    addressesTypes;
  const [editAddressState, editAddressRequest] = useService(putEditOperatorAddresses, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler(`Endereço ${isEditing ? "alterado" : "adicionado"}!`)));
      onClose(true);
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
    resolver: yupResolver(addresses),
  });

  const [, fetchAddressInformationRequest] = useService(getAddressInformationByCEP, {
    onCompleted: (response) => {
      setValue("state", response.data?.uf, { shouldValidate: true });
      setValue("city", response.data?.localidade, { shouldValidate: true });
      setValue("laneName", !response.data?.logradouro ? "" : response.data.logradouro, { shouldValidate: true });
      actualAddressInfo.current = response.data;
    },
    silent: true
  });

  const onSubmit = async (data) => {
    const payload = [
      {
        cep: data.cep,
        state: data.state,
        city: data.city,
        laneName: data.laneName,
        number: data.number,
        complement: data.complement,
        addressType: data.addressType,
        name: data.name,
        id: data.id
      }
    ];
    !isEditing && delete payload[0].id;
    editAddressRequest(operator.id, payload);
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      showCloseIcon={false}
    >
      <ModalTitle>{isEditing ? "Editar Endereço" : "Adicionar Endereço"}</ModalTitle>
      <Form onSubmit={handleSubmit(onSubmit)} id="operator">
        <Grid fluid padding="0" spaceBetweenRows="32px">
          {editAddressState.loading ?
            <Grid.Row>
              <Grid.Col>
                <ModalLoadingWrapper>
                  <Spinner />
                </ModalLoadingWrapper>
              </Grid.Col>
            </Grid.Row> :
            <>
              <Grid.Row>
                <Grid.Col xs={12}>
                  <ErrorWrapper error={errors.cep}>
                    <Text.Label>CEP</Text.Label>
                    <Controller
                      control={control}
                      name={`cep`}
                      render={({ field: { onChange, value, ref } }) => (
                        <Input.Masked
                          value={value}
                          format="#####-###"
                          mask=""
                          placeholder="00000-000"
                          getInputRef={ref}
                          onChange={(e) => {
                            onChange(e);
                            debounce(() => fetchAddressInformationRequest(e.target.value), 500);
                          }}
                        />
                      )}
                    />
                    {errors.cep && (
                      <ErrorMessage>{errors.cep.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col sm={6} >
                  <ErrorWrapper error={errors.laneName}>
                    <Text.Label>Endereço</Text.Label>
                    <Input
                      placeholder="Endereço"
                      maxLength="120"
                      disabled={!!actualAddressInfo.current?.logradouro}
                      {...register("laneName")}
                    />
                    {errors.laneName && (
                      <ErrorMessage>{errors.laneName.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.tradeName}>
                    <Text.Label>N° / Logradouro</Text.Label>
                    <Input
                      maxLength="5"
                      placeholder="000"
                      {...register("number")}
                    />
                    {errors.tradeName && (
                      <ErrorMessage>{errors.tradeName.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.city}>
                    <Text.Label>Cidade</Text.Label>
                    <Input
                      placeholder="Cidade"
                      disabled
                      {...register("city")}
                    />
                    {errors.city && (
                      <ErrorMessage>{errors.city}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.state}>
                    <Text.Label>Estado</Text.Label>
                    <Input
                      placeholder="Estado"
                      disabled={true}
                      {...register("state")}
                    />
                    {errors.state && (
                      <ErrorMessage>{errors.state}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.complement}>
                    <Text.Label>Complemento</Text.Label>
                    <Input
                      placeholder="Nº apartamento, bloco..."
                      maxLength="90"
                      {...register("complement")}
                    />
                    {errors.complement && (
                      <ErrorMessage>{errors.complement}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.name}>
                    <Text.Label>Nome do endereço</Text.Label>
                    <Input
                      maxLength="30"
                      placeholder="Filial, Sede..."
                      {...register("name")}
                    />
                    {errors.name && (
                      <ErrorMessage>{errors.name.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.addressType}>
                    <Text.Label>Tipo de Endereço</Text.Label>
                    <Controller
                      control={control}
                      name="addressType"
                      render={({ field: { onChange, value, ref } }) => (
                        <Input.Select
                          placeholder="Selecione"
                          value={addressTypesOptions.find(option => option.value === value)}
                          inputRef={ref}
                          onChange={e => onChange(e.value)}
                          options={addressTypesOptions}
                          isClearable={false}
                          isDisabled={value === ADDRESSES_TYPES.MAIN}
                        />
                      )}
                    />
                    {errors.addressType && (
                      <ErrorMessage>{errors.addressType.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row justify="between">
                <Grid.Col sm="content">
                  <Button
                    onClick={() => onClose(false)}
                    variant="text"
                  >Cancelar</Button>
                </Grid.Col>
                <Grid.Col sm="content">
                  <Button>Salvar</Button>
                </Grid.Col>
              </Grid.Row>
            </>
          }
        </Grid>
      </Form>
    </Modal >
  );
}

export default AddressModal;