import { ErrorMessage, ErrorWrapper, Input, Modal, Text, Spinner, Grid } from "presentation/components";
import Button from "presentation/components/buttonV2";
import React, { useRef } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { EntrieWrapper, Form, ModalLoadingWrapper, ModalTitle } from "./styles";
import buyingRules from "./schemas/buyingRules";
import { yupResolver } from "@hookform/resolvers/yup";
import useService from "main/hooks/useService";
import { pushNotification } from "@/store/modules/notification/actions";
import { useDispatch } from "react-redux";
import successHandler from "main/utils/successHandler";
import { operatorCardBuyingRulesTypes } from "main/utils/options";
import { OPERATOR_BUYING_RULE_TYPES } from "main/utils/constants";
import { EntriesCounter } from "../../styles";
import { FaRegTrashAlt } from "react-icons/fa";
import { deleteOperatorBuyingRule, putEditOperatorBuyingRules } from "@/services/operatorsServices";
const BuyingRulesModal = ({
  operator,
  onClose,
  isEditing,
  open
}) => {
  const dispatch = useDispatch();
  const defaultValues = isEditing ? { buyingRules: operator.buyingRules } : { buyingRules: [{}] };
  const deletingRuleIndex = useRef(null);
  const hadDeleted = useRef(false);
  const [editBuyingRuleState, editBuyingRuleRequest] = useService(putEditOperatorBuyingRules, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler(`Regra de compra ${isEditing ? "alterada" : "adicionada"}!`)));
      onClose(true);
    },
  });
  const [deleteBuyingRuleState, deleteBuyingRuleRequest] = useService(deleteOperatorBuyingRule, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler(`Regra removida`)));
      remove(deletingRuleIndex.current);
      deletingRuleIndex.current = null;
      hadDeleted.current = true;
    },
  });

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty }
  } = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
    resolver: yupResolver(buyingRules),
  });

  const { fields, remove } = useFieldArray({
    control,
    name: "buyingRules",
    keyName: "formId"
  });

  /* const filterAvailableOptions = () => {
    const actualFormData = isEditing ? getValues() : { buyingRules: [...operator.buyingRules, ...getValues().buyingRules] };
    const inUseOptions = actualFormData.buyingRules.reduce((inUseValues, field) => {
      inUseValues.push(field.type);
      return inUseValues;
    }, []);
    const filteredAvailableOptions = operatorCardBuyingRulesTypes.filter(rule => !inUseOptions.includes(rule.value));
    setAvailableOptions(filteredAvailableOptions);
  }; */

  const removeRule = (e, index, ruleId) => {
    e.preventDefault();
    deletingRuleIndex.current = index;
    deleteBuyingRuleRequest(ruleId);
  };

  const renderArrayFields = () => {
    const actualFormData = getValues();
    return fields.map((field, index) => {
      const hasError = errors.buyingRules?.[index] || {};
      return (
        <React.Fragment key={field.formId}>
          <Grid.Row align="end">
            <Grid.Col sm={6}>
              <EntrieWrapper>
                {isEditing &&
                  <>
                    <EntriesCounter>{index + 1}.</EntriesCounter>
                    <FaRegTrashAlt onClick={(e) => removeRule(e, index, field.id)} />
                  </>
                }
              </EntrieWrapper>
              <ErrorWrapper error={hasError.type}>
                <Text.Label>Tipo de regra</Text.Label>
                <Controller
                  control={control}
                  name={`buyingRules.${index}.type`}
                  render={({ field: { onChange, value, ref } }) => (
                    <Input.Select
                      placeholder="Selecione"
                      value={operatorCardBuyingRulesTypes.find(option => option.value === value)}
                      inputRef={ref}
                      onChange={e => {
                        onChange(e.value);
                      }}
                      options={operatorCardBuyingRulesTypes}
                      isClearable={false}
                    />
                  )}
                />
                {hasError.type && (
                  <ErrorMessage>{hasError.type.message}</ErrorMessage>
                )}
              </ErrorWrapper>
            </Grid.Col>
            <Grid.Col sm={6}>
              <ErrorWrapper error={hasError.value}>
                <Text.Label>{actualFormData.buyingRules[index].type === OPERATOR_BUYING_RULE_TYPES.RECHARGE ? "Dias para Crédito" : "Prazo para coleta"}</Text.Label>
                <Controller
                  control={control}
                  name={`buyingRules.${index}.value`}
                  render={({ field: { onChange, value, ref } }) => (
                    <Input.Masked
                      allowNegative={false}
                      value={value}
                      getInputRef={ref}
                      onChange={onChange}
                    />
                  )}
                />
                {hasError.value && (
                  <ErrorMessage>{hasError.value.message}</ErrorMessage>
                )}
              </ErrorWrapper>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col sm={6}>
              <ErrorWrapper error={hasError.minimumPurchase}>
                <Text.Label>Valor mínimo</Text.Label>
                <Controller
                  control={control}
                  name={`buyingRules.${index}.minimumPurchase`}
                  render={({ field: { onChange, value, ref } }) => (
                    <Input.Decimal
                      allowNegative={false}
                      value={value}
                      placeholder="R$ 00,00"
                      getInputRef={ref}
                      prefix="R$ "
                      onChange={onChange}
                    />
                  )}
                />
                {hasError.minimumPurchase && (
                  <ErrorMessage>{hasError.minimumPurchase.message}</ErrorMessage>
                )}
              </ErrorWrapper>
            </Grid.Col>
            <Grid.Col sm={6}>
              <ErrorWrapper error={hasError.maximumPurchase}>
                <Text.Label>Valor máximo</Text.Label>
                <Controller
                  control={control}
                  name={`buyingRules.${index}.maximumPurchase`}
                  render={({ field: { onChange, value, ref } }) => (
                    <Input.Decimal
                      allowNegative={false}
                      value={value}
                      placeholder="R$ 00,00"
                      getInputRef={ref}
                      prefix="R$ "
                      onChange={onChange}
                    />
                  )}
                />
                {hasError.maximumPurchase && (
                  <ErrorMessage>{hasError.maximumPurchase.message}</ErrorMessage>
                )}
              </ErrorWrapper>
            </Grid.Col>
          </Grid.Row>
        </React.Fragment>
      );
    });
  };

  const brlStringToNumber = (brlString) => {
    const cleanedString = brlString
      .replaceAll(".", "")
      .replaceAll(",", ".")
      .replaceAll("R$ ", "");
    return Number(cleanedString);
  }

  const onSubmit = async (data) => {
    if(!isDirty){
      onClose();
      return;
    }
    const payload = data.buyingRules.map(rule => ({
      type: rule.type,
      minimumPurchase: brlStringToNumber(rule.minimumPurchase),
      maximumPurchase: brlStringToNumber(rule.maximumPurchase),
      value: Number(rule.value),
      id: rule.id
    }))
    if (!isEditing) delete payload[0].id;
    editBuyingRuleRequest(operator.id, payload);
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose(hadDeleted.current)}
      showCloseIcon={false}
    >
      <ModalTitle>{isEditing ? "Editar regras" : "Adicionar regra"} de compra</ModalTitle>
      <Form onSubmit={handleSubmit(onSubmit)} id="operator">
        <Grid fluid padding="0" spaceBetweenRows="32px">
          {editBuyingRuleState.loading || deleteBuyingRuleState.loading ?
            <Grid.Row>
              <Grid.Col>
                <ModalLoadingWrapper>
                  <Spinner />
                </ModalLoadingWrapper>
              </Grid.Col>
            </Grid.Row> :
            <>
              {renderArrayFields()}
              <Grid.Row justify="between">
                <Grid.Col sm="content">
                  <Button
                    onClick={() => onClose(hadDeleted.current)}
                    variant="text"
                  >Cancelar</Button>
                </Grid.Col>
                <Grid.Col sm="content">
                  <Button>Salvar</Button>
                </Grid.Col>
              </Grid.Row>
            </>
          }
        </Grid>
      </Form>
    </Modal >
  );
}

export default BuyingRulesModal;