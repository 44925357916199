import { useRef } from "react";
import {
  CloseIcon,
  Message,
  MessageContainer,
  MessageTitle,
  NotificationMessageBody,
  TimeAnimation,
  TypeIdentifier
} from "./styles";

import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineExclamationCircle,
} from "react-icons/ai"

import { NOTIFICATION_TYPE } from "main/utils/constants";
import { restartCSSAnimation } from "main/utils/functionUtils";

const NotificationMessage = ({
  children,
  notificationType,
  title,
  onClose,
  waitTime
}) => {
  const animationElement = useRef();

  const standardTitle = {
    "success": "Sucesso",
    "error": "Erro",
    "warning": "Aviso",
  }

  const chooseIcon = () => {
    if (notificationType === NOTIFICATION_TYPE.ERROR) return < AiOutlineCloseCircle />
    else if (notificationType === NOTIFICATION_TYPE.SUCCESS) return <AiOutlineCheckCircle />
    else if (notificationType === NOTIFICATION_TYPE.WARNING) return <AiOutlineExclamationCircle />
  }

  const chooseColor = () => {
    if (notificationType === NOTIFICATION_TYPE.ERROR) return "var(--color-message-03)"
    else if (notificationType === NOTIFICATION_TYPE.SUCCESS) return "var(--color-message-01)"
    else if (notificationType === NOTIFICATION_TYPE.WARNING) return "var(--color-message-02)"
  }

  return (
    <NotificationMessageBody>
      <TypeIdentifier color={chooseColor()}>
        {chooseIcon()}
      </TypeIdentifier>
      <CloseIcon onClick={() => {
        onClose();
        restartCSSAnimation(animationElement.current);
      }} />
      <MessageContainer>
        <MessageTitle>
          {title ?? standardTitle[notificationType]}
        </MessageTitle>
        <Message>{children}</Message>
      </MessageContainer>
      {waitTime && <TimeAnimation ref={animationElement} color={chooseColor()} animationTime={waitTime} />}
    </NotificationMessageBody>
  )
};

export default NotificationMessage;