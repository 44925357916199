import { useState } from "react";
import ReactSwitch from "react-switch";
import HoverText from "./hoverText";
import { Body } from "./styles";

const Switch = ({
  checked = false, 
  onChange,
  hover,
  onText,
  offText,
}) => {
  const [isHoverVisible, setIsHoverVisible] = useState(false);
  return (
    <Body 
      checked={checked} 
      onMouseEnter={() => setIsHoverVisible(true)}
      onMouseLeave={() => setIsHoverVisible(false)}
    >
      {hover && <HoverText visible={isHoverVisible} hover={checked ? onText : offText}/>}
      <ReactSwitch
        checked={checked}
        onChange={onChange}
        onColor="#E02E00"
        offColor="#8FEF91"
        onHandleColor="#FFF"
        offHandleColor="#FFF"
        activeBoxShadow="none"
        handleDiameter={18}
        uncheckedIcon={false}
        checkedIcon={false}
        height={26}
        width={58}
        className="react-switch"
        id="material-switch"
      />
    </Body>
  )
};

export default Switch;