import styled from "@emotion/styled"

const Title = styled.h4`
  font-size: 18px;
  font-weight: 500;
`

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 6px;
`

const Input = styled.input`
  background-color: rgba(243, 246, 249, 1);
  height: 48px;
  border: 0;
  padding: 4px 8px;
`

const TextArea = styled.textarea`
  padding: 8px;
  background-color: rgba(243, 246, 249, 1);
  border: 0;
`

const Description = styled.small`
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
`

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
`

const ContentCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px 0;
`

const CheckBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;

  input {
    &[type="checkbox"] {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    &[type="radio"] {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: rgba(33, 33, 33, 1);
  }
`

const ContentSteps = styled.div`
  margin-bottom: 26px;
`

const ContentActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  padding: 24px 0;

  button {
    width: 127px;
    height: 40px;
    padding: 8px 22px 8px 22px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    border-radius: 4px;

    &:first-child {
      color: rgba(158, 158, 158, 1);
      border: 1px solid rgba(158, 158, 158, 1);
    }

    &:last-child {
      background: rgba(25, 118, 210, 1);
      color: #fff;
      border: 0;
    }
  }
`

const Required = styled.div`
  position: relative;
  width: fit-content;
  &:after{
    content: "*";
    position: absolute;
    top: 0;
    right: -8px;
    color:red;
  }
`

export const NewLayoutStyles = {
  Title,
  Content,
  Label,
  Input,
  TextArea,
  ContentCheckBox,
  Description,
  CheckBox,
  ContentActions,
  ContentSteps,
  Form,
  Required,
}
