import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { Grid, Panel, Content, PageHeader } from "@/presentation/components"

import { schema } from "./utils/schema"
import history from "@/services/history"
import Steps from "@/presentation/components/steps"

import { NewLayoutStyles } from "./styles"
import { useGeneralData } from "./hooks/useGeneralData"

const steps = [
  {
    name: "Dados gerais",
    navigable: false,
  },
  {
    name: "Solicitação de campos",
    navigable: false,
  },
  {
    name: "Operadora e exportação",
    navigable: false,
  },
]

export const NewLayout = () => {
  const { generalData } = useGeneralData()

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  })

  return (
    <Content>
      <Panel noPadding>
        <Grid padding="16px 16px 0 16px">
          <Grid.Row align="center" justify="between">
            <Grid.Col md={12} lg="content">
              <PageHeader>Layout de planilhas</PageHeader>
            </Grid.Col>
          </Grid.Row>
        </Grid>
        <Grid padding="16px 16px 0 16px">
          <Grid.Row align="center" justify="between">
            <Grid.Col md={12}>
              <NewLayoutStyles.ContentSteps>
                <Steps currentStep={1} steps={steps} />
              </NewLayoutStyles.ContentSteps>
            </Grid.Col>
            <NewLayoutStyles.Form onSubmit={handleSubmit(generalData)} id="generalData">
              <Grid.Col md={12}>
                <NewLayoutStyles.Content>
                  <NewLayoutStyles.Title style={{ marginBottom: '8px' }}>Informações</NewLayoutStyles.Title>
                  <NewLayoutStyles.Required>
                    <NewLayoutStyles.Label>Título</NewLayoutStyles.Label>
                  </NewLayoutStyles.Required>
                  <NewLayoutStyles.Input {...register("title")} maxLength={100} />
                  <NewLayoutStyles.Label>Descrição</NewLayoutStyles.Label>
                  <NewLayoutStyles.TextArea rows={8} {...register("description")} maxLength={200} />
                  <NewLayoutStyles.ContentCheckBox>
                    <NewLayoutStyles.Required>
                      <NewLayoutStyles.Title>Local de ação</NewLayoutStyles.Title>
                    </NewLayoutStyles.Required>
                    <NewLayoutStyles.Description>Selecione onde o layout será utilizado</NewLayoutStyles.Description>
                    <NewLayoutStyles.CheckBox>
                      <input type="checkbox" {...register("localAction")} />
                      <p>Layout Recarga</p>
                    </NewLayoutStyles.CheckBox>
                  </NewLayoutStyles.ContentCheckBox>
                  <NewLayoutStyles.ContentCheckBox>
                    <NewLayoutStyles.Required>
                      <NewLayoutStyles.Title>Tipo de Benefício</NewLayoutStyles.Title>
                    </NewLayoutStyles.Required>
                    <NewLayoutStyles.Description>
                      Ao selecionar o tipo(s), alguns campos para exportação serão automaticamente aplicados como
                      obrigatórios, além dos campos relativos aos valores de cada benefício.
                    </NewLayoutStyles.Description>
                    <Grid.Row align="center" justify="between">
                      <Grid.Col md={3}>
                        <NewLayoutStyles.CheckBox>
                          <input type="checkbox" />
                          <p>Vale Alimentação</p>
                        </NewLayoutStyles.CheckBox>
                      </Grid.Col>
                      <Grid.Col md={9}>
                        <NewLayoutStyles.CheckBox>
                          <input type="checkbox" />
                          <p>Vale Transporte eletronico</p>
                        </NewLayoutStyles.CheckBox>
                      </Grid.Col>
                      <Grid.Col md={6}>
                        <NewLayoutStyles.CheckBox>
                          <input type="checkbox" />
                          <p>Vale Refeição</p>
                        </NewLayoutStyles.CheckBox>
                      </Grid.Col>
                    </Grid.Row>
                  </NewLayoutStyles.ContentCheckBox>
                  <NewLayoutStyles.ContentCheckBox>
                    <NewLayoutStyles.Required>
                      <NewLayoutStyles.Title>Tecnologia </NewLayoutStyles.Title>
                    </NewLayoutStyles.Required>
                    <NewLayoutStyles.Description>Selecione a tecnologia que vai ser usada</NewLayoutStyles.Description>
                    <Grid.Row>
                      <Grid.Col md={3}>
                        <NewLayoutStyles.CheckBox>
                          <input type="radio" name='technology' value={0} />
                          <p>Tacom</p>
                        </NewLayoutStyles.CheckBox>
                      </Grid.Col>
                      <Grid.Col md={9}>
                        <NewLayoutStyles.CheckBox>
                          <input type="radio" name='technology' value={1} />
                          <p>ProData</p>
                        </NewLayoutStyles.CheckBox>
                      </Grid.Col>
                      <Grid.Col md={3}>
                        <NewLayoutStyles.CheckBox>
                          <input type="radio" name='technology' value={2} />
                          <p>Pat</p>
                        </NewLayoutStyles.CheckBox>
                      </Grid.Col>
                      <Grid.Col md={9}>
                        <NewLayoutStyles.CheckBox>
                          <input type="radio" name='technology' value={3} />
                          <p>SPData</p>
                        </NewLayoutStyles.CheckBox>
                      </Grid.Col>
                      <Grid.Col md={6}>
                        <NewLayoutStyles.CheckBox>
                          <input type="radio" name='technology' value={4} />
                          <p>Empresa 1</p>
                        </NewLayoutStyles.CheckBox>
                      </Grid.Col>
                    </Grid.Row>
                  </NewLayoutStyles.ContentCheckBox>
                </NewLayoutStyles.Content>
              </Grid.Col>
              <Grid.Col md={12}>
                <NewLayoutStyles.ContentActions>
                  <button onClick={() => history.push("/layout")}>Cancelar</button>
                  <button type="submit" form="generalData">
                    Próximo
                  </button>
                </NewLayoutStyles.ContentActions>
              </Grid.Col>
            </NewLayoutStyles.Form>
          </Grid.Row>
        </Grid>
      </Panel>
    </Content>
  )
}
