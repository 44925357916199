import Modal from 'react-modal';
import Button from 'presentation/components/buttonV2';
import { Body, Title, Text, Flex } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ModalCancel = ({ open, close, confirm }) => {
  return (
    <Modal isOpen={open} onRequestClose={close} style={customStyles}>
      <Body>
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          style={{ fontSize: '50px', color: '#fccd2f' }}
        />
        <Title>PERDA DE DADOS</Title>
        <Text>
          Atenção, ao sair da edição os dados desta etapa serão <br />
          perdidos <br />
          <br />
        </Text>
        <Flex>
          <Button variant="text" onClick={() => close()}>
            Cancelar
          </Button>
          <div style={{ marginLeft: '40px' }} />
          <Button type="submit" onClick={() => confirm()}>
            Continuar
          </Button>
        </Flex>
      </Body>
    </Modal>
  );
};

export default ModalCancel;
