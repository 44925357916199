import { BENEFIT_TYPES } from "main/utils/constants";
import * as yup from "yup";

const schema = yup.object().shape({
  benefit: yup
    .number()
    .required("Tipo de benefício é um campo requerido"),
  operatorCardId: yup
    .string()
    .when(
      "benefit", {
      is: value => value === BENEFIT_TYPES.TRANSPORT,
      then: yup
        .string()
        .required("Cartão vinculado é um campo requerido"),
    }),
  description: yup
    .string()
    .max(40)
    .required("Descrição é um campo requerido"),
  value: yup
    .number()
    .required("Valor é um campo requerido"),
  frequency: yup
    .number()
    .required("Períodicidade é um campo requerido"),
});

export default schema;