export function signInRequest(email, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  };
}

export function signInSuccess(token, refresh, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, refresh, user },
  };
}

export function signFailure(response) {
  return {
    type: '@auth/SIGN_FAILURE',
    payload: { response },
  };
}

export function signInRejection() {
  return {
    type: '@auth/SIGN_IN_REJECTION',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}
