import { FloatingBox, Triangle } from './styles';
import React from 'react';

const HoverText = React.forwardRef(({ hover, ...props }, ref) => {
  return (
    <FloatingBox ref={ref} {...props}>
      {hover}
      <Triangle />
    </FloatingBox>
  );
});

export default HoverText;
