import { faExclamationCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Button, Dialog } from "@stationkim/components-lib";
import {
  ButtonLine,
  ModalContent,
  ModalIcon,
  ModalIconFrame,
  StyledDialogDescription,
  StyledDialogTitle
} from "./styles";

export const ModalResaleActivateDeactivate = ({
  open,
  isActiving,
  onClose,
  onConfirm
}) => {
  return (
    <Dialog.Root
      open={open}
    >
      <Dialog.Content >
        <ModalContent>
          <ModalIconFrame>
            <ModalIcon
              icon={isActiving ? faExclamationCircle : faExclamationTriangle}
              css={{ color: isActiving ? '$atention200' : '$error200' }}
            />
          </ModalIconFrame>
          <StyledDialogTitle
            css={{ color: isActiving ? '$atention200' : '$error200' }}
          >
            ATENÇÃO
          </StyledDialogTitle>
          {isActiving ?
            <StyledDialogDescription>
              Ao reativar uma revenda as atividades serão liberadas<br />
              para seu funcionamento padrão.<br />
              <br />
              Caso deseje prosseguir, clique em <strong>&quot;confirmar&quot;</strong>.
            </StyledDialogDescription>
            :
            <StyledDialogDescription>
              Ao inativar uma revenda, todas as ações ficarão<br />
              bloqueadas até que ela seja reativada e seus dados<br />
              permanecerão no registro.<br />
              <br />
              Caso deseje prosseguir, clique em <strong>&quot;confirmar&quot;</strong>.
            </StyledDialogDescription>
          }
          <ButtonLine>
            <Button buttonType="text" onClick={onClose}>Cancelar</Button>
            <Button
              buttonType="outlined"
              color={isActiving ? "atention" : "error"}
              onClick={onConfirm}
            >
              Confirmar
            </Button>
          </ButtonLine>
        </ModalContent>
      </Dialog.Content>
    </Dialog.Root>
  );
};