import { Box, MenuItem } from "@stationkim/front-ui"
import OperatorStatus from "../../components/operatorStatus"
import { OPERATORS_STATUS } from "@/main/utils/constants"
import { applyMask } from "@/main/utils/functionUtils"
import { ActionsMenu } from "../../components/actionsMenu"

export const getTableConfiguration = ({ updateFilter, onEdit }) => {
  return (
    [
      {
        columnDisplayName: 'Cód. Operadora',
        columnValue: ({ row }) => row.code,
        onOrder: ({ order, }) => updateFilter({ sort: 'code', desc: order })
      },
      {
        columnDisplayName: 'Nome',
        columnValue: ({ row }) => row.tradeName,
        onOrder: ({ order }) => updateFilter({ sort: 'tradeName', desc: order })
      },
      {
        columnDisplayName: 'CNPJ',
        columnValue: ({ row }) => applyMask('##.###.###/####-##', row.cnpj),
        onOrder: ({ order }) => updateFilter({ sort: 'cnpj', desc: order })
      },
      {
        columnDisplayName: 'Status',
        columnValue: ({ row }) => row.status,
        onOrder: ({ order }) => updateFilter({ sort: 'status', desc: order }),
        customRender: ({ row }) => (
          <Box sx={{ display: 'flex', justifyContent: 'start' }}>
            <OperatorStatus status={row.status}>
              {OPERATORS_STATUS[row.status]}
            </OperatorStatus>
          </Box>
        )
      },
      {
        columnDisplayName: 'Ações',
        columnValue: ({ row }) => null,
        preventLink: true,
        customRender: ({ row }) => {
          return (
            row.status !== OPERATORS_STATUS.PENDING &&
            <ActionsMenu  >
              <MenuItem onClick={() => onEdit(row.id)}>Editar</MenuItem>
            </ActionsMenu>
          )
        }
      },
    ]
  )
}