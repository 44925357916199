import { Grid, IconButton, Spinner, StatusTag, Switch, Text } from "presentation/components";
import { applyMask, returnValueOrMessageIfIsEmpty } from "main/utils/functionUtils";
import { useState } from "react";
import { CategorieTitleWrapper, OperatorInformation } from "../styles";
import GeneralDataModal from "./editionModals/generalDataModal";
import { FaPencilAlt } from "react-icons/fa";
import { OPERATORS_STATUS } from "main/utils/constants";
import useService from "main/hooks/useService";
import { useDispatch } from "react-redux";
import { pushNotification } from "@/store/modules/notification/actions";
import successHandler from "main/utils/successHandler";
import { ModalResaleActivateDeactivate } from "../../modals/modalResaleActivateDeactivate";
import { putActivateDeactivateResale } from "@/services/resalesServices";

const checkStatusColor = (status) => {
  if (status === 0) return 'yellow';
  if (status === 1) return 'green';
  if (status === 2) return 'red';
}
const emptyMessage = "Não informado";

const GeneralData = ({
  resaleData,
  onEdited = () => null,
  isReviewing,
  onReview,
}) => {

  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [isChangingStatus, setIsChangingStatus] = useState(false);
  const isResaleActive = resaleData.status === OPERATORS_STATUS.ACTIVE;
  const [changeStatusState, changeStatusRequest] = useService(putActivateDeactivateResale, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler(`Revenda ${isResaleActive ? "inativada" : "ativada"}!`)));
      onEdited();
    },
  });

  return (
    <>
      <Grid.Row align="center" justify="between">
        <Grid.Col >
          <CategorieTitleWrapper>
            <Text.Subtitle>Dados Gerais da Revenda</Text.Subtitle>
            <IconButton
              onClick={() => isReviewing ? onReview() : setIsEditing(true)}
            >
              <FaPencilAlt />
            </IconButton>
          </CategorieTitleWrapper>
        </Grid.Col>
        {isReviewing ? null :
          <>
            <Grid.Col sm="content">
              {changeStatusState.loading ? <Spinner variant="sm" /> :
                <Switch
                  onChange={() => setIsChangingStatus(true)}
                  checked={resaleData.status === OPERATORS_STATUS.INACTIVE}
                />
              }
            </Grid.Col>
            <Grid.Col xs={12} sm="content">
              <StatusTag
                color={checkStatusColor(resaleData?.status)}
              >
                {OPERATORS_STATUS[resaleData?.status]}
              </StatusTag>
            </Grid.Col>
          </>
        }
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={6} md={3} lg={3}>
          <Text.SubtitleTwo>CNPJ</Text.SubtitleTwo>
          <OperatorInformation>
            {returnValueOrMessageIfIsEmpty(applyMask("##.###.###/####-##", resaleData.cnpj), emptyMessage)}
          </OperatorInformation>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={6} md={3} lg={3}>
          <Text.SubtitleTwo>Razão Social</Text.SubtitleTwo>
          <OperatorInformation>{returnValueOrMessageIfIsEmpty(resaleData.corporateName, emptyMessage)}</OperatorInformation>
        </Grid.Col>
        <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
          <Text.SubtitleTwo>Nome Fantasia</Text.SubtitleTwo>
          <OperatorInformation>{returnValueOrMessageIfIsEmpty(resaleData.tradeName, emptyMessage)}</OperatorInformation>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={6} md={3} lg={3}>
          <Text.SubtitleTwo>Inscrição estadual</Text.SubtitleTwo>
          <OperatorInformation>{returnValueOrMessageIfIsEmpty(resaleData.stateRegistration, emptyMessage)}</OperatorInformation>
        </Grid.Col>
        <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
          <Text.SubtitleTwo>E-mail</Text.SubtitleTwo>
          <OperatorInformation>{returnValueOrMessageIfIsEmpty(resaleData.email, emptyMessage)}</OperatorInformation>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={6} md={3} lg={3}>
          <Text.SubtitleTwo>Inscrição municipal</Text.SubtitleTwo>
          <OperatorInformation>{returnValueOrMessageIfIsEmpty(resaleData.municipalRegistration, emptyMessage)}</OperatorInformation>
        </Grid.Col>
        <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
          <Text.SubtitleTwo>Site</Text.SubtitleTwo>
          <OperatorInformation>
            {returnValueOrMessageIfIsEmpty(
              resaleData.site ?
                `https://${resaleData.site}.stationkim.com/` : null,
              emptyMessage
            )}
          </OperatorInformation>
        </Grid.Col>
      </Grid.Row>
      <GeneralDataModal
        open={isEditing}
        resaleData={resaleData}
        onClose={(hasEdited) => {
          setIsEditing(false);
          hasEdited && onEdited();
        }}
      />
      <ModalResaleActivateDeactivate
        open={isChangingStatus}
        isActiving={!isResaleActive}
        onConfirm={() => changeStatusRequest(resaleData.id, !isResaleActive)}
        onClose={() => {
          setIsChangingStatus(false);
        }}
      />
    </>
  );

};

export default GeneralData;