import { useState, useMemo } from 'react';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import api from '@/infra/api';
import errorHandler from 'main/utils/errorHandler';
import { pushNotification } from '@/store/modules/notification/actions';
import { useDispatch } from 'react-redux';
import { states } from 'main/utils/states';
import Modal from '../../components/modalCancel';
import axios from 'axios';
import ModalLoading from '../../components/modalLoading';
import { Input, Button, ErrorMessage, Notification, Icon } from 'presentation/components';
import {
  Body,
  Title,
  SubTitle,
  Space,
  Label,
  FlexRight,
  Limited,
  TextMessage,
  SessionAddAddress,
  IconAdd,
  TextAdd,
} from './styles';
import { ADDRESSES_TYPES, NOTIFICATION_TYPE } from 'main/utils/constants';

const Address = ({ setPosition, id }) => {
  const [loading, setLoading] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [ids, setIds] = useState([]);

  const screenClass = useScreenClass();
  const dispatch = useDispatch();

  const typesAddress = [
    { label: 'Principal', value: 1 },
    { label: 'Coleta de Bilhete', value: 2 },
    { label: 'Coleta de Cartão', value: 3 },
    { label: 'Outro', value: 4 },
  ];

  const schemaAddress = yup.array().of(
    yup.object().shape({
      cep: yup.string().required('Campo obrigatório'),
      addressCode: yup.string(),
      state: yup.string().required('Campo obrigatório'),
      city: yup
        .string()
        .required('Campo obrigatório')
        .max(50, 'Permitido máximo de 50 caracteres'),
      neighborhood: yup
        .string()
        .nullable(true)
        .max(50, 'Permitido máximo de 50 caracteres'),
      laneName: yup
        .string()
        .required('Campo obrigatório')
        .max(120, 'Permitido máximo de 120 caracteres '),
      number: yup
        .string()
        .required('Campo obrigatório')
        .max(5, 'Permitido máximo de 5 caracteres'),
      complement: yup.string().max(30, 'Permitido máximo de 30 caracteres'),
      name: yup.string().max(30, 'Permitido máximo de 30 caracteres'),
      addressType: yup
        .number()
        .integer()
        .required('Campo obrigatório')
        .strict(),
    })
  );

  const schema = yup.object().shape({
    abortEarly: false,
    addressess: schemaAddress,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      addressess: [{}],
    },
  });

  const addressFieldArray = useFieldArray({
    control: control,
    name: 'addressess',
  });

  const getAddressCep = async (data, index) => {
    if (data.length > 0) {
      const cep = data.replace(/[^0-9]/g, '');
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      if (response.data) {
        setValue(`addressess.${index}.city`, response.data.localidade);
        setValue(`addressess.${index}.cep`, cep);
        setValue(`addressess.${index}.laneName`, response.data.logradouro);
        setValue(
          `addressess.${index}.complement`,
          response.data.complement ? response.data.complement : ''
        );
        setValue(`addressess.${index}.state`, response.data.uf);
      }
    }
  };

  const onSubmit = async (data) => {
    const mainAddresses = data.addressess.filter((address) => address.addressType === ADDRESSES_TYPES.MAIN);
    const hasMainAddress = mainAddresses.length > 0;
    const hasMoreThanOneMainAddress = mainAddresses.length > 1;

    if (!hasMainAddress) {
      dispatch(pushNotification({
        type: NOTIFICATION_TYPE.ERROR,
        content: "É necessário ter ao menos um endereço principal."
      }));
      return;
    };
    if (hasMoreThanOneMainAddress) {
      dispatch(pushNotification({
        type: NOTIFICATION_TYPE.ERROR,
        content: "Só é possível ter um endereço principal."
      }));
      return;
    };
    try {
      setLoading(true);
      await api.post(`/operators/registration/addresses?id=${id}`, data.addressess);

      setLoading(false);
      setPosition(2);
    } catch (e) {
      dispatch(pushNotification(errorHandler(e?.response)));
      setLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/operators/${id}/registration/addresses`);

      if (response?.data?.length) {
        addressFieldArray.remove(0);

        const reverseArray = await response.data.reverse();

        let arrayIds = [];

        // eslint-disable-next-line
        await reverseArray.map((values) => {
          arrayIds.push({ id: values.id });
          addressFieldArray.append({
            cep: values.cep,
            state: values.state,
            city: values.city,
            id: values.id,
            neighborhood: values.neighborhood,
            laneName: values.laneName,
            number: values.number,
            complement: values.complement,
            name: values.name,
            addressType: values.addressType,
          });
        });
        setIds(arrayIds);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useMemo(() => {
    if (id) getData();
    // eslint-disable-next-line
  }, [id]);

  const removeLine = async (index) => {
    const idLine = ids[index];
    setLoading(true);

    if (idLine?.id) {
      try {
        await api.delete(
          `operators/registration/addresses/${idLine.id}?operatorId=${id}`
        );
        addressFieldArray.remove(index);
        setLoading(false);
        return;
      } catch (e) {
        dispatch(pushNotification(errorHandler(e?.response)));
        setLoading(false);
      }
    } else {
      addressFieldArray.remove(index);
      setLoading(false);
    }
  };

  return (
    <Body>
      <Notification />
      <Title>Endereço</Title>
      <SubTitle>Informe o Endereço Principal da Operadora</SubTitle>
      <Space />
      {loading && (
        <ModalLoading open={loading} close={() => setLoading(false)} />
      )}
      <form onSubmit={handleSubmit(onSubmit)} id="addressInformation">
        <Container fluid style={{ padding: 0 }}>
          {addressFieldArray.fields.map((_, index) => {
            const hasError = !!errors.addressess?.[index];
            return (
              <span key={_.id}>
                <Col xs={12}>
                  {index !== 0 && (
                    <div
                      style={{
                        display: 'flex',
                        marginTop: '50px',
                        marginBottom: '20px',
                      }}
                    >
                      <p style={{ marginTop: '4px' }}>Informe o endereço</p>
                      <Icon
                        name="trash"
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeLine(index)}
                      />
                    </div>
                  )}
                </Col>
                <Row
                  align="center"
                  gutterWidth={16}
                  style={{ marginTop: '20px' }}
                  justify={
                    ['xs', 'sm', 'md', 'lg'].includes(screenClass)
                      ? 'center'
                      : 'start'
                  }
                >
                  <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
                    <Label>CEP</Label>
                    <Controller
                      control={control}
                      name={`addressess.${index}.cep`}
                      id="cep"
                      render={({ field: { onChange, value, ref } }) => (
                        <Input.Masked
                          placeholder="00000-000"
                          format="#####-###"
                          mask=""
                          value={value}
                          getInputRef={ref}
                          onChange={onChange}
                          onBlur={(ev) => getAddressCep(ev.target.value, index)}
                        />
                      )}
                    />

                    {hasError && (
                      <ErrorMessage>
                        {errors.addressess[index]?.cep?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                  <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
                    <Label>Cidade</Label>
                    <Input
                      maxLength="50"
                      {...register(`addressess.${index}.city`)}
                    />
                    {hasError && (
                      <ErrorMessage>
                        {errors.addressess[index]?.city?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                  <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
                    <Label>Estado</Label>
                    <Controller
                      control={control}
                      name={`addressess.${index}.state`}
                      render={({ field: { onChange, value, ref } }) => (
                        <Input.Select
                          placeholder="Selecione o Estado"
                          value={states.find(
                            (option) => option.value === value
                          )}
                          inputRef={ref}
                          onChange={(e) => onChange(e?.value)}
                          options={states}
                        />
                      )}
                    />
                    {hasError && (
                      <ErrorMessage>
                        {errors.addressess[index]?.state?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                </Row>
                <Space />
                <Row
                  align="center"
                  gutterWidth={16}
                  justify={
                    ['xs', 'sm', 'md', 'lg'].includes(screenClass)
                      ? 'center'
                      : 'start'
                  }
                >
                  <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
                    <Label>Endereço</Label>
                    <Input
                      maxLength="120"
                      {...register(`addressess.${index}.laneName`)}
                    />

                    {hasError && (
                      <ErrorMessage>
                        {errors.addressess[index]?.laneName?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                  <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
                    <Label>Complemento</Label>
                    <Input
                      maxLength="30"
                      {...register(`addressess.${index}.complement`)}
                    />
                    {hasError && (
                      <ErrorMessage>
                        {errors.addressess[index]?.complement?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                  <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
                    <Label>Número</Label>
                    <Input
                      maxLength="5"
                      {...register(`addressess.${index}.number`)}
                    />
                    {hasError && (
                      <ErrorMessage>
                        {errors.addressess[index]?.number?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                </Row>
                <Space />
                <Row
                  align="center"
                  gutterWidth={16}
                  justify={
                    ['xs', 'sm', 'md', 'lg'].includes(screenClass)
                      ? 'center'
                      : 'start'
                  }
                >
                  <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
                    <Label>Nome do Endereço</Label>
                    <Input
                      maxLength="30"
                      {...register(`addressess.${index}.name`)}
                    />

                    {hasError && (
                      <ErrorMessage>
                        {errors.addressess[index]?.name?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                  <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
                    <Label>Tipo de Endereço</Label>
                    <Controller
                      control={control}
                      name={`addressess.${index}.addressType`}
                      render={({ field: { onChange, value, ref } }) => (
                        <Input.Select
                          value={typesAddress.find(
                            (option) => option.value === value
                          )}
                          isClearable={false}
                          inputRef={ref}
                          onChange={(e) => onChange(e?.value)}
                          options={typesAddress}
                          disabled
                        />
                      )}
                    />
                    {hasError && (
                      <ErrorMessage>
                        {errors.addressess[index]?.addressType?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                </Row>
              </span>
            );
          })}
          <Row>
            <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
              <TextMessage>
                Caso haja apenas um endereço o mesmo será usado como endereço de
                coleta.
              </TextMessage>
            </Col>
          </Row>
          <Row>
            <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
              <SessionAddAddress
                onClick={() =>
                  addressFieldArray.append({
                    cep: '',
                    state: '',
                    city: '',
                    neighborhood: '',
                    laneName: '',
                    number: '',
                    complement: '',
                    name: '',
                    addressType: 2,
                  })
                }
              >
                <IconAdd active={true}>+</IconAdd>
                <TextAdd active={true}>Adicionar Endereço</TextAdd>
              </SessionAddAddress>
            </Col>
          </Row>
          <Row>
            <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
              <FlexRight>
                <Limited>
                  <Button variant="outlined" onClick={() => setPosition(0)}>
                    Cancelar
                  </Button>
                  <div style={{ marginLeft: '40px' }} />
                  <Button type="submit" form="addressInformation">
                    Continuar
                  </Button>
                </Limited>
              </FlexRight>
            </Col>
          </Row>
        </Container>
      </form>
      {cancel && (
        <Modal
          open={cancel}
          close={() => setCancel(false)}
          confirm={() => setPosition(0)}
        />
      )}
    </Body>
  );
};

export default Address;
