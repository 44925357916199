import * as yup from "yup";

const schema = yup.object().shape({
  benefitType: yup
    .number()
    .required("Tipo de benefício é um campo requerido"),
  name: yup
    .string()
    .required("Nome é um campo requerido"),
});

export default schema;