import styled, { css } from 'styled-components';

const SIZE = {
  medium: css`
    height: 48px;
    padding: 12px;
  `,
  large: css`
    height: 60px;
    padding: 16px;
  `,
};

const VARIANT = {
  default: (theme) => css`
    border: none;
    background-color: ${theme.bg};
    color: ${theme.fg};
    :hover {
      background-color: ${theme.hover};
    }
  `,

  ghost: (theme) => css`
    background-color: transparent;
    border: 2px solid ${theme.bg};
    color: ${theme.bg};
    :hover {
      background-color: ${theme.hoverGhost};
    }
  `,

  text: () => css`
    align-items: flex-end;
    background-color: transparent;
    border: none;
    color: #a5a1ad;
    text-decoration: underline;
    font-weight: var(--font-weight-medium);
    padding-bottom: 0;
  `,
};

export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
  font-weight: var(--font-weight-bold);
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out;
  white-space: nowrap;
  svg {
    height: 24px;
    width: 24px;
    margin-right: 13px;
  }

  ${({ size }) => SIZE[size]};
  ${({ variant, theme, predefTheme }) =>
    Object.keys(theme).length !== 0
      ? VARIANT[variant](theme)
      : VARIANT[variant](predefTheme)};
`;
