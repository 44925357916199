import React, { useRef, useState } from "react";
import _ from "lodash";
import { Grid, IconButton, Text } from "presentation/components";
import { AddEntrieTextButton, CategorieTitleWrapper, EntriesCounter, OperatorInformation } from "../styles";
import { ADDRESSES_TYPES } from "main/utils/constants";
import AddressModal from "./editionModals/addressModal";
import { FaPencilAlt, FaRegTrashAlt } from "react-icons/fa";
import useService from "main/hooks/useService";
import { useDispatch } from "react-redux";
import { pushNotification } from "@/store/modules/notification/actions";
import successHandler from "main/utils/successHandler";
import { returnValueOrMessageIfIsEmpty } from "main/utils/functionUtils";
import { putEditResaleAddresses } from "@/services/resalesServices";
const emptyMessage = "Não informado";

const Addresses = ({
  resaleData,
  isReviewing,
  onReview,
  onEdited = () => null
}) => {

  const dispatch = useDispatch();
  const [isAddressModalFormOpen, setIsAddressModalFormOpen] = useState(false);
  const [, deleteAddressRequest] = useService(putEditResaleAddresses, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler("Endereço removido")));
      onEdited();
    },
  });
  const addressIndexToEdit = useRef(null);
  const isMultiLine = resaleData.addresses.length > 1;

  const deleteAddress = (index) => {
    const newContacts = resaleData.addresses;
    newContacts.splice(index, 1);
    deleteAddressRequest(resaleData.id, newContacts);
  };

  const defineAddressToEdit = (addressIndex) => {
    addressIndexToEdit.current = addressIndex;
    setIsAddressModalFormOpen(true);
  }

  const addressesRender = (resaleData) => {
    if (!resaleData.addresses) return;
    const mainAddressIndex = resaleData.addresses.findIndex(address => address?.addressType === ADDRESSES_TYPES.MAIN);
    const addressesToRender = [...resaleData.addresses];
    if (mainAddressIndex) {
      [addressesToRender[0], addressesToRender[mainAddressIndex]] = [addressesToRender[mainAddressIndex], addressesToRender[0]];
    }
    return addressesToRender.map((address, index) => (
      <React.Fragment key={_.uniqueId()}>
        {isMultiLine &&
          <Grid.Row>
            <Grid.Col>
              <EntriesCounter>
                <Text.SubtitleTwo>{index + 1}.</Text.SubtitleTwo>
                {isReviewing ? null :
                  <>
                    <IconButton onClick={() => defineAddressToEdit(index)}><FaPencilAlt /></IconButton>
                    {address?.addressType !== ADDRESSES_TYPES.MAIN &&
                      <IconButton onClick={() => deleteAddress(index)}><FaRegTrashAlt /></IconButton>
                    }
                  </>
                }
              </EntriesCounter>
            </Grid.Col>
          </Grid.Row>
        }
        <Grid.Row>
          <Grid.Col xs={12} sm={6} md={3} lg={3}>
            <Text.SubtitleTwo>CEP</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(address?.cep, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3}>
            <Text.SubtitleTwo>Endereço</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(address?.laneName, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
            <Text.SubtitleTwo>Número</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(address?.number, emptyMessage)}</OperatorInformation>
          </Grid.Col>

          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs", "sm"]}>
            <Text.SubtitleTwo>Nome do Endereço</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(address?.addressName, emptyMessage)}</OperatorInformation>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs", "sm"]}>
            <Text.SubtitleTwo>Complemento</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(address?.city, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs", "sm"]}>
            <Text.SubtitleTwo>Cidade</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(address?.city, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
            <Text.SubtitleTwo>Estado</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(address?.state, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs", "sm"]}>
            <Text.SubtitleTwo>Tipo de Endereço</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(ADDRESSES_TYPES[address?.addressType], emptyMessage)}</OperatorInformation>
          </Grid.Col>
        </Grid.Row>
        {isMultiLine ? null :
          <Grid.Row>
            <Grid.Col>
              <p style={{ color: 'var(--color-secundary-04)' }}>Endereço de cobrança*</p>
            </Grid.Col>
          </Grid.Row>
        }
      </React.Fragment>
    ));
  };

  return (
    <>
      <Grid.Row>
        <Grid.Col xs={12}>
          <CategorieTitleWrapper>
            <Text.Subtitle>{isMultiLine ? "Endereços" : "Endereço"}</Text.Subtitle>
            {!isMultiLine || isReviewing ?
              <IconButton onClick={() => isReviewing ? onReview() : defineAddressToEdit(0)}><FaPencilAlt /></IconButton> : null
            }
          </CategorieTitleWrapper>
        </Grid.Col>
      </Grid.Row>
      {addressesRender(resaleData)}
      {isReviewing ? null :
        <Grid.Row>
          <Grid.Col xs={12}>
            <AddEntrieTextButton onClick={() => setIsAddressModalFormOpen(true)}>Adicionar endereço</AddEntrieTextButton>
          </Grid.Col>
        </Grid.Row>
      }
      {isAddressModalFormOpen &&
        <AddressModal
          open={isAddressModalFormOpen}
          addressIndex={addressIndexToEdit.current}
          resaleData={resaleData}
          onClose={(hasEdited) => {
            setIsAddressModalFormOpen(false);
            addressIndexToEdit.current = null;
            hasEdited && onEdited();
          }}
        />
      }
    </>
  );
};

export default Addresses;