import { validateCNPJ, validateEmail } from "validations-br";

export const rules = {
  cnpj: {
    required: { value: true, message: "Campo obrigatório" },
    validate: {
      isCNPJValid: e => validateCNPJ(e) || "CNPJ inválido",
    },
  },
  tradeName: {
    required: "Campo requerido",
  },
  corporateName: {
    required: "Campo requerido",
  },
  stateRegistration: {
    minLength: { value: 9, message: "Preencha o campo completo" },
    maxLength: { value: 14, message: "Preencha o campo completo" },
  },
  municipalRegistration: {
    required: "Campo requerido",
    minLength: { value: 11, message: "Preencha o campo completo" },
    maxLength: { value: 11, message: "Limitado a 11 dígitos" },
  },
  email: {
    required: { value: true, message: "Campo obrigatório" },
    validate: {
      isEmailValid: e => validateEmail(e) || "E-mail inválido",
    },
  },
  site: {
    required: "Campo requerido",
  },
};