import produce from 'immer';
import { ACTIONS } from './actions';

const INITIAL_STATE = {
  messages: [],
};

export default function notifications(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  return produce(state, (draft) => {
    switch (type) {
      case ACTIONS.PUSH_NOTIFICATION: {
        if (Array.isArray(payload))
          draft.messages = [...draft.messages, ...payload];
        else draft.messages = [...draft.messages, payload];
        break;
      }

      case ACTIONS.POP_NOTIFICATION: {
        const newMessagesArray = [...draft.messages];
        newMessagesArray.splice(0, 1);
        draft.messages = newMessagesArray;
        break;
      }

      default:
    }
  });
}
