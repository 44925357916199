import { ArrowDown, ArrowUp, OrderContainer, StyledTh } from './styles';

const Th = ({
  align = 'left',
  children,
  orderable = false,
  onArrowUpClick,
  onArrowDownClick,
  wrap = 'nowrap',
  ...props
}) => {
  return (
    <StyledTh align={align} wrap={wrap} {...props}>
      {children}
      {orderable && (
        <OrderContainer>
          <ArrowUp onClick={onArrowUpClick} />
          <ArrowDown onClick={onArrowDownClick} />
        </OrderContainer>
      )}
    </StyledTh>
  );
};

export default Th;
