import styled from "styled-components";

export const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StepsBall = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  color: white;
  height: 48px;
  width: 48px;
  background-color: #F76C39;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;

  > label:nth-child(odd){
    margin-right: 4px;
  }
  > label:nth-child(even){
    margin-right: 16px;
  }
`;

export const Form = styled.form`
  span > label,
  > div > div > div> label {
    display: inline-block;
    margin-bottom: 16px;
  }
`;