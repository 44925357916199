import React, { useEffect, useMemo, useState } from 'react';

import TableList from './TableList/TableList';

import _ from 'lodash';
import PainelList from './PainelList/PainelList';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Text from 'presentation/components/text';

import { IconArrows, Container } from './styles';
import { ModalFee } from 'presentation/pages/resales/modals/modalFee';
import Spinner from 'presentation/components/spinner';

const intersection = (a, b) => {
  return a.filter((item) => b.includes(item.innerId));
};

const notIn = (a, b) => {
  return a.filter((item) => !b.includes(item.innerId));
};

const TransferList = ({ props }) => {
  const {
    left,
    leftName,
    setLeft,
    right,
    rightName,
    setRight,
    displayName,
    loading,
    setLoading,
    rightLinked,
    setRightLinked,
  } = props;

  const [spinner, setSpinner] = useState(false);
  const [filterLeft, setFilterLeft] = useState('');
  const [filterRight, setFilterRight] = useState('');
  const [, setFilterLinkedRight] = useState('');
  const [open, setOpen] = React.useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [disabledAdd, setDisabledAdd] = useState(false);
  const [disabledDiff, setDisabledDiff] = useState(false);
  const [diffModalHandle, setDiffModalHandle] = useState(undefined);

  useMemo(() => {
    setLeft(left?.map((item) => ({ ...item, innerId: _.uniqueId() })));
    setRight(right.map((item) => ({ ...item, innerId: _.uniqueId() })));
    setRightLinked(
      rightLinked.map((item) => ({ ...item, innerId: _.uniqueId() }))
    );

    setLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (diffModalHandle) setOpen(true);
  }, [diffModalHandle]);

  const sendToRight = () => {
    setRight(
      right.concat(
        intersection(
          left.filter((e) => !e.obligatory && !e.assigned),
          checkedItems
        )
      )
    );
    setRightLinked(
      rightLinked.concat(
        intersection(
          left.filter((e) => !e.obligatory && e.assigned),
          checkedItems
        )
      )
    );
    setLeft(notIn(left, checkedItems));
    clearCheckedsAndFilters();
  };

  const sendToLeftConfirm = () => {
    const rightPrepare = rightLinked.filter((e) => !e.obligatory);
    const intersectionSpread = [
      ...intersection(rightPrepare, checkedItems),
      ...intersection(right, checkedItems),
    ];

    if (
      intersection(intersectionSpread, checkedItems).length > 0 ||
      intersection(right, checkedItems).length > 0
    )
      setLeft(left.concat(intersectionSpread));

  

    const checkedIds = intersection(
        right.filter((e) => !e.obligatory),
        checkedItems
      ).filter((x) => x.innerId);
    const uniqueIds = [...new Set(checkedIds.map((item) => item.innerId))];
    setRight(notIn(right, uniqueIds));   
    setRightLinked([ ...rightLinked.filter((e) => e.obligatory) ,...notIn(rightLinked, checkedItems)]);

    setOpen(false);
    clearCheckedsAndFilters();
  };

  const checkboxClick = (item) => {
    const checkedIndex = checkedItems.indexOf(item);
    const newCheckeds = [...checkedItems];
    checkedIndex === -1
      ? newCheckeds.push(item)
      : newCheckeds.splice(checkedIndex, 1);
    setCheckedItems(newCheckeds);
  };

  const clearCheckedsAndFilters = () => {
    setFilterLeft('');
    setFilterRight('');
    setFilterLinkedRight('');
    setCheckedItems([]);
  };

  useEffect(() => {
    checkLeftAdd();
    checkRigthDiff();
  }, [checkedItems]);

  const checkLeftAdd = () => {
    if (intersection(left, checkedItems).length > 0) setDisabledAdd(false);
    else setDisabledAdd(true);
  };

  const checkRigthDiff = () => {
    if (
      intersection(right, checkedItems).length > 0 ||
      intersection(rightLinked, checkedItems).length > 0
    )
      setDisabledDiff(false);
    else setDisabledDiff(true);
  };

  const allLeft = () => {
    if (!checkIfIsAllChecked(left)) {
      const notCheckedsIds = notIn(left, checkedItems).map(
        (item) => item.innerId
      );
      setCheckedItems(checkedItems.concat(notCheckedsIds));
    } else
      setCheckedItems(
        intersection(right, checkedItems).map((item) => item.innerId)
      );
  };

  const allRight = () => {
    if (!checkIfIsAllChecked(right)) {
      const notCheckedsIds = notIn(right, checkedItems).map(
        (item) => item.innerId
      );

      setCheckedItems(checkedItems.concat(notCheckedsIds));
    } else
      setCheckedItems(
        intersection(left, checkedItems).map((item) => item.innerId)
      );
  };

  const allLinkedRight = () => {
    if (!checkIfIsAllChecked(rightLinked)) {
      const notCheckedsIds = notIn(rightLinked, checkedItems).map(
        (item) => item.innerId
      );
      setCheckedItems(checkedItems.concat(notCheckedsIds));
    } else {
      setCheckedItems(
        intersection(left, checkedItems).map((item) => item.innerId)
      );
    }
  };

  const checkIfIsAllChecked = (side) => {
    return (
      side.length === intersection(side, checkedItems).length && side.length > 0
    );
  };

  const tableProps = {
    loading,
    checkedItems,
    checkboxClick,
    displayName,
  };

  const listConfig = {
    leftPanel: {
      filter: filterLeft,
      setFilter: setFilterLeft,
      title: leftName,
    },
    rigthPanel: {
      filter: filterRight,
      setFilter: setFilterRight,
      title: rightName,
    },
    leftTable: {
      allClick: allLeft,
      filter: filterLeft,
      isAllChecked: checkIfIsAllChecked(left),
      list: left,
      ...tableProps,
    },
    rightTable: {
      allClick: allRight,
      filter: filterRight,
      isAllChecked: right.length > 1 && !checkIfIsAllChecked(right),
      //disabledAllChecked: right.length > 1 && !checkIfIsAllChecked(right),
      list: right,
      ...tableProps,
    },
    rightLinkedFeesAssignedTable: {
      allClick: allLinkedRight,
      filter: filterRight,
      isAllChecked: rightLinked.length > 1 && checkIfIsAllChecked(rightLinked),
      //disabledAllChecked: rightLinked.length > 1 && checkIfIsAllChecked(rightLinked),
      list: rightLinked,
      ...tableProps,
    },
  };

  useEffect(() => {
    if (listConfig) setSpinner(true);
  }, [listConfig]);

  return (
    <Container>
      {!spinner && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '40px',
          }}
        >
          <Spinner />
        </div>
      )}
      {spinner && (
        <>
          <>
            <PainelList props={listConfig.leftPanel}>
              <TableList props={listConfig.leftTable} position="left" />
            </PainelList>
            <Text className="arrows">
              <IconArrows
                name="add"
                className={disabledAdd ? 'disabled' : ''}
                onClick={sendToRight}
              />
              {disabledDiff && <IconArrows name="diff" className="disabled" />}
              {!disabledDiff && (
                <IconArrows
                  name="diff"
                  onClick={() => setDiffModalHandle(true)}
                />
              )}
            </Text>
            <PainelList props={listConfig.rigthPanel}>
              <Tabs>
                <TabList>
                  <Tab>Novas</Tab>
                  <Tab>Vinculadas</Tab>
                </TabList>
                <TabPanel>
                  <TableList props={listConfig.rightTable} position="right" />
                </TabPanel>
                <TabPanel>
                  <TableList
                    props={listConfig.rightLinkedFeesAssignedTable}
                    position="left"
                  />
                </TabPanel>
              </Tabs>
            </PainelList>
          </>
          {checkedItems.length > 0 && (
            <ModalFee
              props={{ sendToLeftConfirm, setOpen, open, setDiffModalHandle }}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default TransferList;
