export const ALLOWED_LOGIN_ROLES = 'resale';
export const FIVE_SECONDS = 5000;
export const TEN_SECONDS = 10000;
export const NULL_ID = '00000000-0000-0000-0000-000000000000';
export const TEN_MB = 10000000;
export const DATE_TO_IGNORE = '0001-01-01';
export const TEMP_OPERATOR_ID = '7e17d3a1-4f26-443f-b8ff-0a4a227e2758';

export const FORM_MODE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
};

export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  1: 'success',
  2: 'error',
  3: 'warning',
};

export const BREAK_POINTS = {
  sm: '575px',
  md: '767px',
  lg: '991px',
  xl: '1199px',
  xxl: '1599px',
};

export const BENEFIT_TYPES = {
  1: 'Vale Transporte Eletrônico',
  2: 'Vale Refeição',
  3: 'Vale Alimentação',
  4: 'Vale Combustível',
  5: 'Vale Transporte Papel',
  TRANSPORT: 1,
  FOOD: 2,
  SNACK: 3,
  FUEL: 4,
  TRANSPORT_PAPER: 5,
};

export const OPERATOR_CARD_FEE_FREQUENCY = {
  1: 'Diário',
  2: 'Semanal',
  3: 'Quinzenal',
  4: 'Mensal',
  DAILY: 1,
  WEEKLY: 2,
  BIWEEKLY: 3,
  MONTHLY: 4,
};

export const OPERATOR_CARD_STATUS = {
  1: 'Ativo',
  2: 'Inativo',
  ACTIVE: 1,
  INACTIVE: 2,
};

export const OPERATOR_CARD_FEE_STATUS = {
  1: 'Ativo',
  2: 'Inativo',
  ACTIVE: 1,
  INACTIVE: 2,
};

export const OPERATORS_STATUS = {
  0: 'Pendente',
  1: 'Ativo',
  2: 'Inativo',
  PENDING: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const RESALES_STATUS = {
  0: 'Pendente',
  1: 'Ativo',
  2: 'Inativo',
  3: 'Aguardando publicação',
  PENDING: 0,
  ACTIVE: 1,
  INACTIVE: 2,
  WAITING_PUBLISH: 3
};

export const ADDRESSES_TYPES = {
  1: 'Principal',
  2: 'Coleta de bilhete',
  3: 'Coleta de cartão',
  4: 'Outro',
  MAIN: 1,
  TICKET: 2,
  CARD: 3,
  OTHER: 4,
};

export const CONTACT_TYPES = {
  1: 'Principal',
  2: 'RH',
  3: 'Financeiro',
  4: 'Comercial',
  5: 'Jurídico',
  6: 'Outro',
  MAIN: 1,
  RH: 2,
  FINANCIAL: 3,
  COMMERCIAL: 4,
  LEGAL: 5,
  OTHER: 6,
};

export const OPERATOR_BUYING_RULE_TYPES = {
  1: 'Cartão',
  2: 'VT Papel',
  3: 'Recarga',
  CARD: 1,
  PHYSICAL: 2,
  RECHARGE: 3,
};

export const OPERATOR_DOCUMENTS_TYPES = {
  1: 'Layout de Colaborador',
  2: 'Layout de Pedido',
  EMPLOYEE_LAYOUT: 1,
  ORDER_LAYOUT: 2,
};

export const NUMBER_OF_BENEFICIARIES = {
  1: 'Individual/Autônomo',
  2: '2 a 10 colaboradores',
  3: '11 a 50 colaboradores',
  4: '51 a 100 colaboradores',
  5: '101 a 500 colaboradores',
  6: 'Mais de 500 colaboradores',
};

export const CRITERIONFEES = {
  1: 'Global',
  2: 'Cartão',
  3: 'Revendas',
  4: 'Colaborador',
  5: 'Produtos Operadora',
  6: 'Produtos Dealer',
};

export const APPLICATIONFEES = {
  1: 'Revendas',
  2: 'Parceiros de Negócio',
  3: 'Todos',
};

export const VALUETYPEFEES = {
  1: 'Porcentagem',
  2: 'Reais',
  3: 'Reais + Porcentagem',
};

export const OPERATOR_CARD_PROCESS_TYPE = {
  0: 'Não definido',
  1: 'Dealer',
  2: 'Broker',
  NOT_SET: 0,
  DEALER: 1,
  BROKER: 2,
};
export const RESALE_TYPEFEE = {
 1: "Hospedagem",
 2: "Setup",
 3: "Sustentação",
 4: "Uso do Sistema",
}

export const RESALE_INCIDENCE = {
  1: "Créditos estocados",
  2: "Valor total faturado",
}

export const RESALE_RECURRENCE = {
  1: "Unica",
  2: "Semanal",
  3: "Mensal",
  4: "Anual",
}