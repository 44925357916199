import api from "@/infra/api"
import { useQuery } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import { useMemo } from "react"
import { TTermPaymentRate } from "./utils/interfaces/TTermPaymentRate"

type ResaleDefaultRates = {
  '7days'?: TTermPaymentRate
  '14days'?: TTermPaymentRate
  '21days'?: TTermPaymentRate
  '28days'?: TTermPaymentRate
}
export interface IUseResaleDefaultRatesReturn {
  resaleDefaultRates: ResaleDefaultRates
  isLoadingDefaultRates: boolean
}

export const useResaleDefaultRates = ({ resaleId }: { resaleId: string }): IUseResaleDefaultRatesReturn => {

  const { data, isFetching: isLoading } = useQuery<AxiosResponse<TTermPaymentRate[]>>({
    queryKey: ['resaleDefaultRates', resaleId],
    queryFn: () =>
      api.get(`/ResaleInterestRate/?resaleId=${resaleId}&companyId=null`),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const organizeData = useMemo(() => {
    const rawData = data?.data
    if (!rawData) return null

    const getEmptyRate = (days: number) => ({ rate: '', days })
    const captureDayRate = (days: number) => {
      const targetRate = rawData.find(rate => rate.days === days && rate.active)
      if (targetRate) targetRate.rate = String(targetRate.rate)
      return targetRate || getEmptyRate(days)
    }

    return {
      '7days': captureDayRate(7),
      '14days': captureDayRate(14),
      '21days': captureDayRate(21),
      '28days': captureDayRate(28),
    }
  }
    , [data])

  return {
    resaleDefaultRates: organizeData,
    isLoadingDefaultRates: isLoading
  }
}