import { ErrorMessage, ErrorWrapper, Grid, Input, Modal, Spinner, Text } from "presentation/components";
import Button from "presentation/components/buttonV2";
import { Controller, useForm } from "react-hook-form";
import { Form, ModalLoadingWrapper, ModalTitle } from "./styles";
import generalDataSchema from "./schemas/generalDataSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import useService from "main/hooks/useService";
import { pushNotification } from "@/store/modules/notification/actions";
import successHandler from "main/utils/successHandler";
import { useDispatch } from "react-redux";
import { Input as NewInput, styled } from '@stationkim/components-lib';
import { putEditResaleGeneralData } from "@/services/resalesServices";


const GeneralDataModal = ({ resaleData, onClose, open }) => {
  const dispatch = useDispatch();
  const defaultValues = resaleData;

  const [editGeneralDataState, editGeneralDataRequest] = useService(putEditResaleGeneralData, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler("Dados gerais alterados!")));
      onClose(true);
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
    resolver: yupResolver(generalDataSchema),
  });

  const onSubmit = (data) => {
    if (!isDirty) {
      onClose();
      return;
    }
    const payload = {
      id:resaleData.id,
      corporateName: data.corporateName,
      tradeName: data.tradeName,
      email: data.email,
      site: data.site,
    }
    editGeneralDataRequest(payload);
  };
  const Textfixes = styled('span', {
    color: '$gray400'
  });

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      showCloseIcon={false}
    >
      <ModalTitle>Editar Dados Gerais da Revenda</ModalTitle>
      <Form onSubmit={handleSubmit(onSubmit)} id="resaleData">
        <Grid fluid padding="0" spaceBetweenRows="32px">
          {editGeneralDataState.loading ?
            <Grid.Row>
              <Grid.Col>
                <ModalLoadingWrapper>
                  <Spinner />
                </ModalLoadingWrapper>
              </Grid.Col>
            </Grid.Row>
            :
            <>
              <Grid.Row>
                <Grid.Col xs={12}>
                  <Text.Label>CNPJ</Text.Label>
                  <Controller
                    control={control}
                    name="cnpj"
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Masked
                        placeholder="00.000.000/0000-00"
                        mask={"*"}
                        format="##.###.###/####-##"
                        value={value}
                        getInputRef={ref}
                        disabled
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col xs={12}>
                  <ErrorWrapper error={errors.email}>
                    <Text.Label>E-mail</Text.Label>
                    <Input
                      control={control}
                      {...register("email")}
                    />
                    {errors.email && (
                      <ErrorMessage>{errors.email.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.corporateName}>
                    <Text.Label>Razão Social</Text.Label>
                    <Input
                      placeholder="Razão social"
                      maxLength="70"
                      {...register("corporateName")}
                    />
                    {errors.corporateName && (
                      <ErrorMessage>{errors.corporateName.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.tradeName}>
                    <Text.Label>Nome Fantasia</Text.Label>
                    <Input
                      placeholder="Nome Fantasia"
                      maxLength="40"
                      {...register("tradeName")}
                    />
                    {errors.tradeName && (
                      <ErrorMessage>{errors.tradeName.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={6}>
                  <Text.Label>Inscrição Municipal</Text.Label>
                  <Input
                    maxLength="11"
                    placeholder="00000000000"
                    name="municipalRegistration"
                    disabled
                    {...register("municipalRegistration")}
                  />
                </Grid.Col>
                <Grid.Col sm={6}>
                  <ErrorWrapper error={errors.stateRegistration}>
                    <Text.Label>Inscrição Estadual</Text.Label>
                    <Input
                      maxLength="14"
                      placeholder="000000000"
                      name="stateRegistration"
                      disabled
                      {...register("stateRegistration")}
                    />
                    {errors.stateRegistration && (
                      <ErrorMessage>{errors.stateRegistration.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={6}>
                  <Text.Label>Código da Revenda</Text.Label>
                  <Input
                    disabled
                    {...register("code")}
                  />
                </Grid.Col>

                <Grid.Col sm={6}>
                  <Controller
                    control={control}
                    name="site"
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Text.Label>Site</Text.Label>
                        <NewInput.Root
                          state={errors?.site ? 'error' : null}
                          full
                        >

                          <NewInput.Field style={{
                            gap: 0,
                            justifyContent: 'flex-start',
                            whiteSpace: 'nowrap'
                          }}>
                            <Textfixes>https://</Textfixes>
                            <NewInput.Input
                              style={{
                                flex: 1,
                                maxWidth: (value.length + 1) * 8 + "px",
                                minWidth: '64px',
                                fontWeight: 'var(--font-weight-bold)',
                                textTransform: 'lowercase',
                              }}
                              placeholder="revenda"
                              value={value}
                              onChange={onChange}
                            />
                            <Textfixes>.stationkim.com/</Textfixes>
                          </NewInput.Field>
                          <ErrorWrapper error={errors.site}>

                            {errors.site && (
                              <ErrorMessage>{errors.site.message}</ErrorMessage>
                            )}
                          </ErrorWrapper>
                        </NewInput.Root>
                      </>
                    )}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row justify="between">
                <Grid.Col sm="content">
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      onClose();
                    }}
                    variant="text"
                  >Cancelar</Button>
                </Grid.Col>
                <Grid.Col sm="content">
                  <Button>Salvar</Button>
                </Grid.Col>
              </Grid.Row>
            </>
          }
        </Grid>
      </Form>
    </Modal >
  );
}

export default GeneralDataModal;