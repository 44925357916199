import styled, { css } from 'styled-components';

export const Button = styled.button`
  background-color: #cfffb9;
  border: none;
  border-radius: 1.5rem;
  color: #2c2d5c;
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: var(--font-weight-medium);
  letter-spacing: 0.018125rem;
  line-height: 2rem;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: background-color 0.15s ease-in;
  height: 2.75rem;
  width: 100%;
  position: relative;
  white-space: nowrap;
  ${(props) =>
    props.size === 'sm' &&
    css`
      font-size: 1rem;
      letter-spacing: 0.01625rem;
      line-height: 1.625rem;
    `}
  ${(props) =>
    props.size === 'lg' &&
    css`
      font-size: 2rem;
      letter-spacing: 0.031875rem;
      height: 3.9375rem;
    `}
	/* :hover {
    background-color: var(--color-primary-02);
  } */
  svg {
    height: 1rem;
    margin-right: 0.5rem;
    width: 1rem;
    fill: var(--color-primary-05);
    color: var(--color-primary-05);
    stroke: var(--color-primary-05);
  }
  > svg:nth-child(2) {
    height: 24px;
    width: 24px;
    stroke-width: 2;
  }

  div:last-child {
    right: 0;
    top: calc(100% + 0.5rem);
    font-size: 1rem;
    font-weight: var(--font-weight-medium);
  }

  :disabled,
  :disabled:hover {
    background-color: var(--color-secundary-05);
    border: 1px solid var(--color-secundary-06);
    color: var(--color-primary-03);
    cursor: not-allowed;
    svg {
      fill: var(--color-primary-03);
      stroke: var(--color-primary-03);
    }
  }

  ${(props) =>
    props.variant
      ? props.variant.includes('action') &&
        css`
          border-radius: 1rem;
        `
      : null}

  ${(props) =>
    props.variant
      ? (props.variant.includes('action') ||
          props.variant.includes('outlined')) &&
        css`
          font-weight: var(--font-weight-regular);
          letter-spacing: 0.01625rem;
        `
      : null}

  ${(props) =>
    props.variant
      ? props.variant.includes('outlined') &&
        css`
          background: none;
          border: 0.0625rem solid var(--color-primary-01);
          color: var(--color-primary-01);

          svg {
            fill: var(--color-primary-01);
            color: var(--color-primary-01);
            stroke: var(--color-primary-01);
          }

          :hover {
            background: var(--color-primary-01);
            color: var(--color-primary-05);
          }

          :hover > svg {
            fill: var(--color-primary-05);
            color: var(--color-primary-05);
            stroke: var(--color-primary-05);
          }
        `
      : null}

  ${(props) =>
    props.variant
      ? props.variant === 'action-outlined' &&
        css`
          padding: 0 1rem;

          :disabled {
            background-color: var(--color-secundary-05);
            border: 0.0625rem solid var(--color-primary-03);
            color: var(--color-primary-03);
          }

          :disabled > svg {
            fill: var(--color-primary-03);
            stroke: var(--color-primary-03);
          }
        `
      : null}
`;
