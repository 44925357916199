import styled, { css } from "styled-components";

export const StyledStatusTag = styled.div`
  display: inline-flex;
  align-items: center;
  height: 48px;
  width: fit-content;
  padding: 12px;
  padding-left: 28px;
  border-radius: 4px;
  position: relative;

  :before {
    content: ' ';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    position: absolute;
    left: 12px;
    top: 20px;
  }

  ${({ color }) =>
    css`
      background-color: ${color.bkg};
      :before{
        background-color: ${color.dotBkg};
      }
    `
  }
`;