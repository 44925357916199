import api from "@/infra/api"

export const useGeneralData = () => {
  const generalData = async data => {
    console.log(data)
    try {
      // api.post("/admin-bff/layoutSetting", data)
    } catch (e) {
      return
    }
  }

  return {
    generalData,
  }
}
