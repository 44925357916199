import { useState, useMemo } from 'react';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import api from '@/infra/api';
import errorHandler from 'main/utils/errorHandler';
import { pushNotification } from '@/store/modules/notification/actions';
import { useDispatch } from 'react-redux';
import Modal from '../../components/modalCancel';
import ModalLoading from '../../components/modalLoading';
import {
  Input,
  Button,
  ErrorMessage,
  Notification,
  Icon,
  Message,
} from 'presentation/components';
import {
  Body,
  Title,
  SubTitle,
  Space,
  Label,
  FlexRight,
  Limited,
  SessionAddAddress,
  IconAdd,
  TextAdd,
} from './styles';

const Rules = ({ setPosition, id }) => {
  const [loading, setLoading] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [warning, setWarning] = useState(false);
  const [ids, setIds] = useState([]);

  const screenClass = useScreenClass();
  const dispatch = useDispatch();

  const types = [
    { label: 'Recarga', value: 1 },
    { label: 'Cartão', value: 2 },
    { label: 'VT Papel', value: 3 },
  ];

  const schemaRules = yup
    .array()
    .max(3, 'Permitido cadastrar máximo de 3 regras.')
    .of(
      yup.object().shape({
        type: yup.string(),
        minimumPurchase: yup.string(),
        maximumPurchase: yup.string(),
        value: yup.string('Valor inválido').nullable(true),
      })
    );
  const schema = yup.object().shape({
    abortEarly: false,
    rules: schemaRules,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      rules: [
        {
          value: null,
        },
      ],
    },
  });

  const rulesFieldArray = useFieldArray({
    control: control,
    name: 'rules',
  });

  const converterValue = (value) => {
    if (value) {
      const alterone = value.replace('.', '');
      const alter = alterone.replace(',', '.');
      const parse = parseFloat(alter).toFixed(2);
      return Number(parse);
    } else {
      return 0;
    }
  };

  const onSubmit = async (ev) => {
    setLoading(true);
    let array = [];

    if (ev.rules[0].type !== undefined) {
      try {
        // eslint-disable-next-line
        await ev.rules.map((data, i) => {
          const maximum = data.maximumPurchase
            ? data.maximumPurchase.replace('R$', '')
            : '';
          const minimum = data.minimumPurchase
            ? data.minimumPurchase.replace('R$', '')
            : '';
          const minimumPurchase = converterValue(minimum);
          const maximumPurchase = converterValue(maximum);
          const value = Number(data.value);
          if (i === 0) {
            array.push({
              ...data,
              type: 1,
              minimumPurchase,
              maximumPurchase,
              value: Number(data.value),
            });
          } else {
            array.push({
              ...data,
              minimumPurchase,
              maximumPurchase,
              value,
              type: Number(data.type),
            });
          }
        });
        await api.post(`/operators/registration/buying-rules?id=${id}`, array);
        setLoading(false);
        setPosition(4);
      } catch (e) {
        dispatch(pushNotification(errorHandler(e?.response)));
        setLoading(false);
      }
    } else {
      setPosition(4);
      setLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/operators/${id}/registration/buying-rules`
      );

      if (response?.data?.length) {
        rulesFieldArray.remove(0);
        const reverse = await response.data.reverse();

        let arrayIds = [];

        // eslint-disable-next-line
        await reverse.map((values) => {
          arrayIds.push({ id: values.id });
          rulesFieldArray.append({
            type: Number(values.type),
            minimumPurchase: values.minimumPurchase,
            maximumPurchase: values.maximumPurchase,
            value: values.value,
            id: values.id,
          });
        });
        setIds(arrayIds);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useMemo(() => {
    if (id) getData();
    // eslint-disable-next-line
  }, [id]);

  useMemo(() => {
    if (warning) {
      setTimeout(() => {
        setWarning(false);
      }, 3000);
    }
  }, [warning]);

  const addTow = () => {
    if (rulesFieldArray.fields.length <= 2) {
      rulesFieldArray.append({
        type: '',
        value: '',
        maximumPurchase: '',
        minimumPurchase: '',
      });
    }
  };

  const addRowAlert = () => {
    setWarning(true);
  };

  const removeLine = async (index) => {
    const idLine = ids[index];
    setLoading(true);

    if (idLine?.id) {
      try {
        await api.delete(
          `operators/registration/buying-rule/${idLine.id}?operatorId=${id}`
        );
        rulesFieldArray.remove(index);
        setLoading(false);
        return;
      } catch (e) {
        dispatch(pushNotification(errorHandler(e?.response)));
        setLoading(false);
      }
    } else {
      rulesFieldArray.remove(index);
      setLoading(false);
    }
  };

  return (
    <Body>
      <Notification />
      <Title>Regras de Compra</Title>
      <SubTitle>Defina as regras de compra da Operadora.</SubTitle>
      <Space />
      {loading && (
        <ModalLoading open={loading} close={() => setLoading(false)} />
      )}
      <form onSubmit={handleSubmit(onSubmit)} id="rulesInformation">
        <Container fluid style={{ padding: 0 }}>
          {rulesFieldArray.fields.map((_, index) => {
            const hasError = !!errors.rules?.[index];

            return (
              <>
                <Col xs={12}>
                  {index !== 0 && (
                    <div
                      style={{
                        display: 'flex',
                        marginTop: '50px',
                        marginBottom: '20px',
                      }}
                    >
                      <p style={{ marginTop: '4px' }}>
                        Informe a regra de compra
                      </p>
                      <Icon
                        name="trash"
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeLine(index)}
                      />
                    </div>
                  )}
                </Col>
                <Row
                  align="center"
                  gutterWidth={16}
                  justify={
                    ['xs', 'sm', 'md', 'lg'].includes(screenClass)
                      ? 'center'
                      : 'start'
                  }
                >
                  <Col sm={2}>
                    <Label>Tipos de regra</Label>
                    <Controller
                      control={control}
                      name={`rules.${index}.type`}
                      render={({ field: { onChange, value, ref } }) => (
                        <Input.Select
                          value={types.find((option) => option.value === value)}
                          inputRef={ref}
                          onChange={(e) => onChange(e?.value ? e?.value : '')}
                          options={types}
                          disabled
                        />
                      )}
                    />

                    {hasError && (
                      <ErrorMessage>
                        {errors.rules[index]?.type?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                  <Col sm={1}>
                    <Label>Prazo para coleta</Label>
                    <Controller
                      control={control}
                      name={`rules.${index}.value`}
                      id="value"
                      render={({ field: { onChange, value, ref } }) => (
                        <Input.Masked
                          placeholder="00"
                          format="##"
                          mask=""
                          value={value}
                          getInputRef={ref}
                          onChange={onChange}
                        />
                      )}
                    />

                    {hasError && (
                      <ErrorMessage>
                        {errors.rules[index]?.value?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                </Row>
                <Space />
                <Row
                  align="center"
                  gutterWidth={16}
                  justify={
                    ['xs', 'sm', 'md', 'lg'].includes(screenClass)
                      ? 'center'
                      : 'start'
                  }
                >
                  <Col sm={1}>
                    <Label>Valor mínimo</Label>
                    <Controller
                      control={control}
                      name={`rules.${index}.minimumPurchase`}
                      render={({ field: { onChange, value, ref } }) => (
                        <Input.Decimal
                          prefix={'R$ '}
                          placeholder="00,00"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    {hasError && (
                      <ErrorMessage>
                        {errors.rules[index]?.minimumPurchase?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                  <Col sm={1}>
                    <Label>Valor máximo</Label>
                    <Controller
                      control={control}
                      name={`rules.${index}.maximumPurchase`}
                      render={({ field: { onChange, value, ref } }) => (
                        <Input.Decimal
                          prefix={'R$ '}
                          placeholder="00,00"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    {hasError && (
                      <ErrorMessage>
                        {errors.rules[index]?.maximumPurchase?.message}
                      </ErrorMessage>
                    )}
                  </Col>
                </Row>
              </>
            );
          })}
          <Space />
          <Row>
            <Col sm={12}>
              {errors?.rules?.message && (
                <ErrorMessage>{errors.rules.message}</ErrorMessage>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
              <SessionAddAddress
                onClick={() =>
                  rulesFieldArray.fields.length <= 2 ? addTow() : addRowAlert()
                }
              >
                <IconAdd
                  active={rulesFieldArray.fields.length <= 2 ? true : false}
                >
                  +
                </IconAdd>
                <TextAdd
                  active={rulesFieldArray.fields.length <= 2 ? true : false}
                >
                  Adicionar regra de compra
                </TextAdd>
              </SessionAddAddress>
            </Col>
          </Row>
          <Row>
            <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
              <FlexRight>
                <Limited>
                  <Button
                    variant="outlined"
                    type="button"
                    onClick={() => setCancel(true)}
                  >
                    Cancelar
                  </Button>
                  <div style={{ marginLeft: '40px' }} />
                  <Button type="submit" form="rulesInformation">
                    Continuar
                  </Button>
                </Limited>
              </FlexRight>
            </Col>
          </Row>
        </Container>
      </form>
      {cancel && (
        <Modal
          open={cancel}
          close={() => setCancel(false)}
          confirm={() => setPosition(2)}
        />
      )}
      {warning && (
        <Message type="warning" onXClick={() => setWarning(false)}>
          <p style={{ fontWeight: 'bold' }}>Aviso</p> <br />
          <p style={{ fontWeight: '200' }}>O número máximo de regras</p>
          <p style={{ fontWeight: '200' }}>de compra foi atigindo</p>
        </Message>
      )}
    </Body>
  );
};

export default Rules;
