import styled, { css } from 'styled-components';
import Icon from '../../icon';

export const Button = styled.button`
  background-color: #672ed7;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 19px;
  font-weight: var(--font-weight-medium);
  align-items: center;
  display: flex;
  justify-content: center;
  transition: background-color 0.15s ease-in;
  height: 2.75rem;
  padding: 8px 15px;
  width: ${(props) => (props.width ? props.width : '100%')};
  white-space: nowrap;

  @media (max-width: 1200px) {
    white-space: initial;
  }

  svg {
    height: 1rem;
    margin-right: 0.5rem;
    width: 1rem;
    fill: #2c2d5c;
    color: #2c2d5c;
    stroke: #2c2d5c;
  }

  :disabled {
    background-color: var(--color-secundary-05);
    border: 1px solid var(--color-secundary-06);
    color: var(--color-primary-03);
    cursor: not-allowed;

    svg {
      fill: var(--color-primary-03);
      stroke: var(--color-primary-03);
    }
  }

  ${(props) =>
    props.variant
      ? props.variant.includes('action') &&
        css`
          border-radius: 1rem;
        `
      : null}

  ${(props) =>
    props.variant
      ? (props.variant.includes('action') ||
          props.variant.includes('outlined')) &&
        css`
          font-weight: var(--font-weight-regular);
          /* letter-spacing: 0.01625rem; */
        `
      : null}

  ${(props) =>
    props.variant
      ? props.variant.includes('outlined') &&
        css`
          background: none;
          border: 0.0625rem solid #672ed7;
          color: #2c2d5c;

          svg {
            fill: #2c2d5c;
            color: #2c2d5c;
            stroke: #2c2d5c;
          }

          :hover {
            background: #48f06b;
            color: var(--color-primary-05);
          }

          :hover > svg {
            fill: var(--color-primary-05);
            color: var(--color-primary-05);
            stroke: var(--color-primary-05);
          }
        `
      : null}

  ${(props) =>
    props.variant
      ? props.variant === 'action-outlined' &&
        css`
          padding: 0 1rem;

          :disabled {
            background-color: var(--color-secundary-05);
            border: 0.0625rem solid var(--color-primary-03);
            color: var(--color-primary-03);
            cursor: not-allowed;
          }

          :disabled > svg {
            fill: var(--color-primary-03);
            stroke: var(--color-primary-03);
            cursor: not-allowed;
          }
        `
      : null}
`;

export const ButtonIcon = styled(Icon)`
  min-height: 15px;
  min-width: 15px;
`;
