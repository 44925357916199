import { createContext, useEffect } from "react"
import { IUseModificationTrackerReturn, useModificationTracker } from "../hooks/useModificationTracker"
import { IUseResaleDataReturn, useResale } from "../hooks/useResale"
import { IUseResaleDefaultRatesReturn, useResaleDefaultRates } from "../hooks/useResaleDefaultRates"
import { IUseUpdateTermConfigurationReturn, useUpdateTermConfiguration } from "../hooks/useUpdateTermConfiguration"

interface IRatesContext extends
  IUseModificationTrackerReturn,
  IUseResaleDataReturn,
  IUseResaleDefaultRatesReturn,
  IUseUpdateTermConfigurationReturn {
  resaleId: string
  isFinancedSalesAllowed: boolean,
}

export const RatesContext = createContext<IRatesContext>({} as IRatesContext)

interface IRatesProvider {
  children: React.ReactNode
  id: string
}

export const RatesProvider: React.FC<IRatesProvider> = ({ children, id }) => {
  const resale = useResale({ resaleId: id })
  const { resaleDefaultRates, isLoadingDefaultRates } = useResaleDefaultRates({ resaleId: id })
  const isFinancedSalesAllowed = resale.resaleData?.allowFinancedSales
  const modifyTracker = useModificationTracker()
  const update = useUpdateTermConfiguration({
    values: modifyTracker.modifications,
    resale: resale.resaleData
  })
  
  useEffect(() => {
    if (update.isSucceed) {
      modifyTracker.confirmAllModification()
    }
  }, [update.isSucceed])
  return (
    <RatesContext.Provider
      value={{
        resaleId: id,
        resaleDefaultRates,
        isFinancedSalesAllowed,
        isLoadingDefaultRates,
        ...update,
        ...modifyTracker,
        ...resale,
      }}
    >
      {children}
    </RatesContext.Provider>
  )
}
