import { useCallback, useRef } from 'react';

const useDebounce = () => {
  const debouncedAction = useRef(null);

  const debounce = useCallback((func, delay) => {
    clearTimeout(debouncedAction.current);
    debouncedAction.current = setTimeout(func, delay);
  }, []);

  return debounce;
};

export default useDebounce;
