import usePaginator from "main/hooks/usePaginator"
import { DotMenu, Grid, Input, Line, Pagination, Spinner, Table, Text } from "presentation/components"
import { Hidden } from "react-grid-system"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import { resalesCardsFeesInRequest } from "@/store/modules/resalesCardsFees/actions"
import { FilterModal, InfoModal } from "presentation/compositions"
import Button from "presentation/components/buttonV2"
import { TbFilter } from "react-icons/tb"
import ReactDOM from "react-dom"
import { Form, StatusDot, StatusWrapper, TextCenter } from "../styles"
import routesPaths from "presentation/routes/routesPaths"
import history from "@/services/history"
import { FORM_MODE, NOTIFICATION_TYPE, OPERATOR_CARD_FEE_STATUS } from "main/utils/constants"
import { typeFees, typeValueFees } from "main/utils/options"
import { Controller, useForm } from "react-hook-form"
import { pushNotification } from "@/store/modules/notification/actions"
import { patchResaleChangeCardFeeStatus } from "@/services/resalesServices"
import useService from "main/hooks/useService"
import successHandler from "main/utils/successHandler"
import { BsExclamationCircle, BsExclamationTriangle } from "react-icons/bs"
import { APPLICATIONFEES, CRITERIONFEES, RESALE_TYPEFEE, VALUETYPEFEES } from "main/utils/constants"
import { Search } from "presentation/components"

const ResaleFees = ({ operator }) => {
  const initialQuery = {
    sort: "code",
    desc: false,
  }
  const dispatch = useDispatch()
  const resalesCardsFees = useSelector(store => store.resalesCardsFees)
  const cardFeeToChangeStatus = useRef(null)
  const isCardFeeActive = cardFeeToChangeStatus.current?.status === OPERATOR_CARD_FEE_STATUS.ACTIVE
  const paginator = usePaginator({
    query: initialQuery,
  })
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [isChangeStatusConfirmationOpen, setIsChangeStatusConfirmationOpen] = useState(false)

  const [changeStatusState, changeStatusRequest] = useService(patchResaleChangeCardFeeStatus, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler(`Tarifa ${isCardFeeActive ? "inativada" : "ativada"}!`)))
      setIsChangeStatusConfirmationOpen(false)
      dispatch(resalesCardsFeesInRequest(paginator.buildQuery()))
    },
  })

  // eslint-disable-next-line
  useEffect(() => {
    if (paginator.data.doRequest) {
      dispatch(resalesCardsFeesInRequest(paginator.buildQuery()))
      paginator.preventRefresh()
    }
  }, [paginator, dispatch])

  useEffect(() => {
    paginator.updatePaginator({
      totalItens: resalesCardsFees?.data?.totalItens,
      totalPages: resalesCardsFees?.data?.totalPages,
    })
    // eslint-disable-next-line
  }, [resalesCardsFees])

  const filterForm = useForm()

  const sendToCardFeeVizualization = (cardFeeId, mode) => {
    history.push({
      pathname: routesPaths.resales.visualize_card_fee,
      state: {
        cardFeeId: cardFeeId,
        operator: operator,
        mode,
      },
    })
  }

  const onRowClick = cardFee => {
    sendToCardFeeVizualization(cardFee.feeConfigurationsId, FORM_MODE.VIEW)
  }

  return (
    <>
      <Grid padding="1px 16px 16px 16px" spaceBetweenRows="32px">
        <Grid.Row>
          <Grid.Col>
            <Table visibleRows={10} loading={resalesCardsFees?.loading}>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Código</Table.Th>
                  <Table.Th>Nome</Table.Th>
                  <Table.Th>Critério</Table.Th>
                  <Table.Th>Aplicação</Table.Th>
                  <Table.Th>Tipo de Taxa</Table.Th>
                  <Table.Th>Tipo de Valor</Table.Th>
                  <Table.Th>Status</Table.Th>
                  <Table.Th>Editar</Table.Th>
                  <Table.Th />
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {resalesCardsFees?.data?.data?.length > 0 ? (
                  resalesCardsFees.data.data.map(cardFee => (
                    <Table.Tr key={cardFee.id} onClick={() => onRowClick(cardFee)} interactable>
                      <Table.Td>{cardFee.code}</Table.Td>
                      <Table.Td>{cardFee.name}</Table.Td>
                      <Table.Td>{CRITERIONFEES[cardFee.criterion]}</Table.Td>
                      <Table.Td>{APPLICATIONFEES[cardFee.application]}</Table.Td>
                      <Table.Td>{RESALE_TYPEFEE[cardFee.valueType]}</Table.Td>
                      <Table.Td>{VALUETYPEFEES[cardFee.valueType]}</Table.Td>
                      <Table.Td>
                        <StatusWrapper>
                          <StatusDot status={cardFee.status} />
                          {OPERATOR_CARD_FEE_STATUS[cardFee.status]}
                        </StatusWrapper>
                      </Table.Td>
                      <Table.Td onClick={e => e.stopPropagation()}>
                        <DotMenu>
                          <DotMenu.Option
                            onClick={() => {
                              if (cardFee.status === OPERATOR_CARD_FEE_STATUS.ACTIVE)
                                sendToCardFeeVizualization(cardFee.id, FORM_MODE.EDIT)
                              else
                                dispatch(
                                  pushNotification({
                                    type: NOTIFICATION_TYPE.WARNING,
                                    content: "Para editar uma tarifa é necessário que ela esteja ativa.",
                                  })
                                )
                            }}
                          >
                            Editar
                          </DotMenu.Option>
                          <DotMenu.Option
                            onClick={() => {
                              cardFeeToChangeStatus.current = cardFee
                              setIsChangeStatusConfirmationOpen(true)
                            }}
                          >
                            {cardFee.status === OPERATOR_CARD_FEE_STATUS.ACTIVE ? "Inativar" : "Ativar"}
                          </DotMenu.Option>
                        </DotMenu>
                      </Table.Td>
                    </Table.Tr>
                  ))
                ) : (
                  <Table.Tr>
                    <Table.Td colSpan={8}>
                      <TextCenter>Sem registros</TextCenter>
                    </Table.Td>
                  </Table.Tr>
                )}
              </Table.Tbody>
            </Table>
          </Grid.Col>
        </Grid.Row>
      </Grid>
      <Hidden xs sm md>
        <Line overflow />
      </Hidden>
      <Grid>
        <Grid.Row align="center">
          <Grid.Col>
            <Pagination.ResultAmount
              paginator={paginator.data}
              setPageSize={newPageSize => paginator.setPageSize(newPageSize)}
            />
          </Grid.Col>
          <Grid.Col sm="content">
            <Pagination paginator={paginator.data} setPage={wantedPage => paginator.setPage(wantedPage)} />
          </Grid.Col>
        </Grid.Row>
      </Grid>
      <FilterModal
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        filter={filterForm.handleSubmit(data => {
          paginator.setFilters(data)
        })}
        clearFilter={() =>
          paginator.overrideFilters({
            ...initialQuery,
            pageSize: paginator.data.pageSize,
          })
        }
      >
        <Form>
          <Grid padding="0">
            <Grid.Row>
              <Grid.Col>
                <Text.Label>Tipo de taxa</Text.Label>
                <Controller
                  control={filterForm.control}
                  name="type"
                  render={({ field: { value, onChange } }) => (
                    <Input.Select
                      value={typeFees.find(option => option.value === value)}
                      onChange={option => onChange(option?.value ?? null)}
                      placeholder="Todos"
                      options={typeFees}
                    />
                  )}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Text.Label>Tipo de valor</Text.Label>
                <Controller
                  control={filterForm.control}
                  name="valueType"
                  render={({ field: { value, onChange } }) => (
                    <Input.Select
                      value={typeValueFees.find(option => option.value === value)}
                      onChange={option => onChange(option?.value ?? null)}
                      placeholder="Todos"
                      options={typeValueFees}
                    />
                  )}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </Form>
      </FilterModal>
      <InfoModal
        open={isChangeStatusConfirmationOpen}
        showCloseIcon={true}
        onClose={() => setIsChangeStatusConfirmationOpen(false)}
        closeOnOverlayClick={!changeStatusState.loading}
        closeOnEsc={!changeStatusState.loading}
      >
        {changeStatusState.loading ? (
          <Spinner.Box padding="32px">
            <Spinner />
          </Spinner.Box>
        ) : (
          <>
            <InfoModal.IconContainer color={isCardFeeActive ? "var(--color-status-02)" : "var(--color-status-01)"}>
              {isCardFeeActive ? <BsExclamationTriangle /> : <BsExclamationCircle />}
            </InfoModal.IconContainer>
            <InfoModal.Title color={isCardFeeActive ? "var(--color-status-02)" : "var(--color-status-01)"}>
              ATENÇÃO
            </InfoModal.Title>
            <InfoModal.Content>
              {`Você tem certeza que deseja ${isCardFeeActive ? "inativar" : "reativar"} essa tarifa?`}
            </InfoModal.Content>
            <InfoModal.ButtonsWrapper>
              <Button onClick={() => setIsChangeStatusConfirmationOpen(false)} variant="text">
                Cancelar
              </Button>
              <Button
                variant="ghost"
                predefTheme={isCardFeeActive ? "red" : "green"}
                onClick={() =>
                  changeStatusRequest(
                    cardFeeToChangeStatus.current.id,
                    isCardFeeActive ? OPERATOR_CARD_FEE_STATUS.INACTIVE : OPERATOR_CARD_FEE_STATUS.ACTIVE
                  )
                }
              >
                Confirmar
              </Button>
            </InfoModal.ButtonsWrapper>
          </>
        )}
      </InfoModal>
      {document.querySelector("#resale-data-portal-space-00") &&
        ReactDOM.createPortal(
          <Search
            search={value =>
              paginator.setFilters({
                filterValue: value,
                filterProps: "code,name,criterion,application",
              })
            }
            placeholder="Buscar por número ou nome"
          />,
          document.querySelector("#resale-data-portal-space-00")
        )}
      {document.querySelector("#resale-data-portal-space-01") &&
        ReactDOM.createPortal(
          <Button
            onClick={() => setIsFilterOpen(true)}
            icon={<TbFilter />}
            variant="ghost"
            theme={{
              bg: "#F76C39",
              hoverGhost: "#F76C390D",
            }}
          >
            Filtrar
          </Button>,
          document.querySelector("#resale-data-portal-space-01")
        )}
      {document.querySelector("#resale-data-portal-space-02") &&
        ReactDOM.createPortal(
          <Button
            disabled={!operator.tradeName}
            onClick={() => {
              history.push({
                pathname: routesPaths.resales.register_card_fee,
                state: {
                  operator,
                },
              })
            }}
          >
            Adicionar/Remover Taxa
          </Button>,
          document.querySelector("#resale-data-portal-space-02")
        )}
    </>
  )
}

export default ResaleFees
