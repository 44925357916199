/* eslint-disable jsx-a11y/label-has-associated-control */
import Modal from "react-responsive-modal"
import { Button } from "@mui/material"
import { Container, Content, ContentButtons, DateContent, Title } from "./styles"
import { companies } from "./utils/options"
import { Checkbox, ListItemText, MenuItem, Select } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useFilter } from "../../Table/hooks/useFilter"
import { status } from "../../Table/utils/status"
import dayjs from "dayjs"
import { useEffect } from "react"

interface IFilter {
  open: boolean
  onClose: () => void
  filterProps: {
    companies: string[]
    date: {
      from: string
      to: string
    }
    status: string[]
  }
  setFilterProps: (props: {
    companies: string[]
    date: {
      from: string
      to: string
    }
    status: string[]
  }) => void
}

export const Filter: React.FC<IFilter> = ({ open, onClose, setFilterProps, filterProps }) => {
  const { setFilter, filter } = useFilter()

  useEffect(() => {
    if (JSON.stringify(filter) !== JSON.stringify(filterProps)) {
      return setFilter({
        companies: [],
        date: {
          from: "",
          to: "",
        },
        status: [],
      })
    }
  }, [open])

  const isDisabled =
    (!filter.companies.length && !filter.status.length && !filter.date.from) ||
    new Date(filter.date.from) >= new Date(filter.date.to)

  return (
    <Modal open={open} onClose={onClose} styles={{ modal: { width: 378, position: "absolute", right: 0 } }}>
      <Container>
        <Title>
          <p>Filtrar</p>
        </Title>
        <p>Complete com as informações dos filtros que deseja aplicar.</p>
        <Content>
          <div>
            <label>Empresas</label>
            <Select
              multiple
              displayEmpty
              value={filter.companies.map(company => company)}
              renderValue={selected => {
                if (!selected.length) {
                  return <em>Selecione</em>
                }

                return selected.join(", ")
              }}
              style={{ height: 48 }}
              onChange={e => {
                const {
                  target: { value },
                } = e

                setFilter({
                  ...filter,
                  companies: typeof value === "string" ? value.split(",") : value,
                })
              }}
            >
              {companies.map(({ text: company }) => (
                <MenuItem key={company} value={company}>
                  <Checkbox checked={filter.companies.some(companyFilter => companyFilter === company)} />
                  <ListItemText primary={company} />
                </MenuItem>
              ))}
            </Select>
          </div>
          <div>
            <label>Data de cadastro</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateContent>
                <DatePicker
                  slotProps={{ textField: { placeholder: "De" } }}
                  value={filter.date.from ? dayjs(filter.date.from) : null}
                  format="DD/MM/YYYY"
                  onChange={e =>
                    setFilter({ ...filter, date: { ...filter.date, from: new Date(String(e)).toISOString() } })
                  }
                />
                <DatePicker
                  disabled={!filter.date.from}
                  slotProps={{ textField: { placeholder: "Até" } }}
                  minDate={dayjs(filter.date.from)}
                  value={filter.date.to ? dayjs(filter.date.to) : null}
                  format="DD/MM/YYYY"
                  onChange={e =>
                    setFilter({ ...filter, date: { ...filter.date, to: new Date(String(e)).toISOString() } })
                  }
                />
              </DateContent>
            </LocalizationProvider>
          </div>
          <div>
            <label>Status</label>
            <Select
              multiple
              displayEmpty
              value={filter.status.map(status => status)}
              renderValue={selected => {
                if (!selected.length) {
                  return <em>Selecione</em>
                }

                return selected.join(", ")
              }}
              style={{ height: 48 }}
              onChange={e => {
                const {
                  target: { value },
                } = e

                setFilter({
                  ...filter,
                  status: typeof value === "string" ? value.split(",") : value,
                })
              }}
            >
              {Object.values(status).map(text => (
                <MenuItem key={text} value={text}>
                  <Checkbox checked={filter.status.some(statusFilter => text === statusFilter)} />
                  <ListItemText primary={text} />
                </MenuItem>
              ))}
            </Select>
          </div>
        </Content>
        <ContentButtons>
          <Button
            variant="outlined"
            onClick={() => {
              setFilter({
                companies: [],
                date: {
                  from: "",
                  to: "",
                },
                status: [],
              })
              setFilterProps({
                companies: [],
                date: {
                  from: "",
                  to: "",
                },
                status: [],
              })
            }}
          >
            Limpar
          </Button>
          <Button
            variant="contained"
            disabled={isDisabled}
            onClick={() => {
              setFilterProps(filter)
              onClose()
            }}
          >
            Confirmar
          </Button>
        </ContentButtons>
      </Container>
    </Modal>
  )
}
