import { Grid, IconButton, Text } from "presentation/components";
import { CategorieTitleWrapper, FileLink, OperatorInformation } from "../styles";
import { useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import ComercialDataModal from "./editionModals/comercialDataModal";

const ComercialData = ({
  resaleData,
  isReviewing,
  onReview,
  onEdited
}) => {
  const valueChecker = (value) => (value && value !== "") ? value : "Não Informado";
  const [isFilesModalFormOpen, setIsFilesModalFormOpen] = useState(false);


  return (
    <>
      <Grid.Row>
        <Grid.Col sm={12}>
          <CategorieTitleWrapper>
            <Text.Subtitle>Dados Comerciais</Text.Subtitle>
            <IconButton onClick={() => isReviewing ? onReview() : setIsFilesModalFormOpen(true)}>
              <FaPencilAlt />
            </IconButton>
          </CategorieTitleWrapper>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12}>
          <Text.SubtitleTwo>Cópia Contratual .PDF</Text.SubtitleTwo>
          <FileLink href={resaleData?.document?.temporaryLink} target="blank" rel="noopener">
            {resaleData?.document?.fileName}
            <FontAwesomeIcon icon={faFilePdf} />
          </FileLink>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={12} md={12} lg={12}>
          <Text.SubtitleTwo>Periodo de Vigência</Text.SubtitleTwo>
          <OperatorInformation style={{
            display: 'flex',
            gap: '16px'
          }}>
            <span>
              Início: {valueChecker(
                moment(resaleData?.document?.startOfValidity).format('DD/MM/YYYY')
              )}
            </span>
            <span>
              Fim: {valueChecker(
                moment(resaleData?.document?.endOfValidity).format('DD/MM/YYYY')
              )}
            </span>
          </OperatorInformation>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={6} md={3} lg={3}>
          <Text.SubtitleTwo>Observações</Text.SubtitleTwo>
          <OperatorInformation>{valueChecker(resaleData?.document?.notes)}</OperatorInformation>
        </Grid.Col>
      </Grid.Row>
      {isFilesModalFormOpen &&
        <ComercialDataModal
          open={isFilesModalFormOpen}
          resaleData={resaleData}
          onClose={(hasEdited) => {
            setIsFilesModalFormOpen(false);
            hasEdited === true && onEdited();
          }}
        />
      }
    </>
  );
};

export default ComercialData;