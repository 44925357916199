import { BENEFIT_TYPES, OPERATOR_CARD_STATUS } from "@/main/utils/constants";
import { StatusDot, StatusWrapper } from "../../styles";
import { ActionsMenu } from "../../../components/actionsMenu";
import { MenuItem } from "@stationkim/front-ui";

export const getTableConfiguration = ({
  onEdit,
  onStatusChange
}) => {
  return ([
    { columnDisplayName: 'Código', columnValue: ({ row }) => row.code },
    { columnDisplayName: 'Nome', columnValue: ({ row }) => row.name },
    { columnDisplayName: 'Benefício', columnValue: ({ row }) => BENEFIT_TYPES[row.benefitType] },
    {
      columnDisplayName: 'Status',
      columnValue: () => null,
      customRender: ({ row }) => (
        <StatusWrapper>
          <StatusDot status={row.status} />
          {OPERATOR_CARD_STATUS[row.status]}
        </StatusWrapper>
      ),
    },
    {
      columnDisplayName: 'Ações',
      columnValue: () => null,
      customRender: ({ row }) => (
        <ActionsMenu>
          <MenuItem onClick={() => onEdit({ card: row })}>
            Editar
          </MenuItem>
          <MenuItem onClick={() => onStatusChange({ card: row })}>
            {row.status === OPERATOR_CARD_STATUS.ACTIVE ? "Inativar" : "Ativar"}
          </MenuItem>
        </ActionsMenu>
      ),
    },
  ])
}