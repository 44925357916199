import styled from "styled-components";

export const Wrapper = styled.span`
    position: relative;

    > * {
        transition: all 0.25s;
    }

    > div, label{
        color: ${({error}) => error && "var(--color-status-02)"};
    }

    * {
        border-color: ${({error}) => error && "var(--color-status-02)"} !important ;
    }
    
    > p {
        position: absolute;
    }
`