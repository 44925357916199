import styled from 'styled-components';

export const StyledTitle = styled.h1`
  font-size: 2rem;
  font-weight: var(--font-weight-bold);
`;

export const StyledSection = styled.h2`
  font-size: 1.5rem;
  font-weight: var(--font-weight-bold);
`;

export const StyledH3 = styled.h3`
  font-size: 1.5rem;
  font-weight: var(--font-weight-regular);
`;

export const StyledSubtitle = styled.h4`
  font-size: 1.125rem;
  font-weight: var(--font-weight-bold);
`;

export const StyledFilterSubtitle = styled.h4`
  font-size: 1.125rem;
  font-weight: var(--font-weight-bold);
`;

export const StyledMenuText = styled.h6`
  font-size: 1.125rem;
  font-weight: var(--font-weight-regular);
`;

export const StyledSubtitleOne = styled.p`
  font-size: 1.125rem;
  font-weight: var(--font-weight-semi-bold);
  font-family: Metropolis;
`;

export const StyledSubtitleTwo = styled.p`
  font-size: 1rem;
  font-weight: var(--font-weight-bold);
`;

export const StyledSubtitleThree = styled.p`
  font-size: 1rem;
  font-weight: var(--font-weight-semi-bold);
  font-family: Metropolis;
`;

export const StyledLabel = styled.label`
  font-size: 1rem;
  font-weight: var(--font-weight-bold);
`;

export const StyledBodyText = styled.p`
  font-size: 1rem;
  font-weight: var(--font-weight-regular);
`;

export const StyledHelpSubtitle = styled.p`
  font-size: 0.75rem;
  font-weight: var(--font-weight-medium);
  font-family: Metropolis;
`;
