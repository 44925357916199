import { useEffect, useState } from 'react';
import usePaginator from 'main/hooks/usePaginator';
import { OPERATORS_STATUS } from 'main/utils/constants';
import history from '@/services/history';
import routesPaths from 'presentation/routes/routesPaths';
import {
  Content,
  Panel,
} from 'presentation/components';
import {
  AutoTable,
  Button,
  PageTitle,
  Search,
  Icon,
  Grid,
  TableFooterPagination,
  useFilter,
  usePagination,
  atomWithHashAndName,
} from '@stationkim/front-ui'
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useOperators } from './hooks/useOperators';
import { atomWithHash } from 'jotai-location';
import { getTableConfiguration } from './utils/getTableConfiguration';
import { ContinueRegisterDialog } from '../components/continueRegisterDialog';
import { useForm } from 'react-hook-form';
import { Filter } from '../components/filter';
import { useSetAtom } from 'jotai';
import { operatorIdAtom } from '@/main/store';

const initialFilter = { sort: 'code', desc: true }
const filterAtom = atomWithHashAndName<object>('operatorsFilter', initialFilter)
const paginationAtom = atomWithHash<object>('operatorsPagination', null)

export const ListOperators = () => {
  const paginator = usePaginator();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [operatorToEdit, setOperatorToEdit] = useState(null);
  const setIsContinueDialogOpen = Boolean(operatorToEdit)
  const setOperatorId = useSetAtom(operatorIdAtom)
  const { filter, resetFilter, updateFilter } = useFilter({ filterAtom, initialValue: initialFilter })
  const { pagination, goToPage, setPageSize } = usePagination({ paginationAtom: paginationAtom })
  const { operators, isLoading: isOperatorsLoading } = useOperators({ filter, pagination })

  const form = useForm({
    defaultValues: filter || { status: '' }
  })

  const onEdit = (operatorId: string) => {
    history.push(generateLink({ operatorId }));
    setOperatorId(operatorId)
  }

  const generateLink = ({ operatorId }) => {
    return routesPaths.operators.visualize + `#operatorId="${operatorId}"`
  }

  const onRowClick = (operator) => {
    if (operator.status === OPERATORS_STATUS.PENDING) {
      setOperatorToEdit(operator);
    }
  };

  useEffect(() => {
    if (filter)
      for (const key in filter) {
        form.setValue(key as any, filter[key] ?? '')
      }
  }, [filter])

  return (
    <Content>
      <Panel noPadding>
        <PageTitle
          pageTitle='Operadoras'
        />
        <Grid container spacing={'16px'} padding={'32px 32px 0'}>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <Search
              onSearch={(value) =>
                paginator.setFilters({
                  filterValue: value
                    .replaceAll('.', '')
                    .replaceAll('/', '')
                    .replaceAll('-', ''),
                  filterProps: 'cnpj,tradeName,code',
                })
              }
              placeholder="Buscar Operadoras"
            />
          </Grid>
          <Grid item xs={12} sm={6} md='auto'>
            <Button
              onClick={() => setIsFilterOpen(true)}
              startIcon={<Icon icon={faFilter} />}
              variant='outlined'
              fullWidth
            >
              Filtrar
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md='auto' sx={{ marginLeft: 'auto' }}>
            <Button
              variant='contained'
              fullWidth
              onClick={() =>
                history.push(routesPaths.operators.checkCnpjForRegister)
              }
            >
              Nova Operadora
            </Button>
          </Grid>
          <Grid item xs={12}>
            <AutoTable
              isLoading={isOperatorsLoading}
              link={({ row }) => row.status === OPERATORS_STATUS.PENDING ? null : generateLink({ operatorId: row.id })}
              onRowClick={({ row }) => onRowClick(row)}
              columns={getTableConfiguration({ updateFilter, onEdit })}
              rows={operators?.data}
            />
          </Grid>
          <Grid item xs={12}>
            <TableFooterPagination
              totalItems={operators?.totalItens}
              rowsPerPage={operators?.pageSize}
              totalPages={operators?.totalPages}
              page={operators?.pageNumber}
              onRowsPerPageChange={(e: any) => setPageSize(e.target.value)}
              onPageChange={(e, value) => goToPage(Number(value))}
            />
          </Grid>
        </Grid>
      </Panel>
      <Filter
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onClean={() => {
          resetFilter()
          setIsFilterOpen(false)
          form.reset()
        }}
        onFilter={(data) => {
          updateFilter(data)
          setIsFilterOpen(false)
        }}
        form={form}
      />
      <ContinueRegisterDialog
        open={setIsContinueDialogOpen}
        onClose={() => setOperatorToEdit(null)}
        onConfirm={() => {
          history.push({
            pathname: routesPaths.operators.register,
            state: {
              cnpj: operatorToEdit.cnpj,
              id: operatorToEdit.id,
            },
          })
        }}
      />
    </Content>
  );
};