import styled from 'styled-components';

export const StyledLine = styled.div`
  border-top: 1px solid ${({ color }) => color};
  width: 100%;
`;

export const OverflowingContainer = styled.div`
  position: relative;
  > div {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 110vw;
  }
`;
