import { useState, useMemo } from 'react';
import { Notification, Button, Dropzone } from 'presentation/components';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import api from '@/infra/api';
import ModalLoading from '../../components/modalLoading';
import { useDispatch } from 'react-redux';
import errorHandler from 'main/utils/errorHandler';
import { pushNotification } from '@/store/modules/notification/actions';
import {
  Body,
  Title,
  SubTitle,
  Space,
  FlexRight,
  Limited,
  TextArea,
  Label,
} from './styles';

const Files = ({ setPosition, id }) => {
  const [, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const [fileOne, setFileOne] = useState([]);
  const [fileTwo, setFileTwo] = useState([]);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [message1, setMessage1] = useState(null);
  const [message2, setMessage2] = useState(null);
  const screenClass = useScreenClass();

  const dispatch = useDispatch();

  const handleOnDrop = async (e) => {
    const extension = e[0] && e[0].name.split('.').pop();

    if (extension !== 'txt') {
      setError1(true);
      setMessage1('Permitido somente arquivos .TXT');
    } else {
      setError1(false);
      setMessage1(null);
      setFileOne(e);
    }
  };

  const handleOnDrop2 = async (e) => {
    const extension = e[0] && e[0].name.split('.').pop();

    if (extension !== 'txt') {
      setError2(true);
      setMessage2('Permitido somente arquivos .TXT');
    } else {
      setError2(false);
      setMessage2(null);
      setFileTwo(e);
    }
  };

  const saveFiles = async (e) => {
    setLoading(true);
    try {
      if (fileTwo[0] && fileOne[0]) {
        setError1(false);
        setError2(false);
        setMessage1(null);
        setMessage2(null);
        const data = new FormData();
        data.append('EmployeeLayoutFile', fileOne[0].path ? fileOne[0] : null);
        data.append('OrderLayoutFile', fileTwo[0].path ? fileTwo[0] : null);
        data.append('OperatorId', id);
        data.append('Notes', notes ? notes : '');

        await api.post('/operators/registration/documents', data);
        setLoading(false);
        setPosition(5);
      } else {
        setLoading(false);
        if (!fileTwo.length) {
          setError2(true);
          setMessage2('Necessário o envio do arquivo!');
        }

        if (!fileOne.length) {
          setError1(true);
          setMessage1('Necessário o envio do arquivo!');
        }
        return;
      }
    } catch (e) {
      dispatch(pushNotification(errorHandler(e?.response)));
      setLoading(false);
      setError(true);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/operators/${id}/registration/documents`);

      if (response?.data?.documents?.length === 2) {
        // eslint-disable-next-line
        response.data.documents.map((data) => {
          if (data.type === 1) {
            setFileOne([{ ...data, name: data.fileName }]);
          }

          if (data.type === 2) {
            setFileTwo([{ ...data, name: data.fileName }]);
          }
        });
      }

      setNotes(response?.data.notes);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useMemo(() => {
    if (id) getData();
    // eslint-disable-next-line
  }, [id]);

  function analyseError(file, position) {
    if (file.errors[0].code === 'file-too-large') {
      if (position === 1) {
        setError1(true);
        setMessage1('Não são permitidos arquivos maiores que 1MB');
      } else {
        setError1(false);
        setMessage1(null);
      }

      if (position === 2) {
        setError2(true);
        setMessage2('Não são permitidos arquivos maiores que 1MB');
      } else {
        setError2(false);
        setMessage2(null);
      }
    }
  }
  return (
    <Body>
      <Notification />
      {loading && (
        <ModalLoading open={loading} close={() => setLoading(false)} />
      )}
      <>
        <Title>Envio de documentos</Title>
        <SubTitle>
          Esse layout é fornecido pela operadora para exportação dos pedidos.
          Será necessário para operação Broker.
        </SubTitle>
        <Space />
        <Container fluid style={{ padding: 0 }}>
          <Row>
            <Col md={12} ld={6}>
              <Dropzone.Line2
                accept=".txt"
                formatsLabel="TXT"
                onDrop={(e) => {
                  handleOnDrop(e);
                }}
                onDropRejected={(dropedFile) => analyseError(dropedFile[0], 1)}
                maxSize={1000000}
                multiple={false}
                setError={setError}
                error={error1}
                fileName={fileOne ? 'ok' : false}
                cancelUpload={() => null}
                uploadingFile={{ name: fileOne[0]?.name }}
                exclude={() => setFileOne([])}
                title="1. Layout Colaborador"
              />
              {message1 && (
                <p style={{ color: 'red', paddingTop: '10px' }}>{message1}</p>
              )}
            </Col>
          </Row>
          <Space />
          <Row>
            <Col md={12} ld={6}>
              <Dropzone.Line2
                accept=".txt"
                formatsLabel="TXT"
                onDrop={(e) => {
                  handleOnDrop2(e);
                }}
                onDropRejected={(dropedFile) => analyseError(dropedFile[0], 2)}
                maxSize={1000000}
                multiple={false}
                setError={setError}
                cancelUpload={() => null}
                uploadingFile={{ name: fileTwo[0]?.name }}
                exclude={() => setFileTwo([])}
                error={error2}
                title="2. Layout Pedido"
              />
              {message2 && (
                <p style={{ color: 'red', paddingTop: '10px' }}>{message2}</p>
              )}
            </Col>
          </Row>
          <Space />
          <Row>
            <Col md={12} ld={6}>
              <Label>Observações da operadora</Label>
              <TextArea
                value={notes}
                onChange={(ev) => setNotes(ev.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={['xs', 'sm'].includes(screenClass) ? 12 : null}>
              <FlexRight>
                <Limited>
                  <Button
                    variant="outlined"
                    type="button"
                    onClick={() => setPosition(3)}
                  >
                    Cancelar
                  </Button>
                  <div style={{ marginLeft: '40px' }} />
                  <Button type="button" onClick={() => saveFiles()}>
                    Continuar
                  </Button>
                </Limited>
              </FlexRight>
            </Col>
          </Row>
        </Container>
      </>
    </Body>
  );
};

export default Files;
