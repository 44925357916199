import styled from 'styled-components';

export const Main = styled.div`
  height: 100vh;
  background: rgb(95, 41, 202);
  background: linear-gradient(
    360deg,
    rgba(95, 41, 202, 1) 0%,
    rgba(9, 9, 121, 1) 100%,
    rgba(150, 116, 217, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Body = styled.div`
  background-color: #fff;
  border-radius: 15px;
  width: 35%;
  padding: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.h1`
  text-align: center;
  color: #5e28ca;
  font-size: 1.5em;
  margin: 30px 0 30px 0;
`;

export const Label = styled.div`
  margin-bottom: 10px;
`;

export const ForgetPassword = styled.div`
  text-align: center;
  font-weight: 700;
  color: #757576;
  padding-top: 30px;
  margin-bottom: 40px;
  cursor: pointer;
`;

export const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const Text = styled.p`
  color: var(--color-primary-04);
  font-size: 18px;
  margin-bottom: 40px;
`;
