export const IconPlan = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5625 0.205078C10.1484 -0.0683594 10.8125 -0.0683594 11.3984 0.205078L19.9531 4.15039C20.2656 4.30664 20.5 4.61914 20.5 4.9707C20.5 5.36133 20.2656 5.67383 19.9531 5.83008L11.3984 9.77539C10.8125 10.0488 10.1484 10.0488 9.5625 9.77539L1.00781 5.83008C0.695312 5.67383 0.5 5.36133 0.5 4.9707C0.5 4.61914 0.695312 4.30664 1.00781 4.15039L9.5625 0.205078Z"
        fill="#1565C0"
      />
      <path
        d="M20.5 9.9707C20.5 9.61914 20.2656 9.30664 19.9531 9.15039L17.8437 8.17383L11.9062 10.9082C11.0078 11.3379 9.95312 11.3379 9.05469 10.9082L3.11719 8.17383L1.00781 9.15039C0.695312 9.30664 0.5 9.61914 0.5 9.9707C0.5 10.3613 0.695312 10.6738 1.00781 10.8301L9.5625 14.7754C10.1484 15.0488 10.8125 15.0488 11.3984 14.7754L19.9531 10.8301C20.2656 10.6738 20.5 10.3613 20.5 9.9707Z"
        fill="white"
      />
      <path
        d="M20.5 9.9707C20.5 9.61914 20.2656 9.30664 19.9531 9.15039L17.8437 8.17383L11.9062 10.9082C11.0078 11.3379 9.95312 11.3379 9.05469 10.9082L3.11719 8.17383L1.00781 9.15039C0.695312 9.30664 0.5 9.61914 0.5 9.9707C0.5 10.3613 0.695312 10.6738 1.00781 10.8301L9.5625 14.7754C10.1484 15.0488 10.8125 15.0488 11.3984 14.7754L19.9531 10.8301C20.2656 10.6738 20.5 10.3613 20.5 9.9707Z"
        fill="#1976D2"
        fill-opacity="0.75"
      />
      <path
        d="M20.5 14.894C20.5 14.5425 20.2656 14.23 19.9531 14.0737L17.8437 13.0972L11.9062 15.8315C11.0078 16.2612 9.95312 16.2612 9.05469 15.8315L3.11719 13.0972L1.00781 14.0737C0.695312 14.23 0.5 14.5425 0.5 14.894C0.5 15.2847 0.695312 15.5972 1.00781 15.7534L9.5625 19.6987C10.1484 19.9722 10.8125 19.9722 11.3984 19.6987L19.9531 15.7534C20.2656 15.5972 20.5 15.2847 20.5 14.894Z"
        fill="white"
      />
      <path
        d="M20.5 14.894C20.5 14.5425 20.2656 14.23 19.9531 14.0737L17.8437 13.0972L11.9062 15.8315C11.0078 16.2612 9.95312 16.2612 9.05469 15.8315L3.11719 13.0972L1.00781 14.0737C0.695312 14.23 0.5 14.5425 0.5 14.894C0.5 15.2847 0.695312 15.5972 1.00781 15.7534L9.5625 19.6987C10.1484 19.9722 10.8125 19.9722 11.3984 19.6987L19.9531 15.7534C20.2656 15.5972 20.5 15.2847 20.5 14.894Z"
        fill="#1976D2"
        fill-opacity="0.4"
      />
    </svg>
  )
}
