import Modal from 'react-modal';
import Button from 'presentation/components/buttonV2';
import { Body, Text, Flex } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '15px',
  },
};

const ModalStatus = ({ open, close, confirm, type }) => {
  return (
    <Modal isOpen={open} onRequestClose={close} style={customStyles} s>
      <Body>
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          style={{ fontSize: '90px', color: '#fccd2f' }}
        />
        <Text>
          Você tem certeza que deseja{' '}
          <strong>{type === 1 ? 'Inativar' : 'Reativar'} </strong>
          essa taxa? <br />
          <br />
        </Text>
        <Flex>
          <Button variant="text" onClick={() => close()}>
            Cancelar
          </Button>
          <div style={{ marginLeft: '40px' }} />
          <Button type="submit" onClick={() => confirm()}>
            Continuar
          </Button>
        </Flex>
      </Body>
    </Modal>
  );
};

export default ModalStatus;
