import styled from 'styled-components';

export const TitleFee = styled.p`
  font-weight: bold;
`;

export const Label = styled.div`
  margin-bottom: 10px;
  white-space: nowrap;
`;

export const Flex = styled.div`
  display: flex;
`;

export const Text = styled.p`
  font-weight: bold;
  margin-bottom: 10px;
`;
