import { useEffect } from "react"
import { UseFormReturn, useWatch } from "react-hook-form"
import { IUseModificationTrackerReturn, TargetModification } from "./useModificationTracker"

interface UseTermFormModificationHandlerProps {
  form: UseFormReturn,
  target: TargetModification,
  setModification?: IUseModificationTrackerReturn['setModification'],
}

export const useTermFormModificationHandler = ({ form, target, setModification }: UseTermFormModificationHandlerProps) => {
  const values = useWatch({ control: form.control })

  useEffect(() => {
    const modifiedRowsInitialIndex = Object.keys(form.formState.dirtyFields)
    //check if form has a dirty filed comparing actual values with the default ones
    if (modifiedRowsInitialIndex.length === 0) {
      setModification({ target, modification: { value: null, isEditing: false } })
      return
    }
    const actualValues = form.getValues()
    const modifiedRows = modifiedRowsInitialIndex.map(rowInitialIndex => actualValues[rowInitialIndex])
    setModification({ target, modification: { value: modifiedRows, isEditing: true } })
  }, [values])
}