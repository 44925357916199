import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Button, Dialog } from '@stationkim/components-lib';
import {
  ButtonLine,
  ModalContent,
  ModalIcon,
  ModalIconFrame,
  StyledDialogDescription,
  StyledDialogTitle,
} from './styles';

export const ModalFee = ({ props }) => {
  const { sendToLeftConfirm, setOpen, open, setDiffModalHandle } = props;

  return (
    <Dialog.Root open={open}>
      <Dialog.Content>
        <ModalContent>
          <ModalIconFrame>
            <ModalIcon
              icon={faTriangleExclamation}
              css={{ color: '$atention200' }}
            />
          </ModalIconFrame>
          <StyledDialogTitle css={{ color: '$atention200' }}>
            REMOVER TAXA VINCULADA
          </StyledDialogTitle>
          <StyledDialogDescription>
            Você tem certeza que deseja remover o vinculo da taxa com a revenda?
          </StyledDialogDescription>
          <ButtonLine>
            <Button
              buttonType="text"
              onClick={() => {
                setDiffModalHandle(false);
                setOpen(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              buttonType="outlined"
              color="atention"
              onClick={() => {
                setDiffModalHandle(false);
                sendToLeftConfirm();
              }}
            >
              Confirmar
            </Button>
          </ButtonLine>
        </ModalContent>
      </Dialog.Content>
    </Dialog.Root>
  );
};
