import { Panel, Search, Spacing } from "presentation/components";
import { PanelContainer, Section, TitlePanel } from "../styles";

const PainelList = ({ props, children }) => {
  const { filter, setFilter, title } = props;

  return (
    <Panel style={{ maxWidth: "40%" }}>
      <PanelContainer>
        <TitlePanel>{title}</TitlePanel>
        <Section>
          <Search
            placeholder="Buscar na lista abaixo"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Section>
        <Spacing top="32px" />
        {children}
      </PanelContainer>
    </Panel>
  );
};

export default PainelList;
