import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { useTheme } from "@mui/material"
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Button, Icon } from "@stationkim/front-ui"

interface ContinueRegisterDialogProps extends DialogProps {
  onConfirm: () => void
}

export const ContinueRegisterDialog = ({ onConfirm, ...props }: ContinueRegisterDialogProps) => {
  const theme = useTheme()
  return (
    <Dialog {...props} >
      <Icon icon={faExclamationCircle} sx={{ height: '64px', marginTop: '32px', color: theme.palette.warning.main }} />
      <DialogTitle sx={{ textAlign: 'center', margin: '32px auto' }}>CNPJ AGUARDANDO CADASTRO</DialogTitle>
      <DialogContent sx={{ width: '80%', margin: 'auto' }}>
        O CNPJ informado possui um cadastro em andamento.
        <br />
        Ao clicar em confirmar você será direcionado para o ponto onde o
        processo foi interrompido.
      </DialogContent>
      <DialogActions sx={{ display: 'flex', gap: '32px', padding: '32px' }}>
        <Button onClick={(e) => props.onClose(e, 'escapeKeyDown')} fullWidth>Cancelar</Button>
        <Button onClick={(e) => onConfirm()} variant='outlined' fullWidth>Confirmar</Button>
      </DialogActions>
    </Dialog>
  )
}
