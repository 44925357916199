import React, { useRef, useState } from "react";
import _ from "lodash";
import { Grid, IconButton, Text } from "presentation/components";
import { AddEntrieTextButton, CategorieTitleWrapper, EntriesCounter, OperatorInformation } from "../styles";
import { CONTACT_TYPES } from "main/utils/constants";
import { applyMask, returnValueOrMessageIfIsEmpty } from "main/utils/functionUtils";
import ContactModal from "./editionModals/contactModal";
import { FaPencilAlt, FaRegTrashAlt } from "react-icons/fa";
import useService from "main/hooks/useService";
import { pushNotification } from "@/store/modules/notification/actions";
import successHandler from "main/utils/successHandler";
import { useDispatch } from "react-redux";
import { putEditResaleContacts } from "@/services/resalesServices";

const emptyMessage = "Não informado";

const Contacts = ({
  resaleData,
  isReviewing,
  onReview,
  onEdited = () => null
}) => {

  const dispatch = useDispatch();
  const [isContactModalFormOpen, setIsContactModalFormOpen] = useState(false);
  const contactIndexToEdit = useRef(null);
  const isMultiLine = resaleData.contacts.length > 1;
  const [, deleteContactRequest] = useService(putEditResaleContacts, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler("Contato removido")));
      onEdited();
    },
  });

  const deleteContact = (index) => {
    const newContacts = resaleData.contacts;
    newContacts.splice(index, 1);
    deleteContactRequest(resaleData.id, newContacts);
  };

  const defineContactToEdit = (addressIndex) => {
    contactIndexToEdit.current = addressIndex;
    setIsContactModalFormOpen(true);
  }

  const contactsRender = (resaleData) => {
    if (!resaleData.contacts) return;
    const mainContactIndex = resaleData.contacts.findIndex(contact => contact?.contactType === CONTACT_TYPES.MAIN);
    const contactsToRender = [...resaleData.contacts];
    if (mainContactIndex) {
      [contactsToRender[0], contactsToRender[mainContactIndex]] = [contactsToRender[mainContactIndex], contactsToRender[0]];
    }
    return contactsToRender.map((contact, index) => (
      <React.Fragment key={_.uniqueId()}>
        {isMultiLine ?
          <Grid.Row>
            <Grid.Col>
              <EntriesCounter>
                <Text.SubtitleTwo>{index + 1}.</Text.SubtitleTwo>
                {isReviewing ? null :
                  <>
                    <IconButton onClick={() => defineContactToEdit(index)}><FaPencilAlt /></IconButton>
                    {contact?.contactType !== CONTACT_TYPES.MAIN &&
                      <IconButton onClick={() => deleteContact(index)}><FaRegTrashAlt /></IconButton>
                    }
                  </>
                }
              </EntriesCounter>
            </Grid.Col>
          </Grid.Row> : null
        }
        <Grid.Row >
          <Grid.Col xs={12} sm={6} md={3} lg={3}>
            <Text.SubtitleTwo>Nome completo</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(contact?.fullName, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
            <Text.SubtitleTwo>E-mail</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(contact?.email, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs", "sm"]}>
            <Text.SubtitleTwo>Cargo</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(contact?.role, emptyMessage)}</OperatorInformation>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col xs={12} sm={6} md={3} lg={3}>
            <Text.SubtitleTwo>Tipo de contato</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(CONTACT_TYPES[contact?.contactType], emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
            <Text.SubtitleTwo>Telefone</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(applyMask("(##) ####-#####", contact?.phone), emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs", "sm"]}>
            <Text.SubtitleTwo>Ramal</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(contact?.extension, emptyMessage)}</OperatorInformation>
          </Grid.Col>
        </Grid.Row>
      </React.Fragment>
    ));
  };

  return (
    <>
      <Grid.Row>
        <Grid.Col xs={12} >
          <CategorieTitleWrapper>
            <Text.Subtitle>{isMultiLine ? "Contatos" : "Contato"}</Text.Subtitle>
            {!isMultiLine || isReviewing ?
              <IconButton
                onClick={() => isReviewing ? onReview() : defineContactToEdit(0)}
              >
                <FaPencilAlt />
              </IconButton> : null
            }
          </CategorieTitleWrapper>
        </Grid.Col>
      </Grid.Row>
      {contactsRender(resaleData)}
      {isReviewing ? null :
        <Grid.Row>
          <Grid.Col xs={12}>
            <AddEntrieTextButton onClick={() => setIsContactModalFormOpen(true)}>Adicionar contato</AddEntrieTextButton>
          </Grid.Col>
        </Grid.Row>
      }
      {isContactModalFormOpen &&
        <ContactModal
          open={isContactModalFormOpen}
          contactIndex={contactIndexToEdit.current}
          resaleData={resaleData}
          onClose={(hasEdited) => {
            setIsContactModalFormOpen(false);
            contactIndexToEdit.current = null;
            hasEdited === true && onEdited();
          }}
        />
      }
    </>
  );
};

export default Contacts;