import { Body } from './styles';
import { Spinner } from 'presentation/components';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ModalLoading = ({ open, close }) => {
  return (
    <Modal isOpen={open} onRequestClose={close} style={customStyles}>
      <Body>
        <Spinner />
      </Body>
    </Modal>
  );
};

export default ModalLoading;
