import { MenuItem } from "@stationkim/front-ui";
import { BENEFIT_TYPES, OPERATOR_CARD_FEE_STATUS } from "@/main/utils/constants";
import { StatusDot, StatusWrapper } from "../../styles";
import { formatMoney } from "@/main/utils/functions";
import { ActionsMenu } from "../../../components/actionsMenu";

export const getTableConfiguration = ({
  onEdit,
  onStatusChange
}) => {
  return (
    [
      { columnDisplayName: 'Código', columnValue: ({ row }) => row.code },
      { columnDisplayName: 'Nome', columnValue: ({ row }) => row.operatorName },
      { columnDisplayName: 'Critério', columnValue: ({ row }) => BENEFIT_TYPES[row.benefit] },
      { columnDisplayName: 'Tipo de Taxa', columnValue: ({ row }) => row.description },
      { columnDisplayName: 'Tipo de Valor', columnValue: ({ row }) => row.cardName },
      {
        columnDisplayName: 'Status',
        columnValue: () => null,
        customRender: ({ row }) => (
          <StatusWrapper>
            <StatusDot status={row.status} />
            {OPERATOR_CARD_FEE_STATUS[row.status]}
          </StatusWrapper>
        )
      },
      { columnDisplayName: 'Valor', columnValue: ({ row }) => `R$ ${formatMoney(row.value)}` },
      {
        columnDisplayName: 'Ações',
        columnValue: () => null,
        preventLink: true,
        customRender: ({ row }) => (
          <ActionsMenu>
            <MenuItem onClick={() => onEdit({ card: row })}>
              Editar
            </MenuItem>
            <MenuItem onClick={() => onStatusChange({ card: row })}>
              {row.status === OPERATOR_CARD_FEE_STATUS.ACTIVE ? "Inativar" : "Ativar"}
            </MenuItem>
          </ActionsMenu>
        )
      },
    ]
  )

}