import api from '@/infra/api'
import { IFetchHook } from '@stationkim/front-ui'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

const defaultValue = {
  data: [],
  pageNumber: 1,
  pageSize: 10,
  totalItens: 0,
  totalPages: 1,
}

interface IApiReturn {
  data: Array<{
    benefit: number
    cardName: string
    code: string
    description: string
    id: string
    operatorName: string
    status: number
    value: number
  }>
  pageNumber: number
  pageSize: number
  totalItens: number
  totalPages: number
}

interface IUseOperatorCardsFees extends IFetchHook {
  operatorId: string
}

export const useOperatorCardsFees = ({ operatorId, filter, pagination, immutableQuery }: IUseOperatorCardsFees) => {
  const { data, ...rest } = useQuery<AxiosResponse<IApiReturn>>({
    enabled: Boolean(operatorId),
    queryKey: ['operatorCardsFees', operatorId, filter, pagination, immutableQuery],
    queryFn: () =>
      api.get(`/products?operatorId=${operatorId}`, {
        params: { ...pagination, ...filter, ...immutableQuery },
      }),
  })

  return {
    operatorCardsFees: data?.data || defaultValue,
    ...rest,
  }
}