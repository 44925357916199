import { useRef } from 'react';
import { FaSearch } from 'react-icons/fa';
import { SearchContainer, Field } from './style';

const Search = ({ search, preventEnter, searchOnBlur, ...props }) => {
  const ref = useRef(null);

  return (
    <SearchContainer>
      <FaSearch onClick={() => search(ref.current.value)} />
      <Field
        ref={ref}
        {...props}
        onKeyDown={(e) => {
          if (!preventEnter && e.key === 'Enter') search(ref.current.value);
        }}
        onBlur={() => searchOnBlur && search(ref.current.value)}
      />
    </SearchContainer>
  );
};

export default Search;
