import { StyledButton } from './styles';

const DEFAULT_THEMES = {
  primary: {
    bg: '#672ED7',
    fg: '#FCFDFF',
    hover: '#432184',
    hoverGhost: '#672ED70D',
  },
  secondary: {
    bg: '#28252E',
    fg: '#FCFDFF',
    hover: '#6B6576',
    hoverGhost: '#28252E0D',
  },
  green: {
    bg: '#00E077',
    fg: '#FCFDFF',
    hover: '#00a95a',
    hoverGhost: '#00E0770D',
  },
  red: {
    bg: '#E02E00',
    fg: '#FCFDFF',
    hover: '#b92904',
    hoverGhost: '#E02E000D',
  },
};

const Button = ({
  children,
  icon,
  size = 'medium',
  variant = 'default',
  predefTheme = 'primary',
  theme,
  ...props
}) => {
  return (
    <StyledButton
      variant={variant}
      size={size}
      predefTheme={DEFAULT_THEMES[predefTheme]}
      theme={theme}
      {...props}
    >
      {icon && icon}
      {children}
    </StyledButton>
  );
};

export default Button;
