import { Grid, IconButton, Spinner, StatusTag, Switch, Text } from "presentation/components";
import { applyMask, returnValueOrMessageIfIsEmpty } from "main/utils/functionUtils";
import { useState } from "react";
import { CategorieTitleWrapper, OperatorInformation } from "../styles";
import GeneralDataModal from "./editionModals/generalDataModal";
import { FaPencilAlt } from "react-icons/fa";
import { OPERATORS_STATUS } from "main/utils/constants";
import useService from "main/hooks/useService";
import { patchOperatorChangeStatus } from "@/services/operatorsServices";
import { useDispatch } from "react-redux";
import { pushNotification } from "@/store/modules/notification/actions";
import successHandler from "main/utils/successHandler";

const checkStatusColor = (status) => {
  if (status === 0) return 'yellow';
  if (status === 1) return 'green';
  if (status === 2) return 'red';
}
const emptyMessage = "Não informado";

const GeneralData = ({ operator, onEdited = () => null }) => {

  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const isOperatorActive = operator.status === OPERATORS_STATUS.ACTIVE;
  const [changeStatusState, changeStatusRequest] = useService(patchOperatorChangeStatus, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler(`Operadora ${isOperatorActive ? "inativada" : "ativada"}!`)));
      onEdited();
    },
  });

  return (
    <>
      <Grid.Row align="center" justify="between">
        <Grid.Col >
          <CategorieTitleWrapper>
            <Text.Subtitle>Dados Gerais da Operadora</Text.Subtitle>
            <IconButton onClick={() => setIsEditing(true)}><FaPencilAlt /></IconButton>
          </CategorieTitleWrapper>
        </Grid.Col>
        <Grid.Col sm="content">
          {changeStatusState.loading ? <Spinner variant="sm" /> :
            <Switch
              onChange={() =>
                changeStatusRequest(operator.id, isOperatorActive ?
                  OPERATORS_STATUS.INACTIVE : OPERATORS_STATUS.ACTIVE)
              }
              checked={operator.status === OPERATORS_STATUS.INACTIVE}
            />
          }
        </Grid.Col>
        <Grid.Col xs={12} sm="content">
          <StatusTag
            color={checkStatusColor(operator?.status)}
          >
            {OPERATORS_STATUS[operator?.status]}
          </StatusTag>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={6} md={3} lg={3}>
          <Text.SubtitleTwo>CNPJ</Text.SubtitleTwo>
          <OperatorInformation>
            {returnValueOrMessageIfIsEmpty(applyMask("##.###.###/####-##", operator.cnpj), emptyMessage)}
          </OperatorInformation>
        </Grid.Col>
        <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
          <Text.SubtitleTwo>Código da Empresa</Text.SubtitleTwo>
          <OperatorInformation>{returnValueOrMessageIfIsEmpty(operator.code, emptyMessage)}</OperatorInformation>
        </Grid.Col>
        <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
          <Text.SubtitleTwo>Dias para crédito</Text.SubtitleTwo>
          <OperatorInformation>{returnValueOrMessageIfIsEmpty(operator.creditProcessingDays, emptyMessage)}</OperatorInformation>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={6} md={3} lg={3}>
          <Text.SubtitleTwo>Razão Social</Text.SubtitleTwo>
          <OperatorInformation>{returnValueOrMessageIfIsEmpty(operator.corporateName, emptyMessage)}</OperatorInformation>
        </Grid.Col>
        <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
          <Text.SubtitleTwo>Nome Fantasia</Text.SubtitleTwo>
          <OperatorInformation>{returnValueOrMessageIfIsEmpty(operator.tradeName, emptyMessage)}</OperatorInformation>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={6} md={3} lg={3}>
          <Text.SubtitleTwo>Inscrição estadual</Text.SubtitleTwo>
          <OperatorInformation>{returnValueOrMessageIfIsEmpty(operator.stateRegistration, emptyMessage)}</OperatorInformation>
        </Grid.Col>
        <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
          <Text.SubtitleTwo>E-mail</Text.SubtitleTwo>
          <OperatorInformation>{returnValueOrMessageIfIsEmpty(operator.email, emptyMessage)}</OperatorInformation>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={6} md={3} lg={3}>
          <Text.SubtitleTwo>Inscrição municipal</Text.SubtitleTwo>
          <OperatorInformation>{returnValueOrMessageIfIsEmpty(operator.municipalRegistration, emptyMessage)}</OperatorInformation>
        </Grid.Col>
        <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
          <Text.SubtitleTwo>Site</Text.SubtitleTwo>
          <OperatorInformation>{returnValueOrMessageIfIsEmpty(operator.site, emptyMessage)}</OperatorInformation>
        </Grid.Col>
      </Grid.Row>
      <GeneralDataModal
        open={isEditing}
        operator={operator}
        onClose={(hasEdited) => {
          setIsEditing(false);
          hasEdited && onEdited();
        }}
      />
    </>
  );

};

export default GeneralData;