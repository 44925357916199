import * as yup from "yup";

const addressRegex = /^[0-9A-Za-zªºáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]{1,}\s[0-9A-Za-zªºáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]{1,}/;

const schema = yup.object().shape({
  abortEarly: false,
  laneName: yup.string().max(120).matches(addressRegex, {
    message: "O endereço deve ser composto",
    excludeEmptyString: true,
  }).required("Endereço é um campo obrigatório"),
  number: yup.string().max(5).required("Número é um campo obrigatório"),
  addressType: yup.number().required("Tipo de endereço é um campo obrigatório"),
  complement: yup.string().max(30),
  name: yup.string().max(30).required("Nome do endereço é um campo obrigatório"),
  cep: yup
    .string()
    .test("is-complete-cep", "Preencha o CEP completo.", value => {
      if (value?.match(/-/g)) return value?.length === 9
      if (!value?.match(/-/g)) return value?.length === 8
    })
    .transform((curr, orig) => (orig === "" ? null : curr))
    .nullable()
    .required("CEP é um campo obrigatório"),
})

export default schema;
