import { useState } from 'react';

const DEFAULT_INICIALIZATION = {
  page: 1,
  pageSize: 10,
  query: {},
  totalItens: 0,
  totalPages: 0,
  doRequest: true,
};

const usePaginator = (config = {}) => {
  const [paginator, setPaginator] = useState({
    ...DEFAULT_INICIALIZATION,
    ...config,
  });

  const buildQuery = () => {
    return `?Page=${paginator.page}&PageSize=${paginator.pageSize
      }${__queryBuilder(paginator.query)}`;
  };

  const setOrder = (orderBy, direction = true) => {
    setPaginator((state) => ({
      ...state,
      query: { ...state.query, Sort: orderBy, Desc: !direction },
      doRequest: true,
    }));
  };

  const setFilters = (filters) => {
    setPaginator((state) => ({
      ...state,
      page: 1,
      query: { ...state.query, ...filters },
      doRequest: true,
    }));
  };

  const overrideFilters = (filters) => {
    setPaginator((state) => ({
      ...state,
      page: 1,
      query: filters,
      doRequest: true,
    }));
  };

  const resetFilters = () => {
    setPaginator((state) => ({ ...state, query: {}, doRequest: true }));
  };

  const setPage = (wantedPage) => {
    setPaginator((state) => ({ ...state, page: wantedPage, doRequest: true }));
  };

  const setPageSize = (newSize) => {
    setPaginator((state) => ({
      ...state,
      page: 1,
      pageSize: newSize,
      doRequest: true,
    }));
  };

  const updatePaginator = (newPaginator) => {
    setPaginator((state) => ({ ...state, ...newPaginator, doRequest: false }));
  };

  const refresh = () => {
    setPaginator((state) => ({ ...state, doRequest: true }));
  };

  const preventRefresh = () => {
    setPaginator((state) => ({ ...state, doRequest: false }));
  };

  const __queryBuilder = (queryObject) => {
    if (!queryObject) return '';
    let query = '';
    for (const attribute in queryObject) {
      if (!queryObject[attribute] && queryObject[attribute] !== 0) continue;
      query += `&${attribute}=${queryObject[attribute]}`;
    }
    return query;
  };

  return {
    data: paginator,
    buildQuery,
    refresh,
    preventRefresh,
    setFilters,
    resetFilters,
    setOrder,
    setPage,
    setPageSize,
    overrideFilters,
    updatePaginator,
  };
};

export default usePaginator;
