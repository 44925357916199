import { useState } from "react"
import { CompanyRatesData } from "./utils/interfaces/ICompany"
import { TTermPaymentRate } from "./utils/interfaces/TTermPaymentRate"

export interface CompanyRatesDataTracking extends CompanyRatesData {
  '7days': string,
  '14days': string,
  '21days': string,
  '28days': string,
  shouldSaveAllowFinancedSales?: boolean
  shouldDeleteRates?: boolean
}

type ModificationTrack<T = unknown,> = {
  value?: T | null,
  saveable?: boolean,
  cancelable?: boolean,
  isEditing?: boolean,
  isOpenField?: boolean
}

// In case a new form is added, we should add a new item here to handle its modifications
type Modifications = {
  defaultFinancedRates: ModificationTrack<TTermPaymentRate[]>,
  companiesConfiguredRates: ModificationTrack<CompanyRatesDataTracking[]>,
  allowFinancedSales: ModificationTrack<boolean>,
}

export type TargetModification = keyof Modifications
export type ModificationStatus = 'canceled' | 'pending' | 'succeed' | 'notSet'

type SetModificationFunction = (params: { target: TargetModification, modification: ModificationTrack, override?: boolean }) => void

export interface IUseModificationTrackerReturn {
  modifications: Modifications,
  modificationStatus: ModificationStatus,
  isEditing: boolean,
  isSaveable: boolean,
  setModification: SetModificationFunction
  cancelAllModification: () => void,
  confirmAllModification: () => void,
}
const INITIAL_MODIFICATION_VALUE: ModificationTrack<null> = {
  value: null,
  saveable: true,
  cancelable: true,
  isEditing: false,
}

const INITIAL_MODIFICATIONS: Modifications = {
  allowFinancedSales: INITIAL_MODIFICATION_VALUE,
  companiesConfiguredRates: INITIAL_MODIFICATION_VALUE,
  defaultFinancedRates: INITIAL_MODIFICATION_VALUE,
}

export const useModificationTracker = (): IUseModificationTrackerReturn => {

  const [modifications, setModifications] = useState<Modifications>(INITIAL_MODIFICATIONS)
  const [modificationStatus, setModificationStatus] = useState<ModificationStatus>('notSet')

  // Private methods
  const __checkForModificationProperty = ({ property, modifications, mode = 'some' }: { property: keyof ModificationTrack, modifications: Modifications, mode?: 'some' | 'every' }) => {
    const itemsKeys = Object.keys(modifications)
    const hasValues = itemsKeys[mode](itemKey => {
      const value = modifications[itemKey][property]
      return (value && value !== null && value !== undefined) || typeof value === 'boolean'
    })
    return hasValues
  }
  const isEditing = __checkForModificationProperty({ property: "isEditing", modifications })
  const isSaveable = __checkForModificationProperty({ property: "saveable", modifications, mode: "every" })

  const __checkPendingChanges = ({ modifications }: { modifications: Modifications }): boolean =>
    __checkForModificationProperty({ property: "value", modifications })

  const __resetModifications = (status: ModificationStatus) => {
    setModifications(INITIAL_MODIFICATIONS)
    setModificationStatus(status)
  }
  // Public methods
  const setModification: SetModificationFunction = ({ target, modification, override = false }) => {
    setModifications(modifications => {
      const modificationNewValues = override ? modification : { ...modifications[target], ...modification }
      const newModifications = { ...modifications, [target]: modificationNewValues }
      const hasChanging = __checkPendingChanges({ modifications: newModifications })
      if (hasChanging && modificationStatus !== "pending") setModificationStatus("pending")
      else if (!hasChanging && modificationStatus !== "notSet") setModificationStatus("notSet")
      return (newModifications)
    })
  }

  const cancelAllModification = () => __resetModifications('canceled')
  const confirmAllModification = () => __resetModifications('succeed')

  return {
    modifications,
    modificationStatus,
    isEditing,
    isSaveable,
    setModification,
    cancelAllModification,
    confirmAllModification,
  }
}