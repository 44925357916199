import { useEffect, useRef, useState } from 'react';
import { FilterModal } from 'presentation/compositions';
import { TbFilter } from 'react-icons/tb';
import Button from 'presentation/components/buttonV2';
import usePaginator from 'main/hooks/usePaginator';
import useService from 'main/hooks/useService';
import ResaleStatus from './resaleStatus';
import { RESALES_STATUS } from 'main/utils/constants';
import { applyMask } from 'main/utils/functionUtils';
import { Hidden } from 'react-grid-system';
import { Label } from './styles';
import { operatorsStatus } from 'main/utils/options';
import history from '@/services/history';
import routesPaths from 'presentation/routes/routesPaths';
import {
  Content,
  DotMenu,
  Grid,
  Input,
  Line,
  PageHeader,
  Pagination,
  Panel,
  Search,
  Table,
} from 'presentation/components';
import { getResales } from '@/services/resalesServices';
import { ModalWaitingRegister } from '../modals/modalWaitingRegister';

const ListResales = () => {
  const paginator = usePaginator();
  const [operators, setOperators] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isContinueOpen, setIsContinueOpen] = useState(false);
  const selectedFilterStatus = useRef();
  const resaleToContinue = useRef(null);
  const [fetchOperatorsState, fetchOperatorsRequest] = useService(
    getResales,
    {
      onCompleted: (response) => {
        setOperators(response.data.data);
        paginator.updatePaginator({
          totalItens: response.data.totalItens,
          totalPages: response.data.totalPages,
        });
      },
    }
  );

  useEffect(() => {
    if (paginator.data.doRequest) {
      fetchOperatorsRequest(paginator.buildQuery());
      paginator.preventRefresh();
    }
    // eslint-disable-next-line
  }, [paginator]);

  const onRowClick = (resale) => {
    if (resale.status === RESALES_STATUS.PENDING) {
      resaleToContinue.current = resale;
      setIsContinueOpen(true);
    }
    if (
      resale.status === RESALES_STATUS.ACTIVE ||
      resale.status === RESALES_STATUS.INACTIVE ||
      resale.status === RESALES_STATUS.WAITING_PUBLISH
    ) {
      history.push({
        pathname: routesPaths.resales.visualize,
        state: { resaleId: resale.id },
      });
    }
  };

  return (
    <Content>
      <Panel noPadding>
        <Grid padding="16px 16px 0 16px">
          <Grid.Row align="center" justify="between">
            <Grid.Col md={12} lg="content">
              <PageHeader>Revendas</PageHeader>
            </Grid.Col>
            <Hidden xs sm md lg>
              <Grid.Col />
            </Hidden>
            <Grid.Col sm={12} md={12} lg={4} xl={3}>
              <Search
                search={(value) =>
                  paginator.setFilters({
                    filterValue: value
                      .replaceAll('.', '')
                      .replaceAll('/', '')
                      .replaceAll('-', ''),
                    filterProps: 'cnpj,tradeName,code',
                  })
                }
                placeholder="Buscar Revendas"
              />
            </Grid.Col>
            <Grid.Col
              xs={12}
              sm={6}
              md={6}
              lg={2}
              marginsFor={['xs', 'sm', 'md']}
            >
              <Button
                onClick={() => setIsFilterOpen(true)}
                icon={<TbFilter />}
                variant="ghost"
                theme={{
                  bg: '#F76C39',
                  hoverGhost: '#F76C390D',
                }}
              >
                Filtrar
              </Button>
            </Grid.Col>
            <Grid.Col
              xs={12}
              sm={6}
              md={6}
              lg={3}
              xl={2}
              marginsFor={['xs', 'sm', 'md']}
            >
              <Button
                onClick={() =>
                  history.push(routesPaths.resales.checkCnpjForRegister)
                }
              >
                Nova Revenda
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
        <Hidden xs sm md>
          <Line overflow />
        </Hidden>
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <Table visibleRows={10} loading={fetchOperatorsState.loading}>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th
                      orderable
                      onArrowUpClick={() => paginator.setOrder('code')}
                      onArrowDownClick={() =>
                        paginator.setOrder('code', false)
                      }
                    >
                      Código da Revenda
                    </Table.Th>
                    <Table.Th
                      orderable
                      onArrowUpClick={() => paginator.setOrder('tradeName')}
                      onArrowDownClick={() =>
                        paginator.setOrder('tradeName', false)
                      }
                    >
                      Nome
                    </Table.Th>
                    <Table.Th
                      orderable
                      onArrowUpClick={() => paginator.setOrder('cnpj')}
                      onArrowDownClick={() =>
                        paginator.setOrder('cnpj', false)
                      }
                    >
                      CNPJ
                    </Table.Th>
                    <Table.Th
                      orderable
                      onArrowUpClick={() => paginator.setOrder('status')}
                      onArrowDownClick={() =>
                        paginator.setOrder('status', false)
                      }
                    >
                      Status
                    </Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {operators.map((resale) => (
                    <Table.Tr
                      key={resale.id}
                      interactable
                      onClick={() => onRowClick(resale)}
                    >
                      <Table.Td>{resale.code}</Table.Td>
                      <Table.Td>{resale.tradeName}</Table.Td>
                      <Table.Td>
                        {applyMask('##.###.###/####-##', resale.cnpj)}
                      </Table.Td>
                      <Table.Td>
                        <ResaleStatus status={resale.status}>
                          {RESALES_STATUS[resale.status]}
                        </ResaleStatus>
                      </Table.Td>
                      <Table.Td onClick={(e) => e.stopPropagation()}>
                        <DotMenu>
                          {resale.status !== RESALES_STATUS.PENDING &&
                            <DotMenu.Option onClick={() => onRowClick(resale)}>Editar</DotMenu.Option>
                          }
                        </DotMenu>
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </Grid.Col>
          </Grid.Row>
        </Grid>
        <Hidden xs sm md>
          <Line overflow />
        </Hidden>
        <Grid>
          <Grid.Row align="center">
            <Grid.Col>
              <Pagination.ResultAmount
                paginator={paginator.data}
                setPageSize={(newPageSize) =>
                  paginator.setPageSize(newPageSize)
                }
              />
            </Grid.Col>
            <Grid.Col sm="content">
              <Pagination
                paginator={paginator.data}
                setPage={(wantedPage) => paginator.setPage(wantedPage)}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Panel>
      <FilterModal
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        filter={() =>
          paginator.setFilters({ status: selectedFilterStatus.current })
        }
        clearFilter={() => paginator.resetFilters()}
      >
        <Grid padding="0">
          <Grid.Row>
            <Grid.Col>
              <Label>Filtrar status por</Label>
              <Input.Select
                onChange={(option) =>
                  (selectedFilterStatus.current = option?.value)
                }
                placeholder="Definir Status"
                defaultValue={operatorsStatus.find(
                  (option) => option.value === selectedFilterStatus.current
                )}
                options={operatorsStatus}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </FilterModal>

      <ModalWaitingRegister
        open={isContinueOpen}
        onClose={() => setIsContinueOpen(false)}
        resaleId={resaleToContinue.current?.id}
      />
    </Content>
  );
};

export default ListResales;
