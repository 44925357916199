import styled from "styled-components";

export const Form = styled.form`
  span > label,
  > div > div > div> label {
    display: inline-block;
    margin-bottom: 16px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  & > :first-child{
    margin-right: 8px;
  }
`;

export const LinkButton = styled.button`
  display: block;
  border: none;
  outline: none;
  background: none;
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
  font-size: 1rem;
  color: var(--color-primary-01);
  cursor: pointer;
`;