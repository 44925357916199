import * as yup from "yup";

import { validateCPF } from "validations-br";

const compositeNameRegex = /\w{2,}\s\w{2,}/;

const checkIfPhoneIsCompleate = (value) => {
  return value.replace(/\(|\)| |-/g, "").length >= 10
}

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(compositeNameRegex, {
      message: "Inserir o nome completo",
      excludeEmptyString: true,
    })
    .max(130, "Campo limitado a 130 caracteres")
    .required("Nome é um campo requerido"),
  cpf: yup.string()
    .when("$register", {
      is: true,
      then: yup
        .string()
        .required("CPF é um campo requerido")
        .test("is-cpf", "CPF invalido", (value) => validateCPF(value))
    }),
  phone: yup.string().required("Esse é um campo requerido")
    .when(
      "phone", {
      is: value => value?.length,
      then: yup
        .string()
        .test("is-phone-complete", "Insira o telefone completo", checkIfPhoneIsCompleate),
    }
    ),
  email: yup
    .string()
    .transform((curr, orig) => orig === '' ? null : curr)
    .nullable()
    .required("Email é um campo requerido")
    .matches(EMAIL_REGEX, "Insira um e-mail válido"),
  contactType: yup
    .number()
    .required("Tipo de contato é um campo requerido"),
},
  [
    ["phone", "phone"]
  ]);

export default schema;