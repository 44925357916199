import { Input, Modal, Text, Spinner, Grid, Dropzone } from "presentation/components";
import Button from "presentation/components/buttonV2";
import { Form, ModalLoadingWrapper, ModalTitle } from "./styles";
import useService from "main/hooks/useService";
import { useDispatch } from "react-redux";
import { pushNotification } from "@/store/modules/notification/actions";
import successHandler from "main/utils/successHandler";
import { putEditFiles } from "@/services/operatorsServices";
import { useEffect, useRef, useState } from "react";
import { NOTIFICATION_TYPE, OPERATOR_DOCUMENTS_TYPES } from "main/utils/constants";

const ONE_MB = 1000000;
const FilesModal = ({
  operator,
  onClose,
  open
}) => {

  const dispatch = useDispatch();
  const isDirty = useRef({
    employeeLayoutFile: false,
    orderLayoutFile: false,
    notes: false,
  });
  const [notes, setNotes] = useState(operator.documentNotes);
  const [files, setFiles] = useState({
    employeeLayoutFile: [],
    orderLayoutFile: []
  });

  const updateFilesState = (fileName, value) => {
    setFiles(state => {
      const newState = { ...state };
      newState[fileName] = value;
      return newState;
    });
  };

  const [editFilesState, editFilesRequest] = useService(putEditFiles, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler("Arquivo alterado(s)!")));
      onClose(true);
    },
  });

  useEffect(() => {
    const employeeLayoutFile = operator.documents.find(document =>
      document.type === OPERATOR_DOCUMENTS_TYPES.EMPLOYEE_LAYOUT
    );
    const orderLayoutFile = operator.documents.find(document =>
      document.type === OPERATOR_DOCUMENTS_TYPES.ORDER_LAYOUT
    );

    if (employeeLayoutFile)
      updateFilesState(
        "employeeLayoutFile",
        [new File(["123"], employeeLayoutFile.fileName)]
      );
    if (orderLayoutFile)
      updateFilesState(
        "orderLayoutFile",
        [new File([""], orderLayoutFile.fileName)]
      );
  }, [operator]);

  const handleOnDrop = async (e, targetFileName) => {
    updateFilesState(targetFileName, e);
    isDirty.current[targetFileName] = true;
  };

  function analyseError(file) {
    if (file.errors[0].code === 'file-invalid-type') {
      dispatch(pushNotification({
        type: NOTIFICATION_TYPE.WARNING,
        content: 'Permitido somente arquivos .TXT'
      }));
    }
    if (file.errors[0].code === 'file-too-large') {
      dispatch(pushNotification({
        type: NOTIFICATION_TYPE.WARNING,
        content: 'Não são permitidos arquivos maiores que 1MB'
      }));
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const isFieldDirty = isDirty.current;
    if (
      !isFieldDirty.employeeLayoutFile &&
      !isFieldDirty.orderLayoutFile &&
      !isFieldDirty.notes
    ) {
      onClose();
      return;
    }

    const payload = new FormData();
    payload.append('operatorId', operator.id);
    payload.append('notes', notes);
    if (isFieldDirty.employeeLayoutFile) payload.append('employeeLayoutFile', files.employeeLayoutFile[0]);
    if (isFieldDirty.orderLayoutFile) payload.append('orderLayoutFile', files.orderLayoutFile[0]);
    editFilesRequest(payload);
  };

  return (
    <Modal
      open={open}
      showCloseIcon={false}
      onClose={onClose}
    >
      <ModalTitle>Documentos</ModalTitle>
      <Form onSubmit={onSubmit} id="companyData">
        <Grid fluid padding="0" spaceBetweenRows="32px">
          {editFilesState.loading ? <ModalLoadingWrapper><Spinner /></ModalLoadingWrapper> :
            <>
              <Grid.Row>
                <Grid.Col xs={12}>
                  <Dropzone.Line2
                    accept={{ 'text/plain': ['.txt'] }}
                    formatsLabel="TXT"
                    onDrop={(e) => {
                      handleOnDrop(e, 'employeeLayoutFile');
                    }}
                    onDropRejected={(dropedFile) => analyseError(dropedFile[0], 1)}
                    maxSize={ONE_MB}
                    multiple={false}
                    uploadingFile={{ name: files.employeeLayoutFile[0]?.name }}
                    title="Envie um novo Layout do Colaborador.TXT"
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col xs={12}>
                  <Dropzone.Line2
                    accept={{ 'text/plain': ['.txt'] }}
                    formatsLabel="TXT"
                    onDrop={(e) => {
                      handleOnDrop(e, 'orderLayoutFile');
                    }}
                    onDropRejected={(dropedFile) => analyseError(dropedFile[0], 1)}
                    maxSize={ONE_MB}
                    multiple={false}
                    uploadingFile={{ name: files.orderLayoutFile[0]?.name }}
                    title="Envie um novo Layout do Pedido.TXT"
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={12}>
                  <Text.Label><strong>Observações</strong></Text.Label>
                  <Input.Textarea
                    placeholder="Espaço para observações."
                    value={notes || ""}
                    onChange={e => {
                      setNotes(e.target.value);
                      isDirty.current.notes = true;
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row justify="between">
                <Grid.Col sm="content">
                  <Button
                    onClick={onClose}
                    variant="text"
                  >Cancelar</Button>
                </Grid.Col>
                <Grid.Col sm="content">
                  <Button>Salvar</Button>
                </Grid.Col>
              </Grid.Row>
            </>
          }
        </Grid>
      </Form>
    </Modal >
  );
}

export default FilesModal;