interface ICloseFilterIcon {
  onClick: (e: any) => void
}

export const CloseFilterIcon = ({ onClick }: ICloseFilterIcon) => {
  return (
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        opacity="0.3"
        d="M8.71973 7.59896C8.9541 7.85938 8.9541 8.25 8.71973 8.48438C8.45931 8.74479 8.06868 8.74479 7.83431 8.48438L4.76139 5.38542L1.66243 8.48438C1.40202 8.74479 1.01139 8.74479 0.777018 8.48438C0.516602 8.25 0.516602 7.85938 0.777018 7.59896L3.87598 4.5L0.777018 1.40104C0.516602 1.14063 0.516602 0.75 0.777018 0.515625C1.01139 0.255209 1.40202 0.255209 1.63639 0.515625L4.76139 3.64063L7.86035 0.541667C8.09473 0.28125 8.48535 0.28125 8.71973 0.541667C8.98014 0.776042 8.98014 1.16667 8.71973 1.42708L5.62077 4.5L8.71973 7.59896Z"
        fill="#212121"
      />
    </svg>
  )
}
