import { styled } from "@mui/material";

export const FakeInput = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '12px 8px',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '4px',
  background: theme.palette.grey[100],
  color: theme.palette.grey[700],
  width: '96px',
}))

export const DaysRateContainer = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
  '& > div': {
    width: '96px'
  }
}))

export const FormFieldsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  overflow: 'hidden',
  transition: 'all .25s ease-in-out'
}))