import styled from 'styled-components';

export const PageName = styled.div`
  font-weight: var(--font-weight-semi-bold);
  font-size: 2rem;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PageHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 80px;
  width: fit-content;
  margin-bottom: 8px;
`;
