import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

interface IApiFile {
  fileName: string,
  linkExpiration: string,
  temporaryLink: string
  operatorId: string,
  type: string | number
}

interface IApiReturn {
  id: string,
  addresses: [
    {
      id: string,
      cep: string,
      state: string,
      city: string,
      neighborhood?: string,
      laneName: string,
      number: string | number,
      complement: string,
      addressType: string | number,
      name: string
    }
  ],
  contacts: [
    {
      id: string,
      name: string,
      phone: string,
      extension: string,
      email: string,
      role: string,
      contactType: string | number
    }
  ],
  buyingRules: [
    {
      id: string,
      type: string | number,
      minimumPurchase: number,
      maximumPurchase: number,
      value: number
    }
  ],
  documents: Array<IApiFile>,
  employeeLayoutDocument: IApiFile,
  orderLayoutDocument: IApiFile
  cnpj: string,
  corporateName: string,
  tradeName: string,
  stateRegistration: string,
  municipalRegistration: string,
  email: string,
  site: string,
  documentNotes: any,
  status: string | number,
  code: string | number
}

interface IUseOperator {
  operatorId: string
}

export const useOperator = ({ operatorId }: IUseOperator) => {
  const { data, ...rest } = useQuery<AxiosResponse<IApiReturn>>({
    enabled: Boolean(operatorId),
    queryKey: ['operator', operatorId],
    queryFn: () =>
      api.get(`/operators/${operatorId}/edit`),
  })

  return {
    operator: data?.data,
    ...rest,
  }
}
