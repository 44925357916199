import * as yup from "yup"

export const schema = yup.object({
  title: yup.string().required("Campo obrigatório"),
  description: yup.number().required("Campo obrigatório"),
  localAction: yup.number().required("Campo obrigatório"),
  franchiseMinimal: yup.number().required("Campo obrigatório"),
  valueType: yup.number().required("Campo obrigatório"),
  application: yup.number().required("Campo obrigatório"),
  calculus: yup.number().required("Campo obrigatório"),
})
