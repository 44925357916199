import React, { useRef, useState } from "react";
import _ from "lodash";
import { Grid, IconButton, Text } from "presentation/components";
import { AddEntrieTextButton, CategorieTitleWrapper, EntriesCounter, OperatorInformation } from "../styles";
import { CONTACT_TYPES, OPERATOR_CARD_PROCESS_TYPE } from "main/utils/constants";
import { returnValueOrMessageIfIsEmpty } from "main/utils/functionUtils";
import { FaPencilAlt, FaRegTrashAlt } from "react-icons/fa";
import useService from "main/hooks/useService";
import { pushNotification } from "@/store/modules/notification/actions";
import successHandler from "main/utils/successHandler";
import { useDispatch } from "react-redux";
import BindedCardsModal from "./editionModals/bindedCardsModal";
import { putEditResaleOperatorsCards } from "@/services/resalesServices";
import { Box, Checkbox, Divider } from "@mui/material";
import { ModalConfirmAllowBrokerOrder } from "../../modals/modalConfirmAllowBrokerOrder";

const emptyMessage = "Não informado";

const BindedCards = ({
  resaleData,
  isReviewing,
  onReview,
  onEdited = () => null,
}) => {

  const dispatch = useDispatch();
  const [isCardModalFormOpen, setIsCardModalFormOpen] = useState(false);
  const [isBrokerOderAllowed, setIsBrokerOderAllowed] = useState(resaleData?.allowBrokerOrder);
  const [isAllowBrokerOrderModalOpen, setIsAllowBrokerOrderModalOpen] = useState(false);
  const cardIndexToEdit = useRef(null);
  const isMultiLine = resaleData.operatorsData.length > 1;
  const [, deleteCardRequest] = useService(putEditResaleOperatorsCards, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler("Cartão removido")));
      onEdited();
    },
  });

  const deleteCard = (index) => {
    const newCards = resaleData.operatorsData;
    newCards.splice(index, 1);
    deleteCardRequest(resaleData.id, newCards);
  };

  const defineContactToEdit = (addressIndex) => {
    cardIndexToEdit.current = addressIndex;
    setIsCardModalFormOpen(true);
  }

  const cardsRender = (resaleData) => {
    const cardsToRender = resaleData.operatorsData;
    cardsToRender.sort((a, b) => {
      if (a.operatorName < b.operatorName) return -1;
      if (a.operatorName > b.operatorName) return 1;
      return 0;
    });
    if (!cardsToRender) return;
    return cardsToRender.map((card, index) => (
      <React.Fragment key={_.uniqueId()}>
        {isMultiLine ?
          <Grid.Row>
            <Grid.Col>
              <EntriesCounter>
                <Text.SubtitleTwo>{index + 1}.</Text.SubtitleTwo>
                {isReviewing ? null :
                  <>
                    <IconButton onClick={() => defineContactToEdit(index)}><FaPencilAlt /></IconButton>
                    {card.contactType !== CONTACT_TYPES.MAIN &&
                      <IconButton onClick={() => deleteCard(index)}><FaRegTrashAlt /></IconButton>
                    }
                  </>
                }
              </EntriesCounter>
            </Grid.Col>
          </Grid.Row> : null
        }
        <Grid.Row >
          <Grid.Col xs={12} sm={6} md={3} lg={3}>
            <Text.SubtitleTwo>Operadora</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(card.operatorName, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs"]}>
            <Text.SubtitleTwo>Cartão</Text.SubtitleTwo>
            <OperatorInformation>{returnValueOrMessageIfIsEmpty(card.operatorCardName, emptyMessage)}</OperatorInformation>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3} lg={3} marginsFor={["xs", "sm"]}>
            <Text.SubtitleTwo>Situação</Text.SubtitleTwo>
            <OperatorInformation>
              {returnValueOrMessageIfIsEmpty(OPERATOR_CARD_PROCESS_TYPE[card.processType], emptyMessage)}
            </OperatorInformation>
          </Grid.Col>
        </Grid.Row>
      </React.Fragment>
    ));
  };

  const onCheckAllowBrokerChange = () => {
    setIsAllowBrokerOrderModalOpen(true);
  };
  return (
    <>
      <Grid.Row>
        <Grid.Col xs={12} >
          <CategorieTitleWrapper>
            <Text.Subtitle>Cartões Vinculados</Text.Subtitle>
            {(!isMultiLine || isReviewing) ?
              <IconButton onClick={() => isReviewing ? onReview() : defineContactToEdit(0)}><FaPencilAlt /></IconButton> : null
            }
          </CategorieTitleWrapper>
        </Grid.Col>
      </Grid.Row>
      {cardsRender(resaleData)}
      {isReviewing ? null :
        <Grid.Row>
          <Grid.Col xs={12}>
            <AddEntrieTextButton onClick={() => setIsCardModalFormOpen(true)}>Adicionar cartão</AddEntrieTextButton>
          </Grid.Col>
        </Grid.Row>
      }
      <Divider sx={{ marginBottom: "32px" }} />
      <Text.Subtitle>Pedido 1º e 2º via de cartão</Text.Subtitle>
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px", marginTop: "24px" }}>
        <Checkbox
          checked={isBrokerOderAllowed}
          onChange={onCheckAllowBrokerChange}
          disableRipple
          sx={{ height: "24px", width: "24px" }}
        />
        <Text>Permitir solicitar 1º e 2º via de cartão com situação Broker</Text>
      </Box>
      {isCardModalFormOpen &&
        <BindedCardsModal
          open={isCardModalFormOpen}
          cardIndex={cardIndexToEdit.current}
          resaleData={resaleData}
          onClose={(hasEdited) => {
            setIsCardModalFormOpen(false);
            cardIndexToEdit.current = null;
            hasEdited === true && onEdited();
          }}
        />
      }
      {isAllowBrokerOrderModalOpen ?
        <ModalConfirmAllowBrokerOrder
          resale={resaleData}
          open={isAllowBrokerOrderModalOpen}
          onConfirm={setIsBrokerOderAllowed}
          isBrokerOderAllowed={isBrokerOderAllowed}
          onClose={() => { setIsAllowBrokerOrderModalOpen(false); }}
        /> : null
      }
    </>
  );
};

export default BindedCards;