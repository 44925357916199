import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import notifications from './notification/sagas';
import operatorsReview from './finishOperators/sagas';
import operatorCard from './cards/sagas';
import operatorsCardsFees from './operatorsCardsFees/sagas';
import resalesCardsFees from './resalesCardsFees/sagas';
import fees from './fees/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    user,
    notifications,
    operatorCard,
    operatorsCardsFees,
    operatorsReview,
    fees,
    resalesCardsFees,
  ]);
}
