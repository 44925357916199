import { Modal, Spinner, Grid, Dropzone, ErrorWrapper, ErrorMessage, Text, Input } from "presentation/components";
import Button from "presentation/components/buttonV2";
import { Form, ModalLoadingWrapper, ModalTitle } from "./styles";
import useService from "main/hooks/useService";
import { useDispatch } from "react-redux";
import { pushNotification } from "@/store/modules/notification/actions";
import { useEffect, useRef, useState } from "react";
import { NOTIFICATION_TYPE } from "main/utils/constants";
import { putEditResaleDocuments } from "@/services/resalesServices";
import { useForm, Controller } from "react-hook-form";
import { Label } from "@stationkim/components-lib";
import InputDate from "presentation/components/input/date";
import successHandler from "main/utils/successHandler";

const ComercialDataModal = ({
  resaleData,
  onClose,
  open
}) => {

  const uploadedData = useRef({});
  const fileChangesCounter = useRef(0);

  const [uploadingFile, setUploadingFile] = useState([]);

  const [documentsState, documentsRequest] =
    useService(putEditResaleDocuments, {
      onCompleted: () => {
        dispatch(pushNotification(successHandler(`Dados comerciais alterados com sucesso!`)));
        onClose(true)
      }
    });
  const {
    control,
    register,
    formState: { errors, isDirty },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: resaleData
  });

  const initialDate = watch('document.startOfValidity');
  const dispatch = useDispatch();

  function handleDrop(file) {
    if (file.length < 1) return;
    setUploadingFile(file[0]);
    fileChangesCounter.current += 1;
  };

  const validateFile = () => {
    if (!uploadingFile) {
      dispatch(pushNotification({
        type: NOTIFICATION_TYPE.WARNING,
        content: "Para prosseguir envie o arquivo."
      }));
      return false;
    }
  };

  useEffect(() => {
    if (resaleData.document?.fileName) {
      const file = new File([""], resaleData.document?.fileName);
      setUploadingFile(file);
    };
  }, []);

  const onSubmit = (data) => {
    if (isDirty || fileChangesCounter.current !== 0) {
      if (!validateFile()) {
        const {
          startOfValidity,
          endOfValidity,
          notes
        } = data.document;

        const payload = new FormData();

        payload.append("File", uploadingFile);
        payload.append("startOfValidity",
          typeof startOfValidity === 'string'
            ? startOfValidity : startOfValidity.toISOString()
        );
        payload.append("endOfValidity",
          typeof endOfValidity === 'string'
            ? endOfValidity : endOfValidity.toISOString()
        );
        payload.append("notes", notes);
        uploadedData.current = Object.fromEntries(payload);
        documentsRequest(resaleData.id, payload);
      };
    } else {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      showCloseIcon={false}
      onClose={onClose}
    >
      <ModalTitle>Dados Comerciais</ModalTitle>
      <Form onSubmit={handleSubmit(onSubmit)} id="companyData">
        <Grid fluid padding="0" spaceBetweenRows="32px">
          {documentsState.loading ? <ModalLoadingWrapper><Spinner /></ModalLoadingWrapper> :
            <>
              <Grid.Row>
                <Grid.Col>
                  <Text.Subtitle>Cópia Contratual</Text.Subtitle>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col md={12}>
                  <Dropzone.Line2
                    accept={{
                      'text/pdf': ['.pdf'],
                    }}
                    onDrop={handleDrop}
                    formatsLabel="pdf"
                    multiple={false}
                    maxSize={5000000}
                    uploadingFile={uploadingFile}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Text.Subtitle>Período de Vigência</Text.Subtitle>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row align="start">
                <Grid.Col md={6}>
                  <ErrorWrapper error={errors?.document?.startOfValidity}>
                    <Label style={{ display: 'block', marginBottom: '8px' }}>De</Label>
                    <Controller
                      control={control}
                      name="document.startOfValidity"
                      render={({ field: { onChange, value, ref } }) => (
                        <InputDate
                          value={new Date(value)}
                          onChange={onChange}
                          getInputRef={ref}
                        />
                      )}
                    />
                    {errors?.document?.startOfValidity && (
                      <ErrorMessage>{errors?.document?.startOfValidity.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
                <Grid.Col md={6}>
                  <ErrorWrapper error={errors?.document?.endOfValidity}>
                    <Label style={{ display: 'block', marginBottom: '8px' }}>Até</Label>
                    <Controller
                      control={control}
                      name="document.endOfValidity"
                      render={({ field: { onChange, value, ref } }) => (
                        <InputDate
                          value={new Date(value)}
                          onChange={onChange}
                          getInputRef={ref}
                          minDate={new Date(initialDate)}
                        />
                      )}
                    />
                    {errors?.document?.endOfValidity && (
                      <ErrorMessage>{errors?.document?.endOfValidity.message}</ErrorMessage>
                    )}
                  </ErrorWrapper>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Text.Subtitle>Observações</Text.Subtitle>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col md={12}>
                  <Input
                    {...register("document.notes")}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row justify="end">
                <Grid.Col sm="content">
                  <Button
                    variant="text"
                    onClick={onClose}
                  >
                    Cancelar
                  </Button>
                </Grid.Col>
                <Grid.Col sm="content">
                  <Button >
                    Continuar
                  </Button>
                </Grid.Col>
              </Grid.Row>
            </>
          }
        </Grid>
      </Form>
    </Modal >
  );
}

export default ComercialDataModal;