import { Col, Container, Row } from 'react-grid-system';
import { ButtonWrapper } from '../../styles';
import React, { useState } from 'react';
import history from '@/services/history';
import {
  Content,
  Panel,
  PageHeader,
  Spacing,
  Button,
} from 'presentation/components';
import {
  getConfigurationFee,
  postConfigurationFee,
} from '@/services/resalesServices';
import TransferList from 'presentation/components/form/transferList';
import routesPaths from 'presentation/routes/routesPaths';

const SelectFee = () => {
  const entityId = '79638307-2d03-4780-8159-971b6a4c22a8';
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [rightLinked, setRightLinked] = useState([]);
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);

  React.useEffect(() => {
    getConfigurationFee(entityId).then((apiResponse) => {
      const { response } = apiResponse;
      const responseLeftFilter = response.data.filter(
        (element) => !element.obligatory && !element.assigned
      );
      const responseRightFilter = response.data.filter(
        (element) => !element.obligatory && element.assigned && element.status !== 3
      );

      const responseRightLinkedFeesFilter = response.data.filter(
        (element) => element.assigned || element.obligatory
      );

      setRight(responseRightFilter);
      setLeft(responseLeftFilter);
      setRightLinked(responseRightLinkedFeesFilter);
      setSpinner(true);
    });
  }, []);

  const props = {
    left,
    leftName: 'Taxas não vinculadas',
    setLeft,
    right,
    rightLinked,
    setRightLinked,
    rightName: 'Taxas vinculadas',
    setRight,
    displayName: 'name',
    loading,
    setLoading,
  };
  const filterFeeEntity = right.filter((e) => e.entityId === entityId);
  const filterLinkedFeeEntity = rightLinked.filter(
    (e) => e.entityId === entityId
  );

  const feeSubmit = () => {
    let feeIdList = [];
    filterFeeEntity.forEach((e) => {
      feeIdList.push(e.feeId);
    });
    filterLinkedFeeEntity.forEach((e) => {
      feeIdList.push(e.feeId);
    });

    const entitySubmit = {
      entityType: 1,
      entityId: entityId,
      feeIds: feeIdList,
    };

    postConfigurationFee(entitySubmit).then((e) => {
      history.push({
        pathname: routesPaths.resales.new_select_fee,
        state: { data: `${JSON.stringify(e)}` },
      });
    });
  };

  return (
    <Content>
      <Panel>
        <PageHeader>Nome da revenda</PageHeader>
      </Panel>
      {spinner && (
        <>
          <Spacing top="32px" />
          <Container style={{ padding: 0, maxWidth: 'auto' }}>
            <Row nogutter>
              <Col>
                <TransferList props={props} />
              </Col>
            </Row>
            <Row nogutter>
              <Col sm={12}>
                <ButtonWrapper>
                  <Button
                    variant="action-outlined"
                    onClick={() => history.push('/revendas')}
                  >
                    Cancelar
                  </Button>

                  <Button variant="action" onClick={() => feeSubmit()}>
                    Concluir
                  </Button>
                </ButtonWrapper>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Content>
  );
};

export default SelectFee;
