import api from "@/infra/api"
import { useQuery } from "@tanstack/react-query"
import { AxiosResponse } from "axios"


type Document = {
  id: string;
  fileName: string;
  keyStorage: string;
  startOfValidity: string;
  endOfValidity: string;
  notes: string;
  temporaryLink: string;
  linkExpiration: string;
};

type Address = {
  id: string;
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
  laneType: string;
  laneName: string;
  number: string;
  complement: string;
  addressName: string;
  addressType: number;
};

type Contact = {
  id: string;
  fullName: string;
  role: string;
  email: string;
  phone: string;
  extension: string;
  contactType: number;
};

type OperatorData = {
  id: string;
  user: string | null;
  operatorCardId: string;
  operatorCardName: string;
  active: boolean;
  operatorId: string;
  operatorName: string;
  processType: number;
};

interface IApiResponse {
  id: string;
  code: number;
  cnpj: string;
  corporateName: string;
  tradeName: string;
  phoneNumber: string;
  email: string;
  stateRegistration: string;
  municipalRegistration: string;
  currentTermVersion: number;
  linkedinLink: string;
  instagramLink: string;
  twitterLink: string;
  registrationStatus: number;
  status: number;
  active: boolean;
  allowBrokerOrder: boolean;
  allowFinancedSales: boolean;
  site: string;
  document: Document;
  addresses: Address[];
  contacts: Contact[];
  operatorsData: OperatorData[];
};

export interface IUseResaleDataReturn {
  resaleData: IApiResponse | null,
  isLoadingResaleData: boolean
}

export const useResale = ({ resaleId }: { resaleId: string }): IUseResaleDataReturn => {

  const { data, ...rest } = useQuery<AxiosResponse<IApiResponse>>({
    queryKey: ['resaleData', resaleId],
    queryFn: () =>
      api.get(`/resales/${resaleId}`),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })


  const organizeData = () => {
    const rawData = data?.data
    if (!rawData) return null
    return rawData
  }

  return {
    resaleData: organizeData(),
    isLoadingResaleData: rest.isFetching
  }
}