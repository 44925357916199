import api from "@/infra/api"
import { useQuery } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import { ICompanyRatesData } from "./utils/iCompanyRatesData"
import { useCallback, useMemo } from "react"

interface IApiResponse {
  data: ICompanyRatesData[]
  totalPages: number
  totalItens: number
  pageNumber: number
  pageSize: number
}

const getCompanyRates = (rates: ICompanyRatesData['companyInterestRates']) => {
  if (!rates) return {}
  return rates.reduce((final, actual) => {
    return { ...final, [actual.days + 'days']: String(actual.rate) }
  }, {})
}

export const useResaleCompanies = ({ resaleId }: { resaleId: string }) => {

  const { data, ...rest } = useQuery<AxiosResponse<IApiResponse>>({
    queryKey: ['companiesFinancedSales', resaleId],
    queryFn: () =>
      api.get(`/company?Active=true&ResaleId=${resaleId}&PageSize=${100000}`),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const organizeData = useCallback(() => {
    const rawData = data?.data?.data

    if (!rawData) return []

    const sortedDataByCreationDate = rawData.sort((a, b) => {
      return new Date(b.creationDate)?.getTime() - new Date(a.creationDate)?.getTime()
    })
    const organizedData = sortedDataByCreationDate.map(company => {
      return ({
        ...company,

        ...getCompanyRates(company.companyInterestRates)
      })
    })
    return organizedData
  }, [data])

  const organizedData = useMemo(() => {
    return organizeData()
  }, [organizeData])

  return {
    companies: organizedData,
    ...rest
  }
}