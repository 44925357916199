import { Grid } from "presentation/components";
import { StepDescription, StepTitle } from "./styles";
import { ResaleData } from "../../visualizeAndEdit/resaleData";
export const Review = ({ 
  resaleData, 
  isReviewing,
  setActiveStep,
  onCancel
}) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Col sm="content">
          <StepTitle>Revisão</StepTitle>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm="content">
          <StepDescription>
            Antes de concluir o Cadastro, por favor
            revise as informações da Revenda.
          </StepDescription>
        </Grid.Col>
      </Grid.Row>
      <ResaleData
        resaleData={resaleData}
        isReviewing={isReviewing}
        goToRegisterStep={step => setActiveStep(step)}
        onCancel={onCancel}
      />
    </Grid>
  )
};