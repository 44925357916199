import { ErrorWrapper, Grid, Spinner } from "presentation/components";
import { rules } from "./rules/contactsRules";
import { ErrorMessage } from "@hookform/error-message";
import { Button, Input, Label } from "@stationkim/components-lib";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { StepDescription, StepTitle } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef } from "react";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import Select from "presentation/components/input/select";
import { postRegisterResaleContacts, putEditResaleContacts } from "@/services/resalesServices";
import useService from "main/hooks/useService";
import { contactsTypes } from "main/utils/options";
import { CONTACT_TYPES } from "main/utils/constants";

const defaultFieldValues = {
  fullName: '',
  phone: '',
  email: '',
  contactType: 1,
  role: '',
  extension: '',
}

export const Contacts = ({
  resaleData,
  setResaleData,
  onCancel,
  goToNextStep,
  isEditing,
  isSkipingSave
}) => {

  const uploadedData = useRef({});
  const serviceToBeUsed = isEditing ?
    putEditResaleContacts : postRegisterResaleContacts;
  const [contactsState, contactsRequest] =
    useService(serviceToBeUsed, {
      onCompleted: () => {
        setResaleData(state => ({
          ...state,
          contacts: uploadedData.current,
        }));
        goToNextStep();
      }
    });

  const {
    control,
    register,
    formState: { errors, isDirty },
    handleSubmit,
    getValues
  } = useForm({
    mode: "onChange",
    defaultValues: resaleData
  });
  isSkipingSave(isEditing && isDirty);

  const {
    fields,
    append,
    remove,
    swap
  } = useFieldArray({
    control: control,
    name: 'contacts'
  });

  const isEmailOrPhoneRequired = (index) => {
    const formValues = getValues();
    const contacts = formValues.contacts[index];
    if (
      contacts.phone?.length > 0 ||
      contacts.email?.length > 0
    ) return true;
    return "Informe e-mail ou telefone"
  };

  const FormError = ({ name, fieldErrors }) => {
    if (!fieldErrors) return;
    return (
      <ErrorMessage
        errors={fieldErrors}
        name={name}
        render={({ message }) =>
          <Input.Message>{message}</Input.Message>
        }
      />
    );
  };

  const onSubmit = (data) => {
    if (isDirty) {
      uploadedData.current = data.contacts;
      contactsRequest(resaleData.id, data.contacts);
    } else if (!isDirty && isEditing) {
      goToNextStep();
    }
  };

  const renderArrayFields = () => {
    const fieldsToRender = fields.map((field, index) => {
      const fieldBaseName = `contacts.${index}.`;
      const fieldErrors = errors?.contacts?.[index];
      return (
        <React.Fragment key={field.id}>
          {index !== 0 ?
            <Grid.Row>
              <Grid.Col style={{ display: 'flex', gap: '16px', margin: '32px 0' }}>
                Informe o contato
                <FontAwesomeIcon
                  icon={faTrashCan}
                  style={{ cursor: 'pointer' }}
                  onClick={() => remove(index)}
                />
              </Grid.Col>
            </Grid.Row> : null
          }
          <Grid.Row align="start">
            <Grid.Col md={3.5}>
              <Input.Root
                state={fieldErrors?.fullName ? 'error' : null}
                full
              >
                <Input.Label>
                  Nome
                </Input.Label>
                <Input.Field>
                  <Input.Input
                    {...register(fieldBaseName + 'fullName', rules.fullName)}
                    maxLength={120}
                    placeholder="Nome"
                  />
                </Input.Field>
                <FormError fieldErrors={fieldErrors} name="fullName" />
              </Input.Root>
            </Grid.Col>
            <Grid.Col md={3.5}>
              <Input.Root
                state={fieldErrors?.email ? 'error' : null}
                full
              >
                <Input.Label>
                  E-mail corporativo
                </Input.Label>
                <Input.Field>
                  <Input.Input
                    {...register(fieldBaseName + 'email',
                      {
                        ...rules.email,
                        validate: {
                          ...rules.email.validate,
                          isRequired: () => isEmailOrPhoneRequired(index),
                        }
                      },
                    )}
                    placeholder="E-mail corporativo"
                  />
                </Input.Field>
                <FormError fieldErrors={fieldErrors} name="email" />
              </Input.Root>
            </Grid.Col>
            <Grid.Col md={3.5}>
              <Input.Root
                state={fieldErrors?.role ? 'error' : null}
                full
              >
                <Input.Label>
                  Cargo
                </Input.Label>
                <Input.Field>
                  <Input.Input
                    {...register(fieldBaseName + 'role', rules.role)}
                    placeholder="Cargo na empresa"
                    maxLength={70}
                  />
                </Input.Field>
                <FormError fieldErrors={fieldErrors} name="role" />
              </Input.Root>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row align="start">
            <Grid.Col md={3.5}>
              <Controller
                control={control}
                name={fieldBaseName + "phone"}
                rules={{
                  ...rules.phone,
                  validate: {
                    ...rules.phone.validate,
                    isRequired: () => isEmailOrPhoneRequired(index),
                  }
                }}
                render={({ field: { value, onChange } }) => (
                  <Input.Root
                    state={fieldErrors?.phone ? 'error' : null}
                    full
                  >
                    <Input.Label>
                      Telefone
                    </Input.Label>
                    <Input.Field>
                      <Input.Masked
                        mask={[
                          { mask: '(00) 0000-0000' },
                          { mask: '(00) 00000-0000' },
                        ]}
                        placeholder="DDD + N°"
                        value={value}
                        onAccept={unmaskedValue => onChange(unmaskedValue)}
                        unmask
                        lazy={value?.length === 0}
                      />
                    </Input.Field>
                    <FormError fieldErrors={fieldErrors} name="phone" />
                  </Input.Root>
                )}
              />
            </Grid.Col>
            <Grid.Col md={3.5}>
              <Input.Root
                state={fieldErrors?.extension ? 'error' : null}
                full
              >
                <Input.Label>
                  Ramal
                </Input.Label>
                <Input.Field>
                  <Input.Input
                    {...register(fieldBaseName + 'extension', rules.extension)}
                    placeholder="0000"
                    maxLength={5}
                  />
                </Input.Field>
                <FormError fieldErrors={fieldErrors} name="extension" />
              </Input.Root>
            </Grid.Col>
            <Grid.Col md={3.5}>
              <Controller
                control={control}
                name={fieldBaseName + 'contactType'}
                rules={rules.__contactTyp}
                render={({ field: { value, onChange } }) => (
                  <ErrorWrapper>
                    <Label style={{ display: 'block', marginBottom: "8px" }}>Tipo de Contato</Label>
                    <Select
                      isDisabled={index === 0}
                      isClearable={false}
                      defaultValue={1}
                      value={contactsTypes.find(option => option.value === value)}
                      onChange={option => onChange(option.value)}
                      placeholder="Selecione..."
                      options={contactsTypes}
                    />
                    {!fieldErrors ? null :
                      <ErrorMessage
                        errors={fieldErrors}
                        name={"contactType"}
                      />}
                  </ErrorWrapper>
                )}
              />
              <FormError fieldErrors={fieldErrors} name="contactType" />
            </Grid.Col>
          </Grid.Row>
        </React.Fragment>
      )
    });

    return fieldsToRender;
  };

  useEffect(() => {
    const mainContactIndex = fields.findIndex(field =>
      field.contactType === CONTACT_TYPES.MAIN
    );
    swap(0, mainContactIndex)
  }, [resaleData])

  useEffect(() => {
    if (fields.length === 0) append(defaultFieldValues);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        fluid
        padding="0"
      >
        <Grid.Row>
          <Grid.Col sm="content">
            <StepTitle>Informações de Contato</StepTitle>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col sm="content">
            <StepDescription>Informe o contato principal</StepDescription>
          </Grid.Col>
        </Grid.Row>

        {contactsState.loading ?
          <Spinner.Box >
            <Spinner style={{ margin: "32px auto" }} />
          </Spinner.Box>
          :
          <>
            {renderArrayFields()}

            <Grid.Row>
              <Grid.Col>
                <Button buttonType="borderless" onClick={() => append(defaultFieldValues)}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                  Adicionar contato
                </Button>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row justify="end">
              <Grid.Col sm="content">
                <Button buttonType="text"
                  onClick={e => {
                    e.preventDefault();
                    onCancel(true);
                  }}
                >Cancelar</Button>
              </Grid.Col>
              <Grid.Col sm="content">
                <Button>Continuar</Button>
              </Grid.Col>
            </Grid.Row>
          </>
        }
      </Grid>
    </form>
  );
};