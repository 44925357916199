import { useEffect, useState } from "react"


export const useFloatingComponent = ({ componentId }: { componentId: string }) => {
  const [isOutOfView, setIsOutOfView] = useState(true)

  const isElementoBVisible = () => {
    const component = document.getElementById(componentId)
    if (!component) return false

    const componentRect = component.getBoundingClientRect()
    return (
      componentRect.top >= 0 &&
      componentRect.left >= 0 &&
      componentRect.bottom <= window.innerHeight + componentRect.height &&
      componentRect.right <= window.innerWidth
    )
  }

  useEffect(() => {
    const handleScroll = () => {
      setIsOutOfView(!isElementoBVisible())
    }
    handleScroll()
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return {
    isOutOfView,
  }
}
