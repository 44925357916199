import { atom } from 'jotai'

export enum EMessageType {
  Success = 1,
  Error,
  Warning,
  Info,
}
export interface IMessage {
  type: EMessageType
  content: string
  title?: string
}

export const NotificationsStore = atom<Array<IMessage>>([])
